import React, {useEffect, useState} from 'react'
import classes from './ReportPage.module.css'
import SuccessMessage from '../common/SuccessMessage/SuccessMessage'
import ReportForm from './ReportForm/ReportForm'
import ReportChart from './ReportChart/ReportChart'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {
    GetChartInfoThunk,
    selectActivityReportsDates,
    selectActivityReportsDomain,
    setChartInfo
} from '../../store/reportReducer'
import {ReportTypeDataType} from '../../types/reportTypes'
import PageTitle from '../common/PageTitle/PageTitle'
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {PickerType} from "../../types/dateTypes";


const ReportPage: React.FC<{ reportTypeData: ReportTypeDataType | any }> = ({reportTypeData}) => {
    const dispatch = useAppDispatch()
    const [dateType, setDateType] = useState<PickerType>(reportTypeData.reportType === 'activityReportForm' ? 'currentWeek' : 'period')
    const [isLoading, setIsLoading] = useState(true)
    const activityReportsDates = useAppSelector(selectActivityReportsDates)
    const domainCode = useAppSelector(selectActivityReportsDomain)
    useEffect(() => {
        if(activityReportsDates.start_period !== null){
            dispatch(GetChartInfoThunk({reportTypes: reportTypeData.reportType, dateType: dateType, reportDates: activityReportsDates, domain_code: domainCode}))
            setIsLoading(true)
            return () => {
                dispatch(setChartInfo([]))
            }
        }
    }, [dispatch, reportTypeData, dateType, activityReportsDates, domainCode])
    return (
        <PageWithSidebar>
            <ReportPageComponent
                reportTypeData={reportTypeData}
                dateType={dateType}
                setDateType={setDateType}
                isLoading={isLoading}
                setIsLoading={(isLoading) => setIsLoading(isLoading)}
            />
        </PageWithSidebar>
    )
}

const ReportPageComponent: React.FC<{ reportTypeData: ReportTypeDataType | any, dateType: any, setDateType: any, isLoading: boolean, setIsLoading: (isLoading: boolean) => void }>
    = ({reportTypeData, dateType, setDateType, isLoading, setIsLoading}) => {
    const [isExported, setIsExported] = useState(false)

    return (
        <div className={`pageWrapper ${classes.wrapper}`}>
            <PageTitle
                title={reportTypeData.reportTitle}
                backLink='/control-panel'
                isBackButton={true}
                backLinktext={'Control Panel'}
                // top={'137px'}
                left={'35px'}
            />
            <div className={classes.contentWrapper}>
                <ReportForm
                    setIsExported={setIsExported}
                    reportTypeData={reportTypeData}
                    isFraud={false}
                    dateType={dateType}
                    setDateType={setDateType}
                />
                <ReportChart
                    reportTypeData={reportTypeData}
                    isFraud={false}
                    dateType={dateType}
                    isLoading={isLoading}
                    setIsLoading={(isLoading) => setIsLoading(isLoading)}
                />
            </div>

            <SuccessMessage
                text='Your report has been successfully exported'
                onOk={() => {
                    setIsExported(false)
                }}
                isOpen={isExported}
            />
        </div>
    )
}

export default ReportPage
