import React, {useMemo} from 'react';
import s from "./Properties.module.scss";
import checkIcon from './../../img/icons/check.png';
import {AllPropertyContact, ContactDiv} from "./PropertyListComponent";



type PropertyListComponentItemType = {
    index: number
    contact: AllPropertyContact
    handleClick: (contact: AllPropertyContact) => void
    contactDivs: ContactDiv[]
}


const PropertyListComponentItem = React.memo(({ index, contact, handleClick, contactDivs } : PropertyListComponentItemType) => {

    const onHandleClick = () => {
        handleClick(contact)
    }

    const selectedValues = useMemo(() => new Set(contactDivs.map(obj => obj.value)), [contactDivs]);

    const isItemSelected = selectedValues.has(contact.value);

    return (
        <div onClick={onHandleClick}>
            <div className={s.OfferListItem} style={{ backgroundColor: isItemSelected ? '#F9FAFB' : '#fff' }}>
                <div>
                    <span className={s.offerListName}>{contact.labelName}</span>
                    <span className={s.offerListEmail}>{contact.labelEmail}</span>
                </div>
                {isItemSelected && <img src={checkIcon} alt="checked" />}
            </div>
        </div>
    );
});

export default PropertyListComponentItem;