import axios from 'axios'
import moment from 'moment'
import {tokenDataHelper, userDataHelper} from './localStorageHelper'

declare global {
    interface Window {
        env: {
            CRYPTOR_KEY: string;
            API_DOMAIN_ADDR: string,
            OFFER_PROPERTIES_EXTERNAL_ADDR: string
        }
    }
}

const target = window.env && window.env.API_DOMAIN_ADDR

axios.interceptors.request.use(async (request: any) => {
    const {token, token_expiry_date} = tokenDataHelper.getTokenData()
    if (token && !request.url?.includes('/login/refresh')) {
        request.headers['Authorization'] = token
        const isTokenExpired = moment().isAfter(token_expiry_date)
        if (isTokenExpired) {
            userDataHelper.removeUserData()
            tokenDataHelper.removeTokenData()
            window.location.replace('/sign-in')
        }
    }
    return request
})

axios.defaults.baseURL = target

export default axios
