import {createSlice} from '@reduxjs/toolkit'


interface InitialStateType {

}

const initialState: InitialStateType = {

}

export const filterButtonsSlice = createSlice({
    name: 'filterButtons',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // builder


    }
})

// eslint-disable-next-line
export const {} = filterButtonsSlice.actions








export default filterButtonsSlice.reducer