import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    GetProtocols, onChangeProtocolsGridColumnsPosition,
    onChangeProtocolsGridColumnsWidth,
    onSetProtocolsLocalSortModel, selectProtocolsDefaultColumns,
    selectProtocolsGridColumns, selectProtocolsGridColumnsForFilters,
    selectProtocolsGridRows,
    selectProtocolsHiddenColumns,
    selectProtocolsShownColumns,
    selectProtocolsSortModel,
    setProtocolsGridColumnsForFilters
} from "../../store/protocolsReducer";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {selectUserData} from "../../store/userReducer";
import s from './Protocols.module.scss'
import PropertiesSearchInput from "../PropertiesCommonComponents/PropertiesSearchInput/PropertiesSearchInput";
import {DateRangePicker} from "rsuite";
import {selectCurrentScreenWidth} from "../../store/utilityReducer";
import {predefinedRanges} from "../../helpers/dateHelper";
import {endOfMonth, startOfMonth, subMonths} from "date-fns";
import {useGridApiRef} from "@mui/x-data-grid-pro";
import {GridSortItem} from "@mui/x-data-grid";
import {customSortFunction} from "../../helpers/sortingDataHelper";
import {RequirementType} from "../../types/parametersTypes";
import {debounce} from "../../customFunctions/debounceScreenHeightChange";
import {onSetReqGridHeight, ShownAndHiddenColumnsType} from "../../store/propertiesReducer";
import {GridColumns} from "../../types/commonTypes";
import {saveGridConfigs} from "../../commonGridFunctions/saveGridConfigs";
import {resetGridConfigs} from "../../commonGridFunctions/resetGridConfigs";
import {sessionStorageGridFilters} from "../../helpers/sessionStorageHelper";
import {
    onChangeColdOffersReqGridColumnsPosition,
    onChangeColdOffersReqGridColumnsWidth,
    onChangeReqGridColumnsPosition,
    onChangeReqGridColumnsWidth,
    onReqResetFiltersButtonsSelected,
    onSetFiltersForRequirementsGrid,
    onSetRequirements,
    onSetRequirementsColdOfferLocalSortModel,
    onSetRequirementsLocalSortModel,
    setRequirementsShownColumns
} from "../../store/requirementsReducer";
import {gridFiltering} from "../../customFunctions/gridFiltering";


const Protocols = () => {
    return (
        <PageWithSidebar>
            <ProtocolsPageComponent/>
        </PageWithSidebar>
    )
}

const ProtocolsPageComponent = () => {
    const rowsPerPageOptions = [100, 150, 200]
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1])
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [filtersShown, setFiltersShown] = useState(false)
    const apiRef = useGridApiRef();
    const columnsForFilters = useAppSelector(selectProtocolsGridColumnsForFilters)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const currentDate = useMemo(() => {
        return new Date()
    }, [])
    const dispatch = useAppDispatch()
    const defaultGridColumns = useAppSelector(selectProtocolsDefaultColumns)
    const endOfDay = useMemo(() => {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999)
    }, [currentDate])
    const [height, setHeight] = useState(window.innerHeight)
    const hiddenColumns = useAppSelector(selectProtocolsHiddenColumns)
    const [gridContentHeight, setGridContentHeight] = useState(height - 340)
    const [localSortModel, setLocalSortModel] = useState({})
    const [page, setPage] = React.useState<number>(1)
    const protocolsGridColumns = useAppSelector(selectProtocolsGridColumns)
    const protocolsGridRows = useAppSelector(selectProtocolsGridRows)
    const paginatedRows = protocolsGridRows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    const [selectedDateRange, setSelectedDateRange] = useState<[Date | null, Date | null]>(() => {
        const defaultStartDate = startOfMonth(subMonths(new Date(), 1))
        const defaultEndDate = endOfMonth(subMonths(new Date(), 1))
        return [defaultStartDate, defaultEndDate]
    })
    const sortModel = useAppSelector(selectProtocolsSortModel)
    const shownColumns = useAppSelector(selectProtocolsShownColumns)
    // eslint-disable-next-line
    const threeMonthsAgo = new Date(); // Get today's date
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // Set three months ago
    // eslint-disable-next-line
    const today = new Date(); // Get today's date
    const userData = useAppSelector(selectUserData)
    let columns = protocolsGridColumns.map((g: GridColumns) => {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }
    })

    useEffect(() => {
        dispatch(GetProtocols({
            p_sort_order: null,
            p_start_period: '',
            p_end_period:  '',
            p_search_value: null,
            p_agent_ref: Number(userData.user_ref),
            p_status: null,
            p_page: null,
            p_size: null
        }))
    }, [dispatch, userData.user_ref])

    useEffect(() => {
        const sortModelObject: any = {};
        for (const item of sortModel) {
            sortModelObject[item.field] = {
                field: item.field,
                sort: item.sort
            };
        }
        setLocalSortModel(sortModelObject)
    }, [sortModel])


    useEffect(() => {
            const filterColumnsFromSessionStorage = sessionStorageGridFilters.getProtocolsGridFilters()
            if (filterColumnsFromSessionStorage && filterColumnsFromSessionStorage.length) {
                dispatch(setProtocolsGridColumnsForFilters({
                    columns: protocolsGridColumns.map((g: GridColumns) => {

                        return {
                            title: g.COL_NAME,
                            headerName: g.COL_TITLE ?? '',
                            value: null,
                            condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: g.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }
                    })
                }))
                dispatch(onSetFiltersForRequirementsGrid({gridFilters: filterColumnsFromSessionStorage}))
            }
            dispatch(setProtocolsGridColumnsForFilters({
                columns: protocolsGridColumns.map((g: GridColumns) => {
                    return {
                        title: g.COL_NAME,
                        headerName: g.COL_TITLE ?? '',
                        value: null,
                        condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.COL_TYPE,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
    }, [dispatch, protocolsGridColumns])

    useEffect(() => {
        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getProtocolsGridFilters()
        if (filterColumnsFromSessionStorage) {
            gridFiltering(filterColumnsFromSessionStorage, defaultGridColumns, columnsForFilters, 'Protocols', dispatch)
        }
        // eslint-disable-next-line
    }, [dispatch, defaultGridColumns])

    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage - 340);
            dispatch(onSetReqGridHeight(heightForPage))
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [dispatch]);

    const handleSortModelChange = (newModel: GridSortItem[] | []) => {
        const newLocalModel: any = {...localSortModel}
        newModel.forEach((model: GridSortItem) => {
            newLocalModel[model.field] = model
        })
        setLocalSortModel(newLocalModel)
        const mergedArray = [...sortModel, ...newModel]
        // Find the index of the matching field object in the merged array
        const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);
        // Check if the sort value is different and update it
        if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
            // eslint-disable-next-line
            const updatedObject = {
                ...mergedArray[index],
                sort: newModel[0]?.sort
            }
            mergedArray[index] = updatedObject;
        }
        dispatch(onSetProtocolsLocalSortModel({sort_model: mergedArray}))
    }

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    const rows = customSortFunction(protocolsGridRows, sortModel)
        .map((item: RequirementType) => {
            const {INFORMER, ...rest} = item;
            return rest;
        })
        .filter((row: any) => {
            const entries = Object.entries(row);
            const useStartsWith = searchTerm.endsWith('%');
            const processedSearchTerm = (useStartsWith ? searchTerm.slice(0, -1) : searchTerm).toLowerCase();

            return entries.some(([key, value]) => {
                if (key !== 'MODIFIED_BY') {
                    const stringValue = String(value).toLowerCase();
                    return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(processedSearchTerm);
                }
                return false;
            });
        });


    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event)
    }

    const getRowClassName = (params: any) => {
        return (params.id % 2 === 0) ? 'even-row' : 'odd-row';
    };

    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const onSaveGridConfigs = () => {
        saveGridConfigs(shownColumns, hiddenColumns, protocolsGridColumns, localSortModel, userData, threeMonthsAgo, today, 'Protocols', dispatch)
    }
    const onResetConfigs = () => {
        resetGridConfigs(dispatch, userData, threeMonthsAgo, today, setSearchTerm, setLocalSortModel, "Protocols")
    }

    const onSetFiltersShown = () => {
        setFiltersShown(!filtersShown)
    }

    const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        onSetFiltersShown()
    }

    // We have custom column position in our state, so default function, provided MUI data grid doesn't fit our app. So we get the column order from state and then sort them in our state in redux
    const handleStateChange = (params: any) => {
        dispatch(onChangeProtocolsGridColumnsPosition({columns: params.columns.all}))
        dispatch(onChangeProtocolsGridColumnsWidth({columns: params.columns.lookup}))

    }

    // const onResetFilters = () => {
    //     dispatch(onSetRequirements({requirementsRows: defaultGridColumns}))
    //     const resetShownColumns = shownColumns.map((c: ShownAndHiddenColumnsType) => c && {
    //         ...c,
    //         isFilterSelected: false,
    //         isModalBtnOpened: false
    //     })
    //     dispatch(setRequirementsShownColumns({columns: resetShownColumns}))
    //     dispatch(onReqResetFiltersButtonsSelected())
    //     dispatch(onSetFiltersForRequirementsGrid({gridFilters: []}))
    //     sessionStorageGridFilters.removeRequirementsGridFilters()
    // }
    //
    // const onResetSorting = () => {
    //     if(currentGrid === 'Requirements'){
    //         dispatch(onSetRequirementsLocalSortModel({sort_model: []}))
    //         setLocalSortModel({})
    //     }
    //     else{
    //         dispatch(onSetRequirementsColdOfferLocalSortModel({sort_model: []}))
    //         setLocalColdOffersSortModel({})
    //     }
    // }


    return (
        <div className={s.grid}>
            <h1>Protocols</h1>
            <div style={{
                display: 'flex',
                alignItems: currentScreenWidth!! > 640 ? 'center' : 'flex-start',
                marginBottom: '10px',
                marginTop: currentScreenWidth!! > 640 ? '0' : '10px',
                flexDirection: currentScreenWidth!! > 640 ? 'row' : 'column'
            }}>
                <PropertiesSearchInput
                    searchTerm={searchTerm}
                    setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
                    handleSearchInputChange={handleSearchInputChange}
                    searchPlaceholder={'Search by #, Title ...'}
                />
                <div style={{
                    marginLeft: currentScreenWidth!! > 640 ? '30px' : '0',
                    marginBottom: '15px',
                    marginTop: '5px'
                }}>
                    {/* @ts-ignore */}
                    <DateRangePicker onChange={(value) => onDateChange(value)} ranges={predefinedRanges} placeholder="Start date - End date" style={{width: 280}} defaultCalendarValue={selectedDateRange} value={selectedDateRange}/>
                </div>
            </div>
        </div>
    );
};

export default Protocols;