import React from 'react';
import ActivitiesGridForm from "../ActivitiesGridForm/ActivitiesGridForm";
import {ReactComponent as FormHeaderIcon} from "../../img/icons/activity_form_icon.svg";
import s from './ActivityGridModal.module.scss'
import {onClearActivitiesFormFields, onSetActivitiesModalOpened} from "../../store/activitesGrid";
import {useAppDispatch} from "../../app/hooks";
const ActivitiesGridModal = () => {

    const dispatch = useAppDispatch()
    const onCloseActivityModal = () => {

        dispatch(onClearActivitiesFormFields())
        dispatch(onSetActivitiesModalOpened(false))
    }

    return (
        <div className={s.modal}>
            <div className={s.modal__inner}>
                <div className={s.modal__innerContent}>
                    <div className={s.modal__logo}>
                        <FormHeaderIcon/>
                    </div>
                    <button
                        type={'button'}
                        onClick={onCloseActivityModal}
                        className={s.closeModal}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1387_34908)">
                                <path
                                    d="M4.66667 3L3 4.66667L6.33333 8L3 11.3333L4.66667 13L8 9.66667L11.3333 13L13 11.3333L9.66667 8L13 4.66667L11.3333 3L8 6.33333L4.66667 3Z"
                                    fill="#000" fillOpacity="0.6"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1387_34908">
                                    <rect width="10" height="10" fill="white"
                                          transform="matrix(-1 0 0 -1 13 13)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                    <h1 className={s.modal__title}>Add Activity</h1>
                    <ActivitiesGridForm withButtons={true} editMode={false}/>
                </div>
            </div>
        </div>
    );
};

export default ActivitiesGridModal;