import React from 'react';

import UniversalInput from "./../../common/UniversalInput/UniversalInput";
import s from './../AddressFrom.module.scss'
import {ReactComponent as SearchIcon} from './../../../img/icons/searchOnMapIcon.svg'
import {Switch} from "antd";
interface FormInputsProps {
    formInputs: { inputName: string; inputValue: string }[];
    onInputChange: (inputName: string, inputValue: string) => void;
    onFindAddress: () => void
    isAddressFilled: boolean
    disabled: boolean
    isFreePosition: boolean
    showMainAddress?: boolean,
    mainAddress?: string
    isCoordinatesSearchEnabled: boolean
    onCoordinatesSwitchChange: (enabled: boolean) => void
}

const FormInputs: React.FC<FormInputsProps> = ({isCoordinatesSearchEnabled, onCoordinatesSwitchChange, formInputs, onInputChange, onFindAddress, isAddressFilled, disabled, isFreePosition, showMainAddress, mainAddress}) => {
    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        }}>
            {formInputs.filter((i: any) => i.inputName !== 'Main address').map((input, index) => {
                if(index === 0){
                    return (
                        <div style={{
                            width: '100%'
                        }}>
                            <UniversalInput
                                key={index}
                                type="text"
                                value={input.inputValue}
                                onChange={(event) => onInputChange(input.inputName, event.target.value)}
                                labelText={input.inputName}
                                withLabel
                                lineDirection="vertical"
                                className={s.formInput}
                                labelClassName={s.formLabel}
                            />
                        </div>
                    )
                }
                else if(index === 2){
                    return (
                        <div style={{
                            width: '208px'
                        }}>
                            <UniversalInput
                                key={index}
                                type="text"
                                value={input.inputValue}
                                onChange={(event) => onInputChange(input.inputName, event.target.value)}
                                labelText={input.inputName}
                                withLabel
                                lineDirection="vertical"
                                className={`${s.formInput} ${s.formInput_md}`}
                                labelClassName={s.formLabel}
                            />
                        </div>
                    )
                }
                else if(index === 3){
                    return (
                        <div style={{
                            width: '208px'
                        }}>
                            <UniversalInput
                                key={index}
                                type="text"
                                value={input.inputValue}
                                onChange={(event) => onInputChange(input.inputName, event.target.value)}
                                labelText={input.inputName}
                                withLabel
                                lineDirection="vertical"
                                className={`${s.formInput} ${s.formInput_md}`}
                                labelClassName={s.formLabel}
                                errorMessage={'This field is required'}
                            />
                            {
                                showMainAddress
                                &&
                                <p className={s.shownAddress}>Original City: {mainAddress}</p>
                            }
                        </div>
                    )
                }
                else if(index === 5){

                    return (
                        <div>
                            <UniversalInput
                                key={index}
                                type="text"
                                value={input.inputValue}
                                onChange={(event) => onInputChange(input.inputName, event.target.value)}
                                labelText={input.inputName}
                                withLabel
                                lineDirection="vertical"
                                className={`${s.formInput} ${s.formInput_mdSmaller}`}
                                labelClassName={s.formLabel}
                            />
                        </div>
                    )
                }
                else if(index > 5 && index <= 7){

                    return (
                        <div>
                            <UniversalInput
                                key={index}
                                type="text"
                                value={input.inputValue}
                                onChange={(event) => onInputChange(input.inputName, event.target.value)}
                                labelText={input.inputName}
                                withLabel
                                lineDirection="vertical"
                                className={`${s.formInput} ${s.formInput_sm}`}
                                labelClassName={s.formLabel}
                            />
                        </div>
                    )
                }
                else if(input.inputName === 'Coordinates'){
                    return (
                        <div style={{
                            width: '100%',
                            position: "relative"
                        }}>
                            <div className={s.positionSwitcher} style={{marginTop: '6px'}}>
                                <div className={s.positionSwitcher__content}>
                                    <p className={s.positionSwitcher__positionText}>Search by coordinates</p>
                                    <p className={s.positionSwitcher__abilityText}>Ability to manually enter coordinates and search a point on map by them</p>
                                </div>
                                <Switch checked={isCoordinatesSearchEnabled} onChange={onCoordinatesSwitchChange}/>
                            </div>
                            <UniversalInput
                                key={index}
                                type="text"
                                value={input.inputValue.trim()}
                                onChange={(event) => onInputChange(input.inputName, event.target.value)}
                                labelText={input.inputName}
                                disabled={!isCoordinatesSearchEnabled}
                                withLabel
                                lineDirection="vertical"
                                className={s.formInput}
                                labelClassName={s.formLabel}
                            />
                            <button
                                onClick={onFindAddress}
                                className={`${s.formInput__searchBtn}`}
                                type={'button'}
                            >
                                <SearchIcon/>
                            </button>
                        </div>

                    )
                }
                else{
                    return (
                        <div style={{
                            width: '100%'
                        }}>
                            <UniversalInput
                                key={index}
                                type="text"
                                value={input.inputValue}
                                onChange={(event) => onInputChange(input.inputName, event.target.value)}
                                labelText={input.inputName}
                                withLabel
                                lineDirection="vertical"
                                className={s.formInput}
                                labelClassName={s.formLabel}
                            />
                        </div>

                    )
                }

                }
            )}
        </div>
    );
};

export default FormInputs;