import { Button, InputNumber, Spin, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { beforeUpload, dummyRequest, getBase64, normFile } from '../../../helpers/fileHelpers'
import { GetDisclaimersThunk, selectDisclaimers, UpdateDisclaimersThunk } from '../../../store/parametersReducer'
import { DisclaimerType } from '../../../types/parametersTypes'
import PageTitle from '../../common/PageTitle/PageTitle'
import classes from './DisclaimerInfo.module.css'
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";


const DisclaimerInfo = () => {
  return (
      <PageWithSidebar>
        <DisclaimerInfoComponent />
      </PageWithSidebar>
  )
}

const DisclaimerInfoComponent = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(GetDisclaimersThunk())
  }, [dispatch])

  return (
    <div className='pageWrapper'>
      <PageTitle title='Disclaimer info' backLink='/parameters' backLinktext={'Parameters'} isBackButton={true}/>
      <DisclaimersTable />
    </div>
  )
}

const DisclaimersTable = () => {
  const dispatch = useAppDispatch()
  const disclaimersData = useAppSelector(selectDisclaimers)
  const [tableFormData, setTableFormData] = useState<DisclaimerType[]>([])
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (!!disclaimersData.length && !Object.keys(tableFormData).length) {
      setTableFormData(disclaimersData)
    }
  }, [disclaimersData, tableFormData])

  const handleChange = (bizShortLink: string, field: string, value: string | number) => {
    setTableFormData(tableFormData.map(item => 
      item.bizShortLink === bizShortLink ? {...item, [field]: String(value)} : item
    ))
  }

  const addLogo = async(file:any, bizShortLink: string) => {
    const fileBase64 = await getBase64(file.originFileObj) as string
    const fileBase64Formatted = fileBase64.split('base64,')[1]

    setTableFormData(tableFormData.map(item => 
      item.bizShortLink === bizShortLink 
        ? {...item, base64_logo: fileBase64Formatted, bizLogo: fileBase64Formatted}
        : item
    ))
  }
  const handleSave = () => {
    setIsSaving(true)
    dispatch(UpdateDisclaimersThunk(tableFormData))
      .then(() => setIsSaving(false))
  }
  return (
    <>
      {!!Object.keys(tableFormData)?.length ? (
        <>
          <div className={classes.tableWrapper} style={{gridTemplateColumns: `1.5fr repeat(${disclaimersData?.length}, 2fr)`}}>
            {columns.map((col, index) => (
              <div
                key={col}
                className={
                  index === 0 
                  ? classes.columnTitle
                  : `${classes.columnName} ${index === columns?.length - 1 ? classes.lastColumnName : ''}`
                }
              >
                {col}
              </div>
            ))}
            {Object.values(tableFormData).map((item, index) => {
              return(
                  <React.Fragment key={index}>
                    <div className={`${classes.columnTitle} ${index === Object.values(tableFormData)?.length - 1 ? classes.lastColumnTitle : ''}`}>
                      {item.bizShortLink}
                    </div>
                    <TextArea
                        value={item?.bizDomain}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none'}}
                        onChange={(e) => handleChange(item.bizShortLink, 'bizDomain', e.target.value)}
                    />
                    <TextArea
                        value={item?.additionalName}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'additionalName', e.target.value)}
                    />
                    <TextArea
                        value={item?.city}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'city', e.target.value)}
                    />
                    <TextArea
                        value={item?.companyName}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'companyName', e.target.value)}
                    />
                    <TextArea
                        value={item?.country}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'country', e.target.value)}
                    />
                    <TextArea
                        value={item?.masterLicensePartner}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'masterLicensePartner', e.target.value)}
                    />
                    <TextArea
                        value={item?.street}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'street', e.target.value)}
                    />
                    <InputNumber
                        className={classes.column}
                        min={0}
                        controls={false}
                        value={Number(item?.zipCode)}
                        onChange={(value) => handleChange(item.bizShortLink, 'zipCode', value!)}
                    />
                    <TextArea
                        value={item?.phone}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'phone', e.target.value)}
                    />
                    <TextArea
                        value={item?.www}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'www', e.target.value)}
                    />
                    <TextArea
                        value={item?.dataSecurityStatement}
                        className={classes.column}
                        style={{ height: '100%'}}
                        onChange={(e) => handleChange(item.bizShortLink, 'dataSecurityStatement', e.target.value)}
                    />
                    <TextArea
                        value={item?.dataSecurityUrl}
                        className={classes.column}
                        style={{ height: '100%'}}
                        onChange={(e) => handleChange(item.bizShortLink, 'dataSecurityUrl', e.target.value)}
                    />
                    <TextArea
                        value={item?.email}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'email', e.target.value)}
                    />
                    <TextArea
                        value={item?.imprint}
                        className={classes.column}
                        style={{ height: '100%' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'imprint', e.target.value)}
                    />
                    <TextArea
                        autoSize
                        value={item?.dataSecurityStatementCheckbox}
                        className={classes.column}
                        style={{ height: '100%', resize: 'none' }}
                        onChange={(e) => handleChange(item.bizShortLink, 'dataSecurityStatementCheckbox', e.target.value)}
                    />
                    <div
                        className={classes.column}
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                    >
                      <Upload
                          name='file'
                          onChange={(e) => normFile(e, addLogo, item.bizShortLink)}
                          showUploadList={false}
                          customRequest={dummyRequest}
                          beforeUpload={beforeUpload}
                      >
                        {item?.bizLogo?.length ? (
                            <img src={`data:image/png;base64, ${item?.bizLogo}`} alt='logo' style={{width: '100%', cursor:'pointer'}}/>
                        ) : (
                            <Button>Click to Upload</Button>
                        )}
                      </Upload>
                    </div>
                  </React.Fragment>
              )
            })}
          </div>
          <div className={classes.btnWrapper}>
            <Link to='/parameters'>
              <Button type='default'>
                Cancel
              </Button>
            </Link>
            <Button
              type='primary'
              onClick={handleSave}
              loading={isSaving}
            >
              Save
            </Button>
          </div>
        </>
      ) : (
        <Spin style={{margin: '0 auto'}} />
      )}
    </>
  )
}

const columns = [
  'BIZ_SHORT_LINK',
  'BIZ_DOMAIN',
  'ADDITIONALNAME',
  'CITY',
  'COMPANYNAME',
  'COUNTRY',
  'MASTERLICENCEPARTNER',
  'STREET',
  'ZIPCODE',
  'PHONE',
  'WWW',
  'DATASECURITYSTATEMENT',
  'DATASECURITYURL',
  'EMAIL',
  'IMPRINT',
  'DATASECURITYSTATEMENTCHECKBOX',
  'BIZ_LOGO',
]

export default DisclaimerInfo
