import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {GridColumns} from "../types/commonTypes";
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType, setAppStatus} from "./appStatusReducer";
import {gridApi} from "../app/api";
import moment from "moment";

interface InitialStateType {
    gridColumns: GridColumns[]
    gridRows: GridRows[]
    defaultGridData: GridRows[]
    defaultGridDataForPositions: GridRows[]
    agentReportNames: { value: string, label: string }[]
    agentReportDomains: { value: string | null, label: string | null }[]
    agentReportPositions: { value: string | null, label: string | null }[]
    agentReportAgentNames: { value: string | null, label: string | null }[]
    newAgentReportRank: AddAgentReportsRank
}

const initialState: InitialStateType = {
    gridColumns: [],
    gridRows: [],
    defaultGridData: [],
    agentReportNames: [],
    agentReportDomains:
        [
            {
                label: "CD",
                value: "CD"
            },
            {
                label: "OR",
                value: "OR"
            },
            {
                label: "RR",
                value: "RR"
            },
            {
                label: "RS",
                value: "RS"
            }
        ],
    agentReportPositions: [],
    agentReportAgentNames: [],
    newAgentReportRank: {
        agent_name: '',
        agent_ref: null,
        domain: '',
        order_number: null,
        position: '',
        report_name: '',
    },
    defaultGridDataForPositions: []
}

export const agentReportsSlice = createSlice({
    name: 'agentReports',
    initialState,
    reducers: {
        setAgentReportsGridRows: (state, action: PayloadAction<GridRows[]>) => {
            state.gridRows = action.payload.sort((a, b) => {
                const isASpecial = a.ORDER_NUMBER === null;
                const isBSpecial = b.ORDER_NUMBER === null;
                if (isASpecial && !isBSpecial) return 1;
                if (!isASpecial && isBSpecial) return -1;
                return 0;
            })
        },
        onChangeAgentReportRank: (state, action: PayloadAction<{ fieldName: string; fieldValue: string | number | boolean }>) => {
            const {fieldName, fieldValue} = action.payload;
            if (fieldName in state.newAgentReportRank) {
                state.newAgentReportRank[fieldName] = fieldValue;
            }
        },
        resetAgentReportRankFields: (state) => {
            state.newAgentReportRank = {
                agent_name: '',
                agent_ref: null,
                domain: '',
                order_number: null,
                position: '',
                report_name: '',
            }
        },
        onChangeAgentReportRankOrderNum: (state, action: PayloadAction<{ newOrderNum: number, rowId: number, oldOrderNum: number }>) => {
            let sortedByOrderNum = [...state.gridRows].sort((a, b) => a.ORDER_NUMBER - b.ORDER_NUMBER);
            for (let i = 0; i < sortedByOrderNum.length - 1; i++) {
                if (sortedByOrderNum[i].ORDER_NUMBER === sortedByOrderNum[i + 1].ORDER_NUMBER) {
                    for (let j = i + 1; j < sortedByOrderNum.length; j++) {
                        sortedByOrderNum[j] = {
                            ...sortedByOrderNum[j],
                            ORDER_NUMBER: sortedByOrderNum[j].ORDER_NUMBER + 1
                        };
                    }
                }
            }
            state.gridRows = sortedByOrderNum.map((obj) => {
                if (obj.id === action.payload.rowId) {
                    return {...obj, ORDER_NUMBER: action.payload.newOrderNum + 1};
                }
                if (action.payload.oldOrderNum < action.payload.newOrderNum) {
                    if (obj.ORDER_NUMBER > action.payload.oldOrderNum + 1 && obj.ORDER_NUMBER <= action.payload.newOrderNum + 1) {
                        return {...obj, ORDER_NUMBER: obj.ORDER_NUMBER - 1};
                    }
                } else if (action.payload.oldOrderNum > action.payload.newOrderNum) {
                    if (obj.ORDER_NUMBER >= action.payload.newOrderNum + 1 && obj.ORDER_NUMBER < action.payload.oldOrderNum + 1) {
                        return {...obj, ORDER_NUMBER: obj.ORDER_NUMBER + 1};
                    }
                }
                return obj;
            }).sort((a, b) => a.ORDER_NUMBER - b.ORDER_NUMBER);
        },
        onSetAgentReportRankData: (state, action: PayloadAction<GridRows[]>) => {
            state.gridRows = action.payload.map((a: any, index: number) => a && {
                ...a,
                ORDER_NUMBER: index + 1
            })
        },
        onSetAgentReportRankDataColumns: (state, action: PayloadAction<GridColumns[]>) => {
            state.gridColumns = action.payload
        },
        onSetAgentReportRankDataPositions: (state, action: PayloadAction<any[]>) => {

            state.agentReportPositions = action.payload
        },
        onSetAgentReportRankDataForPositions: (state, action: PayloadAction<GridRows[]>) => {
            // eslint-disable-next-line
            const agentReports = action.payload.map((innerArr: any, index: number) => {
                if (innerArr !== null) {
                    const obj: any = {};
                    innerArr.forEach((currentValue: any) => {
                        if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                            obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                        } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                            obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                        } else {
                            obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                        }

                    });
                    obj['id'] = index;
                    obj['isFiltered'] = false;
                    obj['ORDER_NUMBER'] = index + 1
                    return obj;
                }
            });
            state.defaultGridDataForPositions = agentReports.sort((a, b) => {
                const isASpecial = a.ORDER_NUMBER === null;
                const isBSpecial = b.ORDER_NUMBER === null;
                if (isASpecial && !isBSpecial) return 1;
                if (!isASpecial && isBSpecial) return -1;
                return 0;
            })
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAgentReportsGridData.fulfilled, (state, action) => {
                // eslint-disable-next-line
                const agentReports = action.payload.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['ORDER_NUMBER'] = index + 1
                        return obj;
                    }
                });
                state.gridRows = agentReports.sort((a, b) => {
                    const isASpecial = a.ORDER_NUMBER === null;
                    const isBSpecial = b.ORDER_NUMBER === null;
                    if (isASpecial && !isBSpecial) return 1;
                    if (!isASpecial && isBSpecial) return -1;
                    return 0;
                })
                state.agentReportNames = agentReports.map((a: GridRows) => {
                    return {label: a.REPORT_NAME, value: a.REPORT_NAME}
                }).filter((obj, index, self) =>
                    index === self.findIndex((t) => t.value === obj.value)
                )
                // @ts-ignore
                state.gridColumns = action.payload[0]

                // state.gridColumns = action.payload[0].concat({
                //     COL_NAME: "ACTIONS",
                //     COL_OPTIONS: null,
                //     COL_POS: 6,
                //     COL_TITLE: "Actions",
                //     COL_TYPE: null,
                //     COL_VALUE: null,
                //     COL_WIDTH: 100
                // })
                state.defaultGridData = agentReports
            })

    }
})

export const {
    setAgentReportsGridRows,
    onChangeAgentReportRank,
    resetAgentReportRankFields,
    onChangeAgentReportRankOrderNum,
    onSetAgentReportRankData,
    onSetAgentReportRankDataColumns,
    onSetAgentReportRankDataPositions,
    onSetAgentReportRankDataForPositions
} = agentReportsSlice.actions

export const selectAgentReportsColumns = (state: RootState): GridColumns[] => state.agentReports.gridColumns
export const selectAgentReportsRows = (state: RootState): GridRows[] => state.agentReports.gridRows
export const selectAgentReportsNames = (state: RootState): { value: string, label: string }[] => state.agentReports.agentReportNames
export const selectAgentReportsDomains = (state: RootState): { value: string | null, label: string | null }[] => state.agentReports.agentReportDomains
export const selectAgentReportsPositions = (state: RootState): { value: string | null, label: string | null }[] => state.agentReports.agentReportPositions
export const selectAgentReportAgentNames = (state: RootState): { value: string | null, label: string | null }[] => state.agentReports.agentReportAgentNames
export const selectAgentReportsDefaultRows = (state: RootState): GridRows[] => state.agentReports.defaultGridData
export const selectAgentReportsNewAgentReport = (state: RootState): AddAgentReportsRank => state.agentReports.newAgentReportRank
export const selectAgentReportsDefaultRowsForPositions = (state: RootState): GridRows[] => state.agentReports.defaultGridDataForPositions

export const GetAgentReportsGridData = createAsyncThunk<GridColumns[], {
    "agent_ref": number,
    "domain": string,
    "position": string | null,
    "report_name": string
}, AsyncThunkConfig>(
    'agentReports/getAgentReportsGridData',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.loading))
        try {
            const {
                status,
                data
            } = await gridApi.getAgentsReportsGridData(reqData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle})
                return data.grid_column_data
            } else {
                thunkAPI.dispatch(onSetAgentReportRankDataColumns([]))
                thunkAPI.dispatch(setAgentReportsGridRows([]))
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            thunkAPI.dispatch(onSetAgentReportRankDataColumns([]))
            thunkAPI.dispatch(setAgentReportsGridRows([]))
            return thunkAPI.rejectWithValue('Agents were not found')
        }
    }
)

export const CreateAgentReportRank = createAsyncThunk<void, AgentReportRankForUpdate[], AsyncThunkConfig>(
    'agentReports/createAgentReportRank',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        try {
            const {
                status,
                data
            } = await gridApi.createAgentReportRank(reqData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export interface BaseGridRows {
    COL_NAME: string
    COL_OPTIONS: null | string
    COL_POS: number
    COL_TITLE: string
    COL_TYPE: string
    COL_VALUE: string
    COL_WIDTH: number
}

export interface GridRows extends BaseGridRows {
    [key: string]: any;
}

export interface AddAgentReportsRank {
    agent_name: string
    agent_ref: number | null
    domain: string
    order_number: number | null
    position: null | string
    report_name: string

    [key: string]: string | number | null | boolean
}

export interface AgentReportRankForUpdate {
    agent_name: string
    agent_ref: number | null
    domain: string
    order_number: number | null
    position: null | string
    report_name: string
}


export default agentReportsSlice.reducer