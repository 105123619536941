import React, {useState} from 'react';
import s from "../Properties.module.scss";
import {PropertyActivityType} from "../../../app/api";
import {useAppSelector} from "../../../app/hooks";
import {
    selectActivitiesModalOpened,
    selectEmailDomain,
    selectPropertyActivities
} from "../../../store/propertiesReducer";
import moment from 'moment';


interface PropertiesActivityModalType {
    openedActivity: boolean
    height: number
    gridContentHeight: number
    currentPropId: number
    onCloseActivityModal: () => void
}

const PropertiesActivityModal = ({
                                     openedActivity,
                                     height,
                                     gridContentHeight,
                                     currentPropId,
                                     onCloseActivityModal
                                 }: PropertiesActivityModalType) => {
    let propertyActivities = useAppSelector(selectPropertyActivities)
    const [activeActivityFilter, setActiveActivityFilter] = useState('All')
    const emailDomain = useAppSelector(selectEmailDomain)
    const isActivityModalOpened = useAppSelector(selectActivitiesModalOpened)
    // propertyActivities = propertyActivities.sort((a: any, b: any) => a.DATE_CREA_ACT - b.DATE_CREA_ACT)
    let sortedActivities = [...propertyActivities].sort((a, b) => {
        const dateA = new Date(a.DATE_CREA_ACT) as Date;
        const dateB = new Date(b.DATE_CREA_ACT) as Date;
        return dateB.getTime() - dateA.getTime(); // Sort in descending order
    });

    if (activeActivityFilter === 'Offer') {
        sortedActivities = sortedActivities.filter((a: PropertyActivityType) => a.ACTIVITY_TYPE === 'Offer')
    }
    if (activeActivityFilter === 'Activity') {
        sortedActivities = sortedActivities.filter((a: PropertyActivityType) => a.ACTIVITY_TYPE === 'Activity')
    }
    if (activeActivityFilter === 'All') {
        sortedActivities = sortedActivities.filter((a: PropertyActivityType) => a.ACTIVITY_TYPE)
    }


    return (
        openedActivity && !isActivityModalOpened
            ?
            <div className={s.messageInner}>
                <div
                    style={{
                        height: height > 920 ? gridContentHeight + 260 : gridContentHeight + 410,
                        minHeight: height < 800 ? '700px' : 'auto',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        borderRadius: '8px 0 0 0',
                        minWidth: '427px',
                        maxWidth: '427px'
                    }}
                >
                    <div className={s.messageInner__header}>
                        <p>Activities for {currentPropId}</p>
                        <button
                            type={'button'}
                            onClick={onCloseActivityModal}
                        >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1387_34908)">
                                    <path
                                        d="M4.66667 3L3 4.66667L6.33333 8L3 11.3333L4.66667 13L8 9.66667L11.3333 13L13 11.3333L9.66667 8L13 4.66667L11.3333 3L8 6.33333L4.66667 3Z"
                                        fill="white" fillOpacity="0.6"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1387_34908">
                                        <rect width="10" height="10" fill="white"
                                              transform="matrix(-1 0 0 -1 13 13)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                    <div>
                        <div className={s.activityButtons}>
                            <button
                                className={activeActivityFilter === 'Offer' ? s.activeActivityFilter : s.activityFilter}
                                onClick={() => setActiveActivityFilter('Offer')}
                            >
                                Offers
                            </button>
                            <button
                                className={activeActivityFilter === 'Activity' ? s.activeActivityFilter : s.activityFilter}
                                onClick={() => setActiveActivityFilter('Activity')}
                            >
                                Other
                            </button>
                            <button
                                className={activeActivityFilter === 'All' ? s.activeActivityFilter : s.activityFilter}
                                onClick={() => setActiveActivityFilter('All')}
                            >
                                All
                            </button>
                        </div>
                        <div>
                            {sortedActivities.map((a: PropertyActivityType, index: number) => {
                                let contactsArray: {
                                    contactName: string,
                                    contactEmail: string,
                                    contactMobile: string,
                                    contactPhone: string
                                }[] = [];
                                if (a.CONTACTS !== null) {
                                    const contacts = a.CONTACTS.split('\n');
                                    contactsArray = contacts.map(contact => {
                                        const [contactName, contactEmail, contactMobile, contactPhone] = contact.split(';');
                                        return {
                                            contactName,
                                            contactEmail,
                                            contactMobile,
                                            contactPhone
                                        };
                                    });
                                }
                                return (
                                    <div key={index}>
                                        <p className={s.dateCreatedActivity}>{a.ACTIVITY_TYPE}</p>
                                        <div className={s.activityInner}>
                                            <div className={s.activityDescText}>
                                                <div style={{
                                                    display: 'flex'
                                                }}>
                                                    <div className={s.activityDescText__upper}>
                                                        <p>{a.REF_ACT}</p>
                                                        <p>{emailDomain.message}</p>
                                                    </div>
                                                    <div className={s.activityDescText__lower}>
                                                        <p>{a.AGENT_CREA_ACT}</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className={s.activityDescText__date}>{moment(a.DATE_CREA_ACT).format('YYYY-DD-MM hh:mm:ss')}</p>
                                                </div>
                                            </div>
                                            {
                                                contactsArray.map((c: any) => {
                                                    return (
                                                        <div className={s.contactAddInfo}>
                                                            <span
                                                                className={s.contactAddInfo__name}>{c.contactName}</span>
                                                            <span
                                                                className={s.contactAddInfo__email}>{c.contactEmail}</span>
                                                            <span
                                                                className={s.contactAddInfo__phone}>{c.contactMobile}</span>
                                                            <span
                                                                className={s.contactAddInfo__phone}>{c.contactPhone}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div>
                                                <p className={s.activityInner__sendDate}>Send
                                                    date: {moment(a.DATE_CREA_ACT).format('YYYY-DD-MM hh:mm:ss')}</p>
                                            </div>
                                            <div className={s.activityInner__message}>
                                                <p className={s.activityInner__messageTitle}>Message</p>
                                                <div className={s.activityInner__messageBody}>
                                                    <p>
                                                        {a.REMARK_ACT}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )

                            })}
                        </div>
                    </div>
                </div>
            </div>
            :
            null
    );
};

export default PropertiesActivityModal;