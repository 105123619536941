import React from 'react';
import GoogleMapWithSearch from "./../Map/Map";
const AddressForm = () => {


    return (
        <div>
            <h1>Google Map with Search</h1>
            <div>
                <div>

                </div>
                <GoogleMapWithSearch />
            </div>

        </div>
    );
};

export default AddressForm;