import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectPropertiesPicturesTypes} from "../../../../store/utilityReducer";
import s from "../EditProperty.module.scss";
import {Checkbox, Empty, Input, message, Modal, Select, Upload} from "antd";
import {
    CreateBrokeragePropertyPicture,
    GetBrokeragePropertyPicturesByRef, onFilterPropertyPictureFilteredFields,
    onResetCreateNewPropertyImageFields,
    onSetPropertyPictureFilteredFields,
    onUpdateBrokeragePropertyPictureField,
    onUpdateCreateNewPropertyImageField, selectBrokeragePropertiesPicturesDataLoading,
    selectBrokeragePropertyPicturesTabFields, selectSurfaceTypeColumnFilterValue,
} from "../../../../store/propertiesReducer";
import {sessionStorageUtilityValues} from "../../../../helpers/sessionStorageHelper";
import {format} from "date-fns";
import ShowMore from "./ShowMore/ShowMore";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../../../img/Loader.json";
import PictureUploader from './PictureUploader/PictureUploader';
import CreationModal from "../../../common/CreationModal/CreationModal";
import {DeleteOutlined, FileImageOutlined, PlusOutlined} from "@ant-design/icons";


interface EditPicturesTabPropsType {
    openedAddImageModal: boolean
    setOpenedAddImageModal: (opened: boolean) => void
}

const EditPicturesTab = ({openedAddImageModal, setOpenedAddImageModal}: EditPicturesTabPropsType) => {
    const addPropertyPictureFields = useAppSelector(selectSurfaceTypeColumnFilterValue)
    const currentBrokerageProperty = sessionStorageUtilityValues.getCurrentBrokerageReqProperty()
    const dispatch = useAppDispatch()
    let formFields = useAppSelector(selectBrokeragePropertyPicturesTabFields)
    const [fieldsWithError, setFieldsWithError] = React.useState<boolean>(false)
    const isDataLoading = useAppSelector(selectBrokeragePropertiesPicturesDataLoading)
    const [requestRunning, setIsRequestRunning] = useState(false)
    const picturesTypes = useAppSelector(selectPropertiesPicturesTypes)
    const [propertyPictureType, setPropertyPictureType] = useState('All')


    useEffect(() => {
        if (currentBrokerageProperty.PROP_ID) {
            dispatch(GetBrokeragePropertyPicturesByRef(currentBrokerageProperty.PROP_ID))
        }
    }, [dispatch, currentBrokerageProperty.PROP_ID])

    const propertiesPicturesTypesValues = [
        {value: "All", label: "All"},
        ...picturesTypes.map((type: { id: number, value: string }) => ({
            value: `${type.id}`,
            label: `${type.value}`,
        })),
    ];

    const onFormInputChange = (id: number, e: React.ChangeEvent<HTMLInputElement> | boolean, inputName: string, type: 'Edit' | 'Add') => {
        if (type === 'Edit') {
            if (typeof e === 'boolean') {
                dispatch(onUpdateBrokeragePropertyPictureField({id, fieldKey: inputName, value: e ? 1 : 0}));
                return;
            } else {
                const {value} = e.target;
                dispatch(onUpdateBrokeragePropertyPictureField({id, fieldKey: inputName, value}));
            }
        } else {
            if (typeof e === 'boolean') {
                dispatch(onUpdateCreateNewPropertyImageField({fieldKey: inputName, value: e ? 1 : 0}));
                return;
            } else {
                const {value} = e.target;
                dispatch(onUpdateCreateNewPropertyImageField({fieldKey: inputName, value}));
            }
        }
    };

    const onChangePropertyPictureType = (value: string) => {
        setPropertyPictureType(value)
    }

    if (propertyPictureType === 'All') {
        formFields = formFields.filter((i) => i.type_text !== null)
    } else {
        formFields = formFields.filter((i) => i.type_text === propertyPictureType)
    }


    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (file: File) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.result) {
                const base64Data = reader.result.toString().split(",")[1];
                const img = new Image();
                img.onload = () => {
                    dispatch(onUpdateCreateNewPropertyImageField({fieldKey: 'property-image.format', value: file.type.split('/')[1]}))
                    dispatch(onUpdateCreateNewPropertyImageField({
                        fieldKey: 'property-image.filename',
                        value: file.name
                    }))
                    dispatch(onUpdateCreateNewPropertyImageField({fieldKey: 'property-image.width', value: img.width}))
                    dispatch(onUpdateCreateNewPropertyImageField({
                        fieldKey: 'property-image.height',
                        value: img.height
                    }))
                };
                img.src = reader.result.toString();
                dispatch(onUpdateCreateNewPropertyImageField({fieldKey: 'photo', value: file}))
                setImageUrl(base64Data);
            }
        };

        reader.readAsDataURL(file); // Read the file as data URL
    };

    const beforeUpload = (file: File) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            Modal.error({title: "Invalid file format", content: "Only JPG/PNG is allowed."});
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Modal.error({title: "File too large", content: "Image must be smaller than 2MB."});
            return false;
        }
        handleChange(file);
        return false; // Prevent automatic upload
    };

    const handleDelete = () => {
        setImageUrl(null)
    };

    const uploadButton = (
        <div>
            {loading ? <PlusOutlined spin/> : <PlusOutlined/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const onUploadNewImage = () => {
        if(addPropertyPictureFields.photo === null){
            message.warn('Please add picture for property in order to save it')
        }
        else if(
            addPropertyPictureFields["property-image"].type === 0
            || addPropertyPictureFields["property-image"].title === null
            || addPropertyPictureFields["property-image"].description === null
        ){
            setFieldsWithError(true)
        }
        else {
            const formData = new FormData();
            if (addPropertyPictureFields.photo) {
                const base64Data = addPropertyPictureFields.photo;
                formData.append('photo', new File([base64Data], '', {type: `image/${addPropertyPictureFields["property-image"].format!!}`}))
            }
            formData.append('property-image', new Blob([JSON.stringify(addPropertyPictureFields["property-image"])], {type: 'application/json'}))
            setIsRequestRunning(true)
            dispatch(CreateBrokeragePropertyPicture({ prop_ref: currentBrokerageProperty.PROP_ID, reqData: formData }))
                .then(() => {
                    setTimeout(() => {
                        setIsRequestRunning(false)
                        dispatch(onResetCreateNewPropertyImageFields())
                        setOpenedAddImageModal(false)
                        dispatch(onSetPropertyPictureFilteredFields([]))
                        setImageUrl(null)
                        dispatch(GetBrokeragePropertyPicturesByRef(currentBrokerageProperty.PROP_ID))
                    }, 500)
                })
        }
    };


    const onPictureTypeChange = (value: any) => {
        const numberValue = propertiesPicturesTypesValues.find((p) => p.label === value)?.value || 0
        dispatch(onUpdateCreateNewPropertyImageField({fieldKey: 'property-image.type', value: numberValue}))
    }

    const onCloseAddPictureModal = () => {
        dispatch(onResetCreateNewPropertyImageFields())
        setOpenedAddImageModal(false)
    }

    return (
        <div>
            {
                isDataLoading
                    ?
                    <div style={{
                        height: '52vh',
                        width: '400px',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>
                        <div style={{
                            height: '300px',
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            <Lottie
                                // @ts-ignore
                                config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                            />
                        </div>

                        <p style={{
                            textAlign: 'center'
                        }}>Loading...</p>
                    </div>
                    :
                    !formFields.length
                        ?
                        <>
                            <div className={s.form__row} style={{
                                maxWidth: '392px',
                            }}>
                                <label>Picture type</label>
                                <Select
                                    onChange={(e) => onChangePropertyPictureType(e)}
                                    style={{width: '100%'}}
                                    placeholder={'Select type'}
                                    className={'reqCreateFormSelect'}
                                    value={propertyPictureType}
                                >
                                    {
                                        propertiesPicturesTypesValues.map((d: { value: string, label: string }) => {
                                            return (
                                                <option
                                                    key={d.value}
                                                    value={d.label}
                                                >
                                                    {d.label}
                                                </option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '45vh'
                            }}>
                                <Empty/>
                            </div>
                        </>

                        :
                        <>
                            <div className={s.form__row} style={{
                                maxWidth: '392px'
                            }}>
                                <label>Picture type</label>
                                <Select
                                    onChange={(e) => onChangePropertyPictureType(e)}
                                    style={{width: '100%'}}
                                    placeholder={'Select type'}
                                    className={'reqCreateFormSelect'}
                                    value={propertyPictureType}
                                >
                                    {
                                        propertiesPicturesTypesValues.map((d: { value: string, label: string }) => {
                                            return (
                                                <option
                                                    key={d.value}
                                                    value={d.label}
                                                >
                                                    {d.label}
                                                </option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                            <div className={s.picturesInner}>
                                {
                                    formFields.map((i) => {
                                        return (
                                            <div className={s.pictures}>
                                                <div className={s.picture}>
                                                    <PictureUploader i={i} isMainImage={false}/>
                                                    <div style={{
                                                        width: '100%'
                                                    }}>
                                                        <div className={s.picture__topBlock}>
                                                            <div>
                                                                <p className={s.picture__size}>{i.width} x {i.height}</p>
                                                                <p className={s.picture__id}>Picture number {i.id}</p>
                                                                <p className={s.picture__kind}>Kind: {i.type_text}</p>
                                                            </div>
                                                            <div>
                                                                <p className={s.created} style={{
                                                                    marginBottom: '2px'
                                                                }}>
                                                                    <span>Created at</span> {i.date_created !== null ? format(new Date(i.date_created), 'dd.MM.yyyy') : ''} {i.created_by}
                                                                </p>
                                                                <p className={s.created} style={{
                                                                    marginTop: '0px',
                                                                    marginBottom: '0px'
                                                                }}>
                                                                    <span>Updated at</span> {i.date_modified !== null ? format(new Date(i.date_modified), 'dd.MM.yyyy') : ''} {i.modified_by}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={s.form__row}>
                                                                <label>Title</label>
                                                                <Input placeholder={'Enter title'}
                                                                       value={i.title}
                                                                       onChange={(e) => onFormInputChange(i.id, e, 'title', 'Edit')}/>
                                                            </div>
                                                            <div className={s.form__row} style={{
                                                                marginTop: '6px'
                                                            }}>
                                                                <label>Description</label>
                                                                <Input placeholder={'Enter description'}
                                                                       value={i.description}
                                                                       onChange={(e) => onFormInputChange(i.id, e, 'description', 'Edit')}/>
                                                            </div>
                                                            <ShowMore i={i} onFormInputChange={onFormInputChange} formFields={formFields} propertyPictureType={propertyPictureType}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>

            }
            {
                openedAddImageModal
                &&
                <CreationModal
                    title={'Add new property picture'}
                    image={
                        <div style={{
                            width: 48,
                            height: 48,
                            backgroundColor: '#F9F5FF',
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '15px'
                        }}>
                            <FileImageOutlined style={{color: '#0707FA'}}/>
                        </div>
                    }
                    loading={requestRunning}
                    onSave={onUploadNewImage}
                    onCancel={onCloseAddPictureModal}
                    buttonOkText={'Add'}
                    buttonCancelText={'Cancel'}
                    width={'450px'}
                    primaryBtnWidth={'195px'}
                >
                    <div
                        className={`${s.picture__img} createPicturesUploader`}
                        style={{
                            width: '100%',
                            height: "auto",
                            marginRight: '0px',
                            maxWidth: '450px'
                        }}
                    >
                        <div style={{
                            marginBottom: '6px'
                        }}>
                            <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                            >
                                {imageUrl ? (
                                    <div className="image-container">
                                        <img src={`data:image/jpeg;base64,${imageUrl}`} alt="Base64" style={{
                                            height: '100%'
                                        }}/>
                                        <div className={s.picture__overlay}>
                                            <DeleteOutlined
                                                className={s.picture__icon}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete();
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    uploadButton
                                )}
                            </Upload>
                        </div>

                        <div className={s.form__row} style={{
                            maxWidth: '100%'
                        }}>
                            <label>Picture type</label>
                            <Select
                                onChange={(e) => onPictureTypeChange(e)}
                                style={{width: '100%'}}
                                placeholder={'Select type'}
                                className={'reqCreateFormSelect'}
                                value={propertiesPicturesTypesValues.find((p) => Number(p.value) === addPropertyPictureFields["property-image"].type)?.label}
                            >
                                {
                                    propertiesPicturesTypesValues.filter((p) => p.value !== 'All').map((d: { value: string, label: string }) => {
                                        return (
                                            <option
                                                key={d.value}
                                                value={d.label}
                                            >
                                                {d.label}
                                            </option>
                                        )
                                    })
                                }
                            </Select>
                            {addPropertyPictureFields["property-image"].type === 0 && fieldsWithError &&
                                <p className={s.form__error}>Please select type!</p>}
                        </div>
                        <div style={{
                            marginTop: '6px'
                        }}>
                            <div className={s.form__row}>
                                <label>Title</label>
                                <Input placeholder={'Enter title'}
                                       value={addPropertyPictureFields["property-image"].title!!}
                                       onChange={(e) => onFormInputChange(0, e, 'property-image.title', 'Add')}/>
                                {addPropertyPictureFields["property-image"].title === null && fieldsWithError &&
                                    <p className={s.form__error}>Please enter title!</p>}
                            </div>
                            <div className={s.form__row} style={{
                                marginTop: '6px'
                            }}>
                                <label>Description</label>
                                <Input placeholder={'Enter description'}
                                       value={addPropertyPictureFields["property-image"].description!!}
                                       onChange={(e) => onFormInputChange(0, e, 'property-image.description', 'Add')}/>
                                {addPropertyPictureFields["property-image"].description === null && fieldsWithError &&
                                    <p className={s.form__error}>Please enter description!</p>}
                            </div>
                        </div>
                        <div className={s.additionalParams}>
                            <p>Additional parameters</p>
                            <div className={s.additionalParams__checkboxes} style={{
                                justifyContent: 'space-between'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    gap: '6px',
                                    alignItems: 'center'
                                }} className={s.form__checkbox}>
                                    <Checkbox
                                        onChange={(e) => onFormInputChange(0, e.target.checked, 'property-image.for_expose', 'Add')}
                                        checked={addPropertyPictureFields["property-image"].for_expose === 1}/>
                                    <label className={s.form__label}>Fact
                                        sheet</label>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    gap: '6px',
                                    alignItems: 'center'
                                }} className={s.form__checkbox}>
                                    <Checkbox
                                        onChange={(e) => onFormInputChange(0, e.target.checked, 'property-image.for_portal', 'Add')}
                                        checked={addPropertyPictureFields["property-image"].for_portal === 1}/>
                                    <label className={s.form__label}>Web
                                        Portal, Rank order</label>
                                    <Input
                                        value={addPropertyPictureFields["property-image"].export_order!!}
                                        disabled={addPropertyPictureFields["property-image"].for_portal === 0}
                                        min={1}
                                        onChange={(e) => onFormInputChange(0, e, 'property-image.export_order', 'Add')}
                                        type={'number'} style={{
                                        width: '90px',
                                        height: '24px',
                                        padding: '0 0 0 8px',
                                        marginLeft: '5px'
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </CreationModal>
            }
        </div>
    );
};

export default EditPicturesTab;