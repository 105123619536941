type LowercaseKeys<T extends Record<string, any>> = {
    [K in keyof T as Lowercase<string & K>]: T[K]
};

function keysToLower<T extends Record<string, any>>(obj: T): LowercaseKeys<T> {
    const lowerCaseObj: Record<string, any> = {};
    Object.keys(obj).forEach((key) => {
        lowerCaseObj[key.toLowerCase()] = obj[key];
    });
    return lowerCaseObj as LowercaseKeys<T>;
}

export function arrayKeysToLower<T extends Record<string, any>>(arr: T[]): LowercaseKeys<T>[] {
    return arr.map(obj => keysToLower(obj));
}