import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    CreateNewUserAccess, DeleteUserAccess,
    GetUserAccesses, GetUserMenuItems, GetUserRoles,
    onChangeNewUserAccessField, onSetAccessFieldsForEdit, ResetAccessTypesFields,
    selectUserAccesses,
    selectUserAccessFields, selectUserMenuItems, selectUserRoles, UpdateUserAccess
} from "../../../store/userReducer";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";
import PageTitle from "../../common/PageTitle/PageTitle";
import {Box} from '@mui/material';
import {DataGridPro, useGridApiRef} from '@mui/x-data-grid-pro';
import {debounce} from "lodash";
import PrimaryButton from "../../common/Buttons/PrimaryButton/PrimaryButton";
import s from './Administration.module.scss'
import CreationModal from "../../common/CreationModal/CreationModal";
import {Input, Select} from "antd";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../../img/Loader.json";


const Administration = () => {
    return (
        <PageWithSidebar>
            <AdministrationComponent/>
        </PageWithSidebar>
    )
}


const AdministrationComponent = () => {
    const dispatch = useAppDispatch()
    const userAccesses = useAppSelector(selectUserAccesses)
    const [isLoading, setIsLoading] = useState(false)
    const [height, setHeight] = useState(window.innerHeight)
    const [gridContentHeight, setGridContentHeight] = useState(height - 500)
    const apiRef = useGridApiRef()
    const [isGiveAccessModalOpened, setIsGiveAccessModalOpened] = useState(false)
    const userAccessFields = useAppSelector(selectUserAccessFields)
    const [checkFieldsForErrors, setCheckFieldsForErrors] = useState(false)
    const userRoles = useAppSelector(selectUserRoles)
    const menuItems = useAppSelector(selectUserMenuItems)
    const [editMode, setIsEditMode] = useState(false)

    const userAccessTypeOptions = [
        {value: 'IP', label: 'IP'},
        {value: 'IP_Domain', label: 'IP_Domain'},
        {value: 'Role', label: 'Role'}
    ]

    const userRolesOptions = userRoles.map((r) => {
        return {
            value: r.id, label: r.name
        }
    })

    const menuItemsOptions = menuItems.map((i) => {
        return {
            value: i.id, label: i.objectName
        }
    })

    useEffect(() => {
        setIsLoading(true)
        dispatch(GetUserRoles())
        dispatch(GetUserMenuItems())
        dispatch(GetUserAccesses())
            .then(() => {
                setIsLoading(false)
            })
    }, [dispatch])


    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight - 500;
            setGridContentHeight(heightForPage);
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const deleteUserAccess = (userAccessId: number) => {
        setIsLoading(true)
        dispatch(DeleteUserAccess(userAccessId))
            .then(() => {
                setTimeout(() => {
                    dispatch(GetUserAccesses())
                    setIsLoading(false)
                }, 2000)
            })
    }

    const columns = [
        {
            field: 'menu_item.id',
            headerName: 'Menu item',
            flex: 1,
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            color: '#000',
            fontSize: '14px',
            lineHeight: '140%',
            valueGetter: (params: any) => params.row.menu_item.objectName,
        },
        {
            field: 'role.name',
            headerName: 'Role Name',
            flex: 1,
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            color: '#000',
            fontSize: '14px',
            lineHeight: '140%',
            valueGetter: (params: any) => params.row.role !== null ? params.row.role.name : '',
        },
        {
            field: 'ip_address',
            headerName: 'IP address',
            flex: 1,
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            color: '#000',
            fontSize: '14px',
            lineHeight: '140%',
        },
        {
            field: 'access_type',
            headerName: 'Access type',
            flex: 1,
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
            color: '#000',
            fontSize: '14px',
            lineHeight: '140%',
        },
        {
            field: 'Action',
            headerName: '',
            flex: 0.6,
            disableExport: true,
            renderCell: (data: any) => {
                return (
                    <button className={s.deleteBtn} onClick={() => deleteUserAccess(data.row.id)}>
                        Delete
                    </button>

                )
            }
        }
    ]

    const onChangeSelectValue = (value: string | number | boolean, selectName: string) => {
        dispatch(onChangeNewUserAccessField({fieldName: selectName, fieldValue: value}))
    }

    const onChangeInputValue = (value: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        dispatch(onChangeNewUserAccessField({fieldName: fieldName, fieldValue: value.target.value}))
    }


    const onCancelAccessTypeCreation = () => {
        setIsGiveAccessModalOpened(false)
        dispatch(ResetAccessTypesFields())
        setCheckFieldsForErrors(false)
    }

    const onCreateUserAccess = () => {
        if (userAccessFields.access_type !== 'Role') {
            // @ts-ignore
            if (Object.keys(userAccessFields).some(key => key !== 'roleName' && (userAccessFields[key] === '' || userAccessFields[key] === null))) {
                setCheckFieldsForErrors(true)
                console.log('1123')
                return;
            }
        }
        else {
            // @ts-ignore
            if (Object.keys(userAccessFields).some(key => key !== 'ip_address' && (userAccessFields[key] === '' || userAccessFields[key] === null))) {
                setCheckFieldsForErrors(true)
                return;
            }
        }
        setIsLoading(true)
        if (!editMode) {
            dispatch(CreateNewUserAccess({
                access_type: userAccessFields.access_type!,
                ip_address: userAccessFields.ip_address,
                menu_item_id: userAccessFields.menuItemId!,
                role_id: userAccessFields.roleName !== null ? Number(userAccessFields.roleName) : null
            }))
                .then(() => {
                    setIsGiveAccessModalOpened(false)
                    setTimeout(() => {
                        dispatch(ResetAccessTypesFields())
                        setCheckFieldsForErrors(false)
                        dispatch(GetUserAccesses())
                        setIsLoading(false)
                    }, 2000)
                })
        } else {
            dispatch(UpdateUserAccess({
                reqData: {
                    access_type: userAccessFields.access_type!,
                    ip_address: userAccessFields.ip_address,
                    menu_item_id: userAccessFields.menuItemId!,
                    role_id: userAccessFields.roleName !== null ? Number(userAccessFields.roleName) : null
                },
                userAccessId: userAccessFields.id!!
            }))
                .then(() => {
                    setIsGiveAccessModalOpened(false)
                    setTimeout(() => {
                        dispatch(ResetAccessTypesFields())
                        dispatch(GetUserAccesses())
                        setCheckFieldsForErrors(false)
                        setIsLoading(false)
                    }, 2000)
                })
        }
    }


    const onRowDoubleClick = (row: any) => {
        dispatch(onSetAccessFieldsForEdit({
            access_type: row.row.access_type!,
            ip_address: row.row.ip_address,
            menuItemId: row.row.menu_item.id!,
            roleName: row.row.role !== null ? row.row.role.id : null,
            id: row.row.id
        }))
        setIsGiveAccessModalOpened(true)
        setIsEditMode(true)
    }

    const onOpenCreateModal = () => {
        setIsEditMode(false)
        setIsGiveAccessModalOpened(true)
    }

    return (
        <div>
            <PageTitle
                backLink='/parameters'
                backLinktext={'Back'}
                isBackButton={true}
                top={'20px'}
                left={'35px'}
            />
            <div className={s.table}>
                <div className={s.table__header}>
                    <h1 className={s.table__title}>Administration</h1>
                    <div>
                        <div
                            onClick={onOpenCreateModal}
                        >
                            <PrimaryButton text={'Add user access'} isAddButton={true} width={'175px'} height={'40px'}/>
                        </div>
                    </div>
                </div>
                <Box>
                    {
                        isLoading
                            ?
                            <div style={{
                                height: gridContentHeight + 135,
                                width: '400px',
                                margin: '0 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <div style={{
                                    height: '300px',
                                    width: '100%',
                                    textAlign: 'center'
                                }}>
                                    <Lottie
                                        // @ts-ignore
                                        config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                    />
                                </div>

                                <p style={{
                                    textAlign: 'center'
                                }}>{'Loading...'}</p>
                            </div>
                            :
                            <DataGridPro
                                sx={{
                                    border: 'none',
                                    borderRadius: '0px',
                                    "& .MuiDataGrid-cellContent": {
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        color: '#111827'
                                    },
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 600,
                                        color: '#6B7280',
                                        fontSize: '12px',
                                        lineHeight: '20px',
                                    },
                                    "& .MuiDataGrid-row:hover": {
                                        backgroundColor: 'transparent'
                                    },
                                    "& .MuiDataGrid-row.Mui-selected": {
                                        backgroundColor: 'transparent'
                                    },
                                    "& .MuiDataGrid-row.Mui-selected:hover": {
                                        backgroundColor: 'transparent'
                                    },
                                    "& .MuiDataGrid-cell:focus": {
                                        outline: 'none'
                                    },
                                    "& .MuiDataGrid-toolbarContainer": {
                                        color: '#454545',
                                        position: 'absolute',
                                        top: '-50px',
                                        right: '0px'
                                    },
                                    "& .MuiTablePagination-displayedRows": {
                                        margin: '0',
                                        fontFamily: 'Inter, sans-serif !important',
                                        fontWeight: 400,
                                        color: '#6B7280',
                                        fontSize: '12px',
                                        lineHeight: '20px',
                                    },
                                    "& .MuiPagination-root": {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '7px 0px'
                                    },
                                    "& .MuiButtonBase-root.Mui-selected": {
                                        backgroundColor: 'transparent',
                                        borderRadius: '0px'
                                    },
                                    "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                        width: '40px',
                                        height: '40px',
                                        margin: 0,
                                        borderRight: '1px solid #D0D5DD',
                                        borderLeft: '1px solid #D0D5DD',
                                        borderTop: 'none',
                                        borderBottom: 'none',
                                        fontFamily: 'Inter, sans-serif !important',
                                        fontWeight: 500,
                                        color: '#1D2939',
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                        borderRadius: '0px'
                                    },
                                    "& .MuiPagination-ul": {
                                        border: '1px solid #D0D5DD',
                                        borderRadius: '8px'
                                    },
                                    "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                        borderRadius: '0px',
                                        border: 'none'
                                    },
                                    "& .MuiDataGrid-row:nth-of-type(odd)": {
                                        backgroundColor: 'transparent'
                                    },
                                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                                        width: '100%'
                                    },
                                    "& .MuiDataGrid-virtualScroller": {
                                        overflowY: 'scroll !important',
                                        overflowX: 'scroll',
                                        height: `${gridContentHeight + 100}px !important`,
                                        minHeight: 'auto',
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: '#FFF',
                                        borderTop: 'none',
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        backgroundColor: '#FFF',
                                        borderTop: 'none'
                                    },
                                    "& .MuiDataGrid-columnHeaderRow": {
                                        backgroundColor: '#FFF',
                                        borderTop: 'none'
                                    },
                                    "& .MuiDataGrid-root": {
                                        marginTop: '50px'
                                    },
                                    "& .MuiDataGrid-columnSeparator": {
                                        display: "none"
                                    },
                                    '& .MuiDataGrid-iconButtonContainer': {
                                        visibility: 'visible !important',
                                    },
                                }}
                                apiRef={apiRef}
                                columns={columns}
                                rows={userAccesses}
                                rowHeight={58}
                                headerHeight={39}
                                hideFooter={true}
                                hideFooterSelectedRowCount={true}
                                autoHeight={true}
                                autoPageSize={true}
                                onRowDoubleClick={onRowDoubleClick}
                            />
                    }

                </Box>
            </div>
            {
                isGiveAccessModalOpened
                &&
                <CreationModal
                    title={'Add user access'}
                    image={<svg width="56" height="56" viewBox="0 0 56 56" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                        <rect x="4" y="4" width="48" height="48" rx="24" fill="#F5F5FF"/>
                        <rect x="4" y="4" width="48" height="48" rx="24" stroke="#F9F5FF" strokeWidth="8"/>
                        <path
                            d="M34.6047 22.225C35.9095 22.225 36.9672 21.1673 36.9672 19.8625C36.9672 18.5577 35.9095 17.5 34.6047 17.5C33.2999 17.5 32.2422 18.5577 32.2422 19.8625C32.2422 21.1673 33.2999 22.225 34.6047 22.225Z"
                            stroke="#1755E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M34.6047 30.3625C35.9095 30.3625 36.9672 29.3047 36.9672 28C36.9672 26.6952 35.9095 25.6375 34.6047 25.6375C33.2999 25.6375 32.2422 26.6952 32.2422 28C32.2422 29.3047 33.2999 30.3625 34.6047 30.3625Z"
                            stroke="#1755E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M20.4289 30.3625C21.7337 30.3625 22.7914 29.3047 22.7914 28C22.7914 26.6952 21.7337 25.6375 20.4289 25.6375C19.1241 25.6375 18.0664 26.6952 18.0664 28C18.0664 29.3047 19.1241 30.3625 20.4289 30.3625Z"
                            stroke="#1755E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M34.6047 38.4999C35.9095 38.4999 36.9672 37.4422 36.9672 36.1374C36.9672 34.8326 35.9095 33.7749 34.6047 33.7749C33.2999 33.7749 32.2422 34.8326 32.2422 36.1374C32.2422 37.4422 33.2999 38.4999 34.6047 38.4999Z"
                            stroke="#1755E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M32.2445 19.8625H29.6195C28.4593 19.8625 27.5195 20.8023 27.5195 21.9625V34.0375C27.5195 35.1978 28.4593 36.1375 29.6195 36.1375H32.2445"
                            stroke="#1755E7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M32.243 28H22.793" stroke="#1755E7" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>}
                    onCancel={onCancelAccessTypeCreation}
                    onSave={onCreateUserAccess}
                    loading={isLoading}
                    withMargin={true}
                    buttonOkText={'Add'}
                    buttonCancelText={'Cancel'}
                >
                    <div className={`${s.form} accessSelect`}>
                        {/*<div className={s.form__row}>*/}
                        {/*    <div className={s.form__item}>*/}
                        {/*        <label className={s.form__label}>ID</label>*/}
                        {/*        <InputNumber*/}
                        {/*            placeholder=""*/}
                        {/*            onChange={(value) => onChangeInputValue(value, 'domain_cd')}*/}
                        {/*            defaultValue={userAccessFields.id}*/}
                        {/*            value={userAccessFields.id}*/}
                        {/*            style={{*/}
                        {/*                width: '100% !important',*/}
                        {/*                height: '44px'*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*        {*/}
                        {/*            userAccessFields.id === null && checkFieldsForErrors*/}
                        {/*            &&*/}
                        {/*            <div className={s.error}>Please enter ID</div>*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className={s.form__item}>
                            <label className={s.form__label}>Access type</label>
                            <Select
                                placeholder="Select access type"
                                onChange={(value, option) => onChangeSelectValue(value, 'access_type')}
                                options={userAccessTypeOptions}
                                defaultValue={userAccessFields.access_type}
                                value={userAccessFields.access_type}
                                style={{
                                    width: '100% !important',
                                    height: '44px'
                                }}
                            />
                            {
                                userAccessFields.access_type === null && checkFieldsForErrors
                                &&
                                <div className={s.error}>Please select access type</div>
                            }
                        </div>
                        <div className={s.form__item}>
                            <label className={s.form__label}>Role Name</label>
                            <Select
                                placeholder="Select role name"
                                onChange={(value, option) => onChangeSelectValue(value, 'roleName')}
                                options={userRolesOptions}
                                defaultValue={userAccessFields.roleName}
                                value={userAccessFields.roleName}
                                style={{
                                    width: '100% !important',
                                    height: '44px'
                                }}
                            />
                            {
                                (userAccessFields.access_type ==='Role' || userAccessFields.access_type === null) && userAccessFields.roleName === null && checkFieldsForErrors
                                &&
                                <div className={s.error}>Please select role name</div>
                            }
                        </div>
                        <div className={s.form__item}>
                            <label className={s.form__label}>Menu item</label>
                            <Select
                                placeholder="Select menu item"
                                onChange={(value, option) => onChangeSelectValue(value, 'menuItemId')}
                                options={menuItemsOptions}
                                defaultValue={userAccessFields.menuItemId}
                                value={userAccessFields.menuItemId}
                                style={{
                                    width: '100% !important',
                                    height: '44px'
                                }}
                            />
                            {
                                userAccessFields.menuItemId === null && checkFieldsForErrors
                                &&
                                <div className={s.error}>Please select menu item</div>
                            }
                        </div>
                        <div className={s.form__item}>
                            <label className={s.form__label}>IP address</label>
                            <Input
                                placeholder="Enter IP address"
                                onChange={(value) => onChangeInputValue(value, 'ip_address')}
                                defaultValue={userAccessFields.ip_address}
                                value={userAccessFields.ip_address}
                                style={{
                                    width: '100% !important',
                                    height: '44px'
                                }}
                            />
                            {
                                userAccessFields.access_type !=='Role' && userAccessFields.ip_address === '' && checkFieldsForErrors
                                &&
                                <div className={s.error}>Please enter ip address</div>
                            }
                        </div>
                    </div>
                </CreationModal>
            }
        </div>
    );
};

export default Administration;