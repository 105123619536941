import React, {useRef} from 'react';
import {useAppDispatch} from "../../../app/hooks";
import {onSetPropertiesMessageBody} from "../../../store/propertiesReducer";
import DOMPurify from 'dompurify';


type PropertyEditableContactPropsType = {
    textValue: string
}


const PropertyEditableMessage = ({textValue}: PropertyEditableContactPropsType) => {
    const dispatch = useAppDispatch()
    const editableRef = useRef<HTMLDivElement>(null);
    const handleInput = (event: React.SyntheticEvent<HTMLDivElement>) => {
        dispatch(onSetPropertiesMessageBody(event.currentTarget.innerHTML));
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        if (event.key === 'Enter') {
            event.preventDefault();

            const br = document.createElement('br');
            range?.insertNode(br);
            range?.setStartAfter(br);
            range?.setEndAfter(br);
            range?.collapse(false);
            selection?.removeAllRanges();
            // @ts-ignore
            selection?.addRange(range);
            // @ts-ignore
            document.getElementById('offer').addEventListener('DOMNodeInserted', event => {
                // Prevent adding new elements
                event.preventDefault();
            });
            // Trigger input event manually to update the contentEditable element
            if (editableRef.current) {
                editableRef.current.dispatchEvent(new Event('input', { bubbles: true }));
            }
        }
    };

    return (
        <>
            <div
                style={{
                    outline: 'none'
                }}
                contentEditable={true}
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(textValue)}}
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                // onKeyDown={handleKeyDown}
                // onBeforeInput={handleBeforeInput}
            >
            </div>
        </>

    );
};

export default PropertyEditableMessage;













