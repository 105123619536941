import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {eventsApi} from '../app/api'
import {AppStatusType} from './appStatusReducer'
import {AsyncThunkConfig, RootState} from './store'
import {Moment} from "moment";


interface InitialStateType {
    events: ActivityEventType[]
    currentEvent: ActivityCurrentEvent | null
    currentNotification: ActivityEventType | null
    defaultNotification: ActivityEventType | null
    currentEventTabStatus: string
    currentEventId: number | null
    isDataLoading: boolean
    selectedDatesRange: [Date, Date],
    defaultNotificationDatePickerOpened: boolean,
    notificationDatePickerOpened: boolean,
    headerNotificationDatePickerOpened: boolean
    isOpenedContactsModal: boolean
    contactsByEventId: ContactByEventId[]
    updatedContacts: string[]
    isOpenedDomainModal: boolean
    isHeaderOpenedContactsModal: boolean
}

const initialState: InitialStateType = {
    events: [],
    currentEvent: null,
    currentNotification: null,
    defaultNotification: null,
    currentEventTabStatus: 'All Notifications',
    currentEventId: null,
    isDataLoading: false,
    selectedDatesRange: [
        new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate(), 0, 0, 0),
        new Date(new Date().getFullYear(), new Date().getMonth() + 3, new Date().getDate(), 23, 59, 59, 999)
    ],
    defaultNotificationDatePickerOpened: false,
    notificationDatePickerOpened: false,
    headerNotificationDatePickerOpened: false,
    isOpenedContactsModal: false,
    contactsByEventId: [],
    updatedContacts: [],
    isOpenedDomainModal: false,
    isHeaderOpenedContactsModal: false
}

export const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        onSetActivityCurrentNotification: (state, action: PayloadAction<ActivityEventType>) => {
            state.currentNotification = action.payload
        },
        onSetCurrentEventTabStatus: (state, action: PayloadAction<string>) => {
            state.currentEventTabStatus = action.payload
        },
        onSetActivityEvents: (state, action: PayloadAction<ActivityEventType[]>) => {
            state.events = action.payload
        },
        onSetCurrentEventId: (state, action: PayloadAction<number>) => {
            state.currentEventId = action.payload
        },
        onSetCurrentEvent: (state, action: PayloadAction<ActivityCurrentEvent | null>) => {
            state.currentEvent = action.payload
        },
        onSetIsDataLoading: (state, action: PayloadAction<boolean>) => {
            state.isDataLoading = action.payload
        },
        onChangeEventStatus: (state, action: PayloadAction<{ event_id: number, status: string }>) => {
            state.events = state.events.map((e: ActivityEventType) => Number(e.event_id) === Number(action.payload.event_id) ? {
                ...e,
                event_status: {eventStatus: action.payload.status}
            } : e)
        },
        onChangeSelectedDatesRange: (state, action: PayloadAction<[Date, Date]>) => {
            state.selectedDatesRange = action.payload
        },
        onDefaultNotificationDatePickerOpened: (state, action: PayloadAction<boolean>) => {
            state.defaultNotificationDatePickerOpened = action.payload
        },
        onNotificationDatePickerOpened: (state, action: PayloadAction<boolean>) => {
            state.notificationDatePickerOpened = action.payload
        },
        onHeaderNotificationDatePickerOpened: (state, action: PayloadAction<boolean>) => {
            state.headerNotificationDatePickerOpened = action.payload
        },
        onSetDefaultNotification: (state, action: PayloadAction<ActivityEventType>) => {
            state.defaultNotification = action.payload
        },
        onOpenContactsModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenedContactsModal = action.payload
        },
        onOpenHeaderContactsModal: (state, action: PayloadAction<boolean>) => {
            state.isHeaderOpenedContactsModal = action.payload
        },
        onAddContactsForUpdate: (state, action: PayloadAction<string>) => {
            state.updatedContacts.push(action.payload)
        },
        onOpenDomainModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenedDomainModal = action.payload
        },
        onSetDefaultNotificationWhenEventWithNoActivityRef: (state, action: PayloadAction<number>) => {
            state.defaultNotification = state.events.find((e: ActivityEventType) => e.event_id === action.payload)!!
        },
        onAddContacts: (state, action: PayloadAction<any>) => {
            console.log(action.payload)
            state.contactsByEventId = [...state.contactsByEventId, action.payload]

        },
        onRemoveContacts: (state, action: PayloadAction<number>) => {
            console.log(action.payload)
            state.contactsByEventId = state.contactsByEventId.filter((c) => c.PE_REF !== action.payload).filter((c) => c.NAME !== undefined)
            state.updatedContacts = state.updatedContacts.filter((c: string) => c !== String(action.payload))
        },
        onClearAddContacts: (state) => {
            state.contactsByEventId = []
            state.updatedContacts = []

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetEventsByStatus.fulfilled, (state, action) => {
                state.events = action.payload
                // state.defaultNotification = action.payload[0]
            })
            .addCase(GetEventsByActivityId.fulfilled, (state, action) => {
                state.currentEvent = action.payload
            })
            .addCase(GetEventsById.fulfilled, (state, action) => {
                state.defaultNotification = action.payload
            })
            .addCase(GetContactsByEventId.fulfilled, (state, action) => {
                state.contactsByEventId = action.payload
                state.updatedContacts = action.payload.map((c) => String(c.PE_REF))
            })

    }
})

export const {
    onSetActivityCurrentNotification,
    onSetCurrentEventTabStatus,
    onChangeEventStatus,
    onSetCurrentEventId,
    onSetIsDataLoading,
    onChangeSelectedDatesRange,
    onDefaultNotificationDatePickerOpened,
    onNotificationDatePickerOpened,
    onHeaderNotificationDatePickerOpened,
    onSetDefaultNotification,
    onOpenContactsModal,
    onAddContactsForUpdate,
    onOpenDomainModal,
    onSetDefaultNotificationWhenEventWithNoActivityRef,
    onSetCurrentEvent,
    onAddContacts,
    onRemoveContacts,
    onClearAddContacts,
    onOpenHeaderContactsModal
} = eventsSlice.actions


export const selectActivityEvents = (state: RootState): ActivityEventType[] => state.events.events
export const selectActivityCurrentEvent = (state: RootState): ActivityCurrentEvent | null => state.events.currentEvent
export const selectActivityCurrentNotification = (state: RootState): ActivityEventType | null => state.events.currentNotification
export const selectActivityDefaultNotification = (state: RootState): ActivityEventType | null => state.events.defaultNotification
export const selectCurrentEventTabStatus = (state: RootState): string => state.events.currentEventTabStatus
export const selectCurrentEventId = (state: RootState): number | null => state.events.currentEventId
export const selectIsDataLoading = (state: RootState): boolean => state.events.isDataLoading
export const selectDatesRange = (state: RootState): [Date, Date] => state.events.selectedDatesRange
export const selectDefaultNotificationDatePickerOpened = (state: RootState): boolean => state.events.defaultNotificationDatePickerOpened
export const selectNotificationDatePickerOpened = (state: RootState): boolean => state.events.notificationDatePickerOpened
export const selectHeaderNotificationDatePickerOpened = (state: RootState): boolean => state.events.headerNotificationDatePickerOpened
export const selectIsContactsModalOpened = (state: RootState): boolean => state.events.isOpenedContactsModal
export const selectIsHeaderContactsModalOpened = (state: RootState): boolean => state.events.isHeaderOpenedContactsModal
export const selectContactsByEventId = (state: RootState): ContactByEventId[] => state.events.contactsByEventId
export const selectUpdatedContacts = (state: RootState): string[] => state.events.updatedContacts
export const selectIsDomainModalOpened = (state: RootState): boolean => state.events.isOpenedDomainModal

export const GetEventsByStatus = createAsyncThunk<ActivityEventType[], {
    event_status: string[] | []
    user_ref: number
}, AsyncThunkConfig>(
    'events/getEventsByStatus',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await eventsApi.getEventsByStatus(requestData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.events, {appStatus: AppStatusType.idle,})
            } else {
                return thunkAPI.rejectWithValue(data.events)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetEventsByActivityId = createAsyncThunk<ActivityCurrentEvent, number, AsyncThunkConfig>(
    'events/getEventsByActivityId',
    async (activityRef, thunkAPI) => {
        try {
            const {status, data} = await eventsApi.getEventByActivityNumber(activityRef)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.resultSetList[0], {appStatus: AppStatusType.idle,})
            } else {
                return thunkAPI.rejectWithValue(data.resultSetList[0])
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetContactsByEventId = createAsyncThunk<ContactByEventId[], number, AsyncThunkConfig>(
    'events/getContactsByEventId',
    async (eventId, thunkAPI) => {
        try {
            const {status, data} = await eventsApi.getContactsByEventId(eventId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle,})
            } else {
                return thunkAPI.rejectWithValue(data.resultSetList)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const UpdateContactByEventId = createAsyncThunk<any, {
    event_id: number,
    contacts: string
}, AsyncThunkConfig>(
    'events/updateContactByEventId',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await eventsApi.updateEventContact(reqData.event_id, reqData.contacts)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.succeeded, appMessage: 'Contact was updated successfully'})
            } else {
                return thunkAPI.rejectWithValue(data.resultSetList)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)



export const UpdateDomainByEventId = createAsyncThunk<any, {
    event_id: number,
    domain: string
}, AsyncThunkConfig>(
    'events/updateDomainByEventId',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await eventsApi.updateEventDomain(reqData.event_id, reqData.domain)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.succeeded, appMessage: 'Domain was updated successfully'})
            } else {
                return thunkAPI.rejectWithValue(data.resultSetList)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const UpdateEventStatus = createAsyncThunk<ActivityCurrentEvent, {
    eventId: number, requestData: {
        action_date: Moment | string
        event_status: {
            description: string | null
            eventStatus: string
        },
        is_read: number
    }
}, AsyncThunkConfig>(
    'events/updateEventStatus',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await eventsApi.updateEventStatus(requestData.eventId, requestData.requestData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle,})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetEventsById = createAsyncThunk<ActivityEventType, number, AsyncThunkConfig>(
    'events/getEventsById',
    async (activityRef, thunkAPI) => {
        try {
            const {status, data} = await eventsApi.getEventById(activityRef)
            if (status === 200) {
                console.log(data)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle,})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

// Events types

export type ActivityEventType = {
    IS_CONTACT_EDITABLE: 1 | 0
    action_date: string
    action_type: string | null
    event_status: any
    activity_ref: number
    created_date: string
    description: string
    end_time: string
    event_id: number
    event_type: {
        eventType: string
        description: null | string
    }
    googleEventId: string
    google_account_id: number
    location: string
    start_time: string
    updated_date: string
    user_ref: number
    is_read: number
    event_template: string
    domain: string | null
}

export type ActivityCurrentEvent = {
    CREATED_BY: string
    DATE_CREATED: string
    DATE_MODIFIED: string
    DESCRIPTION: string
    MODIFIED_BY: string
    REF: number
    REMARKS: string
}


export type ContactByEventId = {
    NAME: null | string
    PE_EMAIL: null | string
    PE_REF: number | null
    PE_TEL_NUMBER: null | string
    COMPANY_NAME: null | string
}

export default eventsSlice.reducer