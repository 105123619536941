import React from 'react';
import s from './CommonActivityModal.module.scss'
import {useAppSelector} from "../../../app/hooks";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";
interface CommonActivityModalPropsType {
    modalTitle: string
    onCloseModal? : () => void
    children: any
    gridType: string
    height?: number
    width?: string
    onHideModal?: () => void
}

const CommonActivityModal = ({modalTitle, onCloseModal, children, height, width, gridType, onHideModal} : CommonActivityModalPropsType) => {
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)

    const onCloseModalWindow = () => {
        if (onCloseModal) {
            onCloseModal()
        }
    }

    return (
        <div className={s.inner} style={{
            height: `${height && height + 155}px`,
            width: currentScreenWidth! < 1024 ? '100%' : width ? width : '427px',
            maxWidth:  currentScreenWidth! < 1024 ? '100%' : width ? width : '427px'
        }}>
            <div className={s.inner__header}>
                <p className={s.inner__headerTitle}>{modalTitle}</p>
                <div className={s.inner__headerButtons}>
                    <button
                        type={'button'}
                        onClick={onHideModal}
                        style={{
                            backgroundColor: 'transparent',

                        }}
                    >
                        {
                            (gridType === 'Contact Properties' || gridType === 'Properties')
                            &&
                            <svg width="11" height="3" viewBox="0 0 11 3" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.75 0.208496H10.75V2.2085H0.75V0.208496Z"
                                      fill="white" fillOpacity="0.6"/>
                            </svg>
                        }
                    </button>
                    <button
                        type={'button'}
                        onClick={onCloseModalWindow}
                    >
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1387_34908)">
                                <path
                                    d="M4.66667 3L3 4.66667L6.33333 8L3 11.3333L4.66667 13L8 9.66667L11.3333 13L13 11.3333L9.66667 8L13 4.66667L11.3333 3L8 6.33333L4.66667 3Z"
                                    fill="white" fillOpacity="0.6"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1387_34908">
                                    <rect width="10" height="10" fill="white"
                                          transform="matrix(-1 0 0 -1 13 13)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </button>

                </div>
            </div>
            <div className={s.content} style={{
                maxHeight: `${height && height + 80}px`
            }}>
                {children}
            </div>

        </div>
    );
};

export default CommonActivityModal;