import React, {useEffect, useLayoutEffect, useState} from 'react';
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    GetAgentReportsGridData,
    onChangeAgentReportRankOrderNum, onSetAgentReportRankDataForPositions, onSetAgentReportRankDataPositions,
    selectAgentReportsColumns, selectAgentReportsDefaultRowsForPositions,
    selectAgentReportsDomains,
    selectAgentReportsNames,
    selectAgentReportsPositions,
    selectAgentReportsRows,
} from "../../store/agentReportsReducer";
import {selectUserData} from "../../store/userReducer";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import Box from "@mui/material/Box";
import {DataGridPro, GridRowOrderChangeParams} from "@mui/x-data-grid-pro";
import s from "./ReportsAgents.module.scss";
import {Empty, message, Select} from "antd";
import {MuiGridDataColumns} from "../../types/commonTypes";
import {debounce} from "lodash";
import {onSetReqGridHeight} from "../../store/propertiesReducer";
import PageTitle from "../common/PageTitle/PageTitle";
import {gridApi} from "../../app/api";
import {arrayKeysToLower} from '../../customFunctions/transforObjectKeysToLowerCase';
import {destructureArray} from "../../customFunctions/removeObjectFields";
import {filterForUniqueObjectsByProperty} from "../../customFunctions/filterArrayForUniqueValues";

const ReportsAgents = () => {
    return (
        <PageWithSidebar>
            <ReportsAgentsComponent/>
        </PageWithSidebar>
    )
}

const ReportsAgentsComponent = () => {
    const dispatch = useAppDispatch()
    let gridRows = useAppSelector(selectAgentReportsRows)
    const gridColumns = useAppSelector(selectAgentReportsColumns)
    const userData = useAppSelector(selectUserData)
    const [isGridDataLoading, setIsGridDataLoading] = useState(false)
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 340);
    const agentReportNames = useAppSelector(selectAgentReportsNames)
    const agentReportDomains = useAppSelector(selectAgentReportsDomains)
    const agentReportPositions = useAppSelector(selectAgentReportsPositions)
    const [reportName, setReportName] = useState<string | undefined>('')
    const [reportDomain, setReportDomain] = useState<string | undefined>(undefined)
    const [reportPosition, setReportPosition] = useState<string | undefined | null>(undefined)
    const defaultRowsForPositions = useAppSelector(selectAgentReportsDefaultRowsForPositions)
    const [isRowReorderEnabled, setIsRowReorderEnabled] = useState(false)

    let muiGridDataColumns: MuiGridDataColumns[] = gridColumns.map((g: any) => {
        if (g.COL_NAME === 'Actions') {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: 0,
                dataType: g.COL_TYPE,
                col_pos: 7,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
                withSurface: g.withSurface
            }
        } else {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }
        }


    })

    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage - 340);
            dispatch(onSetReqGridHeight(heightForPage))
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [dispatch]);


    useEffect(() => {
        if (reportDomain) {
            const processedRows = defaultRowsForPositions.map(r => ({
                label: r.POSITION || "", // Use empty string if POSITION is null/undefined
                value: r.POSITION // This can be `null`
            }));

            const uniqueReports = filterForUniqueObjectsByProperty(processedRows, 'value');
            dispatch(onSetAgentReportRankDataPositions(uniqueReports));
        }
    }, [defaultRowsForPositions, dispatch, reportDomain]);


    const onChangeSelectValue = (value: string, name: string) => {
        switch (name) {
            case 'reportName':
                setReportName(value as string);
                break;
            case 'domain':
                setReportDomain(value as string)
                setIsGridDataLoading(true)
                dispatch(GetAgentReportsGridData({
                    agent_ref: Number(userData.user_ref),
                    domain: value,
                    position: 'null',
                    report_name: 'Activity statistics pivot'
                }))
                    .then((res) => {
                        // @ts-ignore
                        dispatch(onSetAgentReportRankDataForPositions(res.payload))
                    })
                    .then(() => {
                        dispatch(GetAgentReportsGridData({
                            agent_ref: Number(userData.user_ref),
                            domain: value,
                            position: null,
                            report_name: 'Activity statistics pivot'
                        }))
                            .then(() => {
                                setIsGridDataLoading(false)
                                setReportPosition(null)
                            })
                    })

                break;
            case 'position':
                setReportPosition(value as string)
                setIsGridDataLoading(true)
                dispatch(GetAgentReportsGridData({
                    agent_ref: Number(userData.user_ref),
                    domain: reportDomain!!,
                    position: value,
                    report_name: 'Activity statistics pivot'
                }))
                    .then(() => {
                        setIsGridDataLoading(false)
                    })
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (reportDomain && (reportPosition || reportPosition === null)) {
            setIsRowReorderEnabled(true)
        } else {
            setIsRowReorderEnabled(false)
        }
    }, [reportName, reportDomain, reportPosition]);


    const onDeleteAgentReport = (report: any) => {
        let {id, isFiltered, ...reqData} = report.row;
        reqData = {
            ...reqData,
            ORDER_NUMBER: null
        }
        reqData = Object.keys(reqData).reduce((newObj, key) => {
            // @ts-ignore
            newObj[key.toLowerCase()] = reqData[key];
            return newObj;
        }, {});
        setIsGridDataLoading(true)
        gridApi.createAgentReportRank([reqData])
            .then(() => {
                setTimeout(() => {
                    dispatch(GetAgentReportsGridData({
                        agent_ref: Number(userData.user_ref),
                        domain: reportDomain!!,
                        position: reportPosition!!,
                        report_name: 'Activity statistics pivot'
                    }))
                        .then(() => {
                            setIsGridDataLoading(false)
                        })
                        .then(() => {
                            message.success('Agents report rank successfully removed')
                        })
                }, 2000)
            })
    }

    const handleOrderChange = (data: GridRowOrderChangeParams) => {
        dispatch(onChangeAgentReportRankOrderNum({
            newOrderNum: data.targetIndex,
            rowId: data.row.id,
            oldOrderNum: data.oldIndex
        }))
        let sortedByOrderNum = [...gridRows].sort((a, b) => a.ORDER_NUMBER - b.ORDER_NUMBER);
        for (let i = 0; i < sortedByOrderNum.length - 1; i++) {
            if (sortedByOrderNum[i].ORDER_NUMBER === sortedByOrderNum[i + 1].ORDER_NUMBER) {
                for (let j = i + 1; j < sortedByOrderNum.length; j++) {
                    sortedByOrderNum[j] = {
                        ...sortedByOrderNum[j],
                        ORDER_NUMBER: sortedByOrderNum[j].ORDER_NUMBER + 1
                    };
                }
            }
        }
        gridRows = sortedByOrderNum.map((obj) => {
            if (obj.id === data.row.id) {
                return {...obj, ORDER_NUMBER: data.targetIndex + 1};
            }
            if (data.oldIndex < data.targetIndex) {
                if (obj.ORDER_NUMBER > data.oldIndex + 1 && obj.ORDER_NUMBER <= data.targetIndex + 1) {
                    return {...obj, ORDER_NUMBER: obj.ORDER_NUMBER - 1};
                }
            } else if (data.oldIndex > data.targetIndex) {
                if (obj.ORDER_NUMBER >= data.targetIndex + 1 && obj.ORDER_NUMBER < data.oldIndex + 1) {
                    return {...obj, ORDER_NUMBER: obj.ORDER_NUMBER + 1};
                }
            }
            return obj;
        }).sort((a, b) => a.ORDER_NUMBER - b.ORDER_NUMBER);
        let updatedRankOrderData = arrayKeysToLower(gridRows)
        // eslint-disable-next-line
        const [removedValues, actualData] = destructureArray(updatedRankOrderData, ['id', 'isfiltered'])
        updatedRankOrderData = actualData
        setIsGridDataLoading(true)
        gridApi.createAgentReportRank(updatedRankOrderData)
            .then(() => {
                setTimeout(() => {
                    dispatch(GetAgentReportsGridData({
                        agent_ref: Number(userData.user_ref),
                        domain: reportDomain!!,
                        position: reportPosition!!,
                        report_name: 'Activity statistics pivot'
                    }))
                        .then(() => {
                            setIsGridDataLoading(false)
                        })
                        .then(() => {
                            message.success('Agents report rank order number was successfully updated')
                        })
                }, 2000)
            })
    }


    return (
        <>
            <div style={{
                marginLeft: '55px'
            }}>
                <PageTitle
                    isBackButton={true}
                    backLinkSecondText={`Back`}
                    top={'28px'}
                    backLink={'/parameters'}
                />
            </div>
            <div className={s.tableInner}>

                <div>
                    <div className={s.tableHeader}>
                        <div className={s.tableInner__top}>
                            <h1 className={s.tableTitle}>Report Agents Order</h1>
                            {/*<div onClick={() => setIsCreationModalOpened(true)}>*/}
                            {/*    <PrimaryButton text={'Add agent'} isAddButton={true} width={'130px'}/>*/}
                            {/*</div>*/}
                        </div>
                        <div className={`reportAgentSelects ${s.selectItems}`}>
                            <div className={s.selectItem}>
                                <label>Report name</label>
                                <Select
                                    placeholder="Report name"
                                    onChange={(value, option) => onChangeSelectValue(value, 'reportName')}
                                    options={agentReportNames}
                                    // defaultValue={attrCodeFormFields.domain_cd}
                                    value={'Activity statistics pivot'}
                                    style={{
                                        width: '100% !important',
                                        height: '44px'
                                    }}
                                    allowClear
                                    disabled={true}
                                />
                            </div>
                            <div className={s.selectItem}>
                                <label>Domain</label>
                                <Select
                                    placeholder="Select domain"
                                    onChange={(value, option) => onChangeSelectValue(value, 'domain')}
                                    options={agentReportDomains}
                                    // defaultValue={attrCodeFormFields.domain_cd}
                                    value={reportDomain}
                                    style={{
                                        width: '100% !important',
                                        height: '44px'
                                    }}
                                    allowClear
                                />
                            </div>
                            <div className={s.selectItem}>
                                <label>Position</label>
                                <Select
                                    placeholder="Select position"
                                    onChange={(value, option) => onChangeSelectValue(value, 'position')}
                                    options={agentReportPositions}
                                    // defaultValue={attrCodeFormFields.domain_cd}
                                    value={reportPosition}
                                    style={{
                                        width: '100% !important',
                                        height: '44px'
                                    }}
                                    allowClear
                                />
                            </div>
                        </div>
                    </div>


                    {
                        isGridDataLoading
                            ?
                            <div style={{
                                height: gridContentHeight,
                                width: '400px',
                                margin: '0 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'
                            }}>
                                <div style={{
                                    height: '300px',
                                    width: '100%',
                                    textAlign: 'center'
                                }}>
                                    <Lottie
                                        // @ts-ignore
                                        config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                    />
                                </div>

                                <p style={{
                                    textAlign: 'center'
                                }}>Loading</p>
                            </div>
                            :
                        muiGridDataColumns.length && reportDomain
                            ?
                            <Box sx={{position: 'relative', width: '100%', padding: '0 20px 0 32px'}}>
                                <>
                                    <DataGridPro
                                        sx={{
                                            borderRight: 'none',
                                            borderLeft: 'none',
                                            borderBottom: 'none',
                                            borderRadius: '0px',
                                            // "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                            //     display: "none"
                                            // },
                                            "& .MuiDataGrid-cellContent": {
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#111827',
                                                backgroundColor: '#fff'
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle": {
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 600,
                                                color: '#6B7280',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            },
                                            "& .MuiDataGrid-row:hover": {
                                                backgroundColor: 'transparent'
                                            },
                                            "& .MuiDataGrid-row.Mui-selected": {
                                                backgroundColor: 'transparent'
                                            },
                                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                                backgroundColor: 'transparent'
                                            },
                                            "& .MuiDataGrid-cell:focus": {
                                                outline: 'none'
                                            },
                                            "& .MuiDataGrid-toolbarContainer": {
                                                color: '#454545',
                                                position: 'absolute',
                                                top: '-50px',
                                                right: '0px'
                                            },
                                            // "& .MuiButtonBase-root": {
                                            //     fontFamily: 'Inter, sans-serif !important',
                                            //     fontWeight: 600,
                                            //     color: '#6B7280',
                                            //     fontSize: '12px',
                                            //     lineHeight: '20px',
                                            // },
                                            "& .MuiTablePagination-displayedRows": {
                                                margin: '0',
                                                fontFamily: 'Inter, sans-serif !important',
                                                fontWeight: 400,
                                                color: '#6B7280',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            },
                                            "& .MuiPagination-root": {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                padding: '7px 0px'
                                            },
                                            "& .MuiButtonBase-root.Mui-selected": {
                                                backgroundColor: '#F9FAFB',
                                                borderRadius: '0px'
                                            },
                                            "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                width: '40px',
                                                height: '40px',
                                                margin: 0,
                                                borderRight: '1px solid #D0D5DD',
                                                borderLeft: '1px solid #D0D5DD',
                                                borderTop: 'none',
                                                borderBottom: 'none',
                                                fontFamily: 'Inter, sans-serif !important',
                                                fontWeight: 500,
                                                color: '#1D2939',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                borderRadius: '0px'
                                            },
                                            "& .MuiPagination-ul": {
                                                border: '1px solid #D0D5DD',
                                                borderRadius: '8px'
                                            },
                                            "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                borderRadius: '0px',
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-row:nth-of-type(odd)": {
                                                backgroundColor: '#F9FAFB'
                                            },
                                            // "& .MuiDataGrid-virtualScrollerRenderZone": {
                                            //     width: '100%'
                                            // },
                                            "& .MuiDataGrid-virtualScroller": {
                                                overflowY: 'scroll !important',
                                                height: `${gridContentHeight - 60}px !important`,
                                                minHeight: height < 800 ? '460px' : 'auto',
                                            },
                                            // "& .MuiDataGrid-virtualScrollerContent": {
                                            //     height: `${gridContentHeight + 100}px !important`
                                            // },
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: '#FCFDFE'
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: '1px solid rgba(156, 163, 175, 0.4)',
                                                backgroundColor: '#fff'
                                            },
                                            "& .MuiDataGrid-columnHeader--moving": {
                                                backgroundColor: 'transparent'
                                            },
                                            "& .MuiDataGrid-columnHeader": {
                                                backgroundColor: '#fff'
                                            }
                                        }}
                                        // apiRef={apiRef}
                                        getRowId={e => e.id}
                                        columns={muiGridDataColumns.filter((c: any) => c.field !== 'REPORT_NAME').map((column: any, index: number) => ({
                                            ...column,
                                            renderCell: (params: any) => {
                                                return (
                                                    <CustomCell field={column.field}
                                                                value={params.value}
                                                                key={index}
                                                                onActivityDelete={() => onDeleteAgentReport(params)}

                                                    />
                                                )


                                            },
                                        }))}
                                        rows={gridRows}
                                        {...gridRows}
                                        // getRowClassName={getRowClassName}
                                        headerHeight={52}
                                        // onStateChange={handleStateChange}
                                        hideFooterSelectedRowCount
                                        // onFilterModelChange={handleFilterModelChange}
                                        // onSortModelChange={handleSortModelChange}
                                        sortingOrder={['desc', 'asc']}
                                        // sortModel={Object.values(localSortModel)}
                                        autoPageSize={true}
                                        // rowHeight={rowHeight}
                                        autoHeight={true}
                                        rowReordering={isRowReorderEnabled}
                                        onRowOrderChange={handleOrderChange}
                                        components={{
                                            Footer: () =>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center'
                                                }}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}>
                                                        {/*<p*/}
                                                        {/*    className={s.footerText}*/}
                                                        {/*>*/}
                                                        {/*    Rows per page:*/}
                                                        {/*</p>*/}
                                                        {/*<Select*/}
                                                        {/*    value={rowsPerPage}*/}
                                                        {/*    onChange={handleRowsPerPageChange}*/}
                                                        {/*    className={s.rowsPerPage}*/}
                                                        {/*>*/}
                                                        {/*    {rowsPerPageOptions.map((option) => (*/}
                                                        {/*        <Select.Option key={option} value={option}>*/}
                                                        {/*            {option}*/}
                                                        {/*        </Select.Option>*/}
                                                        {/*    ))}*/}
                                                        {/*</Select>*/}
                                                    </div>
                                                    {/*<Pagination*/}
                                                    {/*    count={Math.ceil(rows.length / rowsPerPage)}*/}
                                                    {/*    page={page}*/}
                                                    {/*    onChange={handlePageChange}*/}
                                                    {/*    variant="outlined"*/}
                                                    {/*    shape="rounded"*/}
                                                    {/*/>*/}
                                                    {/*<p className={s.footerText}>*/}
                                                    {/*    Total requirements: {rows.length}*/}
                                                    {/*</p>*/}
                                                </div>,
                                            // Row: CustomRow
                                        }}
                                    />
                                </>

                            </Box>
                            :
                            <div style={{
                                height: `${gridContentHeight}px`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Empty description={'Please, select domain and position'}/>
                            </div>
                    }
                </div>


                {/*{*/}
                {/*    isCreationModalOpened*/}
                {/*    &&*/}
                {/*    <CreationModal*/}
                {/*        image={<ReportAgentsIcon/>}*/}
                {/*        title={'Add agent'}*/}
                {/*        loading={buttonIsLoading}*/}
                {/*        onSave={onAddAgentToTheReports}*/}
                {/*        onCancel={onCloseCreationModal}*/}
                {/*    >*/}
                {/*        <div className={`${s.form} addNewAgentRank`}>*/}
                {/*            <div className={s.form__row}>*/}
                {/*                <div className={s.form__item}>*/}
                {/*                    <label className={s.form__label}>Agent Name</label>*/}
                {/*                    <Select*/}
                {/*                        placeholder="Agent Name"*/}
                {/*                        onChange={(value, option) => onChangeFormValue(value, 'agent_name', option)}*/}
                {/*                        options={agentReportsAgentNames}*/}
                {/*                        defaultValue={agentReportsFormFields.agent_name}*/}
                {/*                        value={agentReportsFormFields.agent_name}*/}
                {/*                        style={{*/}
                {/*                            width: '100% !important',*/}
                {/*                            height: '44px'*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                    {*/}
                {/*                        agentReportsFormFields.agent_name === '' && checkFieldsForErrors*/}
                {/*                        &&*/}
                {/*                        <div className={s.error}>Please select agent name</div>*/}
                {/*                    }*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className={s.form__row}>*/}
                {/*                <div className={s.form__item}>*/}
                {/*                    <label className={s.form__label}>Report Name</label>*/}
                {/*                    <Select*/}
                {/*                        placeholder="Report Name"*/}
                {/*                        onChange={(value, option) => onChangeFormValue(value, 'report_name', option)}*/}
                {/*                        options={agentReportNames}*/}
                {/*                        defaultValue={agentReportsFormFields.report_name}*/}
                {/*                        value={agentReportsFormFields.report_name}*/}
                {/*                        style={{*/}
                {/*                            width: '100% !important',*/}
                {/*                            height: '44px'*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                    {*/}
                {/*                        agentReportsFormFields.report_name === '' && checkFieldsForErrors*/}
                {/*                        &&*/}
                {/*                        <div className={s.error}>Please select report name</div>*/}
                {/*                    }*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className={s.form__rowDivided}>*/}
                {/*                <div className={s.form__item}>*/}
                {/*                    <label className={s.form__label}>Domain</label>*/}
                {/*                    <Select*/}
                {/*                        placeholder="Domain"*/}
                {/*                        onChange={(value, option) => onChangeFormValue(value, 'domain', option)}*/}
                {/*                        options={agentReportDomains}*/}
                {/*                        defaultValue={agentReportsFormFields.domain}*/}
                {/*                        value={agentReportsFormFields.domain}*/}
                {/*                        style={{*/}
                {/*                            width: '100% !important',*/}
                {/*                            height: '44px'*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                    {*/}
                {/*                        agentReportsFormFields.domain === '' && checkFieldsForErrors*/}
                {/*                        &&*/}
                {/*                        <div className={s.error}>Please select domain</div>*/}
                {/*                    }*/}
                {/*                </div>*/}
                {/*                <div className={s.form__item}>*/}
                {/*                    <label className={s.form__label}>Position</label>*/}
                {/*                    <Select*/}
                {/*                        placeholder=""*/}
                {/*                        onChange={(value, option) => onChangeFormValue(value, 'position', option)}*/}
                {/*                        options={agentReportPositions}*/}
                {/*                        defaultValue={agentReportsFormFields.position}*/}
                {/*                        value={agentReportsFormFields.position}*/}
                {/*                        style={{*/}
                {/*                            width: '100% !important',*/}
                {/*                            height: '44px'*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                    {*/}
                {/*                        agentReportsFormFields.position === '' && checkFieldsForErrors*/}
                {/*                        &&*/}
                {/*                        <div className={s.error}>Please select position</div>*/}
                {/*                    }*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className={s.form__rowDivided}>*/}
                {/*                <div className={s.form__item}>*/}
                {/*                    <label className={s.form__label}>Agent Ref</label>*/}
                {/*                    <Select*/}
                {/*                        placeholder=""*/}
                {/*                        onChange={(value, option) => onChangeFormValue(value, 'agent_ref', option)}*/}
                {/*                        options={agentReportDomains}*/}
                {/*                        defaultValue={agentReportsFormFields.agent_ref}*/}
                {/*                        value={agentReportsFormFields.agent_ref}*/}
                {/*                        style={{*/}
                {/*                            width: '100% !important',*/}
                {/*                            height: '44px'*/}
                {/*                        }}*/}
                {/*                        disabled={true}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*                <div className={s.form__item}>*/}
                {/*                    <label className={s.form__label}>Order Number</label>*/}
                {/*                    <InputNumber*/}
                {/*                        value={agentReportsFormFields.order_number}*/}
                {/*                        onChange={(value: ValueType | null) => onChangeFormValue(value as number, 'order_number')}*/}
                {/*                        min={1}*/}
                {/*                        style={{*/}
                {/*                            height: '44px'*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </CreationModal>*/}
                {/*}*/}
            </div>
        </>

    );
};


const CustomCell = React.memo(({
                                   value,
                                   field,
                                   onActivityDelete
                               }: any) => {

    if (field === 'ACTIONS') {
        return <button
            className={s.deleteBtn}
            onClick={onActivityDelete}
        >
            Delete
        </button>
    } else {
        return (

            <div>{value}</div>
        )
    }


});

export default ReportsAgents;