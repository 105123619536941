import {Box, Tab, Tabs, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import PageTitle from "../../common/PageTitle/PageTitle";
import s from "./EmailTemplate.module.scss";
import {Button, Form, Input} from "antd";
import messageIcon from './../../../img/icons/email-template-message.svg';
import eyeIcon from './../../../img/icons/email-template-eye.svg';
import tooltipImg from './../../../img/icons/tooltip.svg'
import TextArea from "antd/es/input/TextArea";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    GetPropertiesEmailGreeting, GetPropertyAgentAddress, selectEmailGreeting, selectPropertyAgentAddress
} from "../../../store/propertiesReducer";
import {
    GetRequirementsThunk,
    selectRequirementsTemplates,
    selectTemplatesForEmail, selectTemplatesType, UpdateEmailBodyThunk, UpdateEmailSubjectThunk
} from "../../../store/parametersReducer";
import {selectUserData} from "../../../store/userReducer";
import {parametersDataHelper} from "../../../helpers/localStorageHelper";
import firstPropertyPhoto from './../../../img/propertyEmailPhoto.png';
import {Link} from "react-router-dom";
import Tooltip from '../../Tooltip/Tooltip';
import {useNavigate} from "react-router";
import PageWithSidebar from "../../PageWithSidebar/PageWithSidebar";

const EmailTemplate = () => {
    return (
        <PageWithSidebar>
            <EmailTemplateComponent/>
        </PageWithSidebar>
    )
}

const EmailTemplateComponent = () => {
    const dispatch = useAppDispatch()
    const emailGreeting = useAppSelector(selectEmailGreeting)
    const navigate = useNavigate()
    const [value, setValue] = React.useState(0);
    const userData = useAppSelector(selectUserData)
    const requirementsList = useAppSelector(selectRequirementsTemplates)
    const currentEmailTemplate = parametersDataHelper.getCurrentTemplateForEmail()
    const [introductoryParagraphEn, setIntroductoryParagraphEn] = useState('')
    const [completiveParagraphEn, setCompletiveParagraphEn] = useState('')
    const [introductoryParagraphCz, setIntroductoryParagraphCz] = useState('')
    const [completiveParagraphCz, setCompletiveParagraphCz] = useState('')
    const [subjectEn, setSubjetEn] = useState('')
    const [subjectCz, setSubjetCz] = useState('')
    const templatesForEmail = useAppSelector(selectTemplatesForEmail)
    const agentAddress = useAppSelector(selectPropertyAgentAddress)
    const templatesType = useAppSelector(selectTemplatesType)
    const introductoryTextAreaRef = useRef<HTMLTextAreaElement>(null);
    const completiveTextAreaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        dispatch(GetRequirementsThunk(userData.user_ref))
        dispatch(GetPropertyAgentAddress(userData.user_ref))
    }, [dispatch, userData.user_ref])

    function replaceRowWithPTag(inputString: string) {
        const lines = inputString?.split('\n');

        const modifiedLines = [];
        let currentParagraph = '';
        let isEven = false; // Flag to track even/odd paragraphs
        if (lines) {
            for (const line of lines) {
                const regex = /[A-Z]{4,}/g;
                const matches = line.match(regex);

                if (matches && matches.some(match => match.length >= 4)) {
                    // Append the entire line to the current paragraph
                    currentParagraph += line + '\n';
                } else {
                    // If the current paragraph is not empty, wrap it in a <p> tag with styles
                    if (currentParagraph !== '') {

                        let coloredParagraph = currentParagraph.split(' ')
                        coloredParagraph = coloredParagraph?.map((str: any, index: any) => (
                            `<span key=${index} style="color: ${index % 2 === 0 ? 'black' : 'red'}; font-family: Inter, sans-serif;font-weight: 700;font-size: 12pt; margin-bottom: 0; margin-right: 3px">${str}</span> `
                        ));


                        modifiedLines.push(`<span style="display:flex;">${coloredParagraph.join('')}</span>`);
                        currentParagraph = ''; // Reset the current paragraph
                        isEven = !isEven; // Toggle the even/odd flag
                    }

                    // Keep the original line
                    modifiedLines.push(line);
                }
            }
        }

        // If the last paragraph is not empty, wrap it in a <p> tag with styles
        if (currentParagraph !== '') {
            const paragraphStyle = isEven ? 'color: black;' : 'color: red;';
            modifiedLines.push(`<span style="${paragraphStyle}">${currentParagraph.trim()}</span>`);
        }

        const modifiedString = modifiedLines.join('\n');

        return modifiedString;
    }

    const replacedString = replaceRowWithPTag(agentAddress).replace(/\r\n/g, '<br/>');

    // useEffect(() => {
    //     dispatch(GetTemplatesForEmail(templatesType))
    //
    // }, [dispatch])

    const arr = Object.values(templatesForEmail);

    const templates = arr.map((c: any) => c.filter((a: any) => a.template === currentEmailTemplate.col1_template)).flat(1)
    const engTemplate = templates.find((t: any) => t.language === 'EN')
    const czTemplate = templates.find((t: any) => t.language === 'CZ')


    const introductoryParagraphDefaultEn = engTemplate?.signature?.split('#offer')[0]
    const completiveParagraphDefaultEn = engTemplate?.signature?.split('#offer')[1]?.split('#agent_address')[0].replace(/^\n/, "")
    const introductoryParagraphDefaultCz = czTemplate?.signature?.split('#offer')[0]
    const completiveParagraphDefaultCz = czTemplate?.signature?.split('#offer')[1]?.split('#agent_address')[0].replace(/^\n/, "")

    useEffect(() => {
        const introductoryParagraph = engTemplate?.signature?.split('#offer')[0]
        const completiveParagraph = engTemplate?.signature?.split('#offer')[1]?.split('#agent_address')[0].replace(/^\n/, "")
        const introductoryParagraphCz = czTemplate?.signature?.split('#offer')[0]
        const completiveParagraphCz = czTemplate?.signature?.split('#offer')[1]?.split('#agent_address')[0].replace(/^\n/, "")
        setIntroductoryParagraphEn(introductoryParagraph)
        setCompletiveParagraphEn(completiveParagraph)
        setIntroductoryParagraphCz(introductoryParagraphCz)
        setCompletiveParagraphCz(completiveParagraphCz)
        setSubjetEn(engTemplate?.offer_subject)
        setSubjetCz(czTemplate?.offer_subject)
    }, [czTemplate?.offer_subject, czTemplate?.signature, engTemplate?.offer_subject, engTemplate?.signature
])
    useEffect(() => {
        if (requirementsList.length) {
            dispatch(GetPropertiesEmailGreeting({req_id: Number(requirementsList[0].REQ_ID)}))
        }
    }, [dispatch, requirementsList])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const onFinish = (values: any) => {
        if (value === 0) {
            const engTemplateReq = engTemplate?.signature.replace(introductoryParagraphDefaultEn, introductoryParagraphEn).replace(completiveParagraphDefaultEn, completiveParagraphEn)
            if (values.subject !== engTemplate.offer_subject) {
                dispatch(UpdateEmailSubjectThunk({
                    domain: engTemplate.domain,
                    email_text: values.subject,
                    language: 'EN',
                    template_type: templatesType
                }))
            }
            dispatch(UpdateEmailBodyThunk({
                domain: engTemplate.domain,
                email_text: engTemplateReq,
                language: 'EN',
                template_type: templatesType
            }))

        } else {
            const czTemplateReq = czTemplate?.signature.replace(introductoryParagraphDefaultCz, introductoryParagraphCz).replace(completiveParagraphDefaultCz, completiveParagraphCz)
            if (values.subject !== czTemplate.offer_subject) {
                dispatch(UpdateEmailSubjectThunk({
                    domain: engTemplate.domain,
                    email_text: values.subject,
                    language: 'CZ',
                    template_type: templatesType
                }))
            }
            dispatch(UpdateEmailBodyThunk({
                domain: engTemplate.domain,
                email_text: czTemplateReq,
                language: 'CZ',
                template_type: templatesType
            }))
        }
        setTimeout(() => {
            navigate('/parameters/templates-for-email')
        }, 3000)
    }

    const onChangeIntroductoryParagraphEn = (value: any) => {
        setIntroductoryParagraphEn(value.target.value)
    }

    const onChangeCompletiveParagraphEn = (value: any) => {
        setCompletiveParagraphEn(value.target.value)
    }

    const onChangeIntroductoryParagraphCz = (value: any) => {
        setIntroductoryParagraphCz(value.target.value)
    }

    const onChangeCompletiveParagraphCz = (value: any) => {
        setCompletiveParagraphCz(value.target.value)
    }

    const onSetSubjectEn = (value: any) => {
        setSubjetEn(value.target.value)
    }

    const onSetSubjectCz = (value: any) => {
        setSubjetCz(value.target.value)
    }


    useEffect(() => {
        const textarea = introductoryTextAreaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [introductoryParagraphEn, introductoryParagraphCz]);

    useEffect(() => {
        const textarea = completiveTextAreaRef.current;
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }, [completiveParagraphEn, completiveParagraphCz]);


    return (
        <div className={s.inner}>
            <div className={s.inner__pageTitle}>
                <PageTitle title={`Editing ${engTemplate?.template} - ${engTemplate?.domain}`}
                           backLink='/parameters/templates-for-email' backLinktext={'Back'} isBackButton={true}/>
            </div>
            <div>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    style={{
                        marginBottom: '12px'
                    }}
                >
                    <Tab label="ENGLISH"/>
                    <Tab label="CZECH"/>
                </Tabs>
                <TabPanel value={value} index={0}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <Typography style={{
                            width: '50%',
                            backgroundColor: '#fff'
                        }}>
                            <div className={s.inner__item}>
                                <div className={s.inner__itemHeader}>
                                    <p>Message structure</p>
                                    <img src={messageIcon} alt="message-icon"/>
                                </div>
                                <Form
                                    name='offerForm'
                                    onFinish={onFinish}
                                    autoComplete='off'
                                    validateTrigger='onBlur'
                                    className={s.form}
                                    initialValues={{
                                        subject: engTemplate?.offer_subject,
                                        paragraph_greeting: emailGreeting.message || emailGreeting.message !== null ? emailGreeting.message : '',
                                        introductory_paragraph: introductoryParagraphDefaultEn,
                                        completive_paragraph: completiveParagraphDefaultEn
                                    }}
                                >
                                    <div className={s.formItem}>
                                        <label className={s.formItem__label}>Subject for message</label>
                                        <Form.Item
                                            name='subject'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter subject!',
                                                },
                                            ]}
                                            style={{
                                                marginBottom: '12px'
                                            }}

                                        >
                                            <Input
                                                placeholder={'Enter subject'}
                                                onChange={onSetSubjectEn}
                                                style={{
                                                    height: '38px'
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={s.formItem}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '6px'
                                        }}>
                                            <label
                                                className={s.formItem__label}
                                                style={{
                                                    marginBottom: '0',
                                                    marginRight: '2px'
                                                }}
                                            >
                                                Paragraph Greeting
                                            </label>
                                            <Tooltip text="This field is not editable">
                                                <img src={tooltipImg} alt="tooltip"/>
                                            </Tooltip>
                                            {/*<Tooltip title="This field is not editable"  placement="top">*/}
                                            {/*    */}
                                            {/*</Tooltip>*/}
                                        </div>
                                        <Form.Item
                                            name='paragraph_greeting'
                                            style={{
                                                marginBottom: '12px'
                                            }}
                                        >
                                            <div
                                                className={s.fakeInput}
                                                style={{
                                                    height: '54px'
                                                }}
                                            >
                                                {emailGreeting.message}
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className={s.formItem}>
                                        <label className={s.formItem__label}>Introductory paragraph</label>
                                        <Form.Item
                                            name='introductory_paragraph'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter Introductory paragraph!',
                                                },
                                            ]}
                                            style={{
                                                marginBottom: '12px'
                                            }}
                                            initialValue={introductoryParagraphDefaultEn}
                                        >
                                            <TextArea
                                                placeholder={'Enter Introductory paragraph'}
                                                onChange={onChangeIntroductoryParagraphEn}
                                                style={{
                                                    height: '96px',
                                                    // height: `${introductoryTextAreaRef}px`,
                                                }}
                                                value={introductoryParagraphEn}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={s.formItem}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '12px'
                                        }}>
                                            <label
                                                className={s.formItem__label}
                                                style={{
                                                    marginBottom: '0',
                                                    marginRight: '2px'
                                                }}
                                            >
                                                Addresses of the property (example)
                                            </label>
                                            <Tooltip text="This field is not editable">
                                                <img src={tooltipImg} alt="tooltip"/>
                                            </Tooltip>
                                        </div>
                                        <Form.Item
                                            name='addresses_of_the_property'
                                            style={{
                                                marginBottom: '0'
                                            }}

                                        >
                                            <div
                                                className={s.fakeInput}
                                                style={{
                                                    height: templatesType === 'OFFER' ? '300px' : 'fit-content'
                                                }}
                                            >
                                                {
                                                    templatesType === 'OFFER'
                                                        ?
                                                        <div>
                                                            <div className={s.fakePropertyItem}>
                                                                <div className={s.fakePropertyItem__img}>
                                                                    <img src={firstPropertyPhoto}
                                                                         alt="firstPropertyPhoto"/>
                                                                </div>
                                                                <div className={s.fakePropertyItem__textInner}>
                                                                    <p className={s.fakePropertyItem__title}>30450 -
                                                                        Kožná 488 / 14 Prague</p>
                                                                    <p className={s.fakePropertyItem__text}>
                                                                        Unique town house originally from the 14th
                                                                        century. with classicist reconstruction on one
                                                                        of Prague's best shopping streets 60 m from Old
                                                                        Town Square with a potential yield of 2.8%</p>
                                                                </div>
                                                            </div>
                                                            <div className={s.fakePropertyItem}>
                                                                <div className={s.fakePropertyItem__img}>
                                                                    <img src={firstPropertyPhoto}
                                                                         alt="firstPropertyPhoto"/>
                                                                </div>
                                                                <div className={s.fakePropertyItem__textInner}>
                                                                    <p className={s.fakePropertyItem__title}>220804 -
                                                                        Slovanská 379 / 24 Plzeň 2 Slovany</p>
                                                                    <p className={s.fakePropertyItem__text}>
                                                                        Well-maintained apartment building with a
                                                                        spacious yard
                                                                        and a detached garage</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <p>[229166 - Sixty HouseNa Pankráci 310/60, 140 00
                                                                Praha]</p>
                                                            <p>[128900 - Na Pankráci 310/60 | 140 00 Praha]</p>
                                                        </div>
                                                }

                                            </div>
                                        </Form.Item>
                                        <p className={s.propertyAddresses}>Property addresses that will be converted
                                            into hyperlinks in the letter</p>
                                    </div>
                                    <div className={s.formItem}>
                                        <label className={s.formItem__label}>Completive paragraph</label>
                                        <Form.Item
                                            name='completive_paragraph'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter Completive paragraph!',
                                                },
                                            ]}
                                            style={{
                                                marginBottom: '12px'
                                            }}

                                        >
                                            <TextArea
                                                placeholder={'Enter Completive paragraph'}
                                                onChange={onChangeCompletiveParagraphEn}
                                                style={{
                                                    height: '158px',
                                                    resize: 'none'
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={s.formItem}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '12px'
                                        }}>
                                            <label
                                                className={s.formItem__label}
                                                style={{
                                                    marginBottom: '0',
                                                    marginRight: '2px'
                                                }}
                                            >
                                                The signature of the user who is registered in the system.
                                            </label>
                                            <Tooltip text="This field is not editable">
                                                <img src={tooltipImg} alt="tooltip"/>
                                            </Tooltip>
                                        </div>
                                        <Form.Item
                                            name='user_signature'
                                            style={{
                                                marginBottom: '12px'
                                            }}

                                        >
                                            <div
                                                className={s.fakeInput}
                                            >
                                                <p dangerouslySetInnerHTML={{__html: replacedString}}/>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        margin: '30px auto 0'
                                    }}>
                                        <Form.Item style={{
                                            display: 'flex',
                                            justifyContent: "flex-end",
                                            width: '106px',
                                            marginRight: '20px'
                                        }}
                                        >
                                            <Link to='/parameters/templates-for-email'>
                                                <Button
                                                    type='default'
                                                    style={{
                                                        width: '106px',
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Link>
                                        </Form.Item>
                                        <Form.Item style={{
                                            display: 'flex',
                                            justifyContent: "flex-end",
                                            width: '106px',
                                        }}
                                        >
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                style={{
                                                    height: '38px',
                                                    backgroundColor: '#1755E7',
                                                    width: '106px',
                                                    border: '1px solid #1755E7',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',

                                                }}
                                            >
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </Typography>
                        <Typography style={{
                            width: '50%',
                            marginLeft: '20px',
                            backgroundColor: '#fff'
                        }}>
                            <div className={s.inner__item}>
                                <div className={s.inner__itemHeader}>
                                    <p>Template preview</p>
                                    <img src={eyeIcon} alt="eye-icon"/>
                                </div>
                                <div className={s.previewText}>
                                    <p>This is a preview of the email content.</p>
                                </div>
                                <div>
                                    <p className={s.fakeSubject}>{subjectEn}</p>
                                </div>
                                <div className={s.previewMessage}>
                                    <p
                                        style={{
                                            marginBottom: '35px'
                                        }}
                                    >{emailGreeting.message || emailGreeting.message !== null ? emailGreeting.message : ''}</p>
                                    <textarea
                                        value={introductoryParagraphEn}
                                        ref={introductoryTextAreaRef}
                                        className={s.fakeTextarea}
                                        disabled={true}
                                    />
                                    {
                                        templatesType === 'OFFER'
                                            ?
                                            <div>
                                                <div className={s.fakePropertyItem}>
                                                    <div className={s.fakePropertyItem__img}>
                                                        <img src={firstPropertyPhoto} alt="firstPropertyPhoto"/>
                                                    </div>
                                                    <div className={s.fakePropertyItem__textInner}>
                                                        <p className={s.fakePropertyItem__title}>30450 - Kožná 488 / 14
                                                            Prague</p>
                                                        <p className={s.fakePropertyItem__text}>
                                                            Unique town house originally from the 14th century. with
                                                            classicist reconstruction on one of Prague's best shopping
                                                            streets 60 m from Old Town Square with a potential yield of
                                                            2.8%</p>
                                                    </div>
                                                </div>
                                                <div className={s.fakePropertyItem}>
                                                    <div className={s.fakePropertyItem__img}>
                                                        <img src={firstPropertyPhoto} alt="firstPropertyPhoto"/>
                                                    </div>
                                                    <div className={s.fakePropertyItem__textInner}>
                                                        <p className={s.fakePropertyItem__title}>220804 - Slovanská 379
                                                            / 24 Plzeň 2 Slovany</p>
                                                        <p className={s.fakePropertyItem__text}>
                                                            Well-maintained apartment building with a spacious yard
                                                            and a detached garage</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <p className={s.fakePropertyItem__owner}>[229166 - Sixty HouseNa
                                                    Pankráci 310/60, 140 00 Praha]</p>
                                                <p className={s.fakePropertyItem__owner}>[128900 - Na Pankráci 310/60 |
                                                    140 00 Praha]</p>
                                            </div>
                                    }
                                    <textarea
                                        value={completiveParagraphEn}
                                        className={s.fakeTextarea}
                                        ref={completiveTextAreaRef}
                                        disabled={true}
                                        style={{
                                            // height: '200px',
                                            minHeight: '200px',
                                            marginBottom: '50px'
                                        }}/>
                                    <p dangerouslySetInnerHTML={{__html: replacedString}}/>
                                </div>
                            </div>
                        </Typography>
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Typography style={{
                            width: '50%',
                            backgroundColor: '#fff'
                        }}>
                            <div className={s.inner__item}>
                                <div className={s.inner__itemHeader}>
                                    <p>Struktura zprávy</p>
                                    <img src={messageIcon} alt="message-icon"/>
                                </div>
                                <Form
                                    name='offerForm'
                                    onFinish={onFinish}
                                    autoComplete='off'
                                    validateTrigger='onBlur'
                                    className={s.form}
                                    initialValues={{
                                        subject: czTemplate?.offer_subject,
                                        paragraph_greeting: emailGreeting.message || emailGreeting.message !== null ? emailGreeting.message : '',
                                        introductory_paragraph: introductoryParagraphDefaultCz,
                                        completive_paragraph: completiveParagraphDefaultCz
                                    }}
                                >
                                    <div className={s.formItem}>
                                        <label className={s.formItem__label}>Předmět zprávy</label>
                                        <Form.Item
                                            name='subject'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter subject!',
                                                },
                                            ]}
                                            style={{
                                                marginBottom: '12px'
                                            }}

                                        >
                                            <Input
                                                placeholder={'Enter subject'}
                                                style={{
                                                    height: '38px'
                                                }}
                                                onChange={onSetSubjectCz}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={s.formItem}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '6px'
                                        }}>
                                            <label
                                                className={s.formItem__label}
                                                style={{
                                                    marginBottom: '0',
                                                    marginRight: '2px'
                                                }}
                                            >
                                                Odstavec Pozdrav
                                            </label>
                                            <Tooltip text="Toto pole nelze upravovat">
                                                <img src={tooltipImg} alt="tooltip"/>
                                            </Tooltip>
                                        </div>
                                        <Form.Item
                                            name='paragraph_greeting'
                                            style={{
                                                marginBottom: '12px'
                                            }}
                                        >
                                            <div
                                                className={s.fakeInput}
                                                style={{
                                                    height: '54px'
                                                }}
                                            >
                                                {emailGreeting.message}
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div className={s.formItem}>
                                        <label className={s.formItem__label}>Úvodní odstavec</label>
                                        <Form.Item
                                            name='introductory_paragraph'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter Introductory paragraph!',
                                                },
                                            ]}
                                            style={{
                                                marginBottom: '12px'
                                            }}
                                            initialValue={introductoryParagraphDefaultCz}
                                        >
                                            <TextArea
                                                placeholder={'Enter Introductory paragraph'}
                                                onChange={onChangeIntroductoryParagraphCz}
                                                style={{
                                                    height: '96px',
                                                }}
                                                value={introductoryParagraphCz}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={s.formItem}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '12px'
                                        }}>
                                            <label
                                                className={s.formItem__label}
                                                style={{
                                                    marginBottom: '0',
                                                    marginRight: '2px'
                                                }}
                                            >
                                                Adresy nemovitosti (příklad)
                                            </label>
                                            <Tooltip text="Toto pole nelze upravovat">
                                                <img src={tooltipImg} alt="tooltip"/>
                                            </Tooltip>
                                        </div>
                                        <Form.Item
                                            name='addresses_of_the_property'
                                            style={{
                                                marginBottom: '0'
                                            }}

                                        >
                                            <div
                                                className={s.fakeInput}
                                                style={{
                                                    height: templatesType === 'OFFER' ? '300px' : 'fit-content'
                                                }}
                                            >
                                                {
                                                    templatesType === 'OFFER'
                                                        ?
                                                        <div>
                                                            <div className={s.fakePropertyItem}>
                                                                <div className={s.fakePropertyItem__img}>
                                                                    <img src={firstPropertyPhoto}
                                                                         alt="firstPropertyPhoto"/>
                                                                </div>
                                                                <div className={s.fakePropertyItem__textInner}>
                                                                    <p className={s.fakePropertyItem__title}>30450 -
                                                                        Kožná 488 / 14 Prague</p>
                                                                    <p className={s.fakePropertyItem__text}>
                                                                        Unikátní měšťanský dům původně ze 14. stol. s
                                                                        klasicistní přestavbou na jedné z nejlepších
                                                                        pražských ochodních ulic 60 m od Staroměstského
                                                                        náměstí s potenicálním výnosem 2,8%</p>
                                                                </div>
                                                            </div>
                                                            <div className={s.fakePropertyItem}>
                                                                <div className={s.fakePropertyItem__img}>
                                                                    <img src={firstPropertyPhoto}
                                                                         alt="firstPropertyPhoto"/>
                                                                </div>
                                                                <div className={s.fakePropertyItem__textInner}>
                                                                    <p className={s.fakePropertyItem__title}>220804 -
                                                                        Slovanská 379 / 24 Plzeň 2 Slovany</p>
                                                                    <p className={s.fakePropertyItem__text}>
                                                                        Udržovaný činžovní dům s prostorným dvorem a
                                                                        samostatně stojící garáží</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <p>[229166 - Sixty HouseNa Pankráci 310/60, 140 00
                                                                Praha]</p>
                                                            <p>[128900 - Na Pankráci 310/60 | 140 00 Praha]</p>
                                                        </div>
                                                }
                                            </div>
                                        </Form.Item>
                                        <p className={s.propertyAddresses}>Adresy nemovitostí, které budou v dopise
                                            převedeny na hypertextové odkazy</p>
                                    </div>
                                    <div className={s.formItem}>
                                        <label className={s.formItem__label}>Doplňující odstavec</label>
                                        <Form.Item
                                            name='completive_paragraph'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter Completive paragraph!',
                                                },
                                            ]}
                                            style={{
                                                marginBottom: '12px'
                                            }}

                                        >
                                            <TextArea
                                                placeholder={'Enter Completive paragraph'}
                                                onChange={onChangeCompletiveParagraphCz}
                                                style={{
                                                    height: '158px',
                                                    resize: 'none'
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={s.formItem}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '12px'
                                        }}>
                                            <label
                                                className={s.formItem__label}
                                                style={{
                                                    marginBottom: '0',
                                                    marginRight: '2px'
                                                }}
                                            >
                                                Podpis uživatele, který je registrován v systému.
                                            </label>
                                            <Tooltip text="Toto pole nelze upravovat">
                                                <img src={tooltipImg} alt="tooltip"/>
                                            </Tooltip>
                                        </div>
                                        <Form.Item
                                            name='user_signature'
                                            style={{
                                                marginBottom: '12px'
                                            }}

                                        >
                                            <div
                                                className={s.fakeInput}
                                            >
                                                <p dangerouslySetInnerHTML={{__html: replacedString}}/>
                                            </div>
                                        </Form.Item>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        margin: '30px auto 0'
                                    }}>
                                        <Form.Item style={{
                                            display: 'flex',
                                            justifyContent: "flex-end",
                                            width: '106px',
                                            marginRight: '20px'
                                        }}
                                        >
                                            <Link to='/parameters/templates-for-email'>
                                                <Button
                                                    type='default'
                                                    style={{
                                                        width: '106px',
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Link>
                                        </Form.Item>
                                        <Form.Item style={{
                                            display: 'flex',
                                            justifyContent: "flex-end",
                                            width: '106px',
                                        }}
                                        >
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                style={{
                                                    height: '38px',
                                                    backgroundColor: '#1755E7',
                                                    width: '106px',
                                                    border: '1px solid #1755E7',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',

                                                }}
                                            >
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </Typography>
                        <Typography style={{
                            width: '50%',
                            marginLeft: '20px',
                            backgroundColor: '#fff'
                        }}>
                            <div className={s.inner__item}>
                                <div className={s.inner__itemHeader}>
                                    <p>Náhled šablony</p>
                                    <img src={eyeIcon} alt="eye-icon"/>
                                </div>
                                <div className={s.previewText}>
                                    <p>Toto je náhled obsahu e-mailu.</p>
                                </div>
                                <div>
                                    <p className={s.fakeSubject}>{subjectCz}</p>
                                </div>
                                <div className={s.previewMessage}>
                                    <p style={{
                                        marginBottom: '35px'
                                    }}>{emailGreeting.message || emailGreeting.message !== null ? emailGreeting.message : ''}</p>
                                    <textarea
                                        value={introductoryParagraphCz}
                                        className={s.fakeTextarea}
                                        disabled={true}
                                        ref={introductoryTextAreaRef}
                                    />
                                    {
                                        templatesType === 'OFFER'
                                            ?
                                            <div>
                                                <div className={s.fakePropertyItem}>
                                                    <div className={s.fakePropertyItem__img}>
                                                        <img src={firstPropertyPhoto} alt="firstPropertyPhoto"/>
                                                    </div>
                                                    <div className={s.fakePropertyItem__textInner}>
                                                        <p className={s.fakePropertyItem__title}>30450 - Kožná 488 / 14
                                                            Prague</p>
                                                        <p className={s.fakePropertyItem__text}>
                                                            Unikátní měšťanský dům původně ze 14. stol. s klasicistní
                                                            přestavbou na jedné z nejlepších pražských ochodních ulic 60
                                                            m od Staroměstského náměstí s potenicálním výnosem 2,8%</p>
                                                    </div>
                                                </div>
                                                <div className={s.fakePropertyItem}>
                                                    <div className={s.fakePropertyItem__img}>
                                                        <img src={firstPropertyPhoto} alt="firstPropertyPhoto"/>
                                                    </div>
                                                    <div className={s.fakePropertyItem__textInner}>
                                                        <p className={s.fakePropertyItem__title}>220804 - Slovanská 379
                                                            / 24 Plzeň 2 Slovany</p>
                                                        <p className={s.fakePropertyItem__text}>
                                                            Udržovaný činžovní dům s prostorným dvorem
                                                            a samostatně stojící garáží
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <p className={s.fakePropertyItem__owner}>[229166 - Sixty HouseNa
                                                    Pankráci 310/60, 140 00 Praha]</p>
                                                <p className={s.fakePropertyItem__owner}>[128900 - Na Pankráci 310/60 |
                                                    140 00 Praha]</p>
                                            </div>
                                    }
                                    <textarea
                                        value={completiveParagraphCz}
                                        ref={completiveTextAreaRef}
                                        className={s.fakeTextarea}
                                        disabled={true}
                                        style={{
                                            // height: '200px',
                                            minHeight: '200px'
                                        }}
                                    />
                                    <p dangerouslySetInnerHTML={{__html: replacedString}}/>
                                    {/*<img src={defaultLogo} alt="default-logo" style={{*/}
                                    {/*    marginBottom: '50px',*/}
                                    {/*    marginTop: '50px'*/}
                                    {/*}}/>*/}
                                    {/*<p className={s.fakeInput__fakeLink_second}>{userData.email}</p>*/}
                                </div>
                            </div>
                        </Typography>
                    </div>
                </TabPanel>
            </div>
        </div>
    );
};


function TabPanel(props: any) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>{children}</Box>
        </Typography>
    );
}

export default EmailTemplate;