import { Link } from 'react-router-dom'
import classes from './ControlPanel.module.css'
import { ReactComponent as ActivityReportIcon } from './../../img/controlPanel/activityReport.svg'
import { ReactComponent as BusinessReportIcon } from './../../img/controlPanel/businessReport.svg'
import { ReactComponent as DevReportIcon } from './../../img/controlPanel/devReport.svg'
import { ReactComponent as ComReportIcon } from './../../img/controlPanel/comReport.svg'
import { ReactComponent as TelReportIcon } from './../../img/controlPanel/telReport.svg'
import React, {useEffect} from "react";
import {gridDataHelper} from "../../helpers/localStorageHelper";
import {clearPropertiesForEmail} from "../../store/propertiesReducer";
import {useAppDispatch} from "../../app/hooks";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";



const ControlPanel = () => {
  return (
      <PageWithSidebar>
        <ControlPanelComponent/>
      </PageWithSidebar>
  )
}


const ControlPanelComponent = () => {
  const dispatch = useAppDispatch()
  const panelItems = [
    {id: 1, title: 'Activity Report', img: ActivityReportIcon, link: '/control-panel/activity-report'},
    {id: 2, title: 'Agent last contact', img: BusinessReportIcon, link: '/control-panel/agent-last-contact'},
    {id: 3, title: 'Property last contact', img: DevReportIcon, link: '/control-panel/property-last-contact'},
    {id: 4, title: 'Valuated properties last contact', img: ComReportIcon, link: '/control-panel/valuated-properties-last-contact'},
    {id: 5, title: 'Requirement last contact', img: TelReportIcon, link: '/control-panel/requirement-last-contact'},
  ]


  useEffect(() => {
    gridDataHelper.removePropertiesGridFilters()
    gridDataHelper.removeConditionGridFiltersString()
    dispatch(clearPropertiesForEmail([]))

    // dispatch(setActivityReportsDates({start_period: moment().utc().startOf('year').subtract(6, 'months'), end_period: moment().utc().endOf('month')}))
  },[dispatch])

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <div className={classes.titleWrapper}>
          <h1>
            Control Panel
          </h1>
        </div>

        <div className={classes.panelWrapper}>
          {panelItems.map((item, index) => (
            <Link to={item.link} className={`${classes.panelItem} ${classes['item' + index]}`} key={item.id}>
              <item.img />
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ControlPanel
