import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {pickBy} from 'lodash'
import {CheckPersonBelongsToGroup,userAPI} from '../app/api'
import {
    activitiesDataHelper,
    gridDataHelper,
    tokenDataHelper,
    userDataHelper,
    utilityDataHelper
} from '../helpers/localStorageHelper'
import {
    CreateAndUpdateUserAccess,
    FormUserAccessTypes, MenuItems, MenuItemsResponse,
    NewUserAccess,
    SignInDataType,
    UserAccessResponse,
    UserAccessType,
    UserDataType, UserRoleType, UserRoleTypeResponse
} from './../types/userTypes'
import {AppStatusType} from './appStatusReducer'
import {AsyncThunkConfig, RootState} from './store'
import { resetState as resetReqsState } from './requirementsReducer';
import {sessionStorageGridFilters} from "../helpers/sessionStorageHelper";

export interface AvailableUserMenuItem {
    ID: number,
    IS_ACCESS: 1 | 0,
    OBJECT_NAME: string
}

interface InitialStateType {
    isLoggedIn: boolean
    userData: UserDataType
    isBelongsToGroup: boolean | null
    isIntegratedWithGoogle: boolean
    isExternalLink: boolean | null
    availableMenuItems: AvailableUserMenuItem[]
    userAccesses: UserAccessType[]
    newUserAccess: NewUserAccess
    userAccessTypes: FormUserAccessTypes
    userRoles: UserRoleType[]
    menuItems: MenuItems[]
}

const initialState: InitialStateType = {
    isLoggedIn: !!userDataHelper.getUserData(),
    userData: userDataHelper.getUserData(),
    isBelongsToGroup: null,
    isIntegratedWithGoogle: false,
    isExternalLink: null,
    availableMenuItems: userDataHelper.getAvailableMenuItems(),
    userAccesses: [],
    newUserAccess: {
        access_type: null,
        roleName: null,
        menuItemId: null,
        ip_address: ''
    },
    userAccessTypes: ['IP'],
    userRoles: [],
    menuItems: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload
        },
        setUserData: (state, action: PayloadAction<UserDataType>) => {
            state.userData = action.payload
        },
        setIsExternalLink: (state, action: PayloadAction<boolean | null>) => {
            state.isExternalLink = action.payload
        },
        onChangeNewUserAccessField: (state, action: PayloadAction<{ fieldName: string; fieldValue: string | number | boolean | null }>) => {
            const {fieldName, fieldValue} = action.payload;
            if (fieldName in state.newUserAccess) {
                // @ts-ignore
                state.newUserAccess[fieldName] = fieldValue;
            }
        },
        ResetAccessTypesFields: (state) => {
            state.newUserAccess = {
                access_type: null,
                roleName: null,
                menuItemId: null,
                ip_address: ''
            }
        },
        onSetAccessFieldsForEdit: (state, action: PayloadAction<NewUserAccess>) => {
            state.newUserAccess = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginThunk.fulfilled, (state, action) => {
                state.isLoggedIn = true
                state.userData = pickBy(action.payload, (_, key) => key !== 'token') as UserDataType
                state.availableMenuItems = action.payload.menu_items
            })
            .addCase(SignOutThunk.fulfilled, (state) => {
                state.isLoggedIn = false
                state.userData = {} as UserDataType
            })
            .addCase(CheckPersonBelongToGroup.fulfilled, (state, action) => {
                state.isBelongsToGroup = action.payload
            })
            .addCase(CheckIsGoogleIntegrated.fulfilled, (state, action) => {
                state.isIntegratedWithGoogle = action.payload
            })
            .addCase(GetUserAccesses.fulfilled, (state, action) => {
                state.userAccesses = action.payload.user_access_list
            })
            .addCase(GetUserRoles.fulfilled, (state, action) => {
                state.userRoles = action.payload.roles
            })
            .addCase(GetUserMenuItems.fulfilled, (state, action) => {
                state.menuItems = action.payload.menu_items
            })
    }
})

export const {setIsLoggedIn, setUserData, setIsExternalLink, onChangeNewUserAccessField, ResetAccessTypesFields, onSetAccessFieldsForEdit} = userSlice.actions

export const selectIsLoggedIn = (state: RootState): boolean => state.user.isLoggedIn
export const selectUserData = (state: RootState): UserDataType => state.user.userData
export const selectUsername = (state: RootState): string => state.user.userData.username
export const selectIsBelongsToGroup = (state: RootState): boolean | null => state.user.isBelongsToGroup
export const selectIsIntegratedWithGoogle = (state: RootState): boolean => state.user.isIntegratedWithGoogle
export const selectIsExternalLink = (state: RootState): boolean | null => state.user.isExternalLink
export const selectAvailableMenuItems = (state: RootState): AvailableUserMenuItem[] => state.user.availableMenuItems
export const selectUserAccesses = (state: RootState): UserAccessType[] => state.user.userAccesses
export const selectUserAccessFields = (state: RootState): NewUserAccess => state.user.newUserAccess
export const selectUserFormAccessTypes = (state: RootState): FormUserAccessTypes => state.user.userAccessTypes
export const selectUserRoles = (state: RootState): UserRoleType[] => state.user.userRoles
export const selectUserMenuItems = (state: RootState): MenuItems[] => state.user.menuItems

export const LoginThunk = createAsyncThunk<UserDataType, SignInDataType, AsyncThunkConfig>(
    'user/login',
    async (loginData, thunkAPI) => {
        try {
            const {status, data} = await userAPI.signIn(loginData)
            if (status === 200 && data) {
                userDataHelper.setUserData(pickBy(data, (_, key) => key !== 'token') as UserDataType)
                tokenDataHelper.setTokenData(data.token!)
                utilityDataHelper.setUserLastEmailLogged(data.email)
                userDataHelper.setAvailableMenuItems(data.menu_items)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const SignOutThunk = createAsyncThunk<void, void, AsyncThunkConfig>(
    'user/signOut', 
    async (_, thunkAPI) => {
        userAPI.appLogout()
            .then(() => {
                userDataHelper.removeUserData()
                tokenDataHelper.removeTokenData()
                gridDataHelper.removePropertiesGridConfig()
                gridDataHelper.removePropertiesGridColumnsWithSorting()
                gridDataHelper.removeRowId()
                gridDataHelper.removeRowTitle()
                activitiesDataHelper.removeActivityDateEnd()
                activitiesDataHelper.removeActivityDateStart()
                userDataHelper.removeAvailableMenuItems()
                sessionStorageGridFilters.removeRequirementsGridFilters()
                sessionStorageGridFilters.removeActivitiesGridFilters()
                sessionStorageGridFilters.removePropertiesGridFilters()
                sessionStorageGridFilters.removeContactPropertiesGridFilters()
                sessionStorageGridFilters.removeAuditGridFilters()
                sessionStorageGridFilters.removeBrokeragePropertiesGridFilters()
                sessionStorageGridFilters.removeSurfacesGridFilters()
                sessionStorageGridFilters.removePropertiesWithReqGridFilters()
                thunkAPI.dispatch(resetReqsState())
                localStorage.clear()
                sessionStorage.clear()
                window.location.reload()
            })
        return thunkAPI.fulfillWithValue(_, {appStatus: AppStatusType.idle})
    }
)

export const CheckPersonBelongToGroup = createAsyncThunk<any, CheckPersonBelongsToGroup, AsyncThunkConfig>(
    'user/checkPersonBelongsToGroup',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await userAPI.checkUserRole(requestData)
            if (status === 200) {
                userDataHelper.setIsBelongsToGroup(data)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle,})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const IntegrateWithGoogleThunk = createAsyncThunk<string, void, AsyncThunkConfig>(
    'user/integrateWithGoogle',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await userAPI.integrateWithGoogle()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.message, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const FinishGoogleIntegrationThunk = createAsyncThunk<any, string, AsyncThunkConfig>(
    'user/finishGoogleIntegration',
    async (code, thunkAPI) => {
        try {
            const {status, data} = await userAPI.finishGoogleIntegration(code)
            if (status === 200) {
                userDataHelper.setIsIntegratedWithGoogle(true)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const LogoutFromGoogleThunk = createAsyncThunk<void, void, AsyncThunkConfig>(
    'user/logoutFromGoogle',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await userAPI.googleLogout()
            if (status === 200) {
                userDataHelper.setIsIntegratedWithGoogle(false)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            window.location.reload()
        }
    }
)


export const CheckIsGoogleIntegrated = createAsyncThunk<boolean, number, AsyncThunkConfig>(
    'user/checkIsGoogleIntegrated',
    async (user_ref, thunkAPI) => {
        try {
            const {status, data} = await userAPI.checkIsGoogleIntegrated(user_ref)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle,})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetUserAccesses = createAsyncThunk<UserAccessResponse, void, AsyncThunkConfig>(
    'user/getUserAccesses',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await userAPI.getUserAccesses()
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetUserRoles = createAsyncThunk<UserRoleTypeResponse, void, AsyncThunkConfig>(
    'user/getUserRoles',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await userAPI.getUserRoles()
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetUserMenuItems = createAsyncThunk<MenuItemsResponse, void, AsyncThunkConfig>(
    'user/getUserMenuItems',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await userAPI.getMenuItemsList()
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle,})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CreateNewUserAccess = createAsyncThunk<void, CreateAndUpdateUserAccess, AsyncThunkConfig>(
    'user/createUserAccess',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await userAPI.createNewUserAccess(reqData)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'New user access was successfully created'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const UpdateUserAccess = createAsyncThunk<void, {reqData: CreateAndUpdateUserAccess, userAccessId: number}, AsyncThunkConfig>(
    'user/updateUserAccess',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await userAPI.updateUserAccess(reqData.reqData, reqData.userAccessId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'New user access was successfully created'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const DeleteUserAccess = createAsyncThunk<void, number, AsyncThunkConfig>(
    'user/deleteUserAccess',
    async (userAccessId, thunkAPI) => {
        try {
            const {status, data} = await userAPI.deleteUserAccess(userAccessId)
            if (status === 200) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'User access was successfully deleted'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export default userSlice.reducer
 