import {Button, DatePicker,Select} from 'antd';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {ReactComponent as CalendarIcon} from './../../img/icons/solar_calendar-linear.svg'
import Tooltip from "../Tooltip/Tooltip";
import s from './ActivitiesGridForm.module.scss'
import {
    GetActivitiesCompaniesByContactIdThunk,
    GetActivitiesContactsThunk,
    GetActivitiesTypeKind,
    GetActivitiesTypes,
    onChangeModalActivityFormField,
    onClearActivitiesContacts,
    onClearActivitiesFormFields,
    onClearSearchedCompaniesViaContact,
    onSetActivitiesContactId, onSetActivitiesCurrentContact,
    onSetActivitiesCurrentOrganization,
    onSetActivitiesModalOpened,
    onSetActivitiesOrganizationId,
    onSetCurrentContactRef,
    onSetIsDescriptionFieldError,
    onSwitchInputsMode,
    SearchContactsByCompanyNameThunk, selectActivitiesCurrentContact, selectActivitiesCurrentOrganization,
    selectActivitiesCurrentRow,
    selectActivitiesIsDescriptionFieldError,
    selectActivitiesModalFormFields,
    selectActivitiesTypeKind,
    selectActivitiesTypeNumberEntity,
    selectActivitiesTypes,
    selectActivityFormContacts,
    selectIsInputsSwitched,
    selectSearchedCompaniesViaContact,
    selectSearchedContactsViaCompanies
} from "../../store/activitesGrid";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import {useNavigate} from "react-router";
import {
    GetAllCompaniesThunk,
    onClearCompanies,
    onSetIsCompaniesLoading,
    selectAllCompanies, selectCompany,
    selectIsCompaniesLoading
} from "../../store/companies";
import {LoadingOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {selectUserData} from "../../store/userReducer";
import {selectActivitiesModalOpened} from "../../store/propertiesReducer";
import switchImg from './../../img/icons/switcher.png'
import {selectCurrentScreenWidth} from "../../store/utilityReducer";
import {PhoneOutlined, MailOutlined, MobileOutlined} from '@ant-design/icons';
import Hammer from 'hammerjs';

interface ActivitiesGridFormPropsType {
    withButtons: boolean
    editMode: boolean
    maxWidthInputs?: boolean
    disabledInputs?: boolean
    gridType?: string
}

const ActivitiesGridForm = ({withButtons, editMode, maxWidthInputs, disabledInputs}: ActivitiesGridFormPropsType) => {
    const dispatch = useAppDispatch()
    const activitiesTypes = useAppSelector(selectActivitiesTypes)
    const activitiesTypeKind = useAppSelector(selectActivitiesTypeKind)
    const modalFormFields = useAppSelector(selectActivitiesModalFormFields)
    const navigate = useNavigate()
    const companies = useAppSelector(selectAllCompanies)
    const isCompaniesLoading = useAppSelector(selectIsCompaniesLoading)
    const formContacts = useAppSelector(selectActivityFormContacts)
    const isDescriptionFieldError = useAppSelector(selectActivitiesIsDescriptionFieldError)
    const typeNumberEntity = useAppSelector(selectActivitiesTypeNumberEntity)
    const companyName = useAppSelector(selectCompany)
    const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
    const userData = useAppSelector(selectUserData)
    const searchedCompaniesViaContact = useAppSelector(selectSearchedCompaniesViaContact)
    const searchedContactsViaCompanies = useAppSelector(selectSearchedContactsViaCompanies)
    const switched = useAppSelector(selectIsInputsSwitched)
    const isActivityModalOpened = useAppSelector(selectActivitiesModalOpened)
    let typingTimeout: any;
    const location = window.location.pathname
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const currentContact = useAppSelector(selectActivitiesCurrentContact)
    const currentOrganization = useAppSelector(selectActivitiesCurrentOrganization)

    const contacts = formContacts.flat(1).map((contact: any) => ({
        value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ? '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        // label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}`,
        ref: contact.PE_REF,
        phone: contact?.TEL_NUMBER,
        email: contact?.PE_EMAIL,
        mobile: contact?.MOBILE
    }))

    const activityTypes = activitiesTypes?.map((a: any) => ({
        value: a.AE_LI_VALUE,
        label: a.AE_LI_VALUE,
        valueNumberType: a.AE_NO_INDEX
    }))

    const activityTypesKindArr = activitiesTypeKind?.map((a: any) => ({
        value: a.VALUE,
        label: a.VALUE,
        valueNumberType: a.CODE,
        isPropRequired: a.PROP_REQUIRED
    }))

    const companiesList = companies.map((company: any, index) => ({
        value: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        label: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        key: company.REF,
        ref: company.REF,
        phone: company?.PHONE,
        email: company?.EMAIL
    }))

    const searchedCompaniesViaContactList = searchedCompaniesViaContact.map((contact: any, index) => ({
        value: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ?  '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        label: `${contact.PE_FIRST_NAME} ${contact.PE_LAST_NAME}  ${contact?.PE_EMAIL && true ?  '| ' + contact?.PE_EMAIL : ''} ${contact?.TEL_NUMBER && true ? ` | Phone: ${contact?.TEL_NUMBER}` : ''}`,
        key: contact.PE_REF,
        ref: contact.PE_REF,
        phone: contact?.TEL_NUMBER,
        email: contact?.PE_EMAIL,
        mobile: contact?.MOBILE
    }))

    const searchedContactsViaCompaniesList = searchedContactsViaCompanies.map((company: any, index) => ({
        value: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        label: `${company.NAME} ${company.CITY && true ? '| ' + company.CITY : ''} ${company.PE_EMAIL && true ? '| ' + company.PE_EMAIL : ''} ${company?.PHONE && true ? ` | Phone: ${company?.PHONE}` : ''}`,
        key: company.REF,
        ref: company.REF,
        phone: company?.PHONE,
        email: company?.EMAIL
    }))

    const urgencyItems = [
        {value: 'low', label: 'low'},
        {value: 'avg', label: 'avg'},
        {value: 'high', label: 'high'},
    ]

    const domainNames = [
        {value: 'CD', label: 'ČINŽOVNÍ DŮM'},
        {value: 'RS', label: 'E&V-PRAGUE RESIDENTIAL'},
        {value: 'RR', label: 'E&V-PRAGUE RENTAL'},
        {value: 'OR', label: 'OFFICE-RENT'},
    ]

    const onChangeTextAreaValue = (e: React.ChangeEvent<HTMLTextAreaElement>, inputName: string) => {
        if (inputName === 'Description') {
            dispatch(onSetIsDescriptionFieldError(false))
        }
        dispatch(onChangeModalActivityFormField({value: e.target.value, inputName: inputName}))
    };
    const onChangeSelectValue = (value: string, selectName: string, option?: any) => {
        dispatch(onChangeModalActivityFormField({value: value, inputName: selectName}))

        if (selectName === 'Organizations' && !switched) {
            dispatch(onSetActivitiesCurrentOrganization(option))
            dispatch(GetActivitiesContactsThunk(option?.ref))
            dispatch(onSetActivitiesOrganizationId(option?.ref))
            dispatch(onClearCompanies())
            dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
        }
        //////
        else if (selectName === 'Organizations' && switched) {
            dispatch(onSetActivitiesCurrentOrganization(option))
            dispatch(onSetActivitiesOrganizationId(option.ref))
        }
        ///////
        else if (selectName === 'Contacts' && !switched) {
            dispatch(onSetActivitiesCurrentContact(option))
            dispatch(onSetCurrentContactRef(option.ref))
            dispatch(onSetActivitiesContactId(option.ref))
        }
        //////
        else if(selectName === 'Contacts' && switched){
            dispatch(onSetActivitiesCurrentContact(option))
            dispatch(GetActivitiesCompaniesByContactIdThunk(option.ref))
            dispatch(onSetCurrentContactRef(option.ref))
            dispatch(onSetActivitiesContactId(option.ref))
            dispatch(onClearSearchedCompaniesViaContact())
            dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
        }
        //////
        else if (selectName === 'Type') {
            dispatch(onChangeModalActivityFormField({
                value: value,
                inputName: selectName,
                numberEntity: option.valueNumberType
            }))

            dispatch(GetActivitiesTypeKind({
                activity_type_id: option.valueNumberType,
                domain: userData.department!!,
                position: userData.position
            }))

        } else if (selectName === 'Kind') {
            dispatch(onChangeModalActivityFormField({
                value: value,
                inputName: selectName,
                numberEntity: option.valueNumberType,
                activityKindTypeIsPropAndReqRequired: option.isPropRequired
            }))
        } else if (selectName === 'Domain') {
            dispatch(GetActivitiesTypes({domain: value}))
                .then((res) => {
                    if (Array.isArray(res.payload) && res.payload[0] && 'AE_NO_INDEX' in res.payload[0]) {
                        dispatch(GetActivitiesTypeKind({
                            activity_type_id: res.payload[0].AE_NO_INDEX,
                            domain: value,
                            position: currentRowValues.WE_TYPE_POSITION!!
                        }));
                    }
                })
            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
            dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
        }


    }
    const onSearch = (value: string) => {
        if(!switched){
            if (value !== '') {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(() => {
                    dispatch(onSetIsCompaniesLoading(true))
                    dispatch(GetAllCompaniesThunk(value))
                    dispatch(onClearCompanies())
                    dispatch(onClearActivitiesContacts())
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
                }, 2000);

            }
        }
        else{
            if (value !== '') {
                clearTimeout(typingTimeout);
                typingTimeout = setTimeout(() => {
                    dispatch(onSetIsCompaniesLoading(true))
                    dispatch(SearchContactsByCompanyNameThunk({search_value: value}))
                    dispatch(onClearCompanies())
                    dispatch(onClearActivitiesContacts())
                    dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
                }, 2000);

            }

        }

    };


    useEffect(() => {

            if (!editMode) {
                dispatch(GetActivitiesTypes({domain: userData.department!!, withDomain: true, position: userData.position}))
                    .then(() => {
                        if (typeNumberEntity && typeNumberEntity !== null) {
                            dispatch(GetActivitiesTypeKind({
                                activity_type_id: typeNumberEntity,
                                domain: userData.department!!,
                                position: userData.position
                            }))
                        }

                    })
            } else {
                dispatch(GetActivitiesTypes({domain: currentRowValues.WE_TYPE_DOMAIN_CD!, withDomain: true, position: currentRowValues.WE_TYPE_POSITION!}))
                    .then(() => {
                        if (typeNumberEntity && typeNumberEntity !== null) {
                            dispatch(GetActivitiesTypeKind({
                                activity_type_id: typeNumberEntity,
                                domain: currentRowValues.WE_TYPE_DOMAIN_CD!,
                                position: currentRowValues.WE_TYPE_POSITION!
                            }))
                        }

                    })
            }


        },
        [dispatch, editMode, userData.department, modalFormFields.domain, typeNumberEntity, userData.position, currentRowValues.WE_TYPE_POSITION, currentRowValues.WE_TYPE_DOMAIN_CD])


    // useEffect(() => {
    //     console.log(typeNumberEntity)
    //     if (typeNumberEntity && editMode) {
    //         dispatch(GetActivitiesTypeKind(typeNumberEntity))
    //     }
    // }, [dispatch, typeNumberEntity, editMode])

    const handleBlur = () => {
        // Do nothing to prevent value loss
    };
    const onCreateActivity = () => {
        navigate('/organizationActivities')
    }


    const onCloseActivityModal = () => {
        dispatch(onClearActivitiesFormFields())
        dispatch(onSetActivitiesModalOpened(false))
    }


    useEffect(() => {
        if (editMode) {
            const currentActivityTypeNumber = activitiesTypes?.find((t) => t.AE_LI_VALUE === modalFormFields.type)
            if (currentActivityTypeNumber) {
                // @ts-ignore
                dispatch(GetActivitiesTypeKind({activity_type_id: currentActivityTypeNumber?.AE_NO_INDEX!,
                    domain: currentRowValues.WE_TYPE_DOMAIN_CD!,
                    position: currentRowValues.WE_TYPE_POSITION!
                }))

            }

        }

    }, [dispatch, activitiesTypes, modalFormFields.type, editMode, currentRowValues.WE_TYPE_DOMAIN_CD, currentRowValues.WE_TYPE_POSITION])



    const onSearchModeSwitch = () => {
        dispatch(onSwitchInputsMode(!switched))
        dispatch(onClearCompanies())
        dispatch(onClearSearchedCompaniesViaContact())
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
    }


    const addSeparator = (options: any) => {
        if (options.length > 3) {
            // Insert a separator or a distinct styling flag
            options.splice(3, 0, { value: 'separator', label: '-------------------------------------------', disabled: true });
        }
        return options;
    };

    const activityTypesKind = addSeparator(activityTypesKindArr)

    const maxLength = Math.max(contacts.length, companyName.length);

    const handlePhoneCall = (phoneNumber: string) => {
        const linkRef = document.createElement('a');
        linkRef.href = `tel:${phoneNumber}`;
        linkRef.style.display = 'none';
        document.body.appendChild(linkRef);
        const hammer = new Hammer(linkRef);
        hammer.on('tap', () => {
            window.open(`tel:${phoneNumber}`, '_self');
        });
        const clickEvent = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        linkRef.dispatchEvent(clickEvent);
        setTimeout(() => {
            hammer.destroy();
            document.body.removeChild(linkRef);
            document.cookie.split(";").forEach(cookie => {
                document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
            });
        }, 500);
    };


    return (
        <div className={`${s.form} activitiesGridForm`}>
            {
                userData.position !== null && !editMode
                &&
                <p className={s.badge}>{userData.position}</p>
            }
            {
                !switched
                ?
                 <>
                     {
                         !editMode
                             ?
                             <div
                                 className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth} activitiesSearchInput` : `${s.form__item} activitiesSearchInput`}>
                                 <label className={s.form__label}>Organizations</label>
                                 <Select
                                     filterOption={(input, option) =>
                                         (option?.label as string)?.toLowerCase().includes(input.toLowerCase().replace(/%/g, ''))
                                     }
                                     showSearch
                                     placeholder="Search"
                                     optionFilterProp="children"
                                     onChange={(value, option) => onChangeSelectValue(value, 'Organizations', option)}
                                     onSearch={onSearch}
                                     options={companiesList}
                                     notFoundContent={null}
                                     value={modalFormFields.organisations}
                                     style={{
                                         height: '44px'
                                     }}
                                     onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                     onBlur={handleBlur}
                                     loading={isCompaniesLoading}
                                     disabled={disabledInputs || currentRowValues.ACT_TYPE === 'Documentation'}
                                     suffixIcon={!isCompaniesLoading ? <Tooltip
                                         text="Please, use % in searching string, after/before the actual search value, for example gessel% in order to find organizations"
                                         classname={'activitiesSearchInputTooltip'}>
                                         <QuestionCircleOutlined/>
                                     </Tooltip> : <LoadingOutlined/>}
                                 />
                                 {
                                     location !== '/activitiesGrid'
                                     &&
                                     <div style={{
                                         display: 'flex',
                                         gap: '12px',
                                         justifyContent: 'flex-start',
                                         alignItems: 'center',
                                         height: '25px',
                                         padding: '0 10px',
                                         marginTop: '10px'
                                     }}>
                                         {
                                             currentOrganization.phone && currentOrganization.phone !== null && (
                                                 <Tooltip text={`Call company by phone number: ${currentOrganization.phone}`}
                                                          classname={'activitiesContactIconsModeTooltip'} phoneNumber={currentOrganization.phone}>
                                                     <button onClick={() => handlePhoneCall(currentOrganization.phone)}>
                                                         <PhoneOutlined style={{ fontSize: '18px' }}/>
                                                     </button>
                                                 </Tooltip>
                                             )
                                         }
                                         {
                                             currentOrganization.mobile && currentOrganization.mobile !== null && (
                                                 <Tooltip text={`Call company by phone number: ${currentOrganization.mobile}`}
                                                          classname={'activitiesContactIconsModeTooltip'} phoneNumber={currentOrganization.mobile}>
                                                     <button onClick={() => handlePhoneCall(currentOrganization.mobile)}>
                                                         <MobileOutlined style={{ fontSize: '16px' }}/>
                                                     </button>
                                                     {/*<a href={`tel:${currentOrganization.mobile}`} style={{ textDecoration: 'none' }}>*/}
                                                     {/*    <MobileOutlined style={{ fontSize: '16px' }} />*/}
                                                     {/*</a>*/}
                                                 </Tooltip>
                                             )
                                         }
                                         {
                                             currentOrganization.email && currentOrganization.email !== null && (
                                                 <Tooltip text={`Open with email client: ${currentOrganization.email}`}
                                                          classname={'activitiesContactIconsModeTooltip'} email={currentOrganization.email}>
                                                     <button onClick={() => window.open(`mailto:${currentOrganization.email}`, '_self')}>
                                                         <MailOutlined style={{ fontSize: '19px' }}/>
                                                     </button>
                                                 </Tooltip>
                                             )
                                         }
                                     </div>
                                 }

                             </div>
                             :
                             null

                     }
                     {
                         !isActivityModalOpened && location !== '/organizationActivities'
                         &&
                         <button onClick={onSearchModeSwitch} className={s.switchButton}>
                             {/*<svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                             {/*    <path d="M0.292969 5.70056C0.105381 5.51292 0 5.25845 0 4.99312C0 4.72779 0.105381 4.47332 0.292969 4.28568L4.29547 0.283176C4.48312 0.0955887 4.73758 -0.00979233 5.00291 -0.00979233C5.26824 -0.00979233 5.52271 0.0955887 5.71035 0.283176L9.71286 4.28568C9.89513 4.4744 9.99599 4.72716 9.99371 4.98952C9.99143 5.25188 9.88619 5.50285 9.70067 5.68837C9.51514 5.8739 9.26417 5.97913 9.00181 5.98141C8.73945 5.98369 8.48669 5.88283 8.29797 5.70056L6.00354 3.40613L6.00354 14.9994C6.00354 15.2648 5.89812 15.5193 5.71046 15.7069C5.52281 15.8946 5.2683 16 5.00291 16C4.73753 16 4.48302 15.8946 4.29536 15.7069C4.10771 15.5193 4.00229 15.2648 4.00229 14.9994L4.00229 3.40613L1.70785 5.70056C1.52021 5.88815 1.26574 5.99353 1.00041 5.99353C0.735082 5.99353 0.480614 5.88815 0.292969 5.70056ZM10.2992 10.2894C10.4869 10.1018 10.7413 9.99646 11.0067 9.99646C11.272 9.99646 11.5265 10.1018 11.7141 10.2894L14.0085 12.5839V0.99062C14.0085 0.725237 14.114 0.470723 14.3016 0.28307C14.4893 0.0954161 14.7438 -0.010006 15.0092 -0.010006C15.2745 -0.010006 15.5291 0.0954161 15.7167 0.28307C15.9044 0.470723 16.0098 0.725237 16.0098 0.99062V12.5839L18.3042 10.2894C18.4929 10.1072 18.7457 10.0063 19.0081 10.0086C19.2704 10.0109 19.5214 10.1161 19.7069 10.3016C19.8924 10.4871 19.9977 10.7381 20 11.0005C20.0022 11.2628 19.9014 11.5156 19.7191 11.7043L15.7166 15.7068C15.529 15.8944 15.2745 15.9998 15.0092 15.9998C14.7438 15.9998 14.4894 15.8944 14.3017 15.7068L10.2992 11.7043C10.1116 11.5167 10.0063 11.2622 10.0063 10.9969C10.0063 10.7315 10.1116 10.4771 10.2992 10.2894Z" fill="white"/>*/}
                             {/*</svg>*/}
                             <img src={switchImg} alt="switch"/>
                         </button>
                     }
                     <div className={s.form__row}>
                         {
                             editMode
                                 ?
                                 null
                                 :
                                 <div

                                     className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}
                                 >
                                     <label className={s.form__label}>Contact</label>
                                     <Select
                                         placeholder="Contacts"
                                         onChange={(value, option) => onChangeSelectValue(value, 'Contacts', option)}
                                         options={contacts}
                                         defaultValue={modalFormFields.contacts}
                                         value={modalFormFields.contacts}
                                         disabled={disabledInputs || currentRowValues.ACT_TYPE === 'Documentation'}
                                     />
                                     {
                                         location !== '/activitiesGrid'
                                         &&
                                         <div style={{
                                             display: 'flex',
                                             gap: '12px',
                                             justifyContent: 'flex-start',
                                             alignItems: 'center',
                                             height: '25px',
                                             padding: '0 10px',
                                             marginTop: '10px'
                                         }}>
                                             {
                                                 currentContact.phone && currentContact.phone !== null && (
                                                     <Tooltip text={`Call contact by phone number: ${currentContact.phone}`}
                                                              classname={'activitiesContactIconsModeTooltip'} phoneNumber={currentOrganization.phone}>
                                                         <button onClick={() => handlePhoneCall(currentContact.phone)}>
                                                             <PhoneOutlined style={{fontSize: '18px'}}/>
                                                         </button>
                                                         {/*<a href={`tel:${currentContact.phone}`} style={{ textDecoration: 'none' }}>*/}
                                                         {/*    <PhoneOutlined style={{ fontSize: '18px' }} />*/}
                                                         {/*</a>*/}
                                                     </Tooltip>
                                                 )
                                             }
                                             {
                                                 currentContact.mobile && currentContact.mobile !== null && (
                                                     <Tooltip text={`Call contact by phone number: ${currentContact.mobile}`}
                                                              classname={'activitiesContactIconsModeTooltip'} phoneNumber={currentOrganization.mobile}>
                                                         <button onClick={() => handlePhoneCall(currentContact.mobile)}>
                                                             <MobileOutlined style={{fontSize: '16px'}}/>
                                                         </button>
                                                     </Tooltip>
                                                 )
                                             }
                                             {
                                                 currentContact.email && currentContact.email !== null && (
                                                     <Tooltip text={`Open with email client: ${currentContact.email}`}
                                                              classname={'activitiesContactIconsModeTooltip'} email={currentContact.email}>
                                                         <button onClick={() => window.open(`mailto:${currentContact.email}`, '_self')}>
                                                             <MailOutlined style={{fontSize: '19px'}}/>
                                                         </button>
                                                     </Tooltip>
                                                 )
                                             }
                                         </div>
                                     }
                                 </div>
                         }
                     </div>
                 </>
                 :
                    <>
                        {
                            !editMode
                                ?
                                <div
                                    className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth} activitiesSearchInput` : `${s.form__item} activitiesSearchInput`}>
                                    <label className={s.form__label}>Contacts</label>
                                    <Select
                                        filterOption={(input, option) =>
                                            (option?.label as string)?.toLowerCase().includes(input.toLowerCase().replace(/%/g, ''))
                                        }
                                        showSearch
                                        placeholder="Search"
                                        optionFilterProp="children"
                                        onChange={(value, option) => onChangeSelectValue(value, 'Contacts', option)}
                                        onSearch={onSearch}
                                        options={searchedCompaniesViaContactList}
                                        notFoundContent={null}
                                        value={modalFormFields.contacts}
                                        style={{
                                            height: '44px'
                                        }}
                                        onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                        onBlur={handleBlur}
                                        loading={isCompaniesLoading}
                                        disabled={disabledInputs || currentRowValues.ACT_TYPE === 'Documentation'}
                                        suffixIcon={!isCompaniesLoading ? <Tooltip
                                            text="Please, use % in searching string, after/before the actual search value, for example gessel% in order to find contacts"
                                            classname={'activitiesSearchInputTooltip'}>
                                            <QuestionCircleOutlined/>
                                        </Tooltip> : <LoadingOutlined/>}
                                    />
                                    {
                                        location !== '/activitiesGrid'
                                        &&
                                        <div style={{
                                            display: 'flex',
                                            gap: '12px',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            height: '25px',
                                            padding: '0 10px',
                                            marginTop: '10px'
                                        }}>
                                            {
                                                currentContact.phone && currentContact.phone !== null && (
                                                    <Tooltip text={`Call contact by phone number: ${currentContact.phone}`}
                                                             classname={'activitiesContactIconsModeTooltip'} phoneNumber={currentOrganization.phone}>
                                                        <button onClick={() => handlePhoneCall(currentContact.phone)} >
                                                            <PhoneOutlined style={{fontSize: '18px'}}/>
                                                        </button>
                                                        {/*<a href={`tel:${currentContact.phone}`} style={{ textDecoration: 'none' }}>*/}
                                                        {/*    <PhoneOutlined style={{ fontSize: '18px' }} />*/}
                                                        {/*</a>*/}
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                currentContact.mobile && currentContact.mobile !== null && (
                                                    <Tooltip text={`Call contact by phone number: ${currentContact.mobile}`}
                                                             classname={'activitiesContactIconsModeTooltip'} phoneNumber={currentOrganization.mobile}>
                                                        <button onClick={() => handlePhoneCall(currentContact.mobile)}>
                                                            <MobileOutlined style={{fontSize: '16px'}}/>
                                                        </button>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                currentContact.email && currentContact.email !== null && (
                                                    <Tooltip text={`Open with email client: ${currentContact.email}`}
                                                             classname={'activitiesContactIconsModeTooltip'} email={currentContact.email}>
                                                        <button onClick={() => window.open(`mailto:${currentContact.email}`, '_self')}>
                                                            <MailOutlined style={{fontSize: '19px'}}/>
                                                        </button>
                                                    </Tooltip>
                                                )
                                            }
                                        </div>
                                    }
                                </div>
                                :
                                [...Array(maxLength)].map((_, index) => (
                                    <div
                                        className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}
                                        key={index}
                                        style={{
                                            flexDirection: 'row',
                                            gap: '12px',
                                            borderBottom: '1px solid #EDEFF1',
                                            paddingBottom: '10px',
                                            marginTop: '10px'
                                        }}
                                    >
                                        {/* Contact Select */}
                                        {contacts[index] && (
                                            <>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '50%'
                                                }}>
                                                    <label className={s.form__label}>
                                                        Contact
                                                    </label>
                                                    <Select
                                                        placeholder="Contact"
                                                        onChange={(value, option) => onChangeSelectValue(value, 'Contacts', option)}
                                                        options={contacts}
                                                        defaultValue={contacts[index].value}
                                                        value={contacts[index].value}
                                                        disabled={true}
                                                    />
                                                    <div style={{
                                                        display: 'flex',
                                                        gap: '12px',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        height: '25px',
                                                        padding: '0 10px',
                                                        marginTop: '10px'
                                                    }}>
                                                        {
                                                            contacts[index].phone && contacts[index].phone !== null && (
                                                                <Tooltip text={`Call contact by phone number: ${contacts[index].phone}`}
                                                                         classname={'activitiesContactIconsModeTooltip'} phoneNumber={contacts[index].phone}>
                                                                    {/*<button onClick={() => handlePhoneCall(contacts[index].phone)}>*/}
                                                                    {/*    <PhoneOutlined style={{ fontSize: '18px' }}/>*/}
                                                                    {/*</button>*/}
                                                                    <button
                                                                        onClick={() => handlePhoneCall(contacts[index].phone)}
                                                                    >
                                                                        <PhoneOutlined style={{ fontSize: '18px' }} />
                                                                    </button>
                                                                </Tooltip>
                                                            )
                                                        }
                                                        {
                                                            contacts[index].mobile && contacts[index].mobile !== null && (
                                                                <Tooltip text={`Call contact by phone number: ${contacts[index].mobile}`}
                                                                         classname={'activitiesContactIconsModeTooltip'} phoneNumber={contacts[index].mobile}>
                                                                    <button onClick={() => handlePhoneCall(contacts[index].mobile)}>
                                                                        <MobileOutlined style={{ fontSize: '16px' }}/>
                                                                    </button>
                                                                </Tooltip>
                                                            )
                                                        }
                                                        {
                                                            contacts[index].email && contacts[index].email !== null && (
                                                                <Tooltip text={`Open with email client: ${contacts[index].email}`}
                                                                         classname={'activitiesContactIconsModeTooltip'} email={contacts[index].email}>
                                                                    <button onClick={() => window.open(`mailto:${contacts[index].email}`, '_self')}>
                                                                        <MailOutlined style={{ fontSize: '19px' }}/>
                                                                    </button>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </>

                                        )}

                                        {/* Company Select */}
                                        {companyName[index] && (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                width: '50%'
                                            }}>
                                                <label className={s.form__label}>
                                                    Organization
                                                </label>
                                                <Select
                                                    filterOption={(input, option) =>
                                                        (option?.label as string)?.toLowerCase().includes(input.toLowerCase().replace(/%/g, ''))
                                                    }
                                                    showSearch
                                                    placeholder="Search"
                                                    optionFilterProp="children"
                                                    onChange={(value, option) => onChangeSelectValue(value, 'Organizations', option)}
                                                    onSearch={onSearch}
                                                    options={companiesList}
                                                    notFoundContent={null}
                                                    defaultValue={companyName[index].NAME}
                                                    value={companyName[index].NAME}
                                                    style={{ height: '44px' }}
                                                    onInputKeyDown={(e) => e.stopPropagation()} // Prevent input from being cleared on Enter
                                                    onBlur={handleBlur}
                                                    loading={isCompaniesLoading}
                                                    disabled={true}
                                                />
                                                <div style={{
                                                    display: 'flex',
                                                    gap: '12px',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    height: '25px',
                                                    padding: '0 10px',
                                                    marginTop: '10px'
                                                }}>
                                                    {
                                                        companyName[index].TEL_CNTRY_CODE && companyName[index].TEL_CNTRY_CODE !== null && (
                                                            <Tooltip text={`Call company by phone number: ${companyName[index].TEL_CNTRY_CODE}${companyName[index].TEL_REGION_CODE}${companyName[index].TEL_NUMBER}`}
                                                                     classname={'activitiesContactIconsModeTooltip'} phoneNumber={`${companyName[index].TEL_CNTRY_CODE}${companyName[index].TEL_REGION_CODE}${companyName[index].TEL_NUMBER}`}>
                                                                <button onClick={() => handlePhoneCall(`${companyName[index].TEL_CNTRY_CODE}${companyName[index].TEL_REGION_CODE}${companyName[index].TEL_NUMBER}`)}>
                                                                    <PhoneOutlined style={{ fontSize: '18px' }}/>
                                                                </button>
                                                                {/*<a href={`tel:${companyName[index].TEL_CNTRY_CODE}${companyName[index].TEL_REGION_CODE}${companyName[index].TEL_NUMBER}`} style={{ textDecoration: 'none' }}>*/}
                                                                {/*    <PhoneOutlined style={{ fontSize: '18px' }} />*/}
                                                                {/*</a>*/}
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        companyName[index].TEL_FAX_CNTRY_CODE && companyName[index].TEL_FAX_CNTRY_CODE !== null && (
                                                            <Tooltip text={`Call company by phone number: ${companyName[index].TEL_FAX_CNTRY_CODE}${companyName[index].TEL_FAX_REGION_CODE}${companyName[index].TEL_FAX_NUMBER}`}
                                                                     classname={'activitiesContactIconsModeTooltip'} phoneNumber={`${companyName[index].TEL_FAX_CNTRY_CODE}${companyName[index].TEL_FAX_REGION_CODE}${companyName[index].TEL_FAX_NUMBER}`}>
                                                                <button onClick={() => handlePhoneCall(`${companyName[index].TEL_FAX_CNTRY_CODE}${companyName[index].TEL_FAX_REGION_CODE}${companyName[index].TEL_FAX_NUMBER}`)}>
                                                                    <MobileOutlined style={{ fontSize: '16px' }}/>
                                                                </button>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        companyName[index].EMAIL && companyName[index].EMAIL !== null && (
                                                            <Tooltip text={`Open with email client: ${companyName[index].EMAIL}`}
                                                                     classname={'activitiesContactIconsModeTooltip'} email={companyName[index].EMAIL}>
                                                                <button onClick={() => window.open(`mailto:${companyName[index].EMAIL}`, '_self')}>
                                                                    <MailOutlined style={{ fontSize: '19px' }}/>
                                                                </button>
                                                            </Tooltip>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))

                        }
                        {
                            !isActivityModalOpened && location !== '/organizationActivities'
                            &&
                            <button onClick={onSearchModeSwitch} className={s.switchButton}>
                                <img src={switchImg} alt="switch"/>
                            </button>
                        }
                        <div className={s.form__row} style={{
                            flexDirection: 'column',
                        }}>
                            {
                                editMode
                                    ?
                                    null
                                    :
                                    <div

                                        className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}
                                    >
                                        <label className={s.form__label}>Organizations</label>
                                        <Select
                                            placeholder="Enter organization"
                                            onChange={(value, option) => onChangeSelectValue(value, 'Organizations', option)}
                                            options={searchedContactsViaCompaniesList}
                                            defaultValue={modalFormFields.organisations}
                                            value={modalFormFields.organisations}
                                            disabled={disabledInputs || currentRowValues.ACT_TYPE === 'Documentation'}
                                        />
                                        {
                                            location !== '/activitiesGrid'
                                            &&
                                            <div style={{
                                                display: 'flex',
                                                gap: '12px',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                height: '25px',
                                                padding: '0 10px',
                                                marginTop: '10px'
                                            }}>
                                                {
                                                    currentOrganization.phone && currentOrganization.phone !== null && (
                                                        <Tooltip text={`Call company by phone number: ${currentOrganization.phone}`}
                                                                 classname={'activitiesContactIconsModeTooltip'} phoneNumber={currentOrganization.phone}>
                                                            <button onClick={() => handlePhoneCall(currentOrganization.phone)}>
                                                                <PhoneOutlined style={{fontSize: '18px'}}/>
                                                            </button>
                                                            {/*<a href={`tel:${currentOrganization.phone}`} style={{ textDecoration: 'none' }}>*/}
                                                            {/*    <PhoneOutlined style={{ fontSize: '18px' }} />*/}
                                                            {/*</a>*/}
                                                        </Tooltip>
                                                    )
                                                }
                                                {
                                                    currentOrganization.mobile && currentOrganization.mobile !== null && (
                                                        <Tooltip text={`Call company by phone number: ${currentOrganization.mobile}`}
                                                                 classname={'activitiesContactIconsModeTooltip'} phoneNumber={currentOrganization.mobile}>
                                                            <button onClick={() => handlePhoneCall(currentOrganization.mobile)}>
                                                                <MobileOutlined style={{fontSize: '16px'}}/>
                                                            </button>
                                                        </Tooltip>
                                                    )
                                                }
                                                {
                                                    currentOrganization.email && currentOrganization.email !== null && (
                                                        <Tooltip text={`Open with email client: ${currentOrganization.email}`}
                                                                 classname={'activitiesContactIconsModeTooltip'} email={currentOrganization.email}>
                                                            <button onClick={() => window.open(`mailto:${currentOrganization.email}`, '_self')}>
                                                                <MailOutlined style={{fontSize: '19px'}}/>
                                                            </button>
                                                        </Tooltip>
                                                    )
                                                }
                                            </div>
                                        }
                                    </div>
                            }
                        </div>
                    </>
            }

            <div className={s.form__row}>
                <div className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item} style={{
                    marginRight: '12px'
                }}>
                    <label className={s.form__label}>Domain</label>

                    <Select
                        placeholder="Select domain"
                        onChange={(value, option) => onChangeSelectValue(value, 'Domain', option)}
                        options={domainNames}
                        defaultValue={!editMode
                            ? userData.department !== 'null' && userData.department !== 'ALL'
                                ? userData.department : modalFormFields.domain
                            : modalFormFields.domain
                        }
                        value={!editMode
                            ? userData.department !== 'null' && userData.department !== 'ALL'
                                ? userData.department : modalFormFields.domain
                            : modalFormFields.domain
                        }
                        disabled={
                            (userData.department !== 'null' && userData.department !== 'ALL')
                            || currentRowValues.ACT_TYPE === 'Documentation'
                            || (editMode && currentRowValues.WE_TYPE_DOMAIN_CD !== '')
                            || (editMode && currentRowValues.WE_TYPE_DOMAIN_CD !== null)
                            || disabledInputs
                        }
                    />


                </div>
                <div className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}>
                    <label className={s.form__label}>Type</label>
                    {
                        !activitiesTypes?.length
                            ?
                            <Tooltip text="Please find and select the domain for the activity in the field above"
                                     classname={'addressButtonsTooltip'}>
                                <Select
                                    placeholder="Select type"
                                    onChange={(value, option) => onChangeSelectValue(value, 'Type', option)}
                                    options={activityTypes}
                                    defaultValue={modalFormFields.type}
                                    value={modalFormFields.type}
                                    disabled={disabledInputs || true || currentRowValues.ACT_TYPE === 'Documentation'}
                                    style={{
                                        width: '100%'
                                    }
                                    }
                                />
                            </Tooltip>
                            :
                            <Select
                                placeholder="Select type"
                                onChange={(value, option) => onChangeSelectValue(value, 'Type', option)}
                                options={activityTypes}
                                defaultValue={modalFormFields.type}
                                value={modalFormFields.type}
                                disabled={currentRowValues.ACT_TYPE === 'Documentation'}
                            />
                    }


                </div>
            </div>
            <div className={s.form__row}>
                <div className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}  style={{
                    marginRight: '12px',
                    // width: currentScreenWidth! < 1024 ? '100%' : '365px',
                    // minWidth: !editMode && !maxWidthInputs ? '369px' : '294.5px'
                }}>
                    <label className={s.form__label}>Urgency</label>
                    <Select
                        placeholder="Select urgency"
                        onChange={(value, option) => onChangeSelectValue(value, 'Urgency')}
                        options={urgencyItems}
                        value={modalFormFields.urgency}
                        disabled={currentRowValues.ACT_TYPE === 'Documentation'}
                    />
                </div>
                <div className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}>
                    <label className={s.form__label}>Kind</label>
                    {
                        (typeNumberEntity === null || modalFormFields.type === '' || (editMode && modalFormFields.domain === ''))
                            ?
                            <Tooltip text="Please find and select the type of the activity in the field above"
                                     classname={'addressButtonsTooltip'}>
                                <Select
                                    placeholder="Select kind"
                                    onChange={(value, option) => onChangeSelectValue(value, 'Kind', option)}
                                    options={activityTypesKind}
                                    // defaultValue={modalFormFields.kind}
                                    value={modalFormFields.kind}
                                    disabled={disabledInputs || true || currentRowValues.ACT_TYPE === 'Documentation'}
                                    style={{
                                        width: '100%',
                                        maxWidth: currentScreenWidth! < 1024 ? '100%' : !editMode && !maxWidthInputs ? '369px' : '294.5px'
                                    }
                                    }
                                />
                            </Tooltip>
                            :
                            <Select
                                placeholder="Select kind"
                                onChange={(value, option) => onChangeSelectValue(value, 'Kind', option)}
                                options={activityTypesKind}
                                // defaultValue={modalFormFields.kind}
                                value={modalFormFields.kind}
                                disabled={currentRowValues.ACT_TYPE === 'Documentation'}
                                style={{
                                    maxWidth: currentScreenWidth! < 1024 ? '100%' : !editMode && !maxWidthInputs ? '369px' : '294.5px'
                                }}
                            />
                    }

                </div>
            </div>

            <div className={s.form__row}>
                <div className={s.form__dates} style={{
                    width: '100%',
                }}>
                    <div style={{
                        marginRight: '12px',
                        width: currentScreenWidth! < 1024 ? '100%' : !editMode && !maxWidthInputs ? '369px' : '294.5px',
                        minWidth: currentScreenWidth! < 1024 ? 'initial' : !editMode && !maxWidthInputs ? '369px' : '294.5px'
                    }} className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}>
                        <label className={s.form__label}>Date from</label>
                        <CustomDatePicker
                            datePickerType={'date_from'}
                            value={modalFormFields.date_from}
                            disabled={currentRowValues.ACT_TYPE === 'Documentation'}
                        />
                    </div>
                    <div style={{
                        width: currentScreenWidth! < 1024 ? '100%' : !editMode && !maxWidthInputs ? '369px' : '294.5px',
                        minWidth: currentScreenWidth! < 1024 ? 'initial' : !editMode && !maxWidthInputs ? '369px' : '294.5px'
                    }} className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}>
                        <label className={s.form__label}>Date till</label>
                        <CustomDatePicker
                            datePickerType={'date_till'}
                            value={modalFormFields.date_till}
                            disabledDate={true}
                            disabled={currentRowValues.ACT_TYPE === 'Documentation'}
                        />
                    </div>
                </div>
            </div>

            <div className={s.form__row}>
                <div className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}>
                    <label className={s.form__label}>Description</label>
                    <TextArea
                        placeholder={'Enter a description...'}
                        value={modalFormFields.description}
                        style={{
                            resize: 'none',
                            height: '84px',
                            padding: '10px 14px',
                            borderColor: isDescriptionFieldError ? '#ff0000' : '#D0D5DD'
                        }}
                        onChange={(e) => onChangeTextAreaValue(e, 'Description')}
                        required={true}
                        disabled={currentRowValues.ACT_TYPE === 'Documentation'}
                    />
                    {isDescriptionFieldError && <div className={s.errorText}>Description is required</div>}
                </div>
            </div>
            <div className={s.form__row}>
                <div className={editMode || maxWidthInputs ? `${s.form__item} ${s.form__item_fullWidth}` : s.form__item}>
                    <label className={s.form__label}>Remark</label>
                    <TextArea
                        placeholder={'Enter a remark...'}
                        style={{resize: 'none', height: '94px', padding: '10px 14px'}}
                        value={modalFormFields.remark}
                        onChange={(e) => onChangeTextAreaValue(e, 'Remark')}
                        disabled={currentRowValues.ACT_TYPE === 'Documentation'}
                    />
                </div>
            </div>


            {
                withButtons
                &&
                <div className={s.form__buttons}>
                    <Button
                        type='default'
                        style={{
                            height: '44px',
                            width: '360px'
                        }}
                        onClick={onCloseActivityModal}
                    >
                        Cancel
                    </Button>
                    {
                        modalFormFields.organisations === '' || modalFormFields.contacts === '' || modalFormFields.type === '' || modalFormFields.kind === null
                            ?
                            <Tooltip
                                text="Please find and select the name of the organisation and its contact, select the domain , activity type and activity kind"
                                classname={'addressButtonsTooltip'}>
                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    style={{
                                        height: '44px',
                                        backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                        color: '#fff',
                                        border: '1px solid transparent',
                                        display: 'flex',
                                        width: '360px',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                    }}
                                    onClick={onCreateActivity}
                                    disabled={modalFormFields.organisations === '' || modalFormFields.contacts === '' || modalFormFields.domain === '' || modalFormFields.type === '' || modalFormFields.kind === ''}
                                >
                                    Next
                                </Button>
                            </Tooltip>
                            :
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{
                                    height: '44px',
                                    backgroundColor: '#0707FA',
                                    width: '360px',
                                    border: '1px solid #0707FA',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}
                                onClick={onCreateActivity}
                            >
                                Next
                            </Button>
                    }

                </div>
            }

        </div>
    );
};


function CustomDatePicker({datePickerType, value, disabledDate, disabled}: any) {
    const dispatch = useAppDispatch()
    const modalFormFields = useAppSelector(selectActivitiesModalFormFields)
    const handleDateChange = (date: any) => {
        dispatch(onChangeModalActivityFormField({value: date, inputName: datePickerType}))
    };

    const disabledDateTill = (current: any) => {
        return moment(modalFormFields.date_till) && current && current.isBefore(moment(modalFormFields.date_from), 'day');
    };
    return (

        disabledDate

            ?
            <DatePicker
                value={value ? moment(value) : null}
                disabledDate={disabledDateTill}
                disabled={disabled}
                onChange={handleDateChange}
                suffixIcon={<CalendarIcon/>}
                style={{
                    height: '44px'
                }}
            />
            :
            <DatePicker
                value={value ? moment(value) : null}
                onChange={handleDateChange}
                disabled={disabled}
                suffixIcon={<CalendarIcon/>}
                style={{
                    height: '44px'
                }}
            />


    );
}

export default ActivitiesGridForm;