import { Form, FormInstance, Radio, Spin } from 'antd'
import { useEffect, useState } from 'react'
import classes from './DomainFormItem.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {GetCompaniesByPeriodThunk, onChangeActivityDomainName, selectCompanies} from '../../../store/reportReducer'

const DomainFormItem: React.FC<{form: FormInstance}> = ({form}) => {
  const dispatch = useAppDispatch()
  const companies = useAppSelector(selectCompanies)

  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)

  useEffect(() => {
    if (!companies.length) {
      setIsLoadingCompanies(true)
      dispatch(GetCompaniesByPeriodThunk())
        .then(() => setIsLoadingCompanies(false))
    }
    // eslint-disable-next-line
  }, [dispatch, setIsLoadingCompanies])

  const onChangeDomainName = (domain_code: string) => {
    dispatch(onChangeActivityDomainName(domain_code))
    form.setFields([{...form.getFieldValue('domain'), errors: []}])
  }

  return  (
    <>
      <div className={classes.label}>
        Domain:
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
        <Form.Item
          name='domain'
          rules={[{ required: true, message: 'Please select a domain' }]}
        >
          <Radio.Group optionType='button' className={classes.domainOptions}>
            {companies?.map(company => (
              <Radio
                value={company.domain_code}
                key={company.domain_code}
                onClick={() => onChangeDomainName(company.domain_code)}
              >
                {company.domain_code}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        {isLoadingCompanies &&
          <Spin style={{marginLeft: '10px', paddingTop: '7px'}}/>
        }
      </div>
    </>
  )
}

export default DomainFormItem
