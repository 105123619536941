import {Navigate} from 'react-router'
import classes from './SignIn.module.scss'
import {ReactComponent as Logo} from './../../img/logo.svg'
import {useAppSelector} from '../../app/hooks'
import {selectIsLoggedIn} from '../../store/userReducer'
import footerLogo from './../../img/signInFooterLogo.png'
import {Link} from "react-router-dom";
import SignInForm from './SignInForm/SignInForm'


const SignIn = () => {
    const isLoggedIn = useAppSelector(selectIsLoggedIn)

    if (isLoggedIn) {
        return <Navigate to='/requirements'/>

    }

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.contentWrapper}>
                    <div className={classes.formHeader}>
                        <Logo className={classes.logo}/>
                        <h1>
                            PRIMO PORTAL
                        </h1>
                        <h2>
                            Sign in to your profile
                        </h2>
                    </div>
                    <SignInForm/>
                </div>
            </div>
            <div className={classes.footer}>
                <div className={classes.footer__inner}>
                    <div className={classes.footer__logo}>
                        <img src={footerLogo} alt="primo-portal-logo"/>
                        <p>Primo Portal</p>

                    </div>
                    <div className={classes.footer__links}>
                        {/*<a href="https://www.privacypolicies.com/live/cc4c64c2-9bd4-4e27-81ec-20acf7213754" target={'_blank'}>Terms</a>*/}

                        <Link to='/termsAndConditions'>
                            Terms and conditions
                        </Link>
                        <Link to='/privacyPolicy' style={{
                            marginLeft: '16px'
                        }}>
                            Privacy Policy
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}



export default SignIn
