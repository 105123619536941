import {Link} from 'react-router-dom'
import classes from './PageTitle.module.css'
import {ReactComponent as LeftArrow} from './../../../img/icons/leftArrow.svg'

const PageTitle: React.FC<{ title?: string, backLink?: string, isBackButton?: boolean, backLinktext?: string, backLinkSecondText?: string, top?: string, isLinkDisabled?: boolean, left?: string }> = ({
                                                                                                                                                                                                           title,
                                                                                                                                                                                                           backLink,
                                                                                                                                                                                                           isBackButton,
                                                                                                                                                                                                           backLinktext,
                                                                                                                                                                                                           backLinkSecondText,
                                                                                                                                                                                                           top,
                                                                                                                                                                                                           isLinkDisabled,
                                                                                                                                                                                                           left
                                                                                                                                                                                                       }) => {

    return (
        <>
            {isBackButton
                &&
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    top: top ? top : 'initial',
                    left: left ? left : 'initial'
                }}>
                    {
                        isLinkDisabled
                            ?
                            <div className={classes.backLink} style={{
                                cursor: 'pointer'
                            }}>
                                <LeftArrow/>
                                <span className={classes.backLinkDisabled}>{backLinktext || title}</span>
                            </div>
                            :
                            <Link to={backLink || '/requirements'} className={classes.backLink}>
                                <>
                                    <LeftArrow/><span>{backLinktext || title}</span>
                                </>
                            </Link>
                    }

                    <p className={classes.addText}>{backLinkSecondText}</p>
                </div>
            }
            {title && <div className={classes.titleWrapper}>
                <Link to='/' className={classes.smallScreenBackLink}>
                    <LeftArrow/>
                </Link>
                <h1>
                    {title}
                </h1>
            </div>}

        </>
    )
}

export default PageTitle
