import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    gridApi, requirementsApi,
} from '../app/api'
import {RequirementType} from '../types/parametersTypes'
import {AppStatusType, setAppStatus} from './appStatusReducer'
import {AsyncThunkConfig, RootState} from './store'
import moment, {Moment} from "moment";
import {GridSortItem} from "@mui/x-data-grid";
import {ColumnsForFiltersType, ShownAndHiddenColumnsType} from "./propertiesReducer";
import {ColumnsFiltersType} from "./activitesGrid";
import {GridColumns} from "../types/commonTypes";
import {gridDataHelper, userDataHelper} from "../helpers/localStorageHelper";
import {CreateAndUpdateReqFields} from "../types/requirementsTypes";
import {GridResData} from "../types/commonGridsTypes";
export interface CreateContactType {
    "pe_birthday": null | string | undefined,
    "pe_department": null | string,
    "pe_email": null | string,
    "pe_first_name": null | string,
    "pe_gender_title": null | string,
    "pe_is_mail": number | null,
    "pe_job_position": null | string,
    "pe_lang_index": number | null,
    "pe_last_name": null | string,
    "pe_mob_cntry_code": null | string,
    "pe_mob_number": null | string,
    "pe_mob_region_code": null | string,
    "pe_profession_title": null | string,
    "pe_ref": null | string,
    "pe_remarks": null | string,
    "pe_tel_cntry_code": null | string,
    "pe_tel_number": null | string,
    "pe_tel_region_code": null | string
    id: number
    contact_tel: null
    contact_mob: null
}



export interface CreateOrganization {
    "co_city": any,
    "co_country_ref": any,
    "co_first_contact": any,
    "co_name": any,
    "co_no_connect": any,
    "co_no_from": any,
    "co_no_from_rest": any,
    "co_no_to": any,
    "co_no_to_rest": any,
    "co_quarter": any,
    "co_ref": any,
    "co_source_index": any,
    "co_street_address": any,
    "co_zip": any,
    "co_tel_cntry_code": any,
    "co_tel_number": any,
    "co_tel_region_code": any,
    co_phone: any,
    "co_fax_cntry_code": any,
    "co_fax_number": any,
    "co_fax_region_code": any,
    co_fax: any,
    "co_email": any,
    "co_web": any,
}

export interface CommonCreateReqFields {
    balcony: number,
    city_parts: number[],
    currency: number,
    department: string,
    dispositions: string[],
    is_furnished: string | null,
    loggia: number,
    parking_qnt: null | number,
    price_per_m2_max: number | string,
    price_per_m2_min: number,
    price_total_max: number | string,
    price_total_min: number,
    property_type: string | null,
    reconstruction_state: number | null,
    req_remark: string,
    terrace: number,
    useful_square_max: number | string,
    useful_square_min: number,
    user_ref: number,
    yield_act_max: number | string,
    yield_act_min: number,
    yield_pot_max: number | string,
    yield_pot_min: number
    other_criterias: any[]
}

export interface RequirementFunction {
    AE_LI_VALUE: string
    AE_NO_INDEX: number
}


interface InitialStateType {
    requirements: RequirementType[],
    requirementsColdOffers: any[],
    requirementColumns: GridColumns[],
    requirementColdOfferColumns: GridColumns[],
    requirementShownColumns: ShownAndHiddenColumnsType[],
    requirementHiddenColumns: ShownAndHiddenColumnsType[],
    requirementsGridFiltersForColumns: ColumnsFiltersType[],
    requirementsGridColumnsForFilters: ColumnsForFiltersType[],
    requirementsDefaultGridColumns: RequirementType[],
    requirementsSortingString: string | null,
    requirementsSortingModel: GridSortItem[] | [],
    requirementColdOfferShownColumns: ShownAndHiddenColumnsType[],
    requirementColdOfferHiddenColumns: ShownAndHiddenColumnsType[],
    requirementsColdOfferGridFiltersForColumns: ColumnsFiltersType[],
    requirementsColdOfferGridColumnsForFilters: ColumnsForFiltersType[],
    requirementsColdOfferDefaultGridColumns: RequirementType[],
    requirementsColdOfferSortingString: string | null,
    requirementsColdOfferSortingModel: GridSortItem[] | [],
    requirementsSearchedByContacts: RequirementType[]
    requirementsSearchedByContactsColumns: GridColumns[],
    requirementsSearchedByContactsShownColumns: ShownAndHiddenColumnsType[],
    requirementsSearchedByContactsHiddenColumns: ShownAndHiddenColumnsType[],
    requirementsSearchedByContactsGridFiltersForColumns: ColumnsFiltersType[],
    requirementsSearchedByContactsGridColumnsForFilters: ColumnsForFiltersType[],
    requirementsSearchedByContactsDefaultGridColumns: RequirementType[],
    requirementsSearchedByContactsSortingString: string | null,
    requirementsSearchedByContactsSortingModel: GridSortItem[] | [],
    reqsSearchedByCompany: any[],
    currentCreateReqStep: 'Create contact' | 'Create organization' | 'Create requirement' | string,
    requirementCreateContacts: CreateContactType[],
    requirementCreateOrganization: CreateOrganization,
    isStepsClickAvailable: boolean
    organizationStepSkipped: boolean
    organizationName: string
    isSearchContactsLoading: boolean
    commonCreateReqFields: CommonCreateReqFields
    reqDataWasNotFound: boolean
    isReqDataLoading: boolean
    isReqEditMode: boolean
    requirementsFunctions: RequirementFunction[]
    currentGridType: 'Requirements' | 'Requirements/Cold Offer'
    coldOfferRequirementLinkedProperties: {
        "ADDRESS": string | null,
        "CITY": string | null,
        "DESCRIPTION": string | null,
        "QUARTER": string | null,
        "REF": number | null
    }[]
}

const initialState: InitialStateType = {
    requirements: [],
    requirementsColdOffers: [],
    requirementColumns: [],
    requirementColdOfferColumns: [],
    requirementShownColumns: [],
    requirementHiddenColumns: [],
    requirementsGridFiltersForColumns: [],
    requirementsGridColumnsForFilters: [],
    requirementsDefaultGridColumns: [],
    requirementsSortingString: null,
    requirementsSortingModel: [],
    requirementColdOfferShownColumns: [],
    requirementColdOfferHiddenColumns: [],
    requirementsColdOfferGridFiltersForColumns: [],
    requirementsColdOfferGridColumnsForFilters: [],
    requirementsColdOfferDefaultGridColumns: [],
    requirementsColdOfferSortingString: null,
    requirementsColdOfferSortingModel: [],
    requirementsSearchedByContacts: [],
    requirementsSearchedByContactsColumns: [],
    requirementsSearchedByContactsShownColumns: [],
    requirementsSearchedByContactsHiddenColumns: [],
    requirementsSearchedByContactsGridFiltersForColumns: [],
    requirementsSearchedByContactsGridColumnsForFilters: [],
    requirementsSearchedByContactsDefaultGridColumns: [],
    requirementsSearchedByContactsSortingString: null,
    requirementsSearchedByContactsSortingModel: [],
    reqsSearchedByCompany: [],
    currentCreateReqStep: 'Create contact',
    requirementCreateContacts: [
        {
            "pe_birthday": null,
            "pe_department": userDataHelper.getUserData().department,
            "pe_email": null,
            "pe_first_name": null,
            "pe_gender_title": null,
            "pe_is_mail": 0,
            "pe_job_position": null,
            "pe_lang_index": null,
            "pe_last_name": null,
            "pe_mob_cntry_code": null,
            "pe_mob_number": null,
            "pe_mob_region_code": null,
            "pe_profession_title": null,
            "pe_ref": null,
            "pe_remarks": null,
            "pe_tel_cntry_code": null,
            "pe_tel_number": null,
            "pe_tel_region_code": null,
            id: 0,
            contact_tel: null,
            contact_mob: null
        },
    ],
    requirementCreateOrganization: {
        "co_city": null,
        "co_country_ref": null,
        "co_first_contact": null,
        "co_name": null,
        "co_no_connect": null,
        "co_no_from": null,
        "co_no_from_rest": null,
        "co_no_to": null,
        "co_no_to_rest": null,
        "co_quarter": null,
        "co_ref": null,
        "co_source_index": null,
        "co_street_address": null,
        "co_zip": null,
        "co_tel_cntry_code": null,
        "co_tel_number": null,
        "co_tel_region_code": null,
        "co_fax_cntry_code": null,
        "co_fax_number": null,
        "co_fax_region_code": null,
        co_phone: null,
        co_fax: null,
        co_email: null,
        co_web: null
    },
    isStepsClickAvailable: false,
    organizationStepSkipped: false,
    organizationName: '',
    isSearchContactsLoading: false,
    commonCreateReqFields: {
        balcony: 0,
        city_parts: [],
        currency: 1,
        department: '',
        dispositions: [],
        is_furnished: null,
        loggia: 0,
        parking_qnt: null,
        price_per_m2_max: 0,
        price_per_m2_min: 0,
        price_total_max: 0,
        price_total_min: 0,
        property_type: null,
        reconstruction_state: null,
        req_remark: '',
        terrace: 0,
        useful_square_max: 0,
        useful_square_min: 0,
        user_ref: 0,
        yield_act_max: 0,
        yield_act_min: 0,
        yield_pot_max: 0,
        yield_pot_min: 0,
        other_criterias: []
    },
    reqDataWasNotFound: false,
    isReqDataLoading: false,
    isReqEditMode: false,
    requirementsFunctions: [],
    currentGridType: 'Requirements',
    coldOfferRequirementLinkedProperties: []
}

export const requirementsSlice = createSlice({
    name: 'requirements',
    initialState,
    reducers: {
        resetState: () => initialState,
        setRequirementsShownColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.requirementShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setRequirementsHiddenColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.requirementHiddenColumns = action.payload.columns
        },
        setRequirementsColdOfferShownColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.requirementColdOfferShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setRequirementsColdOfferHiddenColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.requirementColdOfferHiddenColumns = action.payload.columns
        },
        setRequirementsSearchedByContactsShownColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.requirementsSearchedByContactsShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setRequirementsSearchedByContactsHiddenColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.requirementsSearchedByContactsHiddenColumns = action.payload.columns
        },
        onSetRequirementsLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.requirementsSortingModel = action.payload.sort_model
        },
        onSetRequirementsColdOfferLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.requirementsColdOfferSortingModel = action.payload.sort_model
        },
        onSetRequirementsSearchedByContactsLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.requirementsSearchedByContactsSortingModel = action.payload.sort_model
        },
        onChangeRequirementsLocalSortModel: (state, action: PayloadAction<{ sort_model_item: GridSortItem }>) => {
            state.requirementsSortingModel = [...state.requirementsSortingModel, action.payload.sort_model_item]
        },
        onChangeRequirementsSearchedByContactsLocalSortModel: (state, action: PayloadAction<{ sort_model_item: GridSortItem }>) => {
            state.requirementsSearchedByContactsSortingModel = [...state.requirementsSortingModel, action.payload.sort_model_item]
        },
        setRequirementGridColumns: (state, action: PayloadAction<GridColumns[]>) => {
            state.requirementColumns = action.payload
        },
        setRequirementsSearchedByContactsGridColumns: (state, action: PayloadAction<GridColumns[]>) => {
            state.requirementsSearchedByContactsColumns = action.payload
        },
        onChangeReqGridColumnsPosition: (state, action: PayloadAction<{ columns: string[] }>) => {
            state.requirementShownColumns = state.requirementShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
        },
        onChangeColdOffersReqGridColumnsPosition: (state, action: PayloadAction<{ columns: string[] }>) => {
            state.requirementColdOfferShownColumns = state.requirementColdOfferShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
        },
        onChangeReqSearchedByContactsGridColumnsPosition: (state, action: PayloadAction<{ columns: string[] }>) => {
            state.requirementsSearchedByContactsShownColumns = state.requirementsSearchedByContactsShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
        },
        onChangeReqGridColumnsWidth: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            const currentStateColumns = state.requirementShownColumns
            Object.entries(action.payload.columns).forEach((item: [string, ShownAndHiddenColumnsType]) => {
                const columnName = item[0];
                const gridColumnParams = item[1];
                const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                if (currentColumn && gridColumnParams && gridColumnParams.width) {
                    currentColumn.width = gridColumnParams.width;
                }
            });
        },
        onChangeColdOffersReqGridColumnsWidth: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            const currentStateColumns = state.requirementColdOfferShownColumns
            Object.entries(action.payload.columns).forEach((item: [string, ShownAndHiddenColumnsType]) => {
                const columnName = item[0];
                const gridColumnParams = item[1];
                const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                if (currentColumn && gridColumnParams && gridColumnParams.width) {
                    currentColumn.width = gridColumnParams.width;
                }
            });
        },
        onChangeReqSearchedByContactsGridColumnsWidth: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            const currentStateColumns = state.requirementsSearchedByContactsShownColumns
            Object.entries(action.payload.columns).forEach((item: [string, ShownAndHiddenColumnsType]) => {
                const columnName = item[0];
                const gridColumnParams = item[1];
                const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                if (currentColumn && gridColumnParams && gridColumnParams.width) {
                    currentColumn.width = gridColumnParams.width;
                }
            });
        },
        onSetRequirementsGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.requirementsGridColumnsForFilters = state.requirementsGridColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onSetRequirementsColdOfferGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.requirementsColdOfferGridColumnsForFilters = state.requirementsColdOfferGridColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onSetRequirementsSearchedByContactsGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.requirementsSearchedByContactsGridColumnsForFilters = state.requirementsSearchedByContactsGridColumnsForFilters.map((m: ColumnsForFiltersType) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onSetRequirements: (state, action: PayloadAction<{ requirementsRows: RequirementType[] }>) => {
            state.requirements = action.payload.requirementsRows
        },
        onSetColdOfferRequirements: (state, action: PayloadAction<{ requirementsRows: RequirementType[] }>) => {
            state.requirementsColdOffers = action.payload.requirementsRows
        },
        onSetSearchedByContactsRequirements: (state, action: PayloadAction<{ requirementsRows: RequirementType[] }>) => {
            state.requirementsSearchedByContacts = action.payload.requirementsRows
        },
        setReqGridColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[] }>) => {
            state.requirementsGridColumnsForFilters = action.payload.columns
        },
        setColdOfferReqGridColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[] }>) => {
            state.requirementsColdOfferGridColumnsForFilters = action.payload.columns
        },
        setReqSearchedByContactsGridColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[] }>) => {
            state.requirementsSearchedByContactsGridColumnsForFilters = action.payload.columns
        },
        onChangeRequirementsGridFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsGridColumnsForFilters = state.requirementsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                conditionValue: action.payload.value
            } : m)
        },
        onChangeRequirementsColdOfferGridFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsColdOfferGridColumnsForFilters = state.requirementsColdOfferGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                conditionValue: action.payload.value
            } : m)
        },
        onChangeRequirementsSearchedByContactsGridFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsSearchedByContactsGridColumnsForFilters = state.requirementsSearchedByContactsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                conditionValue: action.payload.value
            } : m)
        },
        onSetFiltersForRequirementsGrid: (state, action: PayloadAction<{ gridFilters: ColumnsFiltersType[] }>) => {
            state.requirementsGridFiltersForColumns = action.payload.gridFilters
        },
        onSetFiltersForRequirementsColdOfferGrid: (state, action: PayloadAction<{ gridFilters: ColumnsFiltersType[] }>) => {
            state.requirementsColdOfferGridFiltersForColumns = action.payload.gridFilters
        },
        onSetSearchedByContactsFiltersForRequirementsGrid: (state, action: PayloadAction<{ gridFilters: ColumnsFiltersType[] }>) => {
            state.requirementsSearchedByContactsGridFiltersForColumns = action.payload.gridFilters
        },
        onSetRequirementsGridFiltersButtonsSelected: (state, action: PayloadAction<{ headerName?: string, isFilterSelected: boolean, columnTitle?: string }>) => {
            state.requirementsGridColumnsForFilters = state.requirementsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName || m.title === action.payload.columnTitle ? {
                ...m,
                isFilterSelected: action.payload.isFilterSelected
            } : m)
        },
        onSetColdOfferRequirementsGridFiltersButtonsSelected: (state, action: PayloadAction<{ headerName?: string, isFilterSelected: boolean, columnTitle?: string }>) => {
            state.requirementsColdOfferGridColumnsForFilters = state.requirementsColdOfferGridColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName || m.title === action.payload.columnTitle ? {
                ...m,
                isFilterSelected: action.payload.isFilterSelected
            } : m)
        },
        onSetSearchedByContactsRequirementsGridFiltersButtonsSelected: (state, action: PayloadAction<{ headerName?: string, isFilterSelected: boolean, columnTitle?: string }>) => {
            state.requirementsSearchedByContactsGridColumnsForFilters = state.requirementsSearchedByContactsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName || m.title === action.payload.columnTitle ? {
                ...m,
                isFilterSelected: action.payload.isFilterSelected
            } : m)
        },
        onChangeRequirementsGridFilterValue: (state, action: PayloadAction<{ title?: string, value: string| number, startValue?: string, endValue?: string, columnTitle?: string }>) => {
            state.requirementsGridColumnsForFilters = state.requirementsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title || m.title === action.payload.columnTitle ? {
                ...m,
                value: action.payload.value,
                startValue: action.payload.startValue,
                endValue: action.payload.endValue
            } : m)
        },
        onChangeColdOfferRequirementsGridFilterValue: (state, action: PayloadAction<{ title?: string, value: string| number, startValue?: string, endValue?: string, columnTitle?: string }>) => {
            state.requirementsColdOfferGridColumnsForFilters = state.requirementsColdOfferGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title || m.title === action.payload.columnTitle ? {
                ...m,
                value: action.payload.value,
                startValue: action.payload.startValue,
                endValue: action.payload.endValue
            } : m)
        },
        onChangeSearchedByContactsRequirementsGridFilterValue: (state, action: PayloadAction<{ title?: string, value: string| number, startValue?: string, endValue?: string, columnTitle?: string }>) => {
            state.requirementsSearchedByContactsGridColumnsForFilters = state.requirementsSearchedByContactsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title || m.title === action.payload.columnTitle ? {
                ...m,
                value: action.payload.value,
                startValue: action.payload.startValue,
                endValue: action.payload.endValue
            } : m)
        },
        onChangeRequirementsGridFilterStartValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsGridColumnsForFilters = state.requirementsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                startValue: action.payload.value
            } : m)
        },
        onChangeRequirementsColdOfferGridFilterStartValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsColdOfferGridColumnsForFilters = state.requirementsColdOfferGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                startValue: action.payload.value
            } : m)
        },
        onChangeSearchedByContactsRequirementsGridFilterStartValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsSearchedByContactsGridColumnsForFilters = state.requirementsSearchedByContactsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                startValue: action.payload.value
            } : m)
        },
        onChangeRequirementsGridFilterEndValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsGridColumnsForFilters = state.requirementsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                endValue: action.payload.value
            } : m)
        },
        onChangeRequirementsColdOfferGridFilterEndValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsColdOfferGridColumnsForFilters = state.requirementsColdOfferGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                endValue: action.payload.value
            } : m)
        },
        onChangeSearchedByContactsRequirementsGridFilterEndValue: (state, action: PayloadAction<{ title: string, value: string }>) => {
            state.requirementsSearchedByContactsGridColumnsForFilters = state.requirementsSearchedByContactsGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                endValue: action.payload.value
            } : m)
        },
        onReqResetFiltersButtonsSelected: (state) => {

            state.requirementsGridColumnsForFilters = state.requirementsGridColumnsForFilters.map((m: ColumnsForFiltersType) => m && {
                ...m,
                isFilterSelected: false,
                isModalBtnOpened: false,
                value: '',
                startValue: '',
                endValue: '',
                conditionValue: ''
            })

        },
        onReqResetSearchedByContactsFiltersButtonsSelected: (state) => {

            state.requirementsSearchedByContactsGridColumnsForFilters = state.requirementsSearchedByContactsGridColumnsForFilters.map((m: ColumnsForFiltersType) => m && {
                ...m,
                isFilterSelected: false,
                isModalBtnOpened: false,
                value: '',
                startValue: '',
                endValue: '',
                conditionValue: ''
            })

        },
        onSetReqSortingString: (state, action: PayloadAction<{ sorting_string: string | null }>) => {
            state.requirementsSortingString = action.payload.sorting_string
        },
        onSetReqColdOfferSortingString: (state, action: PayloadAction<{ sorting_string: string | null }>) => {
            state.requirementsColdOfferSortingString = action.payload.sorting_string
        },
        onSetReqSearchedByContactsSortingString: (state, action: PayloadAction<{ sorting_string: string | null }>) => {
            state.requirementsSearchedByContactsSortingString = action.payload.sorting_string
        },
        onAddReq: (state, action: PayloadAction<any>) => {
            state.requirementsSearchedByContacts.push(action.payload)
        },
        onResetContactsReqs: (state) => {
            state.requirementsSearchedByContacts = []
        },
        onSetReqDataWasNotFound: (state, action: PayloadAction<boolean>) => {
            state.reqDataWasNotFound = action.payload
        },
        onSetReqLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.requirementsSortingModel = action.payload.sort_model
        },
        onSetReqColdOfferLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.requirementsColdOfferSortingModel = action.payload.sort_model
        },
        onSetReqSearchedByContactsLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.requirementsSearchedByContactsSortingModel = action.payload.sort_model
        },
        onChangeCreateReqCurrentStep: (state, action: PayloadAction<'Create contact' | 'Create organization' | 'Create requirement' | string>) => {
            state.currentCreateReqStep = action.payload
        },
        // onAddContactToTheContactForm: (state, action: PayloadAction<number>) => {
        //     state.requirementCreateContacts.push({
        //         first_name: '',
        //         last_name: '',
        //         mobile: '',
        //         telephone: '',
        //         email: '',
        //         corresp_language: '',
        //         birthday: '',
        //         salutation: '',
        //         title: '',
        //         position: '',
        //         department: '',
        //         remark: '',
        //         id: action.payload
        //     })
        // },
        onSetCommonCreateReqFieldsForUpdate: (state, action: PayloadAction<CommonCreateReqFields>) => {
            state.commonCreateReqFields = {
                ...action.payload,
                other_criterias: action.payload.other_criterias.map((item, index) => ({
                    ...item,
                    id: index,
                    isChecked: true
                })),
            };
        },
        onChangeCreateContactFormField: (state, action: PayloadAction<{ value: string | null | Moment | number, inputName: string, contactId: number }>) => {
            const { value, inputName, contactId } = action.payload;
            const contact = state.requirementCreateContacts.find(c => c.id === contactId);
            if (contact) {
                (contact as any)[inputName] = value;
            }
        },
        onChangeReqOtherCriteria: (state, action: PayloadAction<{criteriaId: number, isChecked: boolean}>) => {
            console.log(action.payload.criteriaId)
            state.commonCreateReqFields = {
                ...state.commonCreateReqFields,
                other_criterias: state.commonCreateReqFields.other_criterias.map((item: any) => item.id === action.payload.criteriaId ? {...item, isChecked: action.payload.isChecked} : item)
            }
        },
        onChangeCreateReqStepFormField: (state, action: PayloadAction<{ value: string | null | Moment | number | string[] | number[], inputName: string }>) => {
            const { value, inputName } = action.payload;
            if (state.commonCreateReqFields.hasOwnProperty(inputName)) {
                (state.commonCreateReqFields as any)[inputName] = value;
            }
        },
        onChangeCreateOrganizationFormField: (state, action: PayloadAction<{ value: string | null | Moment | number, inputName: string }>) => {
            if(action.payload.inputName === 'co_name'){
                state.requirementCreateOrganization.co_name = action.payload.value
            }
            else if(action.payload.inputName === 'co_source_index'){
                state.requirementCreateOrganization.co_source_index = action.payload.value
            }
            else if(action.payload.inputName === 'co_first_contact'){
                state.requirementCreateOrganization.co_first_contact = action.payload.value
            }
            else if(action.payload.inputName === 'co_country_ref'){
                state.requirementCreateOrganization.co_country_ref = String(action.payload.value)
            }
            else if(action.payload.inputName === 'co_city'){
                state.requirementCreateOrganization.co_city = action.payload.value
            }
            else if(action.payload.inputName === 'co_zip'){
                state.requirementCreateOrganization.co_zip = action.payload.value
            }
            else if(action.payload.inputName === 'co_street_address'){
                state.requirementCreateOrganization.co_street_address = action.payload.value
            }

            else if(action.payload.inputName === 'co_no_from'){
                state.requirementCreateOrganization.co_no_from = action.payload.value
            }
            else if(action.payload.inputName === 'co_no_from_rest'){
                state.requirementCreateOrganization.co_no_from_rest = action.payload.value
            }
            else if(action.payload.inputName === 'co_no_connect'){
                state.requirementCreateOrganization.co_no_connect = action.payload.value
            }
            else if(action.payload.inputName === 'co_no_to'){
                state.requirementCreateOrganization.co_no_to = action.payload.value
            }
            else if(action.payload.inputName === 'co_no_to_rest'){
                state.requirementCreateOrganization.co_no_to_rest = action.payload.value
            }
            else if(action.payload.inputName === 'co_fax'){
                state.requirementCreateOrganization.co_fax = action.payload.value
            }
            else if(action.payload.inputName === 'co_phone'){
                state.requirementCreateOrganization.co_phone = action.payload.value
            }
            else if(action.payload.inputName === 'co_email'){
                state.requirementCreateOrganization.co_email = action.payload.value
            }
            else if(action.payload.inputName === 'co_web'){
                state.requirementCreateOrganization.co_web = action.payload.value
            }
        },
        onChangeIsStepsClickAvailable: (state, action: PayloadAction<boolean>) => {
            state.isStepsClickAvailable = action.payload
        },
        onClearReqCreationFields: (state) => {
            state.requirementCreateContacts = [
                {
                    "pe_birthday": null,
                    "pe_department": null,
                    "pe_email": null,
                    "pe_first_name": null,
                    "pe_gender_title": null,
                    "pe_is_mail": 0,
                    "pe_job_position": null,
                    "pe_lang_index": null,
                    "pe_last_name": null,
                    "pe_mob_cntry_code": null,
                    "pe_mob_number": null,
                    "pe_mob_region_code": null,
                    "pe_profession_title": null,
                    "pe_ref": null,
                    "pe_remarks": null,
                    "pe_tel_cntry_code": null,
                    "pe_tel_number": null,
                    "pe_tel_region_code": null,
                    id: 0,
                    contact_tel: null,
                    contact_mob: null
                },
            ];
            state.requirementCreateOrganization = {
                "co_city": null,
                "co_country_ref": null,
                "co_first_contact": null,
                "co_name": null,
                "co_no_connect": null,
                "co_no_from": null,
                "co_no_from_rest": null,
                "co_no_to": null,
                "co_no_to_rest": null,
                "co_quarter": null,
                "co_ref": null,
                "co_source_index": null,
                "co_street_address": null,
                "co_zip": null,
                "co_tel_cntry_code": null,
                "co_tel_number": null,
                "co_tel_region_code": null,
                "co_fax_cntry_code": null,
                "co_fax_number": null,
                "co_fax_region_code": null,
                co_phone: null,
                co_fax: null,
                co_email: null,
                co_web: null
            };
            state.commonCreateReqFields = {
                balcony: 0,
                    city_parts: [],
                    currency: 1,
                    department: '',
                    dispositions: [],
                    is_furnished: null,
                    loggia: 0,
                    parking_qnt: null,
                    price_per_m2_max: 0,
                    price_per_m2_min: 0,
                    price_total_max: 0,
                    price_total_min: 0,
                    property_type: null,
                    reconstruction_state: null,
                    req_remark: '',
                    terrace: 0,
                    useful_square_max: 0,
                    useful_square_min: 0,
                    user_ref: 0,
                    yield_act_max: 0,
                    yield_act_min: 0,
                    yield_pot_max: 0,
                    yield_pot_min: 0,
                other_criterias: []
            }
        },
        onSetIsOrganizationStepSkipped: (state, action: PayloadAction<boolean>) => {
            state.organizationStepSkipped = action.payload
        },
        onSetOrganizationName: (state, action: PayloadAction<string>) => {
            state.organizationName = action.payload
        },
        onSetIsSearchContactsLoading: (state, action: PayloadAction<boolean>) => {
            state.isSearchContactsLoading = action.payload
        },
        onSetIsReqDataLoading: (state, action: PayloadAction<boolean>) => {
            state.isReqDataLoading = action.payload
        },
        onSetIsReqEditMode: (state, action: PayloadAction<boolean>) => {
            state.isReqEditMode = action.payload
        },
        onChangeRequirementsCurrentGridType: (state, action: PayloadAction<"Requirements" | "Requirements/Cold Offer">) => {
            state.currentGridType = action.payload
        },
        onSetColdOfferRequirementIsOpened: (state, action: PayloadAction<{ isOpened: boolean, reqId: string }>) => {
            state.requirementsColdOffers = state.requirementsColdOffers.map((p: any) => {
                return (
                    Number(p.REQ_ID) === Number(action.payload.reqId) ? {...p, isOpened: action.payload.isOpened} : p
                )
            })
        },
        onSetRequirementsLinkedProperties:(state, action: PayloadAction<{reqId: number, linkedProperties: any[]}>) => {
            state.requirementsColdOffers = state.requirementsColdOffers.map((p: any) => {
                return (
                    Number(p.REQ_ID) === Number(action.payload.reqId) ? {...p, linkedProperties: action.payload.linkedProperties} : p
                )
            })
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetRequirementsGridDataThunk.fulfilled, (state, action) => {
                const gridData = action.payload.grid_column_data;
                // eslint-disable-next-line
                const requirements = gridData.map((innerArr: GridColumns[], index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: GridColumns) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.'))
                                    : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss')
                                    : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }
                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['reqs'] = [];
                        obj['isOpened'] = false;
                        return obj;
                    }
                })
                state.requirements = requirements;
                if (gridData.length > 0) {
                    state.requirementColumns = gridData[0].concat({
                        COL_NAME: "ACTIONS",
                        COL_OPTIONS: null,
                        COL_POS: requirements.length,
                        COL_TITLE: "Actions",
                        COL_TYPE: null,
                        COL_VALUE: null,
                        COL_WIDTH: 80,
                        id: requirements.length,
                        isFiltered: false,
                        surfaces: [],
                        isOpened: false
                    });
                }

                state.requirementsDefaultGridColumns = requirements;
            })
            .addCase(GetRequirementsByContactIdGridDataThunk.fulfilled, (state, action) => {
                const gridData = action.payload.grid_column_data;
                // eslint-disable-next-line
                const requirements = gridData.map((innerArr: GridColumns[], index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: GridColumns) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.'))
                                    : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss')
                                    : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }
                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['reqs'] = [];
                        obj['isOpened'] = false;
                        return obj;
                    }
                })
                state.requirementsSearchedByContactsColumns = gridData[0]
                state.requirementsSearchedByContacts = requirements;
                state.requirementsSearchedByContactsDefaultGridColumns = requirements;
            })
            .addCase(GetRequirementsByContactIdThunk.fulfilled, (state, action) => {
                state.requirementsSearchedByContacts = action.payload
            })
            .addCase(GetRequirementsByCompanyIdThunk.fulfilled, (state, action) => {
                state.reqsSearchedByCompany = action.payload
            })
            .addCase(GetRequirementContactFunctionsThunk.fulfilled, (state, action) => {
                state.requirementsFunctions = action.payload
            })
            .addCase(GetRequirementsColdOffersGridDataThunk.fulfilled, (state, action) => {
                const gridData = action.payload.grid_column_data;
                // eslint-disable-next-line
                const requirements = gridData.map((innerArr: GridColumns[], index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: GridColumns) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.'))
                                    : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss')
                                    : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }
                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['linkedProperties'] = [];
                        obj['isOpened'] = false;
                        return obj;
                    }
                })
                state.requirementsColdOffers = requirements;
                if (gridData.length > 0) {
                    state.requirementColdOfferColumns = gridData[0].concat({
                        COL_NAME: "ACTIONS",
                        COL_OPTIONS: null,
                        COL_POS: requirements.length,
                        COL_TITLE: "Actions",
                        COL_TYPE: null,
                        COL_VALUE: null,
                        COL_WIDTH: 80,
                        id: requirements.length,
                        isFiltered: false,
                        surfaces: [],
                        isOpened: false
                    });
                }

                state.requirementsColdOfferDefaultGridColumns = requirements;
            })

    }
})

export const {
    setRequirementsShownColumns,
    setRequirementsHiddenColumns,
    setRequirementsColdOfferShownColumns,
    setRequirementsColdOfferHiddenColumns,
    onSetRequirementsLocalSortModel,
    setRequirementGridColumns,
    onChangeReqGridColumnsWidth,
    onChangeReqGridColumnsPosition,
    onSetRequirementsGridFiltersButtonsModalOpened,
    onSetRequirements,
    setReqGridColumnsForFilters,
    onChangeRequirementsGridFilterConditionValue,
    onSetFiltersForRequirementsGrid,
    onSetRequirementsGridFiltersButtonsSelected,
    onChangeRequirementsGridFilterValue,
    onChangeRequirementsGridFilterStartValue,
    onChangeRequirementsGridFilterEndValue,
    onReqResetFiltersButtonsSelected,
    onSetReqSortingString,
    onSetReqLocalSortModel,
    resetState,
    onAddReq,
    onResetContactsReqs,
    onChangeCreateReqCurrentStep,
    // onAddContactToTheContactForm,
    onChangeCreateContactFormField,
    onChangeCreateOrganizationFormField,
    onChangeIsStepsClickAvailable,
    onClearReqCreationFields,
    onSetIsOrganizationStepSkipped,
    onSetOrganizationName,
    onSetIsSearchContactsLoading,
    onChangeCreateReqStepFormField,
    onSetReqDataWasNotFound,
    onSetIsReqDataLoading,
    onSetCommonCreateReqFieldsForUpdate,
    onSetIsReqEditMode,
    onChangeReqOtherCriteria,
    onSetReqSearchedByContactsSortingString,
    onSetReqSearchedByContactsLocalSortModel,
    setRequirementsSearchedByContactsShownColumns,
    setRequirementsSearchedByContactsHiddenColumns,
    setReqSearchedByContactsGridColumnsForFilters,
    onSetSearchedByContactsFiltersForRequirementsGrid,
    onChangeReqSearchedByContactsGridColumnsPosition,
    onChangeReqSearchedByContactsGridColumnsWidth,
    onSetSearchedByContactsRequirements,
    onSetSearchedByContactsRequirementsGridFiltersButtonsSelected,
    onChangeSearchedByContactsRequirementsGridFilterValue,
    onSetRequirementsSearchedByContactsGridFiltersButtonsModalOpened,
    onChangeRequirementsSearchedByContactsGridFilterConditionValue,
    onChangeSearchedByContactsRequirementsGridFilterStartValue,
    onChangeSearchedByContactsRequirementsGridFilterEndValue,
    onReqResetSearchedByContactsFiltersButtonsSelected,
    onSetRequirementsSearchedByContactsLocalSortModel,
    onChangeRequirementsCurrentGridType,
    onChangeColdOffersReqGridColumnsPosition,
    onChangeColdOffersReqGridColumnsWidth,
    onSetColdOfferRequirementIsOpened,
    onSetRequirementsLinkedProperties,
    setColdOfferReqGridColumnsForFilters,
    onSetFiltersForRequirementsColdOfferGrid,
    onSetColdOfferRequirements,
    onSetColdOfferRequirementsGridFiltersButtonsSelected,
    onChangeColdOfferRequirementsGridFilterValue,
    onSetRequirementsColdOfferGridFiltersButtonsModalOpened,
    onChangeRequirementsColdOfferGridFilterConditionValue,
    onChangeRequirementsColdOfferGridFilterStartValue,
    onChangeRequirementsColdOfferGridFilterEndValue,
    onSetReqColdOfferLocalSortModel,
    onSetReqColdOfferSortingString,
    onSetRequirementsColdOfferLocalSortModel
} = requirementsSlice.actions

export const selectRequirements = (state: RootState): RequirementType[] => state.requirements.requirements
export const selectRequirementsColdOffer = (state: RootState): any[] => state.requirements.requirementsColdOffers
export const selectRequirementsColumns = (state: RootState): GridColumns[] => state.requirements.requirementColumns
export const selectRequirementsColdOfferColumns = (state: RootState): GridColumns[] => state.requirements.requirementColdOfferColumns
export const selectRequirementsShownColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.requirements.requirementShownColumns
export const selectRequirementsHiddenColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.requirements.requirementHiddenColumns
export const selectRequirementsColdOfferShownColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.requirements.requirementColdOfferShownColumns
export const selectRequirementsColdOfferHiddenColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.requirements.requirementColdOfferHiddenColumns
export const selectRequirementsGridFiltersForArray = (state: RootState): ColumnsFiltersType[] => state.requirements.requirementsGridFiltersForColumns
export const selectRequirementsColdOfferGridFiltersForArray = (state: RootState): ColumnsFiltersType[] => state.requirements.requirementsColdOfferGridFiltersForColumns
export const selectRequirementGridColumnsForFilters = (state: RootState): ColumnsForFiltersType[] => state.requirements.requirementsGridColumnsForFilters
export const selectRequirementColdOfferGridColumnsForFilters = (state: RootState): ColumnsForFiltersType[] => state.requirements.requirementsColdOfferGridColumnsForFilters
export const selectRequirementsSearchedByContactsDefaultGridColumns = (state: RootState): RequirementType[] => state.requirements.requirementsSearchedByContactsDefaultGridColumns
export const selectRequirementsColdOfferGridColumns = (state: RootState): RequirementType[] => state.requirements.requirementsColdOfferDefaultGridColumns
export const selectRequirementsSearchedByContacts = (state: RootState): RequirementType[] => state.requirements.requirementsSearchedByContacts
export const selectRequirementsSearchedByContactsColumns = (state: RootState): GridColumns[] => state.requirements.requirementsSearchedByContactsColumns
export const selectRequirementsSearchedByContactsShownColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.requirements.requirementsSearchedByContactsShownColumns
export const selectRequirementsSearchedByContactsHiddenColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.requirements.requirementsSearchedByContactsHiddenColumns
export const selectRequirementsSearchedByContactsGridFiltersForArray = (state: RootState): ColumnsFiltersType[] => state.requirements.requirementsSearchedByContactsGridFiltersForColumns
export const selectRequirementsSearchedByContactsGridColumnsForFilters = (state: RootState): ColumnsForFiltersType[] => state.requirements.requirementsSearchedByContactsGridColumnsForFilters
export const selectRequirementsDefaultGridColumns = (state: RootState): RequirementType[] => state.requirements.requirementsDefaultGridColumns
export const selectRequirementsSortingString = (state: RootState): string | null => state.requirements.requirementsSortingString
export const selectRequirementsSearchedByContactsSortingString = (state: RootState): string | null => state.requirements.requirementsSearchedByContactsSortingString
export const selectRequirementsSortingModel = (state: RootState): [] | GridSortItem[] => state.requirements.requirementsSortingModel
export const selectRequirementsSearchedByContactsSortingModel = (state: RootState): [] | GridSortItem[] => state.requirements.requirementsSearchedByContactsSortingModel
export const selectRequirementsSearchedByCompany = (state: RootState): any[] => state.requirements.reqsSearchedByCompany
export const selectRequirementsCreateReqCurrentStep = (state: RootState): 'Create contact' | 'Create organization' | 'Create requirement' | string => state.requirements.currentCreateReqStep
export const selectRequirementsContactsForCreation = (state: RootState): CreateContactType[] => state.requirements.requirementCreateContacts
export const selectRequirementsOrganizationForCreation = (state: RootState): CreateOrganization => state.requirements.requirementCreateOrganization
export const selectOrganizationStepSkipped = (state: RootState): boolean  => state.requirements.organizationStepSkipped
export const selectOrganizationName = (state: RootState): string  => state.requirements.organizationName
export const selectIsReqContactsAndCompaniesLoading = (state: RootState): boolean  => state.requirements.isSearchContactsLoading
export const selectCreateReqStepFormFields = (state: RootState): CommonCreateReqFields  => state.requirements.commonCreateReqFields
export const selectRequirementFunctions = (state: RootState): RequirementFunction[]  => state.requirements.requirementsFunctions
export const selectRequirementsGridCurrentType = (state: RootState): 'Requirements' | 'Requirements/Cold Offer'  => state.requirements.currentGridType
export const selectRequirementsColdOfferLinkedProperties = (state: RootState): {
    "ADDRESS": string | null,
    "CITY": string | null,
    "DESCRIPTION": string | null,
    "QUARTER": string | null,
    "REF": number | null
}[]  => state.requirements.coldOfferRequirementLinkedProperties
export const selectRequirementsColdOffersSortingModel = (state: RootState): [] | GridSortItem[] => state.requirements.requirementsColdOfferSortingModel
export const selectRequirementsColdOffersSortingString = (state: RootState): string | null => state.requirements.requirementsColdOfferSortingString
export const selectIsReqDataLoading = (state: RootState): boolean | null => state.requirements.isReqDataLoading

export const GetRequirementsGridDataThunk = createAsyncThunk<GridResData, {
    "p_agent_ref": number,
    "p_end_period": Date,
    "p_sort_order": string | null,
    "p_start_period": Date
}, AsyncThunkConfig>(
    'requirements/getRequirementsGridData',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(onSetReqDataWasNotFound(false))
        thunkAPI.dispatch(onSetIsReqDataLoading(true))
        try {
            const {status, data} = await gridApi.getRequirementsGridData(reqData)
            if (status === 200 && data) {
                thunkAPI.dispatch(onSetIsReqDataLoading(false))
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetReqSortingString({sorting_string: data.sorting_rule}));
                    thunkAPI.dispatch(onSetReqLocalSortModel({sort_model: sortingCondition}));
                } else {
                    thunkAPI.dispatch(onSetReqSortingString({sorting_string: null}));
                    thunkAPI.dispatch(onSetReqLocalSortModel({sort_model: []}));
                }
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            thunkAPI.dispatch(onSetReqDataWasNotFound(true))
            thunkAPI.dispatch(onSetIsReqDataLoading(false))
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetRequirementsByContactIdThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'requirements/getRequirementsByContactId',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        thunkAPI.dispatch(onSetIsSearchContactsLoading(true))
        try {
            const {status, data} = await gridApi.getReqByContactId(reqData)
            if (status === 200 && data) {
                console.log(data)
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.resultSetList)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            thunkAPI.dispatch(onSetIsSearchContactsLoading(false))
        }
    }
)

export const GetRequirementsByCompanyIdThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'requirements/getRequirementsByCompanyId',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        thunkAPI.dispatch(onSetIsSearchContactsLoading(true))
        try {
            const {status, data} = await gridApi.getReqByCompanyId(reqData)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.resultSetList)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            thunkAPI.dispatch(onSetIsSearchContactsLoading(false))
        }
    }
)

export const GetRequirementCriteriasForReqEditThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'requirements/getRequirementCriteriasForReqEditThunk',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        thunkAPI.dispatch(onSetIsSearchContactsLoading(true))
        try {
            const {status, data} = await requirementsApi.getCriteriasForReqEditing(reqData)
            if (status === 200 && data) {
                thunkAPI.dispatch(onSetCommonCreateReqFieldsForUpdate(data))
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CreateRequirement = createAsyncThunk<any, any, AsyncThunkConfig>(
    'requirements/createRequirement',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await gridApi.createRequirement(reqData)
            if (status === 200) {
                console.log(data)
                gridDataHelper.setRowId(data)
                setTimeout(() => {
                    window.location.replace('/requirements/properties')
                }, 3000)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Requirement was successfully created!'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const UpdateRequirement = createAsyncThunk<void, {requirement: CreateAndUpdateReqFields, reqId: number}, AsyncThunkConfig>(
    'requirements/updateRequirement',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await gridApi.updateRequirement(reqData.requirement, reqData.reqId)
            if (status === 200) {
                gridDataHelper.setRowId(reqData.reqId)
                setTimeout(() => {
                    window.location.replace('/requirements/properties')
                }, 3000)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Requirement was successfully updated!'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const UpdateRequirementForColdOffer = createAsyncThunk<void, {requirement: any, reqId: number}, AsyncThunkConfig>(
    'requirements/updateRequirementForColdOffer',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(1))
        try {
            const {status, data} = await gridApi.updateRequirementForColdOffer(reqData.requirement, reqData.reqId)
            if (status === 200) {
                gridDataHelper.setRowId(reqData.reqId)
                setTimeout(() => {
                    window.location.replace('/requirements/properties')
                }, 3000)
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Requirement was successfully updated!'})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetRequirementContactFunctionsThunk = createAsyncThunk<RequirementFunction[], void, AsyncThunkConfig>(
    'requirements/getRequirementContactFunctionsThunk',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await requirementsApi.getRequirementsContactFunctions()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const GetRequirementsByContactIdGridDataThunk = createAsyncThunk<GridResData, {p_agent_ref: number, p_contact_ref: number, p_sort_order: string | null}, AsyncThunkConfig>(
    'requirements/getRequirementsByContactIdGridData',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(onSetIsReqDataLoading(true))
        try {
            const {status, data} = await gridApi.getRequirementsByContactId(reqData)
            if (status === 200 && data) {
                thunkAPI.dispatch(onSetIsReqDataLoading(false))
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetReqSearchedByContactsSortingString({sorting_string: data.sorting_rule}));
                    thunkAPI.dispatch(onSetReqSearchedByContactsLocalSortModel({sort_model: sortingCondition}));
                } else {
                    thunkAPI.dispatch(onSetReqSearchedByContactsSortingString({sorting_string: null}));
                    thunkAPI.dispatch(onSetReqSearchedByContactsLocalSortModel({sort_model: []}));
                }
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            thunkAPI.dispatch(onSetIsReqDataLoading(false))
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetRequirementsColdOffersGridDataThunk = createAsyncThunk<GridResData, {
    "p_agent_ref": number,
    // "p_end_period": Date,
    "p_sort_order": string | null,
    // "p_start_period": Date
}, AsyncThunkConfig>(
    'requirements/getRequirementsColdOffersGridData',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(onSetIsReqDataLoading(true))
        try {
            const {status, data} = await gridApi.getRequirementsColdOffers(reqData)
            if (status === 200 && data) {
                thunkAPI.dispatch(onSetIsReqDataLoading(false))
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetReqColdOfferSortingString({sorting_string: data.sorting_rule}));
                    thunkAPI.dispatch(onSetReqColdOfferLocalSortModel({sort_model: sortingCondition}));
                } else {
                    thunkAPI.dispatch(onSetReqColdOfferSortingString({sorting_string: null}));
                    thunkAPI.dispatch(onSetReqColdOfferLocalSortModel({sort_model: []}));
                }
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            thunkAPI.dispatch(onSetReqDataWasNotFound(true))
            thunkAPI.dispatch(onSetIsReqDataLoading(false))
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            thunkAPI.dispatch(onSetIsReqDataLoading(false))
        }
    }
)

export const GetColdOfferRequirementLinkedPropertiesThunk = createAsyncThunk<{
    "ADDRESS": string | null,
    "CITY": string | null,
    "DESCRIPTION": string | null,
    "QUARTER": string | null,
    "REF": number | null
}[], number, AsyncThunkConfig>(
    'requirements/getColdOfferRequirementLinkedPropertiesThunk',
    async (reqId, thunkAPI) => {
        try {
            const {status, data} = await gridApi.getRequirementsColdOffersLinkedProperties(reqId)
            if (status === 200 && data) {
                thunkAPI.dispatch(onSetRequirementsLinkedProperties({reqId: reqId, linkedProperties: data.resultSetList}))
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export default requirementsSlice.reducer
