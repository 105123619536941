import {MuiGridDataColumns} from "../types/commonTypes";
import {numericComparator} from "../helpers/sortingDataHelper";

export const getShownAndHiddenColumns = (columns: MuiGridDataColumns[], columnsType: 'Shown' | 'Hidden') => {
    return columns
        .filter(c => columnsType === 'Shown' ? c?.width !== 0 : c?.width === 0)
        .map((c, index) => {
            const baseConfig = {
                field: c.field,
                headerName: c.headerName,
                width: c.width,
                checked: false,
                id: index,
                dataType: c.dataType,
                isFilterSelected: false,
                col_pos: c.col_pos,
                col_value: c.col_value,
                col_options: c.col_options,
                withSurface: c.withSurface,
                key: index,
            }

            if (c.field === 'ACTIVITY_EXISTED') {
                return {
                    ...baseConfig,
                    field: 'ACTIVITY_EXISTED',
                    headerName: 'activity',
                    width: 100,
                    col_pos: 2,
                    dataType: "class java.lang.String",
                };
            } else if (c.field === 'SURFACE_EXISTED') {
                return {
                    ...baseConfig,
                    width: 0,
                    col_pos: -1,
                };
            } else if (c.dataType === 'class java.math.BigDecimal') {
                return {
                    ...baseConfig,
                    sortComparator: numericComparator,
                };
            } else {
                return baseConfig;
            }
        })
}
