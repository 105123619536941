type PickAndRest<T, K extends keyof T> = [Pick<T, K>, Omit<T, K>];

function destructure<T, K extends keyof T>(
    obj: T,
    keys: K[]
): PickAndRest<T, K> {
    const picked = {} as Pick<T, K>;
    const rest = { ...obj };

    keys.forEach((key) => {
        if (key in obj) {
            picked[key] = obj[key];
            delete rest[key];
        }
    });

    return [picked, rest];
}

export function destructureArray<T, K extends keyof T>(
    arr: T[],
    keys: K[]
): [Pick<T, K>[], Omit<T, K>[]] {
    const pickedArray: Pick<T, K>[] = [];
    const restArray: Omit<T, K>[] = [];

    arr.forEach(obj => {
        const [picked, rest] = destructure(obj, keys);
        pickedArray.push(picked);
        restArray.push(rest);
    });

    return [pickedArray, restArray];
}