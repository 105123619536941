import React from 'react';
import s from "./../GridTabs.module.scss";

type GridTabButtonProps = {
    gridType: string;
    isActive: boolean;
    onClick: () => void;
    buttonName: string;
    className?: string;
};

const GridTabButton: React.FC<GridTabButtonProps> = ({ gridType, isActive, onClick, buttonName, className = '' }) => {
    return (
        <button
            className={`${className} ${isActive ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : `${s.tabButtons__button}`}`}
            onClick={onClick}
        >
            {buttonName}
        </button>
    );
};

export default GridTabButton;
