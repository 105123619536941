import { Button, Modal } from 'antd'
import classes from './SuccessMessage.module.css'
import successImg from './../../../img/success.png'

const SuccessMessage: React.FC<{text: string, onOk: () => void, isOpen: boolean}> = ({text, onOk, isOpen}) => {
  return (
    <Modal
      open={isOpen}
      centered
      footer={false}
      onCancel={onOk}
      closable
    >
      <div className={classes.wrapper}>
        <img src={successImg} alt='success' />
        <div className={classes.successMessage}>
          {text}
        </div>
        <Button onClick={onOk} type='primary' style={{width: '100px', margin: '0 auto'}}>
          Ok
        </Button>
      </div>
    </Modal>
  )
}

export default SuccessMessage
