import React, {useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';


const SignatureComponent = () => {
    const sigCanvasRef = useRef<SignatureCanvas>(null);
    // eslint-disable-next-line
    const [savedSignature, setSavedSignature] = useState<string | null>(null);
    // eslint-disable-next-line
    const [signatureColor, setSignatureColor] = useState('black')
    const [lineWidth, setLineWidth] = useState<number>(1); // Default line width

// eslint-disable-next-line
    const changeWidth = (width: number): void => {
        if (sigCanvasRef.current) {
            ((sigCanvasRef.current as any)._canvas as HTMLCanvasElement).getContext('2d')!.lineWidth = width;
        }
    };
    const saveSignature = (): void => {
        if (sigCanvasRef.current) {
            setSavedSignature(sigCanvasRef.current.toDataURL());
        }
    };

    const clearSignature = (): void => {
        if (sigCanvasRef.current) {
            sigCanvasRef.current.clear();
        }
    };


    const handleLineWidthChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newWidth = parseInt(event.target.value, 10);
        setLineWidth(newWidth);
        if (sigCanvasRef.current) {
            // @ts-ignore
            sigCanvasRef.current._canvas.getContext('2d')!.lineWidth = newWidth;
        }
    };

    return (
        <div>
            <SignatureCanvas
                ref={sigCanvasRef}
                penColor={signatureColor}
                canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                throttle={20}
                maxWidth={lineWidth}
                minWidth={lineWidth}
            />
            <div>
                {/*<button onClick={() => changeColor('black')}>Black</button>*/}
                {/*<button onClick={() => changeColor('blue')}>Blue</button>*/}
                {/*<button onClick={() => changeColor('red')}>Red</button>*/}
                <select value={lineWidth.toString()} onChange={handleLineWidthChange}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    {/* Add more options as needed */}
                </select>
                <button onClick={saveSignature}>Save</button>
                <button onClick={clearSignature}>Clear</button>

            </div>
        </div>
    );
};

export default SignatureComponent;