import React, { useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    onChangeCreateContactFormField,
    onChangeCreateOrganizationFormField,
    selectRequirementsContactsForCreation,
    selectRequirementsOrganizationForCreation
} from "../../../store/requirementsReducer";

interface PhoneInputComponentProps {
    inputType: string
}
const PhoneInputComponent = ({ inputType }: PhoneInputComponentProps) => {
    const dispatch = useAppDispatch();
    const organizationFormFields = useAppSelector(selectRequirementsOrganizationForCreation);
    const contactsForCreation = useAppSelector(selectRequirementsContactsForCreation);
    // eslint-disable-next-line
    const [countryCode, setCountryCode] = useState<string>('cz');

    const formatPhoneNumber = (value: string, countryCallingCode: string) => {
        const withoutCountryCode = value.replace(countryCallingCode, '').trim();
        const formattedNumber = `+${countryCallingCode} ${withoutCountryCode.slice(0, 3)} ${withoutCountryCode.slice(3)}`;
        return formattedNumber;
    };

    const handlePhoneChange = (value: string, data: CountryData) => {
        const countryCallingCode = data.dialCode;
        const formattedValue = formatPhoneNumber(value, countryCallingCode);
        console.log(formattedValue);

        if (inputType === 'organizationsPhone') {
            dispatch(onChangeCreateOrganizationFormField({ value: formattedValue, inputName: 'co_phone' }));
        } else if (inputType === 'organizationsFax') {
            dispatch(onChangeCreateOrganizationFormField({ value: formattedValue, inputName: 'co_fax' }));
        } else if (inputType === 'contactMobile') {
            dispatch(onChangeCreateContactFormField({ value: formattedValue, inputName: 'contact_mob', contactId: 0 }));
        } else if (inputType === 'contactTelephone') {
            dispatch(onChangeCreateContactFormField({ value: formattedValue, inputName: 'contact_tel', contactId: 0 }));
        }
    };

    const getPhoneValue = () => {
        if (inputType === 'organizationsPhone') return organizationFormFields.co_phone;
        if (inputType === 'organizationsFax') return organizationFormFields.co_fax;
        if (inputType === 'contactMobile') return contactsForCreation[0].contact_mob;
        return contactsForCreation[0].contact_tel;
    };

    console.log(getPhoneValue());

    return (
        <div>
            <PhoneInput
                country={countryCode.toLowerCase()}
                value={getPhoneValue()}
                onChange={handlePhoneChange}
                containerClass="phone-input"
                inputClass="phone-input-field"
                buttonClass="country-code-button"
                dropdownClass="country-dropdown"
            />
        </div>
    );
};

export default PhoneInputComponent;

