import React, {ReactNode} from 'react';
import s from "./GridTabs.module.scss";

type GridTabsProps = {
    children: ReactNode;
};


const GridTabs = ({children}:GridTabsProps) => {
    return (
        <div className={s.tabButtons}>
            {children}
        </div>
    );
};

export default GridTabs;