interface Item {
    label: string | null;
    value: string | null;
}

export function filterForUniqueObjectsByProperty<T extends Item>(array: T[], property: keyof T): T[] {
    const uniqueItems: T[] = [];
    const seenValues = new Set();

    for (const item of array) {
        const value = item[property];
        if (value === null) {
            // Directly add items with `value` as `null` if they don't exist in `uniqueItems`
            if (!uniqueItems.some(x => x[property] === null)) {
                uniqueItems.push(item);
            }
        } else {
            // For non-null values, check if we've seen this value before
            if (!seenValues.has(value)) {
                uniqueItems.push(item);
                seenValues.add(value);
            }
        }
    }

    return uniqueItems;
}