import React from 'react';
import {CopyOutlined} from "@ant-design/icons";
import {message} from "antd";

type TooltipProps = {
    text: string;
    children: React.ReactNode;
    classname?: string
    phoneNumber?: string
    email?: string
};

const Tooltip = ({text, children, classname, phoneNumber, email}: TooltipProps) => {

    const handleCopy = (copyType: string) => {
        if (copyType === 'phone') {
            navigator.clipboard.writeText(phoneNumber!!)
            message.success('Phone was copied')
        } else {
            navigator.clipboard.writeText(email!!)
            message.success('Email was copied')
        }
    }

    return (
        <div style={{
            display: 'block'
        }} className={`${classname} tooltip` || "tooltip"}>
            {children}
            <span className={"tooltiptext"}>
                {text}
                {
                    phoneNumber
                    &&
                    <CopyOutlined onClick={() => handleCopy('phone')}
                                  style={{marginLeft: 8}}/>
                }
                {
                    email
                    &&
                    <CopyOutlined onClick={() => handleCopy('email')}
                                  style={{marginLeft: 8}}/>
                }
            </span>
        </div>
    );
};

export default Tooltip;