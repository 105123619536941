import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router";
import { FinishGoogleIntegrationThunk } from '../../store/userReducer';
import { useAppDispatch } from '../../app/hooks';
import { Spin } from 'antd';

const Home = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        let code = location.search.split('code=')[1]
        code = code?.split('&')[0]
        if (code) {
            dispatch(FinishGoogleIntegrationThunk(code))
                .then(() => navigate('/requirements'))
        } else {
            navigate('/requirements')
        }
    }, [location, dispatch, navigate])

    return (
        <div
            style={{
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100px',
            }}
        >
            <Spin />
        </div>
    );
};

export default Home;
