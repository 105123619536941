import React from 'react';
import PropertiesViewOptions from "../../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
import {SystemGridsTypes} from "../../../types/commonGridsTypes";
import s from './MobileFiltersMenu.module.scss'
import backArrow from './../../../img/icons/mobileFiltersBackArrow.svg'
import {setIsMobileFiltersOpened} from "../../../store/utilityReducer";
import {useAppDispatch} from "../../../app/hooks";
import PropertiesFilterButtons from "../../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import {ShownAndHiddenColumnsType} from "../../../store/propertiesReducer";
import PropertiesHeaderButtons from "../../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";
import {saveGridConfigs} from "../../../commonGridFunctions/saveGridConfigs";
import {resetGridConfigs} from "../../../commonGridFunctions/resetGridConfigs";
import {UserDataType} from "../../../types/userTypes";
import {GridColumns} from '../../../types/commonTypes';
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../../img/Loader.json";
import { onAuditResetFiltersButtonsSelected } from '../../../store/parametersReducer';

type MobileMenuFiltersType = {
    checkedViewOptions: string[]
    filtersShown: boolean
    setCheckedViewOptions: (options: string[]) => void
    setFiltersShown: (isShown: boolean) => void
    gridType: SystemGridsTypes
    shownColumns: ShownAndHiddenColumnsType[]
    userData: UserDataType
    threeMonthsAgo: Date
    today: Date
    hiddenColumns: ShownAndHiddenColumnsType[]
    gridColumns: GridColumns[]
    setSearchTerm: (search: string) => void,
    setLocalSortModel: (model: {}) => void,
    localSortModel: any
    handleFiltersShownClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    resetFilters: () => void
    top?: string
    // setFilterShown
}

const MobileFiltersMenu = ({
                               checkedViewOptions,
                               filtersShown,
                               setCheckedViewOptions,
                               setFiltersShown,
                               gridType,
                               shownColumns,
                               userData,
                               threeMonthsAgo,
                               today,
                               gridColumns,
                               hiddenColumns,
                               setLocalSortModel,
                               setSearchTerm,
                               localSortModel,
                               handleFiltersShownClick,
                               resetFilters,
                               top
                           }: MobileMenuFiltersType) => {
    const dispatch = useAppDispatch()
    const onSaveGridConfigs = () => {
        saveGridConfigs(shownColumns, hiddenColumns, gridColumns, localSortModel, userData, threeMonthsAgo, today, gridType, dispatch)
    }
    const onResetConfigs = () => {
        resetGridConfigs(dispatch, userData, threeMonthsAgo, today, setSearchTerm, setLocalSortModel, gridType)
    }

    const onCloseModal = () => {
        dispatch(setIsMobileFiltersOpened(false))
        dispatch(onAuditResetFiltersButtonsSelected())
    }

    return (
        <>
            {
                !shownColumns.length
                    ?
                    <div style={{
                        height: '100vh',
                        backgroundColor: '#fff',
                        width: '100%',
                        margin: '0 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: 100
                    }}>
                        <div style={{
                            height: '300px',
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            <Lottie
                                // @ts-ignore
                                config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                            />
                        </div>

                        <p style={{
                            textAlign: 'center'
                        }}>Loading</p>
                    </div>
                    :
                    <div className={s.inner} style={{
                        top: top ? top : '90px'
                    }}>
                        <div
                            className={s.inner__header}
                            onClick={onCloseModal}
                        >
                            <img src={backArrow} alt="back-arrow"/>
                            <p className={s.inner__headerTitle}>Grid settings</p>
                        </div>
                        {
                            gridType !== 'Audit'
                            &&
                            <PropertiesViewOptions
                                checked={checkedViewOptions}
                                filtersShown={filtersShown}
                                setChecked={(checkedArray: string[]) => setCheckedViewOptions(checkedArray)}
                                setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
                                gridType={gridType}
                                isMobileMenu={true}
                            />
                        }
                        <div>
                            <p className={s.filtersTitle}>Filters</p>
                            <PropertiesFilterButtons
                                shownColumns={shownColumns}
                                onResetFilters={() => {
                                }}
                                onResetSorting={() => {
                                }}
                                gridType={gridType}
                                isMobile={true}
                            />
                        </div>
                        <div>
                            <PropertiesHeaderButtons
                                onSetFiltersShown={handleFiltersShownClick}
                                onResetConfigs={onResetConfigs}
                                onSaveGridConfigs={onSaveGridConfigs}
                                isDisabledSaveBtn={false}
                                gridType={gridType}
                                isMobile={true}
                                resetFilters={resetFilters}
                            />
                        </div>
                    </div>
            }
        </>
    );
};

export default MobileFiltersMenu;