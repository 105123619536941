import React, {useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    ActivityEventType,
    onDefaultNotificationDatePickerOpened,
    onHeaderNotificationDatePickerOpened,
    onNotificationDatePickerOpened,
    selectActivityEvents,
    selectCurrentEventTabStatus,
    selectDatesRange,
    selectIsDataLoading,
} from "../../store/eventsReducer";
import NotificationItem from "../Notifications/NotificationItem/NotificationItem";
import s from './HeaderMenuNotifications.module.scss'



type Handler = (event: MouseEvent | TouchEvent) => void;

interface HeaderMenuNotificationsTypes {
    setIsMenuOpened: (isMenuOpened: boolean) => void
}

const HeaderMenuNotifications = ({setIsMenuOpened}: HeaderMenuNotificationsTypes) => {
    let activityEvents = useAppSelector(selectActivityEvents)
    const tabStatus = useAppSelector(selectCurrentEventTabStatus)
    const isDataLoading = useAppSelector(selectIsDataLoading)
    const selectedDateRange = useAppSelector(selectDatesRange)
    const startDate = new Date(selectedDateRange[0]);
    const endDate = new Date(selectedDateRange[1]);
    const [visibleActivities, setVisibleActivities] = useState<any[]>([])
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch()


    useEffect(() => {
        if (selectedDateRange !== null && selectedDateRange[0] && selectedDateRange[1]) {
            const startDate = new Date(selectedDateRange[0]);
            const endDate = new Date(selectedDateRange[1]);
            const currentEvents = activityEvents.filter((l) => {
                const activityDate = new Date(l.action_date)
                return activityDate >= startDate && activityDate <= endDate
            })
            setVisibleActivities(currentEvents)
        } else {
            setVisibleActivities(activityEvents)
        }
    }, [activityEvents, selectedDateRange])

    // useEffect for tracking outside the filters modal click to close it , when user click outside the filters modal
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, []);
    // Function for tracking outside the filters modal click to close it , when user click outside the filters modal
    const handleClickOutside: Handler = (event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            // Check if the event target or any of its ancestors has the 'rs-picker-menu' class,
            // which is common in dropdowns like those in DatePickers.
            if ((event.target as HTMLElement).closest('.rs-picker-menu')) {
                // If the click was inside the DatePicker dropdown, do nothing
                return;
            }

            // Get the element that might be a select dropdown with the class 'notificationsSelect'
            const selectEl = ref.current.querySelector(".notificationsSelect");

            // Check if the select element does not exist or the click was outside this element
            if (!selectEl || (selectEl && !selectEl.contains(event.target as Node))) {
                // Dispatch Redux actions to update state indicating that the date pickers are closed
                dispatch(onNotificationDatePickerOpened(false));
                dispatch(onHeaderNotificationDatePickerOpened(false));
                dispatch(onDefaultNotificationDatePickerOpened(false));

                // Update local state to indicate that the menu is closed
                setIsMenuOpened(false);
            }
        }
    };







    return (
        <div className={s.inner} ref={ref}>
            <div className={s.inner__header}>
                <p className={s.title}>Notifications</p>
            </div>
            <div>
                {
                    visibleActivities.filter((l) => {
                        const activityDate = new Date(l.action_date)
                        return activityDate >= startDate && activityDate <= endDate
                    }).filter((e: ActivityEventType) => e?.event_status?.eventStatus !== 'not_shown').map((e: ActivityEventType) => {
                        return (
                            <NotificationItem
                                status={e.event_status !== null ? e.event_status.eventStatus : ''}
                                validTill={e.action_date}
                                activityNumber={e.activity_ref}
                                withActions={false}
                                eventStatus={tabStatus}
                                headerNotificationItem={true}
                                eventNumber={e.event_id}
                                isRead={e.is_read}
                                description={e.event_status !== null ? e.event_status.description : ''}
                                isLoading={isDataLoading}
                                eventDescription={e.event_template !== null ? e.event_template : null}
                                event={e}
                                eventComment={e.description}
                            />
                        )
                    })
                }
            </div>
        </div>
    );
};

export default HeaderMenuNotifications;