import {Link} from 'react-router-dom'
import classes from './ParametersPage.module.css'
import {ReactComponent as RightArrow} from './../../img/icons/rightArrow.svg'
import {ReactComponent as MarketingReportsIcon} from './../../img/parameters/marketingReportsIcon.svg'
import {ReactComponent as DisclaimerInfoIcon} from './../../img/parameters/disclaimerInfoIcon.svg'
import {ReactComponent as AttributeIcon} from './../../img/icons/atributecodesicon.svg'
import {ReactComponent as ReportAgentsIcon} from './../../img/icons/report-agents.svg'
import PageTitle from '../common/PageTitle/PageTitle'
import React, {useEffect} from "react";
import {gridDataHelper} from "../../helpers/localStorageHelper";
import {clearPropertiesForEmail} from "../../store/propertiesReducer";
import {useAppDispatch} from "../../app/hooks";
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";

const ParametersPage = () => {
    return (
        <PageWithSidebar>
            <ParametersPageComponent/>
        </PageWithSidebar>
    )
}
const ParametersPageComponent = () => {
    const dispatch = useAppDispatch()
    const parameters = [
        {
            title: 'Marketing reports',
            description: 'Upload templates',
            link: '/parameters/marketing-reports',
            icon: <MarketingReportsIcon/>
        },
        {
            title: 'Disclaimer info',
            description: 'Information about domain',
            link: '/parameters/disclaimer-info',
            icon: <DisclaimerInfoIcon/>
        },
        {
            title: 'Templates for email',
            description: 'Create templates for your emails',
            link: '/parameters/templates-for-email',
            icon: <DisclaimerInfoIcon/>
        },
        {
            title: 'Activities',
            description: 'Manage activity kinds',
            link: '/parameters/create-activity-codes',
            icon: <AttributeIcon/>
        },
        {
            title: 'Report Agents Order',
            description: 'Set up agent order for reports',
            link: '/parameters/report-agents-order',
            icon: <ReportAgentsIcon/>
        },
        {
            title: 'Administration',
            description: 'Access control functionality',
            link: '/parameters/administration',
            icon: <DisclaimerInfoIcon/>
        }
    ]

    useEffect(() => {
        gridDataHelper.removePropertiesGridFilters()
        gridDataHelper.removeConditionGridFiltersString()
        dispatch(clearPropertiesForEmail([]))
    }, [dispatch])

    return (
        <div className='pageWrapper'>
            <PageTitle title='Parameters' isBackButton={false}/>
            <div className={classes.contentWrapper}>
                {parameters.map(param => (
                    <Link to={param.link} key={param.title} className={classes.parameterWrapper}>
                        {param.icon}
                        <div className={classes.text}>
                            <h2>
                                {param.title}
                            </h2>
                            <div className={classes.description}>
                                {param.description}
                            </div>
                        </div>
                        <RightArrow className={classes.arrow}/>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default ParametersPage
