import React from 'react';
import s from "./SecondaryButton.module.scss";


interface SecondaryButtonProps {
    text: string
    width: string
    isWhiteBg?: boolean
    height: string
    fontSize?: string
    disabled?: boolean
}

const SecondaryButton = ({text, width, isWhiteBg, height, fontSize, disabled}: SecondaryButtonProps) => {
    return (
        <button disabled={disabled} className={!isWhiteBg ? s.button : `${s.button} ${s.button_whiteBg}`} style={{
            width: width,
            minWidth: '90px',
            height: height,
            fontSize: fontSize ? fontSize : '14px',
        }}>
            {text}
        </button>
    );
};

export default SecondaryButton;