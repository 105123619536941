export function numericComparator(valueA: any, valueB: any) {
    if (valueA === null && valueB !== null) {
        return -1; // Treat null as lower value, so valueA comes first
    } else if (valueA !== null && valueB === null) {
        return 1; // Treat null as lower value, so valueB comes first
    }

    valueA = valueA === null ? '0' : valueA;
    valueB = valueB === null ? '0' : valueB;

    const numA = convertIfPossible(valueA);
    const numB = convertIfPossible(valueB);

    return numA - numB;
}

export function customSortFunction(rows: any[], sortModel: any): any[] {
    const sortedRows = [...rows];
    if (sortModel?.length > 0) {
        sortedRows.sort((rowA, rowB) => {
            for (const {field, sort} of sortModel) {

                const valueA = convertIfPossible(rowA[field]);
                const valueB = convertIfPossible(rowB[field]);
                let compareResult;

                if (typeof valueA === 'number' && typeof valueB === 'number') {
                    compareResult = numericComparator(valueA, valueB);
                } else {
                    compareResult = compareValues(valueA, valueB);
                }

                if (compareResult !== 0) {
                    return sort === 'asc' ? compareResult : -compareResult;
                }
            }
            return 0;
        });
    }
    return sortedRows;
}


function convertIfPossible(value: any): any {
    if (!value) {
        return null;
    }
    const europeanNumberRegex = /^(\d{1,3}(?:\.\d{3})*|\d+)(?:,\d+)?$/;
    if (europeanNumberRegex.test(value)) {
        const withoutSeparators = String(value).replace(/[.,]/g, '');
        const withCorrectDecimalSeparator = withoutSeparators.replace(',', '.');
        const numberValue = parseFloat(withCorrectDecimalSeparator);

        if (!isNaN(numberValue)) {
            return numberValue;
        }
    }
    return value;
}

function compareValues(valueA: any, valueB: any): number {
    if (valueA === null && valueB === null) {
        return 0;
    }
    if (valueA === null) {
        return -1;
    }
    if (valueB === null) {
        return 1;
    }

    const numA = convertIfPossible(toEuropeanFormat(valueA));
    const numB = convertIfPossible(toEuropeanFormat(valueB));

    if (!isNaN(numA) && !isNaN(numB)) {
        return numA - numB;
    } else {
        return String(valueA).localeCompare(String(valueB));
    }
}

function toEuropeanFormat(value: any): string {
    if (typeof value === 'number') {
        return value.toLocaleString('de-DE');
    }
    return value;
}