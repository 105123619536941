import { message, Upload } from "antd"

export const dummyRequest = ({ onSuccess }:any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const getBase64 = (file:any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const normFile = (e: any, addPhoto: (file:any, bizShortLink: string) => void, bizShortLink: string) => {
  addPhoto(e.file, bizShortLink)
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

export const beforeUpload = (file:any) => {
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) message.error('File must be less than 2 mb')
  return isLt2M ? true : Upload.LIST_IGNORE
}
