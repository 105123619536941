import React from 'react';
import s from './NotificationsActions.module.scss'
import {
    ActivityEventType,
    GetContactsByEventId,
    onDefaultNotificationDatePickerOpened,
    onHeaderNotificationDatePickerOpened,
    onNotificationDatePickerOpened,
    onOpenContactsModal,
    onOpenDomainModal,
    onSetActivityCurrentNotification
} from "../../../store/eventsReducer";
import {useAppDispatch} from "../../../app/hooks";


type NotificationsActionsProps = {
    onChangeStatus: (status: string) => void
    isDefaultNotification: boolean
    isHeaderNotification?: boolean
    currentEventStatus: string
    event: ActivityEventType | null
}


const NotificationsActions = ({
                                  onChangeStatus,
                                  isDefaultNotification,
                                  isHeaderNotification,
                                  currentEventStatus,
                                  event
                              }: NotificationsActionsProps) => {
    const dispatch = useAppDispatch()
    let eventStatuses = ['hold', 'cancelled', 'confirmed', 'edit data', 'edit contact', 'edit domain']

    const onChangeEventStatus = (status: string) => {
        if (status === 'edit data') {
            if (isHeaderNotification) {
                dispatch(onDefaultNotificationDatePickerOpened(false))
                dispatch(onNotificationDatePickerOpened(false))
                setTimeout(() => {
                    dispatch(onHeaderNotificationDatePickerOpened(true))
                }, 250)
            } else if (isDefaultNotification) {
                dispatch(onHeaderNotificationDatePickerOpened(false))
                dispatch(onNotificationDatePickerOpened(false))
                setTimeout(() => {
                    dispatch(onDefaultNotificationDatePickerOpened(true))
                }, 250)
            } else {
                dispatch(onHeaderNotificationDatePickerOpened(false))
                dispatch(onDefaultNotificationDatePickerOpened(false))
                setTimeout(() => {
                    dispatch(onNotificationDatePickerOpened(true))
                }, 250)
            }

        } else if (status === 'edit contact') {
            if(isHeaderNotification){
                dispatch(GetContactsByEventId(event?.event_id!!))
                dispatch(onOpenContactsModal(true))
            }
            else{
                dispatch(GetContactsByEventId(event?.event_id!!))
                dispatch(onOpenContactsModal(true))
            }
        }
        else if(status === 'edit domain'){
            // dispatch(GetContactsByEventId(event?.event_id!!))
            dispatch(onOpenDomainModal(true))
            dispatch(onSetActivityCurrentNotification(event!!))
        }
        onChangeStatus(status)
    }
    eventStatuses = currentEventStatus === 'cancelled'
        ? eventStatuses.filter((status: string) => status !== 'hold')
        : currentEventStatus === 'action_required' ? eventStatuses
            :
            eventStatuses.filter((status: string) => status !== 'edit domain')

    // filter event actions with not editable contact
    eventStatuses = event!.IS_CONTACT_EDITABLE === 0 ? eventStatuses.filter((status: string) => status !== 'edit contact') : eventStatuses
    // forbid user to confirm event with action_type === "SET_CONTACT"
    eventStatuses = event!.action_type === "SET_CONTACT" ? eventStatuses.filter((status: string) => status !== 'confirmed') : eventStatuses
    // forbid user to confirm event with action_type === "SET_CONTACT"
    // eventStatuses = eventStatuses.filter((status: string) => status !== 'edit contact' && status !== 'edit domain')
    return (
        <div className={isDefaultNotification ? s.defaultInner : isHeaderNotification ? s.headerInner : s.inner}>
            {
                eventStatuses.filter((event: string) => event !== currentEventStatus).map((e: string) => {
                    return (
                        <div className={s.inner__item}
                             onClick={() => onChangeEventStatus(e)}>
                            {e === 'hold' ? 'Hold' : e === 'cancelled' ? 'Cancel' : e === 'confirmed' ? 'Confirm' : e === 'edit data' ? 'Editing the date' : e === 'edit contact' ? 'Editing the contact' : 'Editing domain'}
                        </div>
                    )
                })
            }
        </div>
    );
};

export default NotificationsActions;