import {Spin} from 'antd'
import {Column} from '@ant-design/plots'
import {useEffect} from 'react'
import classes from './ReportChart.module.css'
import {useAppSelector} from '../../../app/hooks'
import {
    selectActivityReportsDates,
    selectChartInfo,
    selectCompanies,
    selectReportsDiagram
} from '../../../store/reportReducer'
import {ReportTypeDataType} from '../../../types/reportTypes'
import moment from 'moment'

const ReportChart: React.FC<{ reportTypeData: ReportTypeDataType, isFraud: boolean, dateType?: string | null, isLoading?: boolean, setIsLoading?: (isLoading: boolean) => void }> = ({
                                                                                                                                                                                         reportTypeData,
                                                                                                                                                                                         isFraud,
                                                                                                                                                                                         dateType,
                                                                                                                                                                                         isLoading,
                                                                                                                                                                                         setIsLoading
                                                                                                                                                                                     }) => {
    const chartInfo = useAppSelector(selectChartInfo)
    const companies = useAppSelector(selectCompanies)
    const fraudDiagram = useAppSelector(selectReportsDiagram)
    const activityReportsDates = useAppSelector(selectActivityReportsDates)

    const colors = [
        {prevMonth: 'rgba(249, 142, 42, 1)', currentMonth: 'rgba(249, 142, 42, 0.5)'},
        {prevMonth: 'rgba(255, 255, 0, 1)', currentMonth: 'rgba(255, 255, 0, 0.5)'},
        {prevMonth: 'rgba(79, 202, 100, 1)', currentMonth: 'rgba(79, 202, 100, 0.5)'},
        {prevMonth: 'rgba(24, 144, 255, 1)', currentMonth: 'rgba(24, 144, 255, 0.5)'},
        {prevMonth: 'rgba(156,42,249, 1)', currentMonth: 'rgba(221,42,249,0.5)'},
        {prevMonth: 'rgba(10,146,206, 1)', currentMonth: 'rgb(5,59,86)'},
        {prevMonth: 'rgba(202,79,83, 1)', currentMonth: 'rgba(202,79,83,0.5)'},
        {prevMonth: 'rgba(255,140,24, 1)', currentMonth: 'rgba(255,224,24,0.5)'},
    ]
    const companyColors = !isFraud
        ? Object.assign({}, ...companies.map((company, index) => ({[company.domain_code]: colors[index] || colors[0]})))
        : Object.assign({}, ...fraudDiagram.map((company, index) => ({[company.prop_count]: colors[index] || colors[0]})))
    //do not remove space form type ` ${item.prop_count}`
    const data = isFraud
        ? fraudDiagram.map((item) => ({
            type: ` ${item.prop_count}`,
            value: item.prop_count_quantity,
            month: moment().month(),
            monthCompany: item.prop_count

        })) : chartInfo.map((item) => ({
            type: item.company.domainCode,
            value: item.count,
            month: item.month,
            monthCompany:
                dateType === 'year' || dateType === 'month' || dateType === 'date'
                    ? `${item.month}-company-name-${item.company.domainCode}`
                    : dateType === 'week'
                        ? `${item.month} week-company-name-${item.company.domainCode}`
                        : dateType === 'period'
                            ? `${activityReportsDates?.start_period ? moment(activityReportsDates.start_period).format('yyyy-MM-DD') : ''} - ${activityReportsDates?.end_period ? moment(activityReportsDates.end_period).format('yyyy-MM-DD') : ''}-company-name-${item.company.domainCode}`
                            : `${item.month}-company-name-${item.company.domainCode}`
        }))


    useEffect(() => {
        if (isFraud) {
            // @ts-ignore
            setIsLoading(false)
            // !!fraudDiagram?.length && setIsLoading(false)
        } else {
            // @ts-ignore
            !!chartInfo?.length && setIsLoading(false)
        }
    }, [chartInfo, isFraud, fraudDiagram, setIsLoading])

    console.log(data)
    let config = {
        data,
        isGroup: !isFraud,
        xField: 'type',
        yField: 'value',
        seriesField: 'monthCompany',
        color: ({monthCompany}: { monthCompany: string }) => {
            const color = !isFraud ? companyColors[monthCompany.split('-company-name-')[1] as keyof typeof companyColors] : companyColors[monthCompany]
            const currentMonth = moment().format('MMM')
            const currentWeek = 'Last week'
            const lastYear = 'Last year'
            const isCurrentMonth = !isFraud ? currentMonth === monthCompany.split('-company-name-')[0]  || currentWeek === monthCompany.split('-company-name-')[0]  || lastYear === monthCompany.split('-company-name-')[0]: false
            return isCurrentMonth ? color?.currentMonth : color?.prevMonth
        },
        legend: false,
        tooltip: {
            formatter: (datum: any) => {
                return {
                    name: !isFraud ? datum?.monthCompany?.split('-company-name-')[0] : 'Count of offers',
                    value: datum.value,
                    title: !isFraud
                        ? datum?.type

                        : `Count of properties: ${datum.type}`
                };
            },
        },
        label: {
            position: 'bottom',
            formatter: (datum: any) =>
                (!isFraud && (dateType === 'currentMonth' || dateType === 'lastMonth'))
                    ? datum.month
                    : isFraud
                        ? ''
                        : dateType === 'currentYear'
                            ? ''
                            : '',
            animate: false,
        },
        minColumnWidth: 45,
        maxColumnWidth: 45,
        animation: {
            appear: false
        },
        xAxis: {
            title: {
                text: !isFraud ? '' : 'Count of properties',
            },
            label: {
                style: {
                    fill: 'black'
                }
            },
        },
        yAxis: {
            title: {
                text: !isFraud ? '' : 'Count of offers',
            },
            label: {
                style: {
                    fill: 'black'
                }
            },
        }
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.title}>
                {
                    isFraud
                        ? dateType === 'currentYear'
                            ? `Email Breaks Property Count Record (Current Year)`
                            : dateType === 'currentWeek' ? `Email Breaks Property Count Record (Current Week)`
                                : dateType === 'currentMonth' ? `Email Breaks Property Count Record (Current Month)`
                                    : dateType === 'lastWeek' ? `Email Breaks Property Count Record (Last Week)`
                                        : dateType === 'lastMonth' ? `Email Breaks Property Count Record (Last Month)`
                                            : dateType === 'year' ? `Email Breaks Property Count Record (${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format("YYYY") : ''})`
                                                : dateType === 'month' ? `Email Breaks Property Count Record (${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format("YYYY MMMM") : ''})`
                                                    : dateType === 'week' ? `Email Breaks Property Count Record (${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format("YYYY [Week] W") : ''})`
                                                        : dateType === 'date' ? `Email Breaks Property Count Record (${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format('yyyy-MM-DD') : ''})`
                                                            : dateType === 'period' ? `Email Breaks Property Count Record (${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format('yyyy-MM-DD') : ''} - ${activityReportsDates?.start_period ? moment(activityReportsDates?.end_period).format('yyyy-MM-DD') : null})`
                                                                : `Email Breaks Property Count Record ${moment().utc().startOf('month').format('yyyy-MM-DD')} - ${moment().utc().format('yyyy-MM-DD')}`


                        : dateType === 'currentYear' ? `Current year total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                            : dateType === 'currentWeek' ? `Current week total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                                : dateType === 'currentMonth' ? `Current month total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                                    : dateType === 'lastWeek' ? `Last week total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                                        : dateType === 'year' ? `${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format("YYYY") : ''} year total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                                            : dateType === 'month' ? `${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format("YYYY MMMM") : ''} total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                                                : dateType === 'week' ? `${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format("YYYY [Week] W") : ''} total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                                                    : dateType === 'date' ? `${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format('yyyy-MM-DD') : ''} total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                                                        : dateType === 'period' ? `${activityReportsDates?.start_period ? moment(activityReportsDates?.start_period).format('yyyy-MM-DD') : ''} - ${activityReportsDates?.start_period ? moment(activityReportsDates?.end_period).format('yyyy-MM-DD') : null} 
                                                             total ${reportTypeData.reportType === 'activityReportForm' ? 'activities' : reportTypeData.reportType === 'agentLastContactForm' ? 'agents' : reportTypeData.reportType === 'propertyLastContact' ? 'property' : reportTypeData.reportType === 'valuatedPropertiesLastContact' ? 'valuated properties' : 'requirement'} graph`
                                                            : 'Last month total activities graph'
                }
            </div>
            {isLoading ? (
                <div className={classes.spinWrapper}>
                    <Spin/>
                </div>
            ) : (
                // @ts-ignore
                <Column {...config} />
            )}
            {
                isFraud
                &&
                <p className={classes.tableDesc}>*The graph shows how many offers are sent with a certain number of
                    properties</p>
            }

        </div>
    )
}

export default ReportChart
