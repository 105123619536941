const reportData = {
  '/control-panel/activity-report': {
    reportType: 'activityReportForm',
    reportTitle: 'Activity report',
    chartTitle: 'Previous/current month total activities graph',
  },
  '/control-panel/agent-last-contact': {
    reportType: 'agentLastContactForm',
    reportTitle: 'Agent last contact',
    chartTitle: 'Previous/current month total agents graph',
  },
  '/control-panel/property-last-contact': {
    reportType: 'propertyLastContact',
    reportTitle: 'Property last contact',
    chartTitle: 'Previous/current month total property graph',
  },
  '/control-panel/valuated-properties-last-contact': {
    reportType: 'valuatedPropertiesLastContact',
    reportTitle: 'Valuated Properties last contact',
    chartTitle: 'Previous/current month total valuated properties graph',
  },
    '/control-panel/requirement-last-contact': {
    reportType: 'requirementLastContact',
    reportTitle: 'Requirement last contact',
    chartTitle: 'Previous/current month total requirement graph',
  },
}

export default reportData
