import { ConfigProvider, DatePicker, Form, FormInstance, Radio } from 'antd'
import classes from './DatePickerFormItem.module.css'
import en_GB from 'antd/lib/locale-provider/en_GB'
import moment, { Moment } from 'moment'
import { PickerType } from '../../../types/dateTypes'
import {useAppDispatch} from "../../../app/hooks";
import {setActivityReportsDates, setFraudReportsDates} from "../../../store/reportReducer";

const DatePickerFormItem: React.FC<DatePickerFormItemPropTypes> = ({type, form, setDateType, defaultValue}) => {
  const dispatch = useAppDispatch()
  const selectDateType = (type: PickerType) => {
    console.log(type)
    if (type === 'currentMonth' || type === 'currentWeek' || type === 'currentYear' ) {
      const start = moment().utc().startOf(type === 'currentMonth' ? 'month' : (type === 'currentWeek' ? 'week' : 'year'))
      const end = moment().utc()
      form.setFieldsValue({dates: [start, end]})
      dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      dispatch(setFraudReportsDates({start_period: start, end_period: end}))
    } else if (type === 'lastMonth' || type === 'lastWeek' ) {
      const start = moment().utc().startOf(type === 'lastWeek' ? 'week' : 'month').subtract(1, type === 'lastWeek' ? 'weeks' : 'months')
      const end = moment().utc().endOf(type === 'lastWeek' ? 'week' : 'month').subtract(1, type === 'lastWeek' ? 'weeks' : 'months')
      form.setFieldsValue({dates: [start, end]})
      dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      dispatch(setFraudReportsDates({start_period: start, end_period: end}))
    }
    else if(type === 'month'){

        // const start = moment().utc().startOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[0]
        // const end = type !== "period" ?  moment().utc().endOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[1]
        // dispatch(setActivityReportsDates({start_period: start, end_period: end}))
        // dispatch(setFraudReportsDates({start_period: start, end_period: end}))
        const start = moment().utc().startOf('month')
        const end = moment().utc().endOf('month')

        dispatch(setActivityReportsDates({start_period: start, end_period: end}))
        dispatch(setFraudReportsDates({start_period: start, end_period: end}))
    }
    else if(type === 'week'){
      // const start = moment().utc().startOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[0]
      // const end = type !== "period" ?  moment().utc().endOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[1]
      // dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      // dispatch(setFraudReportsDates({start_period: start, end_period: end}))
      const start = moment().utc().startOf('week')
      const end = moment().utc().endOf('week')
      dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      dispatch(setFraudReportsDates({start_period: start, end_period: end}))
    }
    else if(type === 'year'){
      // const start = moment().utc().startOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[0]
      // const end = type !== "period" ?  moment().utc().endOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[1]
      // dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      // dispatch(setFraudReportsDates({start_period: start, end_period: end}))
      const start = moment().utc().startOf('year')
      const end = moment().utc().endOf('year')
      dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      dispatch(setFraudReportsDates({start_period: start, end_period: end}))
    }
    else if(type === 'date'){
      // const start = moment().utc().startOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[0]
      // const end = type !== "period" ?  moment().utc().endOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[1]
      // dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      // dispatch(setFraudReportsDates({start_period: start, end_period: end}))
      const start = moment().utc().startOf('date')
      const end = moment().utc().endOf('date')
      dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      dispatch(setFraudReportsDates({start_period: start, end_period: end}))
    }
    else if(type === 'period'){
      // const start = moment().utc().startOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[0]
      // const end = type !== "period" ?  moment().utc().endOf(type === 'year' ? 'year' : (type === 'month' ? 'month' : type === 'week' ? 'week' : type === 'date' ? 'date' : 'year')) : defaultValue[1]
      // dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      // dispatch(setFraudReportsDates({start_period: start, end_period: end}))
      const start = defaultValue[0]
      const end = defaultValue[1]
      dispatch(setActivityReportsDates({start_period: start, end_period: end}))
      dispatch(setFraudReportsDates({start_period: start, end_period: end}))
    }
    else {
      form.setFieldsValue({dates: null})
    }

    setDateType(type)
  }

  return (
    <>
      <div className={classes.label}>
        Choose dates:
      </div>
      <DatePickerTypes type={type} setActiveType={selectDateType} showTemplates={false} />
      <div>
        <Form.Item
          name='dates'
          rules={[{ required: true, message: 'Please select dates' }]}
          style={{width: '100%', maxWidth: '400px'}}
        >
          <ConfigProvider locale={en_GB}>
            <PickerWithType type={type} form={form} defaultValue={defaultValue}/>
          </ConfigProvider>
        </Form.Item>
      </div>
      <div style={{marginBottom: '10px'}}>Or select your option</div>
      <DatePickerTypes type={type} setActiveType={selectDateType} showTemplates={true} />
    </>
  )
}

const DatePickerTypes: React.FC<DatePickerOptionsPropTypes> = ({type, setActiveType, showTemplates}) => {
  const dateTypes: {title: string, value: PickerType}[] = [
    {title: 'Year', value: 'year'},
    {title: 'Month', value: 'month'},
    {title: 'Week', value: 'week'},
    {title: 'Date', value: 'date'},
    {title: 'Period', value: 'period'},
  ]

  const defaultDates: {title: string, value: PickerType}[] = [
    {title: 'Current Week', value: 'currentWeek'},
    {title: 'Current Month', value: 'currentMonth'},
    {title: 'Current Year', value: 'currentYear'},
    {title: 'Last Week', value: 'lastWeek'},
    {title: 'Last Month', value: 'lastMonth'},
  ]




  return (
    <>
      {!showTemplates ? (
        <Radio.Group onChange={(e) => setActiveType(e.target.value)} value={type} className={classes.dateTypesOptions}>
          {dateTypes.map(option => (
            <Radio value={option.value} key={option.value}>
              {option.title}
            </Radio>
          ))}
        </Radio.Group>
      ) : (
        <Radio.Group
          optionType='button'
          onChange={(e) => setActiveType(e.target.value)}
          value={type}
          className={classes.templateDateOptions}
        >
          {defaultDates.map(option => (
            <Radio value={option.value} key={option.value} style={{fontSize: '14px'}}>
              {option.title}
            </Radio>
          ))}
        </Radio.Group>
      )}
    </>
  )
}

const PickerWithType: React.FC<PickerWithTypePropTypes> = ({type, form, defaultValue}) => {
  const dispatch = useAppDispatch()
  const handleChange = (value: any) => {
    let dates: [Moment, Moment]

    if (type === 'year') {
      dates = [moment(value).utc().startOf('year'), moment(value).utc().isSame(new Date(), 'year') ? moment().utc() : moment(value).utc().endOf('year')]
    } else if (type === 'month') {
      dates = [moment(value).utc().startOf('month'), moment(value).utc().isSame(new Date(), 'month') ? moment().utc() : moment(value).utc().endOf('month')]
    } else if (type === 'week') {
      dates = [moment(value).utc().startOf('week'), moment(value).utc().isSame(new Date(), 'week') ? moment().utc() : moment(value).utc().endOf('week')]
    } else if (type === 'date') {
      dates = [value, value]
    } else {
      dates = [value?.[0], value?.[1]]
    }
    dispatch(setActivityReportsDates({start_period: dates[0], end_period: dates[1]}))
    dispatch(setFraudReportsDates({start_period: dates[0], end_period: dates[1]}))
    form.setFieldsValue({dates})

  }

  if (type === 'period' || !type) {
    return <DatePicker.RangePicker
      style={{width: '100%'}}
      onChange={handleChange}
      allowClear={false}
      disabled={!type}
      disabledDate={(date) => moment(date).isAfter(moment())}
      // defaultValue={[moment().utc().subtract(3, 'days'), moment().utc()]}
      defaultValue={defaultValue}

    />
  } else if (type === 'year' || type === 'month' || type === 'week' || type === 'date' ) {
    return <DatePicker
      style={{width: '100%'}}
      picker={type}
      allowClear={false}
      onChange={handleChange}
      value={form.getFieldValue('dates')?.[1]}
      disabledDate={(date) => moment(date).isAfter(moment())}
    />
  } else {
    return <DatePicker disabled style={{width: '100%'}}/>
  }
}

interface DatePickerFormItemPropTypes {
  type: PickerType
  form: FormInstance
  setDateType: (type: PickerType) => void
  defaultValue: [Moment, Moment]
}

interface DatePickerOptionsPropTypes {
  type: PickerType
  setActiveType: (type: PickerType) => void
  showTemplates: boolean
}

interface PickerWithTypePropTypes {
  type: PickerType
  form: FormInstance
  defaultValue: [Moment, Moment]
}

export default DatePickerFormItem
