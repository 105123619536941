import moment from "moment";
import {
    onChangeColdOfferRequirementsGridFilterValue,
    onChangeRequirementsGridFilterValue,
    onChangeSearchedByContactsRequirementsGridFilterValue,
    onSetColdOfferRequirements,
    onSetColdOfferRequirementsGridFiltersButtonsSelected,
    onSetRequirements,
    onSetRequirementsGridFiltersButtonsSelected,
    onSetSearchedByContactsRequirements,
    onSetSearchedByContactsRequirementsGridFiltersButtonsSelected
} from "../store/requirementsReducer";
import {
    onChangeActivitiesGridFilterValue,
    onSetActivitiesGridData,
    onSetActivitiesGridFiltersButtonsSelected
} from "../store/activitesGrid";
import {
    onChangeAuditGridFilterValue,
    onSetAuditGridFiltersButtonsSelected,
    setUserActivities
} from "../store/parametersReducer";
import {
    onChangeBrokerageReqsGridFilterValue,
    onSetBrokerageReqs,
    onSetBrokerageReqsGridFiltersButtonsSelected
} from "../store/brokerageReqsReducer";
import {
    onChangeColumnForFilterValue,
    onSetFiltersButtonsSelected,
    onSetProperties,
    onSetPropertiesWithReq,
    onSetSurfaces, onSetUpcomingProperties
} from "../store/propertiesReducer";
import { convertToNumber } from "./convertToNumber";
import {GridFiltersSessionStorageType} from "../helpers/sessionStorageDataTypes";

export const gridFiltering = (filters: GridFiltersSessionStorageType[], arrayForFiltering: any[], columnsForFilters: any[], gridType: string, dispatch: any) => {
    filters.forEach((a: any) => {
        if (a.type === 'class java.lang.String' && a.property === "STATUS") {
            arrayForFiltering = arrayForFiltering.filter((obj: any) => {
                const objValue = obj[a.property]?.toLowerCase() || null;
                return objValue === null || objValue === 'active' || objValue === 'passive';
            });
        }
        if (a.type === 'class java.lang.String' && a.property === "ACTIVITY_EXISTED") {
            arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) =>
                obj[a.property] !== null && a.value === 'yes' ? Number(obj[a.property]) === 1
                    : obj[a.property] !== null && a.value === 'Yes' ? Number(obj[a.property]) === 1
                        : obj[a.property] !== null && a.value === 'no' ? Number(obj[a.property]) === 0
                            : obj[a.property] !== null && a.value === 'No' ? Number(obj[a.property]) === 0
                                : null);
        }
        if (a.type === 'class java.lang.String' && a.property !== "ACTIVITY_EXISTED" && a.property !== "POSITION" && a.property !== "STATUS") {
            if (a.value.trim().endsWith('%')) {
                arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.slice(0, -1).toLowerCase()));
            } else {
                arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.trim().toLowerCase()) || obj[a.property]!.toLowerCase().includes(a.value!.trim().toLowerCase()));
            }

        }
        if (a.type === 'class java.lang.String' && a.property === "POSITION" && a.value === 'no position') {
            arrayForFiltering = arrayForFiltering.filter((obj: any) => obj['POSITION'] === null);
        }
        if (a.type === 'class java.lang.String' && a.property === "POSITION" && a.value !== 'no position') {
            arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.toLowerCase()) || obj[a.property]!.toLowerCase().includes(a.value!.toLowerCase()));
        }
        if (a.type === 'class java.math.BigDecimal') {
            arrayForFiltering = arrayForFiltering.filter((obj: any) =>
                obj[a.property] !== null && a.condition === 'less' ? convertToNumber(obj[a.property]) < convertToNumber(a.value)
                    : obj[a.property] !== null && a.condition === 'equals' ? convertToNumber(obj[a.property]) === convertToNumber(a.value)
                        : obj[a.property] !== null && a.condition === 'higher' ? convertToNumber(obj[a.property]) > convertToNumber(a.value)
                            : obj[a.property] !== null && a.condition === 'between' ? convertToNumber(obj[a.property]) >= convertToNumber(a.startValue) && convertToNumber(obj[a.property]) <= convertToNumber(a.endValue) : null);
        }
        if (a.type === 'class java.time.LocalDateTime' || a.type === "class oracle.sql.TIMESTAMP") {
            arrayForFiltering = arrayForFiltering.filter((obj: any) => {
                const date = moment(obj[a.property]);
                return a.dateCondition === 'onDate' ? date.isSame(a.date, "second") : a.dateCondition === 'before' ? date.isBefore(a.date, "second") : date.isAfter(a.date, "second");
            });
        }
        if (gridType === 'Requirements') {
            dispatch(onSetRequirements({requirementsRows: arrayForFiltering}))
            dispatch(onSetRequirementsGridFiltersButtonsSelected({
                columnTitle: a.property,
                isFilterSelected: true
            }))
            dispatch(onChangeRequirementsGridFilterValue({columnTitle: a.property, value: a.value}))
        } else if (gridType === 'Requirements/Cold Offer') {
            dispatch(onSetColdOfferRequirements({requirementsRows: arrayForFiltering}))
            dispatch(onSetColdOfferRequirementsGridFiltersButtonsSelected({
                columnTitle: a.property,
                isFilterSelected: true
            }))
            dispatch(onChangeColdOfferRequirementsGridFilterValue({columnTitle: a.property, value: a.value}))
        }
        else if (gridType === 'Activities Grid') {
            dispatch(onSetActivitiesGridData({properties: arrayForFiltering}))
            dispatch(onSetActivitiesGridFiltersButtonsSelected({
                columnTitle: a.property,
                isFilterSelected: true
            }))
            dispatch(onChangeActivitiesGridFilterValue({columnTitle: a.property, value: a.value}))
        } else if (gridType === 'Audit') {
            dispatch(onSetAuditGridFiltersButtonsSelected({
                columnTitle: a.property,
                isFilterSelected: true
            }))
            dispatch(onChangeAuditGridFilterValue({columnTitle: a.property, value: a.value}))
            dispatch(setUserActivities(arrayForFiltering))
        } else if (gridType === 'Brokerage/Requirements') {
            dispatch(onSetBrokerageReqsGridFiltersButtonsSelected({
                columnTitle: a.property,
                isFilterSelected: true
            }))
            dispatch(onChangeBrokerageReqsGridFilterValue({columnTitle: a.property, value: a.value}))
            dispatch(onSetBrokerageReqs(arrayForFiltering))
        }
        else if (gridType === 'Properties') {
            dispatch(onSetFiltersButtonsSelected({
                headerName: a.property,
                isFilterSelected: true,
                gridType: gridType
            }))
            dispatch(onChangeColumnForFilterValue({
                title: a.property,
                value: a.value,
                gridType: gridType
            }))
            dispatch(onSetProperties({properties: arrayForFiltering, gridType: gridType}))
        }
        else if (gridType === 'Surfaces') {
            dispatch(onSetFiltersButtonsSelected({
                headerName: a.property,
                isFilterSelected: true,
                gridType: gridType
            }))
            dispatch(onChangeColumnForFilterValue({
                title: a.property,
                value: a.value,
                gridType: gridType
            }))
            dispatch(onSetSurfaces({properties: arrayForFiltering, gridType: gridType}))
        }
        else if (gridType === 'All Properties/REQ') {
            dispatch(onSetFiltersButtonsSelected({
                headerName: a.property,
                isFilterSelected: true,
                gridType: gridType
            }))
            dispatch(onChangeColumnForFilterValue({
                title: a.property,
                value: a.value,
                gridType: gridType
            }))
            dispatch(onSetPropertiesWithReq({properties: arrayForFiltering, gridType: gridType}))
        }
        else if (gridType === 'All Properties/Upcoming') {
            dispatch(onSetFiltersButtonsSelected({
                headerName: a.property,
                isFilterSelected: true,
                gridType: gridType
            }))
            dispatch(onChangeColumnForFilterValue({
                title: a.property,
                value: a.value,
                gridType: gridType
            }))
            dispatch(onSetUpcomingProperties({properties: arrayForFiltering, gridType: gridType}))
        }
        else if (gridType === 'Requirements/Searched by contacts') {
            dispatch(onSetSearchedByContactsRequirements({requirementsRows: arrayForFiltering}))
            dispatch(onSetSearchedByContactsRequirementsGridFiltersButtonsSelected({
                columnTitle: a.property,
                isFilterSelected: true
            }))
            dispatch(onChangeSearchedByContactsRequirementsGridFilterValue({columnTitle: a.property, value: a.value}))
        }
        else {
            dispatch(onSetProperties({properties: arrayForFiltering, gridType: gridType}))
        }
        // dispatch(onSetProperties({properties: arrayForFiltering, gridType: gridType}))
        // dispatch(onSetActivitiesGridData({properties: arrayForFiltering}))
    })
}