import React from 'react';
import s from "./Properties.module.scss";
import checkIcon from "../../img/icons/check.png";
import {AllPropertyContact, ContactDiv} from "./PropertyListComponent";


interface PropertyListCheckedContactProps {
    handleClick: (contact: AllPropertyContact) => void
    uncheckedContact: any
    contactDivs: ContactDiv[]
}


const PropertyListUncheckedContact = ({handleClick, uncheckedContact, contactDivs} : PropertyListCheckedContactProps) => {
    const isItemSelected = contactDivs.some(obj => obj.value === uncheckedContact.PE_EMAIL)

    return (
        <div>
            <div onClick={() => {
                handleClick({
                    label: `${uncheckedContact.PE_FIRST_NAME} ${uncheckedContact.PE_LAST_NAME} ${uncheckedContact.PE_EMAIL}`,
                    labelEmail: uncheckedContact.PE_EMAIL,
                    labelName: `${uncheckedContact.PE_FIRST_NAME} ${uncheckedContact.PE_LAST_NAME}`,
                    value: uncheckedContact.PE_EMAIL
                })
            }}>
                <div className={s.OfferListItem}
                     style={{
                         backgroundColor: isItemSelected ? '#F9FAFB' : '#fff'
                     }}>
                    <div>
                                <span
                                    className={s.offerListName}>
                                    {`${uncheckedContact.PE_FIRST_NAME} ${uncheckedContact.PE_LAST_NAME}`}
                                </span>
                        <span
                            className={s.offerListEmail}>{uncheckedContact.PE_EMAIL}</span>
                    </div>
                    {isItemSelected && <img src={checkIcon} alt="checked"/>}
                </div>
            </div>
        </div>
    );
};

export default PropertyListUncheckedContact;