import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import moment, {Moment} from 'moment'
import {reportAPI, reportChartAPI} from '../app/api'
import {CompanyType} from '../types/companyTypes'
import {
    ChartDataItemType,
    FraudReportsDiagramItem,
    ReportRequestDataType,
    ReportsCategoriesType,
    ReportTypes
} from '../types/reportTypes'
import {AppStatusType} from './appStatusReducer'
import {AsyncThunkConfig, RootState} from './store'
interface InitialStateType {
    companies: CompanyType[]
    chartInfo: ChartDataItemType[],
    reportsCategories: ReportsCategoriesType[]
    reportsDiagramItems: FraudReportsDiagramItem[]
    activityReportDates: { start_period: Moment | null, end_period: Moment| null }
    activityReportDomainCode: string
    fraudReportDates: { start_period: Moment | null, end_period: Moment| null }

}

const initialState: InitialStateType = {
    companies: [],
    chartInfo: [],
    reportsCategories: [],
    reportsDiagramItems: [],
    activityReportDates: {
        start_period: moment().utc().startOf('month'),
        end_period: moment().utc().endOf('month')
    },
    activityReportDomainCode: 'OR',
    fraudReportDates: {
        start_period: moment().utc().startOf('month'),
        end_period: moment().utc().endOf('month')
    },
}

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setCompanies: (state, action: PayloadAction<CompanyType[]>) => {
            state.companies = action.payload
        },
        setChartInfo: (state, action: PayloadAction<ChartDataItemType[]>) => {
            state.chartInfo = action.payload
        },
        setActivityReportsDates: (state, action: PayloadAction<{ start_period: Moment | null, end_period: Moment | null}>) => {
            state.activityReportDates = action.payload
        },
        onChangeActivityDomainName: (state, action: PayloadAction<string>) => {
            state.activityReportDomainCode = action.payload
        },
        setFraudReportsDates: (state, action: PayloadAction<{ start_period: Moment | null, end_period: Moment | null}>) => {
            state.fraudReportDates = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCompaniesByPeriodThunk.fulfilled, (state, action) => {
                state.companies = action.payload
            })
            .addCase(GetChartInfoThunk.fulfilled, (state, action) => {
                state.chartInfo = action.payload
            })
            .addCase(GetReportsCategoriesThunk.fulfilled, (state, action) => {
                state.reportsCategories = action.payload.sort((a: any, b: any) => a.report_id - b.report_id)
            })
            .addCase(GetFraudReportsDiagramThunk.fulfilled, (state, action) => {
                state.reportsDiagramItems = action.payload
            })
    }
})

export const {setCompanies, setChartInfo, setActivityReportsDates, onChangeActivityDomainName, setFraudReportsDates} = reportSlice.actions

export const selectCompanies = (state: RootState): CompanyType[] => state.report.companies
export const selectChartInfo = (state: RootState): ChartDataItemType[] => state.report.chartInfo
export const selectReportsCategories = (state: RootState): ReportsCategoriesType[] => state.report.reportsCategories
export const selectReportsDiagram = (state: RootState): FraudReportsDiagramItem[] => state.report.reportsDiagramItems
export const selectActivityReportsDates = (state: RootState): { start_period: Moment | null, end_period: Moment | null} => state.report.activityReportDates
export const selectActivityReportsDomain = (state: RootState): string => state.report.activityReportDomainCode
export const selectFraudReportsDates = (state: RootState): { start_period: Moment | null, end_period: Moment | null} => state.report.activityReportDates

export const GetCompaniesByPeriodThunk = createAsyncThunk<CompanyType[], void, AsyncThunkConfig>(
    'report/getCompaniesByPeriod',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await reportAPI.getDomainsByPeriod()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.companies, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetActivityReportThunk = createAsyncThunk<string, ReportRequestDataType, AsyncThunkConfig>(
    'report/getActivityReport',
    async (dataForReport, thunkAPI) => {
        try {
            const {status, data} = await reportAPI.getActivityReport(dataForReport)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetReportFileThunk = createAsyncThunk<string, { requestData: ReportRequestDataType, reportType: ReportTypes }, AsyncThunkConfig>(
    'report/getReportFile',
    async (dataForReport, thunkAPI) => {
        const getReportRequest = async () => {
            if (dataForReport.reportType === 'activityReportForm') {
                return reportAPI.getActivityReport(dataForReport.requestData)
            } else if (dataForReport.reportType === 'agentLastContactForm') {
                return reportAPI.getAgentLastContact(dataForReport.requestData)
            } else if (dataForReport.reportType === 'propertyLastContact') {
                return reportAPI.getPropertyLastContact(dataForReport.requestData)
            } else if (dataForReport.reportType === 'requirementLastContact') {
                return reportAPI.getRequirementLastContact(dataForReport.requestData)
            } else {
                return reportAPI.getValuatedPropertiesLastContact(dataForReport.requestData)
            }
        }
        try {
            const {status, data} = await getReportRequest()
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetChartInfoThunk = createAsyncThunk<ChartDataItemType[], { reportTypes: ReportTypes, dateType: string | null, reportDates?: { start_period: Moment | null, end_period: Moment | null}, domain_code: string }, AsyncThunkConfig>(
    'report/getChartInfo',
    async (reportType, thunkAPI) => {
        const getReportRequest = async (date: { start_period: Moment, end_period: Moment, domain: string}) => {
            if (reportType.reportTypes === 'activityReportForm') {
                return reportChartAPI.getActivityChartInfo(date)
            } else if (reportType.reportTypes === 'agentLastContactForm') {
                return reportChartAPI.getAgentLastContactChartInfo(date)
            } else if (reportType.reportTypes === 'propertyLastContact') {
                return reportChartAPI.getPropertyLastContactChartInfo(date)
            } else if (reportType.reportTypes === 'requirementLastContact') {
                return reportChartAPI.getRequirementLastContactChartInfo(date)
            } else {
                return reportChartAPI.getValuatedPropertiesLastContactChartInfo(date)
            }
        }
        try {
            if (reportType.dateType === 'lastMonth') {
                const resp = await Promise.all(
                    [
                        getReportRequest({start_period: moment().utc().startOf('month').subtract(1, 'month'), end_period: moment().utc().endOf('month').subtract(1, 'month'), domain: reportType.domain_code}),
                        getReportRequest({start_period: moment().utc().startOf('month'), end_period: moment().utc().endOf('month'), domain: reportType.domain_code})
                    ])

                if (resp[0].status === 200 && resp[1].status === 200) {
                    const prevMonthData = resp[0]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment().utc().startOf('month').subtract(1, 'month').format('MMM')
                    }))
                    const thisMonthData = resp[1]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment().utc().startOf('month').format('MMM')
                    }))
                    const chartData = [...prevMonthData, ...thisMonthData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                }

                else {
                    return thunkAPI.rejectWithValue({})
                }
            }
            if (reportType.dateType === 'lastWeek' || reportType.dateType === 'currentWeek') {
                const resp = await Promise.all([
                    getReportRequest({start_period: moment().utc().startOf('week').subtract(1, 'week'), end_period: moment().utc().endOf('week'), domain: reportType.domain_code}),
                    getReportRequest({start_period: moment().utc().startOf('week'), end_period: moment().utc().endOf('week'), domain: reportType.domain_code})
                ])

                if (resp[0].status === 200 && resp[1].status === 200) {
                    const prevWeekData = resp[0]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: 'Last week'
                    }))
                    const thisWeekData = resp[1]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: 'Current week'
                    }))
                    const chartData: any = [...prevWeekData, ...thisWeekData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                }
                else {
                    return thunkAPI.rejectWithValue({})
                }
            }
            else if(reportType.dateType === 'currentMonth'){
                const resp = await Promise.all(
                    [
                        getReportRequest({start_period: moment().utc().startOf('month'), end_period: moment().utc().endOf('month'), domain: reportType.domain_code}),
                        getReportRequest({start_period: moment().utc().startOf('month').subtract(1, 'month'), end_period: moment().utc().endOf('month').subtract(1, 'month'), domain: reportType.domain_code})
                    ])

                if (resp[0].status === 200 && resp[1].status === 200) {
                    const thisMonthData = resp[0]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment().utc().startOf('month').format('MMM')
                    }))
                    const prevMonthData = resp[1]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment().utc().startOf('month').subtract(1, 'month').format('MMM')
                    }))
                    const chartData = [...prevMonthData, ...thisMonthData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                }

                else {
                    return thunkAPI.rejectWithValue({})
                }
            }
            else if(reportType.dateType === 'currentYear'){
                const resp = await Promise.all([
                    getReportRequest({start_period: moment().utc().startOf('year').subtract(1, 'year'), end_period: moment().utc().endOf('year').subtract(1, 'year'), domain: reportType.domain_code}),
                    getReportRequest({start_period: moment().utc().startOf('year'), end_period: moment().utc().endOf('year'), domain: reportType.domain_code})
                ])

                if (resp[0].status === 200 && resp[1].status === 200) {
                    const prevYearData = resp[0]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: 'Last year'
                    }))
                    const thisYearData = resp[1]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: 'Current year'
                    }))
                    const chartData: any = [...prevYearData, ...thisYearData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                }
                else {
                    return thunkAPI.rejectWithValue({})
                }
            }
            else if (reportType.dateType === 'year' && reportType.reportDates?.start_period !== null) {
                const resp = await Promise.all([
                    getReportRequest({start_period: moment(reportType?.reportDates?.start_period).startOf('year').subtract(1, 'year'), end_period: moment(reportType?.reportDates?.start_period).endOf('year').subtract(1, 'year'), domain: reportType.domain_code}),
                    getReportRequest({start_period: moment(reportType?.reportDates?.start_period), end_period: moment(reportType?.reportDates?.end_period), domain: reportType.domain_code})
                ])

                if (resp[0].status === 200 && resp[1].status === 200) {
                    const prevYearData = resp[0]?.data?.diagramList?.map(item => ({
                        ...item,
                        month:  moment(reportType?.reportDates?.start_period).subtract(1, 'year').format("YYYY")
                    }))
                    const thisYearData = resp[1]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment(reportType?.reportDates?.start_period).format("YYYY")
                    }))
                    const chartData: any = [...prevYearData, ...thisYearData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                }
                else {
                    return thunkAPI.rejectWithValue({})
                }
            }
            else if (reportType.dateType === 'month' && reportType.reportDates?.start_period !== null) {
                const resp = await Promise.all([
                    getReportRequest({start_period: moment(reportType?.reportDates?.start_period).startOf('month').subtract(1, 'month'), end_period: moment(reportType?.reportDates?.start_period).endOf('month').subtract(1, 'month'), domain: reportType.domain_code}),
                    getReportRequest({start_period: moment(reportType?.reportDates?.start_period), end_period: moment(reportType?.reportDates?.end_period), domain: reportType.domain_code})
                ])
                if (resp[0].status === 200 && resp[1].status === 200) {
                    const prevYearData = resp[0]?.data?.diagramList?.map(item => ({
                        ...item,
                        month:  moment(reportType?.reportDates?.start_period).subtract(1, 'month').format("MMM")
                    }))
                    const thisYearData = resp[1]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment(reportType?.reportDates?.start_period).format("MMM")
                    }))
                    const chartData: any = [...prevYearData, ...thisYearData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                }
                else {
                    return thunkAPI.rejectWithValue({})
                }
            }
            else if (reportType.dateType === 'week' && reportType.reportDates?.start_period !== null) {
                const resp = await Promise.all([
                    getReportRequest({start_period: moment(reportType?.reportDates?.start_period).startOf('week').subtract(1, 'week'), end_period: moment(reportType?.reportDates?.start_period).endOf('week').subtract(1, 'week'), domain: reportType.domain_code}),
                    getReportRequest({start_period: moment(reportType?.reportDates?.start_period), end_period: moment(reportType?.reportDates?.end_period), domain: reportType.domain_code})
                ])
                if (resp[0].status === 200 && resp[1].status === 200) {
                    const prevYearData = resp[0]?.data?.diagramList?.map(item => ({
                        ...item,
                        month:  moment(reportType?.reportDates?.start_period).subtract(1, 'week').format("W")
                    }))
                    const thisYearData = resp[1]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment(reportType?.reportDates?.start_period).format("W")
                    }))
                    const chartData: any = [...prevYearData, ...thisYearData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                }
                else {
                    return thunkAPI.rejectWithValue({})
                }
            }
            else if (reportType.dateType === 'date' && reportType.reportDates?.start_period !== null) {
                const resp = await Promise.all([
                    getReportRequest({start_period: moment(reportType?.reportDates?.start_period).startOf('day').subtract(1, 'day'), end_period: moment(reportType?.reportDates?.start_period).endOf('day').subtract(1, 'day'), domain: reportType.domain_code}),
                    getReportRequest({start_period: moment(reportType?.reportDates?.start_period), end_period: moment(reportType?.reportDates?.end_period), domain: reportType.domain_code})
                ])
                if (resp[0].status === 200 && resp[1].status === 200) {
                    const prevYearData = resp[0]?.data?.diagramList?.map(item => ({
                        ...item,
                        month:  moment(reportType?.reportDates?.start_period).subtract(1, 'day').format('DD MMM')
                    }))
                    const thisYearData = resp[1]?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment(reportType?.reportDates?.start_period).format('DD MMM')
                    }))
                    const chartData: any = [...prevYearData, ...thisYearData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                }
                else {
                    return thunkAPI.rejectWithValue({})
                }
            }
            // eslint-disable-next-line
            else if (reportType.dateType === 'date' && reportType.reportDates?.start_period !== null || reportType.dateType === 'period' && reportType.reportDates?.start_period !== null) {
                // thunkAPI.dispatch(setActivityReportsDates({start_period: null, end_period: null}))

                // @ts-ignore
                const resp = await getReportRequest({start_period: reportType.reportDates.start_period, end_period: reportType.reportDates.end_period, domain: reportType.domain_code
                })
                // @ts-ignore
                if (resp.status === 200) {
                    const thisMonthData = resp?.data?.diagramList?.map(item => ({
                        ...item,
                        month: moment().utc().startOf('month').format('MMM')
                    }))
                    const chartData = [...thisMonthData]
                    return thunkAPI.fulfillWithValue(chartData, {appStatus: AppStatusType.idle})
                } else {
                    return thunkAPI.rejectWithValue({})
                }
            } else {

                    return thunkAPI.fulfillWithValue([], {appStatus: AppStatusType.idle})

            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        // finally {
        //     thunkAPI.dispatch(setActivityReportsDates({start_period: null, end_period: null}))
        // }
    }
)


export const GetReportsCategoriesThunk = createAsyncThunk<ReportsCategoriesType[], number, AsyncThunkConfig>(
    'report/getReportsCategories',
    async (category_id, thunkAPI) => {
        try {
            const {status, data} = await reportAPI.getReportByCategories(category_id)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.reports, {appStatus: AppStatusType.idle})
                return data.reports
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetFraudReportsDiagramThunk = createAsyncThunk<FraudReportsDiagramItem[], {
    end_period: Moment | null
    start_period: Moment| null
    value_count: number | null
}, AsyncThunkConfig>(
    'report/getFraudReportsDiagram',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await reportAPI.getFraudReportsDiagram(reqData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.email_count_statistic_list, {appStatus: AppStatusType.idle})
                return data.email_count_statistic_list
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetFraudReportFileThunk = createAsyncThunk<string, { reqData: any, reportType: string | undefined }, AsyncThunkConfig>(
    'report/getFraudReportFile',
    async (dataForReport, thunkAPI) => {
        const getReportRequest = async () => {
            if (dataForReport.reportType === 'Max properties in organizations exceeded') {
                return reportAPI.getCountPeriodOrganization(dataForReport.reqData)
            } else if (dataForReport.reportType === 'Max properties in contacts exceeded') {
                return reportAPI.getMaximumCountOfPropertiesInThePeriodContacts(dataForReport.reqData)
            } else {
                return reportAPI.getMaximumCountOfPropertiesInOneEmail(dataForReport.reqData)
            }
        }
        try {
            const {status, data} = await getReportRequest()
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export default reportSlice.reducer
 