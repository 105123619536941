import React, {useEffect, useState} from 'react';
import s from "../CreateContactForReq/CreateContactForReq.module.scss";
import {DatePicker, Input, message, Radio, RadioChangeEvent, Select} from "antd";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    onChangeCreateOrganizationFormField, onChangeCreateReqCurrentStep, onChangeIsStepsClickAvailable, selectRequirementsContactsForCreation,
    selectRequirementsOrganizationForCreation
} from "../../../store/requirementsReducer";
import {Moment} from "moment";
import SecondaryButton from "../../common/Buttons/SecondaryButton/SecondaryButton";
import PrimaryButton from "../../common/Buttons/PrimaryButton/PrimaryButton";
import CreationModal from "../../common/CreationModal/CreationModal";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import {useNavigate} from "react-router";
import {GetCompanySources, selectCompanySources} from "../../../store/utilityReducer";
import {selectCountriesArray} from "../../../store/propertiesReducer";
import PhoneInputComponent from "../../common/PhoneInput/PhoneInputComponent";

const CreateOrganizationStep = () => {
    const organizationFormFields = useAppSelector(selectRequirementsOrganizationForCreation)
    const dispatch = useAppDispatch()
    const [fieldsWithError, setFieldsWithError] = React.useState(false)
    const [openedDomainModal, setOpenedDomainModal] = useState<boolean>(false)
    const companySources = useAppSelector(selectCompanySources)
    const countries = useAppSelector(selectCountriesArray)
    const contactsForCreation = useAppSelector(selectRequirementsContactsForCreation)
    const navigate = useNavigate()
    const domain = gridDataHelper.getCurrentCreateReqDomain()

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const isValid = /^[/-]*$/.test(newValue); // Regex to allow only '/' and '-'

        if (isValid) {
            dispatch(onChangeCreateOrganizationFormField({ value: e.target.value, inputName: 'co_no_connect' }));
        }
    };

    const onFormInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, inputName: string) => {
        const { value } = e.target;
        dispatch(onChangeCreateOrganizationFormField({ value, inputName }))
    }

    const onFormSelectChange = (value: string, inputName: string) => {
        dispatch(onChangeCreateOrganizationFormField({ value, inputName }))
    }


    const onDateChange = (value: Moment | null, inputName: string) => {
        dispatch(onChangeCreateOrganizationFormField({ value, inputName }))
    }


    const onGoToNextStep = () => {
        if (organizationFormFields.co_name === null || organizationFormFields.co_street_address === null || organizationFormFields.co_city === null || organizationFormFields.co_zip === null) {
            setFieldsWithError(true)
        } else {
            setFieldsWithError(false)
            const requiredFields = ['pe_first_name', 'pe_last_name', 'pe_email', 'pe_lang_index']
            // @ts-ignore
            const invalidContacts = contactsForCreation.filter(contact => requiredFields.some(field => !contact[field]));
            if (invalidContacts.length > 0) {
                message.warn('Please fill fields in create contact form to view create requirement form', 10)
            } else {
                setFieldsWithError(false)
                dispatch(onChangeCreateReqCurrentStep('Create requirement'))
            }
        }
    }

    const onCreateReq = () => {
        dispatch(onChangeCreateReqCurrentStep('Create requirement'))
        dispatch(onChangeIsStepsClickAvailable(true))
    }

    const handleChange = (e: RadioChangeEvent) => {
        gridDataHelper.setCurrentCreateReqDomain(e.target.value)
    };

    useEffect(() => {
        dispatch(GetCompanySources())
    }, [dispatch])


    const companiesSources = companySources.map((a: {id: number, value: string}) => ({
        value: a.id,
        label: a.value,
    }))

    const countryOptions = countries.map((a: any) => ({
        value: a.pk_country,
        label: a.cntry_desc_default,
    }))


    const handleStreetAddressChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const value = e.target.value;
        if (/^[0-9]{0,5}$/.test(value)) {
            onFormInputChange(e, inputName);
        }
    };

    return (
        <div className={s.form}>
            <p className={s.title}>Create organization</p>
            <div className={s.form__inner} style={{
                borderBottom: 'none',
                marginBottom: '0',
                paddingBottom: '0'
            }}>
                <div className={s.form__row} style={{
                    marginBottom: '16px',
                }}>
                    <label>Organization name<span>*</span></label>
                    <Input placeholder={'Enter organization name'}
                           value={organizationFormFields.co_name!!}
                           onChange={(e) => onFormInputChange(e, 'co_name')}/>
                    {organizationFormFields.co_name === null && fieldsWithError &&
                        <p className={s.form__error}>Please enter your organization name!</p>}
                </div>
                <div className={s.form__rowDouble}>
                    <div className={s.form__row}>
                        <label>Telephone</label>
                        <PhoneInputComponent inputType={'organizationsPhone'}/>
                    </div>
                    <div className={s.form__row}>
                        <label>Fax</label>
                        <PhoneInputComponent inputType={'organizationsFax'}/>
                    </div>
                </div>
                <div className={s.form__rowDouble} style={{
                    marginTop: '16px'
                }}>
                    <div className={s.form__row}>
                        <label>E-mail</label>
                        <Input placeholder={'Enter e-mail'}
                               value={organizationFormFields.co_email!!}
                               onChange={(e) => onFormInputChange(e, 'co_email')}/>
                    </div>
                    <div className={s.form__row}>
                        <label>Website</label>
                        <Input placeholder={'Enter website'}
                               value={organizationFormFields.co_web!!}
                               onChange={(e) => onFormInputChange(e, 'co_web')}/>
                    </div>
                </div>
                <div className={s.form__rowDouble}>
                    <div className={s.form__row}>
                        <label>Source</label>
                        <Select
                            placeholder="Select source"
                            value={organizationFormFields.co_source_index}
                            onChange={(value) => onFormSelectChange(value, 'co_source_index')}
                            style={{width: '100%'}}
                            className={'reqCreateFormSelect'}
                            options={companiesSources}
                            allowClear={true}
                        />
                    </div>
                    <div className={`${s.form__row} birthdayContactPicker`}>
                        <label>First contact</label>
                        <DatePicker value={organizationFormFields.co_first_contact!!}
                                    onChange={(e) => onDateChange(e, 'co_first_contact')}
                        />
                    </div>
                </div>
                <div className={s.form__row}>
                    <label>Country<span>*</span></label>
                    <Select
                        showSearch
                        placeholder="Select country"
                        value={countryOptions.find((c) => Number(c.value) === Number(organizationFormFields.co_country_ref))?.label}
                        onChange={(value) => onFormSelectChange(value, 'co_country_ref')}
                        style={{ width: '100%' }}
                        className={'reqCreateFormSelect'}
                        options={countryOptions}
                        optionFilterProp="label"
                        allowClear={true}
                        filterOption={(input, option) =>
                            option?.label.toLowerCase().includes(input.toLowerCase())
                        }
                    />
                    {organizationFormFields.co_country_ref === null && fieldsWithError &&
                        <p className={s.form__error}>Please select country!</p>}
                </div>
                <div className={s.form__rowDouble} style={{
                    marginTop: '16px'
                }}>
                    <div className={s.form__row}>
                        <label>Post code<span>*</span></label>
                        <Input placeholder={'Enter post code'}
                               value={organizationFormFields.co_zip!!}
                               onChange={(e) => onFormInputChange(e, 'co_zip')}/>
                        {organizationFormFields.co_zip === null && fieldsWithError &&
                            <p className={s.form__error}>Please enter your post code!</p>}
                    </div>
                    <div className={s.form__row}>
                        <label>City<span>*</span></label>
                        <Input placeholder={'Enter city'}
                               value={organizationFormFields.co_city!!}
                               onChange={(e) => onFormInputChange(e, 'co_city')}/>
                        {organizationFormFields.co_city === null && fieldsWithError &&
                            <p className={s.form__error}>Please enter your city!</p>}
                    </div>
                </div>
                <div className={s.form__rowDouble} style={{
                    marginTop: '16px'
                }}>
                    <div className={s.form__row}>
                        <label>Street Address<span>*</span></label>
                        <Input placeholder={'Enter street address'}
                               value={organizationFormFields.co_street_address!!}
                               onChange={(e) => onFormInputChange(e, 'co_street_address')}/>
                        {organizationFormFields.co_street_address === null && fieldsWithError &&
                            <p className={s.form__error}>Please enter your street address!</p>}
                    </div>
                    <div className={s.form__row}>
                        <label>House number</label>
                        <div style={{
                            display: 'flex',
                            gap: '4px'
                        }}>
                            <Input
                                value={organizationFormFields.co_no_from!!}
                                style={{
                                    width: '22.5%'
                                }}
                                onChange={(e) => handleStreetAddressChange(e, 'co_no_from')}/>
                            <Input
                                style={{
                                    width: '22.5%'
                                }}
                                value={organizationFormFields.co_no_from_rest!!}
                                onChange={(e) => onFormInputChange(e, 'co_no_from_rest')}/>
                            <Input
                                style={{
                                    width: '10%'
                                }}
                                value={organizationFormFields.co_no_connect!!}
                                placeholder={'/'}
                                onChange={(e) => handleInputChange(e)}/>
                            <Input
                                style={{
                                    width: '22.5%'
                                }}
                                value={organizationFormFields.co_no_to!!}
                                onChange={(e) => handleStreetAddressChange(e, 'co_no_to')}/>
                            <Input
                                style={{
                                    width: '22.5%'
                                }}
                                value={organizationFormFields.co_no_to_rest!!}
                                onChange={(e) => onFormInputChange(e, 'co_no_to_rest')}/>
                        </div>

                    </div>
                </div>
            </div>
            <div className={s.form__asterics}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                     fill="none">
                    <path
                        d="M12 8V12M12 16H12.01M7.86 2H16.14L22 7.86V16.14L16.14 22H7.86L2 16.14V7.86L7.86 2Z"
                        stroke="#A0A0A0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <p>Please fill all fields that have an asterisk (<span>*</span>).</p>
            </div>
            {
                domain === 'RR'
                &&
                <div className={s.note}>
                    <p>Note: Remember, filled fields and filters will only be added to the requirement properties, not surfaces.</p>
                </div>
            }
            <div className={s.buttons}>
                <div onClick={() => navigate('/requirements')} style={{
                    width: '100%'
                }}>
                    <SecondaryButton text={'Cancel'} width={'100%'} height={'44px'} isWhiteBg={true}/>
                </div>
                <div style={{
                    width: '100%'
                }}
                     onClick={onGoToNextStep}
                >
                    <PrimaryButton text={'Next'} isAddButton={false} width={'100%'} height={'44px'}/>
                </div>
            </div>
            {
                openedDomainModal
                &&
                <CreationModal
                    title={'Select Domain'}
                    image={''}
                    onCancel={() => setOpenedDomainModal(false)}
                    onSave={onCreateReq}
                    loading={false}
                    withMargin={false}
                    customButtons={true}
                    buttonOkText={'Confirm'}
                    buttonCancelText={'Cancel'}
                >
                    <p className={s.modalText}>Before you start creating requirements, select a domain</p>
                    <div style={{
                        width: '100%'
                    }}>
                        <Radio.Group onChange={handleChange} style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Radio value="OR">OR</Radio>
                            <Radio value="RS">RS</Radio>
                            <Radio value="CD">CD</Radio>
                            <Radio value="RR">RR</Radio>
                        </Radio.Group>
                    </div>
                </CreationModal>
            }
        </div>
    );
};

export default CreateOrganizationStep;