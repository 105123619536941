import React, { useState } from 'react';
import s from './CreateReqMobileSelect.module.scss'
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    onChangeCreateReqCurrentStep,
    selectRequirementsContactsForCreation,
    selectRequirementsCreateReqCurrentStep, selectRequirementsOrganizationForCreation
} from "../../../store/requirementsReducer";
import {message} from "antd";

interface FormStep {
    title: string;
    img: JSX.Element;
    arrowImg: JSX.Element | string;
}

interface FormStepsSelectProps {
    formSteps: FormStep[];
}

const FormStepsSelect: React.FC<FormStepsSelectProps> = ({ formSteps }) => {
    const [selectedStep, setSelectedStep] = useState<string>(formSteps[0].title);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const formStep = useAppSelector(selectRequirementsCreateReqCurrentStep)
    const dispatch = useAppDispatch()
    const contactsForCreation = useAppSelector(selectRequirementsContactsForCreation)
    const organizationFormFields = useAppSelector(selectRequirementsOrganizationForCreation)


    const handleStepClick = (title: string) => {

        if (title === 'Create requirement') {
            const requiredFields = ['pe_first_name', 'pe_last_name', 'pe_email', 'pe_lang_index']
            // @ts-ignore
            const invalidContacts = contactsForCreation.filter(contact => requiredFields.some(field => !contact[field]));
            if (invalidContacts.length > 0 || (organizationFormFields.co_name === null || organizationFormFields.co_street_address === null || organizationFormFields.co_city === null || organizationFormFields.co_zip === null)) {
                message.warn('Please fill fields in create contact and organization forms to view create requirement form', 10)
            } else {
                dispatch(onChangeCreateReqCurrentStep(title))
                setSelectedStep(title)
                setIsDropdownOpen(false)
            }
        }
        else {
            dispatch(onChangeCreateReqCurrentStep(title))
            setSelectedStep(title)
            setIsDropdownOpen(false)
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const selectedStepData = formSteps.find(step => step.title === selectedStep);

    return (
        <div className={s.mobileSelect}>
            <div
                onClick={toggleDropdown}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '8px',
                    justifyContent: 'space-between',
                    height: '100%'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '8px', marginTop: '4px', opacity: formStep === selectedStepData?.title ? '1' : "0.6"}}>{selectedStepData?.img}</div>
                    <div>{selectedStepData?.title}</div>
                </div>
                {typeof selectedStepData?.arrowImg === 'string' ? null : (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'rotate(90deg)',

                    }}>{selectedStepData?.arrowImg}</div>
                )}
            </div>
            {isDropdownOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: '0',
                        width: '100%',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        zIndex: 1,
                    }}
                >
                    {formSteps.map((step, index) => (
                        step.title !== selectedStep && (
                            <div
                                key={index}
                                onClick={() => handleStepClick(step.title)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    padding: '8px',
                                    borderBottom: index < formSteps.length - 1 ? '1px solid #eee' : 'none',
                                }}
                            >
                                <div style={{ marginRight: '8px' , marginTop: '4px'}}>{step.img}</div>
                                <div>{step.title}</div>
                            </div>
                        )
                    ))}
                </div>
            )}
        </div>
    );
};

export default FormStepsSelect;
