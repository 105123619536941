import React, {ChangeEvent} from 'react';
import {InputLabel} from "@mui/material";
import searchIcon from "../../../img/icons/searchTable.svg";
import s from './PropertiesSearchInput.module.scss';
import {useAppSelector} from "../../../app/hooks";
import {selectAllPropertiesCurrentGrid} from "../../../store/propertiesReducer";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";


interface PropertiesSearchInputType {
    searchTerm: string
    setSearchTerm: (searchTerm: string) => void
    handleSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => void
    isActivity?: boolean
    isActivityReq?: boolean
    isActivityProp?: boolean
    isAudit?: boolean
    isBrokerage?: boolean
    resetInput?: () => void
    searchPlaceholder?: string
}

const PropertiesSearchInput = ({isBrokerage, searchTerm, setSearchTerm, handleSearchInputChange, isActivity, isActivityReq, isActivityProp, isAudit, resetInput, searchPlaceholder} : PropertiesSearchInputType) => {
    const currentGrid = useAppSelector(selectAllPropertiesCurrentGrid)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
            width: currentScreenWidth!! > 768 ? '230px' : '260px'
        }}>
            <InputLabel htmlFor="searchInput" style={{marginRight: '8px'}}>
                <img src={searchIcon} alt="search"/>
            </InputLabel>
            <input
                className={s.search_input}
                type="text"
                value={searchTerm}
                placeholder={searchPlaceholder ? searchPlaceholder : isActivity
                    ? 'Search by Activity Number, Description, Type....'
                    : isActivityReq
                        ? 'Search by Req Id, Description, City'
                        : isActivityProp
                            ? 'Search by Prop Id, Description, City'
                            : isAudit ? 'Search by Prop Id, REQ, Activity time'
                                : currentGrid === 'Surfaces' ? 'Search by Prop Ref. Surf Ref, Addr Street'
                                : 'Search by Prop Id, Agent Name, Addr Street'}
                onChange={handleSearchInputChange}
            />
            <div>
                {searchTerm !== ''
                    &&
                    <p
                        onClick={() => setSearchTerm('')}
                        style={{
                            marginBottom: '0',
                            fontSize: '12px',
                            color: '#9CA3AF',
                            cursor: "pointer"
                        }}>x</p>
                }
            </div>
        </div>
    );
};

export default PropertiesSearchInput;