import React from 'react';
import s from './PrimaryButton.module.scss'
import plusImg from "../../../../img/icons/plus.svg";
import {LoadingOutlined} from "@ant-design/icons";

interface PrimaryButtonProps {
    text: string
    isAddButton: boolean
    width: string,
    height?: string
    fontSize?: string
    disabled?: boolean
    loading?: boolean
}


const PrimaryButton = ({disabled, text, isAddButton, width, height, fontSize, loading}: PrimaryButtonProps) => {
    return (
        <button disabled={disabled} className={s.button} style={{
            width: width,
            minWidth: '90px',
            height: height ? height : 'initial',
            fontSize: fontSize ? fontSize : '14px',
            backgroundColor: '#0707FA'
        }}>
            {isAddButton ? <><img
                alt={'plus'}
                src={plusImg} style={{
                marginRight: '8px'
            }}/>{text}</> : text}
            {
                loading
                    ?
                    <LoadingOutlined style={{ fontSize: 20, marginLeft: '30px' }} spin />
                    :
                    null
            }
        </button>
    );
};

export default PrimaryButton;