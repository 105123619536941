import React, {useEffect, useState} from 'react';
import s from "../../OrganizationActivities/OrganizationActivitiesGrid.module.scss";
import {ReactComponent as UserPlusIcon} from "../../../img/icons/user-plus.svg";
import {Button, Checkbox, Form, Input, message, Select} from "antd";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    ContactsTitlesType,
    GetContactsJobTitles,
    GetContactsProfessionTitles,
    GetPropertiesGridContacts,
    GetPropertiesGridContactsByReqId, GetPropertiesGridContactsUncheckedByReqId,
    onSetCreateContactModalOpened, selectContactsGenderTitles, selectContactsJobTitles, selectContactsProfessionTitles
} from "../../../store/propertiesReducer";
import classes from "./CreateContactModal.module.scss";
import {contactsApi} from "../../../app/api";
import {selectUserData} from "../../../store/userReducer";
import {gridDataHelper} from "../../../helpers/localStorageHelper";

interface CreateContactModalPropsType {
    reqId: number
    gridType?: string
}

const CreateContactModal = ({reqId, gridType}: CreateContactModalPropsType) => {
    // eslint-disable-next-line
    const [withReq, setWithReq] = useState(true)
    const userData = useAppSelector(selectUserData)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useAppDispatch()
    const genderTitles = useAppSelector(selectContactsGenderTitles)
    const jobTitles = useAppSelector(selectContactsJobTitles)
    const professionTitles = useAppSelector(selectContactsProfessionTitles)
    const reqGridRowData = gridDataHelper.getReqGridRowData()
    useEffect(() => {
        dispatch(GetContactsProfessionTitles())
        dispatch(GetContactsJobTitles())
    }, [dispatch])

    const onCreateContact = (formData: any) => {

        const reqData = {
            ...formData,
            p_req_ref: reqId,
            user_ref: userData.user_ref

        }
        setIsLoading(true)
        contactsApi.createContactFromOffer(reqData)
            .then(() => {
                dispatch(onSetCreateContactModalOpened(false))
                dispatch(GetPropertiesGridContacts())
                dispatch(GetPropertiesGridContactsByReqId({reqId: reqId}))
                dispatch(GetPropertiesGridContactsUncheckedByReqId({reqId: reqId}))
                if (gridType === 'All Properties/REQ') {
                    dispatch(GetPropertiesGridContactsByReqId({reqId: reqId, gridType}))
                    dispatch(GetPropertiesGridContactsUncheckedByReqId({reqId: reqId, gridType}))
                }
            })
            .then(() => {
                message.success('Contact was added successfully', 5)
            })
            .catch(() => {
                message.error(`Contact already exists`, 5)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }


    const mapAndFilterTitles = (titlesArray: ContactsTitlesType[]) =>
        Array.isArray(titlesArray)
            ? titlesArray.map(t => ({ value: t.AE_LI_VALUE, label: t.AE_LI_VALUE })).filter((title) => title.label !== null)
            : [];

    const titles = mapAndFilterTitles(genderTitles);
    const jobTitlesValues = mapAndFilterTitles(jobTitles);
    const professionTitlesValues = mapAndFilterTitles(professionTitles);


    return (
        <div className={s.popConfirm}>
            <div className={s.popConfirm__content} style={{
                height: 'auto'
            }}>
                <div className={s.popConfirm__createContact}>
                    <UserPlusIcon/>
                </div>
                <p className={s.popConfirm__deleteTitle} style={{
                    marginBottom: '20px'
                }}>Create new contact</p>
                <Form
                    name='login'
                    onFinish={onCreateContact}
                    autoComplete='off'
                    layout='vertical'
                    validateTrigger='onBlur'
                    className={'createContactForm'}
                    requiredMark={false}
                >
                    <Form.Item
                        name='p_first_name'
                        label='First Name'
                        rules={[{required: true, message: 'Please enter your first name!'}]}
                    >
                        <Input placeholder='Enter first name'/>
                    </Form.Item>

                    <Form.Item
                        name='p_last_name'
                        label='Last Name'
                        rules={[{required: true, message: 'Please enter your last name!'}]}
                    >
                        <Input placeholder='Enter last name'/>
                    </Form.Item>
                    <Form.Item
                        name='p_gender_title'
                        label='Title'
                    >
                        <Select options={titles}/>
                    </Form.Item>
                    <Form.Item
                        name='p_job_position'
                        label='Job position'
                    >
                        <Select options={jobTitlesValues}/>
                    </Form.Item>
                    <Form.Item
                        name='p_profession_title'
                        label='Profession title'
                    >
                        <Select options={professionTitlesValues}/>
                    </Form.Item>
                    <Form.Item
                        name='p_email'
                        label='Email address '
                        rules={[
                            {required: true, message: 'Please enter your email!'},
                            {type: 'email', message: 'Invalid e-mail!'},
                        ]}
                    >
                        <Input placeholder='Enter E-mail address'/>
                    </Form.Item>
                    <div className={classes.checkbox}>
                        <Checkbox defaultChecked={withReq}/>
                        <span className={classes.checkbox__text}>Add the contact to send an offer</span>
                        <p>This contact will be connected to "{reqGridRowData.CO_NAME}".</p>
                    </div>
                    <div className={classes.form__buttons}>
                        <Form.Item style={{
                            marginBottom: 0
                        }}>
                            <Button
                                type='default'
                                style={{
                                    height: '44px',
                                    width: '100%',
                                    marginBottom: 0
                                }}
                                onClick={() => dispatch(onSetCreateContactModalOpened(false))}
                            >
                                Cancel
                            </Button>
                        </Form.Item>
                        <Form.Item style={{
                            marginBottom: 0
                        }}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{
                                    height: '44px',
                                    backgroundColor: '#0707FA',
                                    width: '100%',
                                    border: '1px solid #0707FA',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: 0
                                }}
                                loading={isLoading}
                            >
                                Create
                            </Button>
                        </Form.Item>
                    </div>
                </Form>


            </div>
        </div>
    );
};

export default CreateContactModal;