import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType} from "./appStatusReducer";
import {protocolsApi} from "../app/api";
import {GridColumns} from "../types/commonTypes";
import moment from "moment";
import {GridResData} from "../types/commonGridsTypes";
import {ColumnsForFiltersType, ShownAndHiddenColumnsType} from "./propertiesReducer";
import {ColumnsFiltersType} from "./activitesGrid";
import {GridSortItem} from "@mui/x-data-grid";


interface InitialStateType {
    protocolsData: ProtocolType[]
    protocolsColumns: GridColumns[]
    protocolsDefaultGridColumns: ProtocolType[]
    protocolsShownColumns: ShownAndHiddenColumnsType[],
    protocolsHiddenColumns: ShownAndHiddenColumnsType[],
    protocolsGridFiltersForColumns: ColumnsFiltersType[],
    protocolsGridColumnsForFilters: ColumnsForFiltersType[],
    protocolsSortingString: string | null,
    protocolsSortingModel: GridSortItem[] | [],
    isProtocolsDataLoading: boolean
}

const initialState: InitialStateType = {
    protocolsData: [],
    protocolsColumns: [],
    protocolsDefaultGridColumns: [],
    protocolsShownColumns: [],
    protocolsHiddenColumns: [],
    protocolsGridFiltersForColumns: [],
    protocolsGridColumnsForFilters: [],
    protocolsSortingString: null,
    protocolsSortingModel: [],
    isProtocolsDataLoading: false
}

export const protocolsSlice = createSlice({
    name: 'protocols',
    initialState,
    reducers: {
        onSetIsProtocolsDataLoading: (state, action: PayloadAction<boolean>) => {
            state.isProtocolsDataLoading = action.payload
        },
        onSetProtocolsSortingString: (state, action: PayloadAction<{ sorting_string: string | null }>) => {
            state.protocolsSortingString = action.payload.sorting_string
        },
        onSetProtocolsLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.protocolsSortingModel = action.payload.sort_model
        },
        setProtocolsShownColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.protocolsShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setProtocolsHiddenColumns: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            state.protocolsHiddenColumns = action.payload.columns
        },
        setProtocolsGridColumns: (state, action: PayloadAction<GridColumns[]>) => {
            state.protocolsColumns = action.payload
        },
        onSetFiltersForProtocolsGrid: (state, action: PayloadAction<{ gridFilters: ColumnsFiltersType[] }>) => {
            state.protocolsGridFiltersForColumns = action.payload.gridFilters
        },
        setProtocolsGridColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[] }>) => {
            state.protocolsGridColumnsForFilters = action.payload.columns
        },
        onChangeProtocolsGridColumnsPosition: (state, action: PayloadAction<{ columns: string[] }>) => {
            state.protocolsShownColumns = state.protocolsShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field!!) - action.payload.columns.indexOf(b.field!!))
        },
        onChangeProtocolsGridColumnsWidth: (state, action: PayloadAction<{ columns: ShownAndHiddenColumnsType[] }>) => {
            const currentStateColumns = state.protocolsShownColumns
            Object.entries(action.payload.columns).forEach((item: [string, ShownAndHiddenColumnsType]) => {
                const columnName = item[0];
                const gridColumnParams = item[1];
                const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                if (currentColumn && gridColumnParams && gridColumnParams.width) {
                    currentColumn.width = gridColumnParams.width;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetProtocols.fulfilled, (state, action) => {
                // state.protocolsData = action.payload
                const gridData = action.payload.grid_column_data;
                // eslint-disable-next-line
                const protocols = gridData.map((innerArr: GridColumns[], index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: GridColumns) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.'))
                                    : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null
                                    ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss')
                                    : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }
                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['reqs'] = [];
                        obj['isOpened'] = false;
                        return obj;
                    }
                })
                state.protocolsData = protocols;
                if (gridData.length > 0) {
                    state.protocolsColumns = gridData[0]
                }

                state.protocolsDefaultGridColumns = protocols;
            })
    }
})

export const {
    onSetIsProtocolsDataLoading,
    onSetProtocolsSortingString,
    onSetProtocolsLocalSortModel,
    setProtocolsShownColumns,
    setProtocolsHiddenColumns,
    setProtocolsGridColumns,
    onSetFiltersForProtocolsGrid,
    setProtocolsGridColumnsForFilters,
    onChangeProtocolsGridColumnsPosition,
    onChangeProtocolsGridColumnsWidth
} = protocolsSlice.actions

export const selectProtocolsGridRows = (state: RootState): ProtocolType[] => state.protocols.protocolsData
export const selectProtocolsGridColumns = (state: RootState): GridColumns[] => state.protocols.protocolsColumns
export const selectProtocolsSortModel = (state: RootState): GridSortItem[] => state.protocols.protocolsSortingModel
export const selectProtocolsShownColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.protocols.protocolsShownColumns
export const selectProtocolsHiddenColumns = (state: RootState): ShownAndHiddenColumnsType[] => state.protocols.protocolsHiddenColumns
export const selectProtocolsDefaultColumns = (state: RootState): ProtocolType[] => state.protocols.protocolsDefaultGridColumns
export const selectProtocolsGridColumnsForFilters = (state: RootState): ColumnsForFiltersType[] => state.protocols.protocolsGridColumnsForFilters

export const GetProtocols = createAsyncThunk<GridResData, {
    p_sort_order: string | null
    p_start_period: string | null
    p_end_period: string | null
    p_search_value: string | null
    p_agent_ref: number | null
    p_status: number | null
    p_page: number | null
    p_size: number | null
}, AsyncThunkConfig>(
    'protocols/getProtocols',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(onSetIsProtocolsDataLoading(true))
        try {
            const {status, data} = await protocolsApi.getProtocolsForGrid(reqData)
            if (status === 200 && data) {
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetProtocolsSortingString({sorting_string: data.sorting_rule}));
                    thunkAPI.dispatch(onSetProtocolsLocalSortModel({sort_model: sortingCondition}));
                } else {
                    thunkAPI.dispatch(onSetProtocolsSortingString({sorting_string: null}));
                    thunkAPI.dispatch(onSetProtocolsLocalSortModel({sort_model: []}));
                }
                return thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data.resultSetList)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            thunkAPI.dispatch(onSetIsProtocolsDataLoading(false))
        }
    }
)

export default protocolsSlice.reducer

export interface ProtocolType {
    protocol: {
        domain: string | null
        agent_ref: number | null
        buyer_ref: number | null
        owner_ref: number | null
        req_ref: number | null
        prop_ref: number | null
        surf_ref: number | null
        activity_ref: number | null
        comments: string | null
    },
    file: string | null
}