//@ts-nocheck
import React, { useEffect, useRef, useState } from 'react';

const GoogleMapWithSearch = () => {
    const mapRef = useRef(null);
    const inputRef = useRef(null);
    const [selectedPlace, setSelectedPlace] = useState(null);

    useEffect(() => {
        if (!window.google) {
            // Load the Google Maps API
            const googleMapScript = document.createElement('script');
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCUo-8t6XzRYuJH2FyGdCZ1Z89wYR2rtSw&libraries=places`;
            googleMapScript.onload = initAutocomplete;
            document.head.appendChild(googleMapScript);
        } else {
            initAutocomplete();
        }
    }, []);

    function initAutocomplete() {
        const map = new window.google.maps.Map(mapRef.current, {
            center: { lat: -33.8688, lng: 151.2195 },
            zoom: 13,
            mapTypeId: 'roadmap',
        });

        const input = inputRef.current;
        const searchBox = new window.google.maps.places.SearchBox(input);

        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

        map.addListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds());
        });

        let markers = [];

        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();

            if (places.length === 0) {
                return;
            }

            markers.forEach((marker) => marker.setMap(null));
            markers = [];

            const bounds = new window.google.maps.LatLngBounds();

            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log('Returned place contains no geometry');
                    return;
                }

                const icon = {
                    url: place.icon,
                    size: new window.google.maps.Size(71, 71),
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(17, 34),
                    scaledSize: new window.google.maps.Size(25, 25),
                };

                markers.push(
                    new window.google.maps.Marker({
                        map,
                        icon,
                        title: place.name,
                        position: place.geometry.location,
                    })
                );

                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });

            map.fitBounds(bounds);

            // Update the selectedPlace state with full details
            setSelectedPlace(places[0]);
        });
    }

    return (
        <div>
            {/*<input*/}
            {/*    ref={inputRef}*/}
            {/*    id="pac-input"*/}
            {/*    type="text"*/}
            {/*    placeholder="Enter a location"*/}
            {/*    style={{ width: '300px', padding: '5px' }}*/}
            {/*/>*/}
            <div ref={mapRef} style={{ width: '100%', height: '500px' }} />
            {selectedPlace && (
                <div>
                    <h2>{selectedPlace.name}</h2>
                    <p>Latitude: {selectedPlace.geometry.location.lat()}</p>
                    <p>Longitude: {selectedPlace.geometry.location.lng()}</p>
                </div>
            )}
        </div>
    );
};

export default GoogleMapWithSearch;
