import React from 'react';
import s from "./Properties.module.scss";
import checkIcon from "../../img/icons/check.png";
import {AllPropertyContact, ContactDiv} from "./PropertyListComponent";

interface PropertyListCheckedContactProps {
    handleClick: (contact: AllPropertyContact) => void
    defaultContact: any
    contactDivs: ContactDiv[]
}

const PropertyListCheckedContact = ({handleClick, defaultContact, contactDivs} : PropertyListCheckedContactProps) => {
    const isItemSelected = contactDivs.some(obj => obj.value === defaultContact.PE_EMAIL)

    return (
        <div onClick={() => {
            handleClick({
                label: `${defaultContact.PE_FIRST_NAME} ${defaultContact.PE_LAST_NAME} ${defaultContact.PE_EMAIL}`,
                labelEmail: defaultContact.PE_EMAIL,
                labelName: `${defaultContact.PE_FIRST_NAME} ${defaultContact.PE_LAST_NAME}`,
                value: defaultContact.PE_EMAIL
            })
        }}>
            <div className={s.OfferListItem}
                 style={{
                     backgroundColor: isItemSelected ? '#F9FAFB' : '#fff'
                 }}>
                <div>
                                <span
                                    className={s.offerListName}>{`${defaultContact.PE_FIRST_NAME} ${defaultContact.PE_LAST_NAME}`}</span>
                    <span
                        className={s.offerListEmail}>{defaultContact.PE_EMAIL}</span>
                </div>
                {isItemSelected && <img src={checkIcon} alt="checked"/>}
            </div>
        </div>
    );
};

export default PropertyListCheckedContact;