import React, {useEffect, useState} from 'react';
import s from "../../EditProperty.module.scss";
import {Empty, message, Modal, Upload} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
    BrokeragePropertyPicturesTabData,
    DeleteBrokeragePropertyPicture, GetBrokeragePropertyPicturesByRef,
    onSetPropertyPictureFilteredFields
} from "../../../../../store/propertiesReducer";
import { useAppDispatch } from "../../../../../app/hooks";
import { sessionStorageUtilityValues } from "../../../../../helpers/sessionStorageHelper";

type PictureUploaderPropsType = {
    i: BrokeragePropertyPicturesTabData;
    isMainImage: boolean
};

const PictureUploader = ({ i,isMainImage}: PictureUploaderPropsType) => {
    const currentBrokerageProperty = sessionStorageUtilityValues.getCurrentBrokerageReqProperty();
    const dispatch = useAppDispatch();
    // @ts-ignore
    const [imageUrl, setImageUrl] = useState<string | null>(i.raw_data);
    const [loading, setLoading] = useState(false);

    const handleChange = (file: File) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                setImageUrl(reader.result.toString().split(",")[1]); // Extract base64 data
            }
        };
        reader.readAsDataURL(file); // Read the file as data URL
    };


    useEffect(() => {
        // @ts-ignore
        setImageUrl(i.raw_data)
    }, [])

    const beforeUpload = (file: File) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            Modal.error({ title: "Invalid file format", content: "Only JPG/PNG is allowed." });
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            Modal.error({ title: "File too large", content: "Image must be smaller than 2MB." });
            return false;
        }
        handleChange(file);
        return false; // Prevent automatic upload
    };

    const confirmDelete = (propId: number) => {
        Modal.confirm({
            title: "Are you sure you want to delete this picture?",
            content: "This action cannot be undone.",
            okText: "Yes, delete it",
            cancelText: "Cancel",
            onOk: () => handleDelete(propId),
        });
    };

    const handleDelete = (propId: number) => {
        dispatch(DeleteBrokeragePropertyPicture(propId))
            .then(() => {
                setTimeout(() => {
                    dispatch(onSetPropertyPictureFilteredFields([]));
                    dispatch(GetBrokeragePropertyPicturesByRef(currentBrokerageProperty.PROP_ID));
                } ,500)
            });
    };

    const uploadButton = (
        <div>
            {loading ? <PlusOutlined spin /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <div className={`${s.picture__img} editPicturesUploader`}>
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                disabled={isMainImage}
            >
                {!isMainImage ? imageUrl ? (
                    <div className="image-container">
                        <img src={`data:image/jpeg;base64,${i.raw_data}`} alt="Base64" />
                        <div className={s.picture__overlay}>
                            <DeleteOutlined
                                className={s.picture__icon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    confirmDelete(i.id);
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    uploadButton
                ) : <Empty/>}
            </Upload>
        </div>
    );
};

export default PictureUploader;
