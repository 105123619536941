import React from 'react';
import {setIsMobileFiltersOpened} from "../../../store/utilityReducer";
import mobileFilters from "../../../img/icons/mobileMenuFilters.svg";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import s from './MobileFiltersButton.module.scss'
import {onChangeReqPropsWindowModesMode, selectReqPropsWindowModes} from "../../../store/propertiesReducer";

interface MobileFiltersButtonProps {
    customMargin?: string
}

const MobileFiltersButton = ({customMargin} : MobileFiltersButtonProps) => {
    const dispatch = useAppDispatch()
    const reqPropsWindowModes = useAppSelector(selectReqPropsWindowModes)
    const emailButtonMode = reqPropsWindowModes?.find((m) => m.modeName === 'Email')
    const activityButtonMode = reqPropsWindowModes?.find((m) => m.modeName === 'Activity')


    const onOpenMobileMenu = () => {
        if (activityButtonMode?.mode !== 'Closed') {
            dispatch(onChangeReqPropsWindowModesMode({modeName: 'Activity', mode: 'Hided'}))
        }
        else{
            dispatch(onChangeReqPropsWindowModesMode({modeName: 'Activity', mode: 'Closed'}))
        }
        if (emailButtonMode?.mode !== 'Closed') {
            dispatch(onChangeReqPropsWindowModesMode({modeName: 'Email', mode: 'Hided'}))
        }
        else{
            dispatch(onChangeReqPropsWindowModesMode({modeName: 'Email', mode: 'Closed'}))
        }
        dispatch(setIsMobileFiltersOpened(true))
    }

    return (
        <button
            onClick={onOpenMobileMenu}
            className={s.mobileFiltersButton}
            style={{
                margin: customMargin ? customMargin : '0 20px 10px 10px'
            }}

        >
            <img src={mobileFilters} alt="mobile-filters"/>
        </button>
    );
};

export default MobileFiltersButton;