import {GridFiltersSessionStorageType} from "./sessionStorageDataTypes";
import {BrokerageReqsPropertyType} from "../store/propertiesReducer";

export const sessionStorageGridFilters = {
    setRequirementsGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('requirementsGridFilters', JSON.stringify(data))
    },
    getRequirementsGridFilters() {
        const requirementsGridFilters = sessionStorage.getItem('requirementsGridFilters')
        return !!requirementsGridFilters && JSON.parse(requirementsGridFilters)
    },
    removeRequirementsGridFilters() {
        return sessionStorage.removeItem('requirementsGridFilters')
    },
    setRequirementsSearchedByContactsGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('requirementsSearchedByContactsGridFilters', JSON.stringify(data))
    },
    getRequirementsSearchedByContactsGridFilters() {
        const requirementsSearchedByContactsGridFilters = sessionStorage.getItem('requirementsSearchedByContactsGridFilters')
        return !!requirementsSearchedByContactsGridFilters && JSON.parse(requirementsSearchedByContactsGridFilters)
    },
    removeRequirementsSearchedByContactsGridFilters() {
        return sessionStorage.removeItem('requirementsSearchedByContactsGridFilters')
    },
    setRequirementsColdOfferGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('requirementsColdOfferGridFilters', JSON.stringify(data))
    },
    getRequirementsColdOfferGridFilters() {
        const requirementsColdOfferGridFilters = sessionStorage.getItem('requirementsColdOfferGridFilters')
        return !!requirementsColdOfferGridFilters && JSON.parse(requirementsColdOfferGridFilters)
    },
    removeRequirementsColdOfferGridFilters() {
        return sessionStorage.removeItem('requirementsColdOfferGridFilters')
    },
    setPropertiesGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('propertiesGridFilters', JSON.stringify(data))
    },
    getPropertiesGridFilters() {
        const propertiesGridFilters = sessionStorage.getItem('propertiesGridFilters')
        return !!propertiesGridFilters && JSON.parse(propertiesGridFilters)
    },
    removePropertiesGridFilters() {
        return sessionStorage.removeItem('propertiesGridFilters')
    },
    setSurfacesGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('surfaceGridFilters', JSON.stringify(data))
    },
    getSurfacesGridFilters() {
        const surfaceGridFilters = sessionStorage.getItem('surfaceGridFilters')
        return !!surfaceGridFilters && JSON.parse(surfaceGridFilters)
    },
    removeSurfacesGridFilters() {
        return sessionStorage.removeItem('surfaceGridFilters')
    },
    setBrokeragePropertiesGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('brokeragePropertiesGridFilters', JSON.stringify(data))
    },
    getBrokeragePropertiesGridFilters() {
        const brokeragePropertiesGridFilters = sessionStorage.getItem('brokeragePropertiesGridFilters')
        return !!brokeragePropertiesGridFilters && JSON.parse(brokeragePropertiesGridFilters)
    },
    removeBrokeragePropertiesGridFilters() {
        return sessionStorage.removeItem('brokeragePropertiesGridFilters')
    },
    setContactPropertiesGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('contactPropertiesGridFilters', JSON.stringify(data))
    },
    getContactPropertiesGridFilters() {
        const contactPropertiesGridFilters = sessionStorage.getItem('contactPropertiesGridFilters')
        return !!contactPropertiesGridFilters && JSON.parse(contactPropertiesGridFilters)
    },
    removeContactPropertiesGridFilters() {
        return sessionStorage.removeItem('contactPropertiesGridFilters')
    },
    setPropertiesWithReqGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('propertiesWithReqGridFilters', JSON.stringify(data))
    },
    getPropertiesWithReqGridFilters() {
        const propertiesWithReqGridFilters = sessionStorage.getItem('propertiesWithReqGridFilters')
        return !!propertiesWithReqGridFilters && JSON.parse(propertiesWithReqGridFilters)
    },
    removePropertiesWithReqGridFilters() {
        return sessionStorage.removeItem('propertiesWithReqGridFilters')
    },
    setActivitiesGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('activitiesGridFilters', JSON.stringify(data))
    },
    getActivitiesGridFilters() {
        const activitiesGridFilters = sessionStorage.getItem('activitiesGridFilters')
        return !!activitiesGridFilters && JSON.parse(activitiesGridFilters)
    },
    removeActivitiesGridFilters() {
        return sessionStorage.removeItem('activitiesGridFilters')
    },
    setAuditGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('auditGridFilters', JSON.stringify(data))
    },
    getAuditGridFilters() {
        const auditGridFilters = sessionStorage.getItem('auditGridFilters')
        return !!auditGridFilters && JSON.parse(auditGridFilters)
    },
    removeAuditGridFilters() {
        return sessionStorage.removeItem('currentBrokerageReqProperty')
    },
    setProtocolsGridFilters(data: GridFiltersSessionStorageType[]) {
        sessionStorage.setItem('protocolsGridFilters', JSON.stringify(data))
    },
    getProtocolsGridFilters() {
        const requirementsGridFilters = sessionStorage.getItem('protocolsGridFilters')
        return !!requirementsGridFilters && JSON.parse(requirementsGridFilters)
    },
    removeProtocolsGridFilters() {
        return sessionStorage.removeItem('protocolsGridFilters')
    },

}

export const sessionStorageUtilityValues = {
    setCurrentReqIdForReqEdit(data: number) {
        sessionStorage.setItem('reqIdForReqEdit', JSON.stringify(data))
    },
    getCurrentReqIdForReqEdit() {
        const reqIdForReqEdit = sessionStorage.getItem('reqIdForReqEdit')
        return !!reqIdForReqEdit && JSON.parse(reqIdForReqEdit)
    },
    removeCurrentReqIdForReqEdit() {
        return sessionStorage.removeItem('reqIdForReqEdit')
    },
    setCurrentBrokerageReqProperty(data: BrokerageReqsPropertyType) {
        sessionStorage.setItem('currentBrokerageReqProperty', JSON.stringify(data))
    },
    getCurrentBrokerageReqProperty() {
        const currentBrokerageReqProperty = sessionStorage.getItem('currentBrokerageReqProperty')
        return !!currentBrokerageReqProperty && JSON.parse(currentBrokerageReqProperty)
    },
    removeCurrentBrokerageReqProperty() {
        return sessionStorage.removeItem('currentBrokerageReqProperty')
    },
}
