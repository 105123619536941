import React from 'react';
import s from "../../PropertiesPage/Properties.module.scss";
import {Select} from "antd";
import {Pagination} from "@mui/material";
import {useAppSelector} from "../../../app/hooks";
import {
    brokerageReqCheckedReqs,
    selectAllPropertiesCurrentGrid, selectAllPropertiesGridPropertiesForEmail,
    selectPropertiesForEmail,
    selectSurfacesForEmail
} from "../../../store/propertiesReducer";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";
import {selectBrokerageReqCheckedProperties} from "../../../store/brokerageReqsReducer";
import {SystemGridsTypes} from "../../../types/commonGridsTypes";



interface PropertiesGridFooterType {
    rowsPerPage: number
    handleRowsPerPageChange: (event: any) => void
    rowsPerPageOptions: number[]
    rows: any
    page: number
    handlePageChange: (params: any, value: number) => void
    isActivity?: boolean
    activityCreationGridType?: 'Reqs' | 'Properties' | 'Surfaces'
    gridType?: SystemGridsTypes
    filteredRows?: any
    filters?: any
}

const PropertiesGridFooter = ({
                                  rows,
                                  handleRowsPerPageChange,
                                  page,
                                  rowsPerPageOptions,
                                  handlePageChange,
                                  rowsPerPage,
                                  isActivity,
                                  activityCreationGridType,
                                  gridType,
                                  filteredRows,
                                  filters
                              }: PropertiesGridFooterType) => {
    const currentGrid = useAppSelector(selectAllPropertiesCurrentGrid)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const propertiesGridPropertiesForEmail = useAppSelector(selectPropertiesForEmail)
    const allPropertiesGridPropertiesForEmail = useAppSelector(selectAllPropertiesGridPropertiesForEmail)
    const brokerageReqCheckedProperties = useAppSelector(selectBrokerageReqCheckedProperties)
    const allPropertiesCheckedReqs = useAppSelector(brokerageReqCheckedReqs)
    const propertiesForEmail = gridType === 'Properties' ? propertiesGridPropertiesForEmail : allPropertiesGridPropertiesForEmail
    const surfacesForEmail = useAppSelector(selectSurfacesForEmail)

    return (
        <div style={{
            display: 'flex',
            justifyContent: currentScreenWidth! > 1200
                ? 'space-between'
                : currentScreenWidth! > 768 && currentScreenWidth! < 1200 && (propertiesForEmail.length === 0 && surfacesForEmail.length === 0 && brokerageReqCheckedProperties.length === 0 && allPropertiesCheckedReqs.length === 0)
                    ? 'space-between'
                    : currentScreenWidth! < 768
                        ? 'center'
                        : 'center',
            alignItems: 'center',
            width: '100%'
        }}>
            {
                currentScreenWidth! > 768 && currentScreenWidth! < 1200 && (propertiesForEmail.length === 0 && surfacesForEmail.length === 0 && brokerageReqCheckedProperties.length === 0 && allPropertiesCheckedReqs.length === 0)
                    ?
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        <p
                            className={s.footerText}
                        >
                            Rows per page:
                        </p>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className={s.rowsPerPage}
                        >
                            {rowsPerPageOptions.map((option) => (
                                <Select.Option key={option} value={option}>
                                    {option}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                    :
                    currentScreenWidth! > 1200
                        ?
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                            <p
                                className={s.footerText}
                            >
                                Rows per page:
                            </p>
                            <Select
                                value={rowsPerPage}
                                onChange={handleRowsPerPageChange}
                                className={s.rowsPerPage}
                            >
                                {rowsPerPageOptions.map((option) => (
                                    <Select.Option key={option} value={option}>
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        :
                        currentScreenWidth! < 768
                            ?
                            null
                            :
                            null
            }

            <Pagination
                count={Math.ceil(((isActivity || currentGrid === 'Surfaces' || currentGrid === 'Properties' || currentGrid === 'All Properties/REQ' || currentGrid === 'All Properties/Upcoming') && filteredRows?.length > 0 ? (filteredRows?.length / rowsPerPage) : (rows.length / rowsPerPage)))}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
            />
            {
                currentScreenWidth! > 768 && currentScreenWidth! < 1200 && (propertiesForEmail.length === 0 && surfacesForEmail.length === 0 && brokerageReqCheckedProperties.length === 0 && allPropertiesCheckedReqs.length === 0)
                    ?
                    (activityCreationGridType
                        ?
                        activityCreationGridType === 'Reqs'
                            ?
                            <p className={s.footerText}>
                                Total requirements : {rows.length}
                            </p>
                            :
                            activityCreationGridType === 'Properties'
                                ?
                                <p className={s.footerText}>
                                    Total properties : {rows.length}
                                </p>
                                :
                                <p className={s.footerText}>
                                    Total surfaces : {rows.length}
                                </p>
                        :
                        <p className={s.footerText}>
                            Total {isActivity ? 'activities' : currentGrid === 'Surfaces' ? 'surfaces' : 'properties'} : {rows.length}
                        </p>)
                    :
                    currentScreenWidth! > 1200
                        ?
                        (activityCreationGridType
                            ?
                            activityCreationGridType === 'Reqs'
                                ?
                                <p className={s.footerText}>
                                    Total requirements : {rows.length}
                                </p>
                                :
                                activityCreationGridType === 'Properties'
                                    ?
                                    <p className={s.footerText}>
                                        Total properties : {rows.length}
                                    </p>
                                    :
                                    <p className={s.footerText}>
                                        Total surfaces : {rows.length}
                                    </p>
                            :
                            <p className={s.footerText}>
                                Total
                                {(isActivity || currentGrid === 'Surfaces' || currentGrid === 'Properties' || currentGrid === 'All Properties/REQ' || currentGrid === 'All Properties/Upcoming' ) && filteredRows?.length > 0 && filters?.length ? '/Filtered' : ''}
                                {/*{isActivity && filteredRows?.length > 0 && filters?.length ? '/Filtered ' : ''}*/}
                                {isActivity ? ' activities' : currentGrid === 'Surfaces' ? ' surfaces' : ' properties'}
                                : {rows.length}
                                {/*{isActivity && filteredRows?.length > 0 && filters?.length ? `/${filteredRows?.length}` : ''}*/}
                                {(isActivity || currentGrid === 'Surfaces' || currentGrid === 'Properties' || currentGrid === 'All Properties/REQ' || currentGrid === 'All Properties/Upcoming') && filteredRows?.length > 0 && filters?.length ? `/${filteredRows?.length}` : ''}
                            </p>)
                        :
                        currentScreenWidth! < 768
                            ?
                            null
                            :
                            null
            }

        </div>
    );
};

export default PropertiesGridFooter;
