import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType, setAppStatus, setSuccessMessage} from "./appStatusReducer";
import {
    activitiesApi, ActivityTypeKindReqData,
    CheckPasswordData, companiesApi,
    contactsApi,
    CreateAndUpdateActivityRequest,
    GridActivitiesRequest,
    gridApi, userAPI
} from "../app/api";
import {
    ColumnsForFiltersType,
    onSetAllPropertiesReqLoading,
    setAbortController,
    setAllPropertiesGridColumns
} from "./propertiesReducer";
import moment, {Moment} from "moment";
import {message} from "antd";
import {gridDataHelper, userDataHelper} from "../helpers/localStorageHelper";
import {onSetIsCompaniesLoading} from "./companies";
import {GridSortItem} from "@mui/x-data-grid";
import {getAbortController} from "../helpers/AbortControllerManager";
import {GridColumns} from "../types/commonTypes";

export interface ActivitiesGetDataMode {
    id: number,
    name: string,
    gridType: 'Reqs' | 'Properties' | 'Surfaces'
    currentMode: 1 | 2
}

export interface RequirementsSearchedContact {
    value: string,
    label: string,
    key: number,
    ref: number,
    name: string,
    email: string,
    company: string
}

export interface RequirementsSearchedOrganization {
    value: string,
    label: string,
    key: number,
    ref: number
}

export interface ActivitiesGridColumnType {
    "COL_NAME": string
    "COL_OPTIONS": any
    "COL_POS": number
    "COL_TITLE": string
    "COL_WIDTH": number
    "COL_TYPE": string
    "COL_VALUE": string
}

export interface ActivitiesTypeKind {
    AE_CO_TNAME: string | number | null
    AE_IS_EDITABLE: string | number | null
    AE_LI_VALUE: string | null
    AE_LI_VALUE2: string | number | null
    AE_LI_VALUE3: string | number | null
    AE_LI_VALUE4: string | number | null
    AE_LI_VALUE5: string | number | null
    AE_NO_INDEX: number | null
    AE_NO_VALUE: string | number | null
    AE_NO_VALUE2: string | number | null
    AE_NO_VALUE3: string | number | null
    AE_NO_VALUE4: string | number | null
    VALUE: string | number | null
    CODE: string | number | null
    PROP_REQUIRED: number
}

export interface ModalActivitiesFormFields {
    organisations: string | null
    contacts: string | null
    type: string | null
    kind: string | null
    domain: string
    date_from: string | Moment | null
    date_till: string | Moment | null
    urgency: string | null
    description: string
    remark: string
    position: string | null
}

export interface CurrentRowType {
    ACT_KIND: string | null
    ACT_TYPE: string | null
    DATE_CREATED: string | null
    DATE_MODIFIED: string | null
    DESCRIPTION: string | null
    MODIFIED_BY: string | null
    REF: number
    REMARKS: string | null
    REQ_REF: number | null
    IX_PRIORITY: 0 | 1 | 2
    COMPANY_REF: string | null
    CONTACT_REF: string | null
    DATE_BEGIN: string | null
    DATE_END: string | null
    WE_TYPE_DOMAIN_CD: string | null
    WE_TYPE_POSITION: string | null
}

export interface ActivitiesReqType {
    CO_ADDR_CITY: string | null
    REF: number
    REMARKS: string | null
}

export interface ActivityPropertyType {
    DESCRIPTION: null | string
    PROP_ADDR_CITY: null | string
    PROP_ADDR_STREET_ADDRESS: null | string
    PROP_CNTRY_DESC_LOCAL: null | string
    REF: null | number
    REMARKS: null | string
    SURFACE_RELATION: 1 | 0
    REQ_REF: null | number
    PROP_REF: null | number
    SURF_REF: null | number
    PROP_ID: number
    CO_REF: null | number
    [key: string]: any
}

interface InitialStateType {
    activitiesGridColumns: GridColumns[]
    activitiesGridRows: any
    activitiesDefaultGridRows: any
    activitiesGridColumnsForFilters: ColumnsForFiltersType[],
    activitiesGridColumnsForFiltersStr: ColumnsForFiltersType[],
    activitiesGridFiltersForArray: ColumnsFiltersType[]
    activitiesTypeKind: ActivitiesTypeKind[]
    activitiesTypes: ActivitiesTypeKind[]
    modalActivityFormFields: ModalActivitiesFormFields
    isActivitiesModalOpened: boolean
    activitiesCurrentContact: any
    activitiesCurrentOrganization: any
    currentRow: CurrentRowType
    activityEditMode: boolean
    activitiesReqs: ActivitiesReqType[]
    activitiesReqsColumns: any[]
    activitiesFormContacts: any[]
    currentContactRef: number
    activitiesProperties: any[]
    activitiesSurfaces: any[]
    currentActivitiesGridType: 'Requirements' | 'Properties'
    currentContactReq: number
    currentCompanyRef: number | null
    checkedPropertiesIds: number[]
    checkedProperties: ActivityPropertyType[]
    checkedSurfacesIds: number[]
    checkedSurfaces: any[],
    checkedReqIds: number[]
    checkedReqs: any[],
    typeNumberEntity: number | undefined | null
    kindNumberEntity: number | undefined | null
    currentActivityRef: number
    isDataLoading: boolean
    isEmptyReqs: boolean
    isPropsLoading: boolean | null
    isSurfacesLoading: boolean | null
    isDescriptionFieldError: boolean
    currentModalTab: 'General' | 'Existing Links'
    activityRefLinks: any,
    allActivitiesEntitiesArray: any[],
    isPasswordCorrect: 1 | 0,
    organizationId: number | null
    contactId: number | null
    isPasswordNeeded: 1 | 0,
    contactsByActivityRef: any[],
    activityKindTypeIsPropAndReqRequired: number | undefined,
    isSavingButtonDisabled: boolean,
    searchedCompaniesViaContact: any[]
    searchedContactsViaCompanies: any[]
    activitiesModalSwitch: boolean
    currentGridTab: 'Reqs' | 'Properties' | 'Surfaces',
    activitiesCreationGridPropsByReqColumns: any[],
    activitiesCreationGridPropsByReqRows: ActivityCreationGridRowType[],
    activitiesCreationGridReqColumns: any[],
    activitiesCreationGridReqRows: ActivityCreationGridRowType[],
    activitiesCreationGridPropColumns: any[],
    activitiesCreationGridPropRows: ActivityCreationGridRowType[],
    activitiesCreationGridSurfColumns: any[],
    activitiesCreationGridSurfRows: ActivityCreationGridRowType[],
    currentActivityPropertiesAndSurfaces: any[],
    entityListFromServer: { entity_id: number, ref_id: number }[],
    activitiesGridShownColumns: any[],
    activitiesGridHiddenColumns: any[],
    activitiesCreationGridShownColumns: any[],
    activitiesCreationGridHiddenColumns: any[],
    activitiesSortModel: GridSortItem[] | [],
    activitiesSortingString: string | null,
    activitiesRequirementsSortModel: GridSortItem[] | [],
    activitiesRequirementsSortingString: string | null,
    activitiesPropertiesSortModel: GridSortItem[] | [],
    activitiesPropertiesSortingString: string | null,
    activitiesSurfacesSortModel: GridSortItem[] | [],
    activitiesSurfacesSortingString: string | null,
    allPropertiesShown: boolean | null
    allSurfacesShown: boolean | null
    filtersActivitiesTypeKind: ActivitiesTypeKind[]
    filtersActivitiesTypes: ActivitiesTypeKind[]
    currentFilterKind: string | null
    currentFilterType: string | null
    isActivityDeleteEnabled: boolean
    contactNotFound: boolean,
    requirementsSearchedContact: RequirementsSearchedContact | null,
    requirementsSearchedOrganization: RequirementsSearchedOrganization | null
    activitiesGetDataMode: ActivitiesGetDataMode[]
    activitiesPropertiesSearchValue: string | null
    activitySurfacesGridError: boolean
    activityPropertiesGridError: boolean
    abortControllers: AbortController[]
}

const initialState: InitialStateType = {
    activitiesGridColumns: [],
    activitiesGridRows: [],
    activitiesGridColumnsForFilters: [],
    activitiesGridColumnsForFiltersStr: [],
    activitiesGridFiltersForArray: [],
    activitiesDefaultGridRows: [],
    activitiesTypeKind: [],
    activitiesTypes: [],
    modalActivityFormFields: {
        organisations: null,
        contacts: null,
        domain: '',
        type: null,
        kind: null,
        date_from: moment(),
        date_till: null,
        urgency: 'low',
        description: '',
        remark: '',
        position: userDataHelper.getUserData().position
    },
    isActivitiesModalOpened: false,
    currentRow: {
        ACT_KIND: "Some Kind",
        ACT_TYPE: "Some Type",
        DATE_CREATED: "2023-08-18",
        DATE_MODIFIED: "2023-08-18",
        DESCRIPTION: "Some description",
        MODIFIED_BY: "User123",
        REF: 123,
        REMARKS: "Some remarks",
        REQ_REF: 456,
        CONTACT_REF: '',
        COMPANY_REF: '',
        IX_PRIORITY: 1,
        DATE_BEGIN: '',
        DATE_END: '',
        WE_TYPE_DOMAIN_CD: '',
        WE_TYPE_POSITION: ''
    },
    activityEditMode: false,
    activitiesReqs: [],
    activitiesFormContacts: [],
    currentContactRef: 0,
    activitiesProperties: [],
    currentActivitiesGridType: 'Requirements',
    currentContactReq: 0,
    checkedPropertiesIds: [],
    checkedProperties: [],
    checkedSurfacesIds: [],
    checkedSurfaces: [],
    typeNumberEntity: null,
    kindNumberEntity: null,
    currentActivityRef: 22,
    isDataLoading: false,
    isEmptyReqs: false,
    isPropsLoading: null,
    isSurfacesLoading: null,
    isDescriptionFieldError: false,
    currentModalTab: 'Existing Links',
    activityRefLinks: {},
    allActivitiesEntitiesArray: [],
    isPasswordCorrect: 0,
    organizationId: null,
    contactId: null,
    isPasswordNeeded: 1,
    contactsByActivityRef: [],
    activityKindTypeIsPropAndReqRequired: 0,
    isSavingButtonDisabled: false,
    searchedCompaniesViaContact: [],
    searchedContactsViaCompanies: [],
    activitiesModalSwitch: true,
    activitiesReqsColumns: [],
    currentGridTab: 'Reqs',
    activitiesSurfaces: [],
    activitiesCreationGridReqColumns: [],
    activitiesCreationGridReqRows: [],
    activitiesCreationGridPropColumns: [],
    activitiesCreationGridPropRows: [],
    activitiesCreationGridSurfColumns: [],
    activitiesCreationGridSurfRows: [],
    checkedReqIds: [],
    checkedReqs: [],
    currentActivityPropertiesAndSurfaces: [],
    entityListFromServer: [],
    activitiesCreationGridPropsByReqColumns: [],
    activitiesCreationGridPropsByReqRows: [],
    activitiesGridShownColumns: [],
    activitiesGridHiddenColumns: [],
    activitiesSortModel: [],
    activitiesSortingString: null,
    activitiesRequirementsSortModel: [],
    activitiesRequirementsSortingString: null,
    activitiesPropertiesSortModel: [],
    activitiesPropertiesSortingString: null,
    activitiesSurfacesSortModel: [],
    activitiesSurfacesSortingString: null,
    activitiesCreationGridShownColumns: [],
    activitiesCreationGridHiddenColumns: [],
    currentCompanyRef: null,
    allPropertiesShown: false,
    allSurfacesShown: false,
    filtersActivitiesTypeKind: [],
    filtersActivitiesTypes: [],
    currentFilterKind: null,
    currentFilterType: null,
    isActivityDeleteEnabled: false,
    contactNotFound: false,
    requirementsSearchedContact: null,
    requirementsSearchedOrganization: null,
    activitiesCurrentContact: {},
    activitiesCurrentOrganization: {},
    activitiesGetDataMode: [
        {
            id: 1,
            name: 'Function',
            gridType: 'Properties',
            currentMode: 1
        },
        {
            id: 2,
            name: 'Agent',
            gridType: 'Surfaces',
            currentMode: 1
        },
    ],
    activitiesPropertiesSearchValue: null,
    activityPropertiesGridError: false,
    activitySurfacesGridError: false,
    abortControllers: []
}

export interface ColumnsFiltersType {
    condition: string
    date: string
    dateCondition: string
    endValue: string
    property: string
    startValue: string
    type: string
    value: string
    headerName: string
}

export const activitiesGridSlice = createSlice({
    name: 'activitiesGrid',
    initialState,
    reducers: {
        setActivitiesGridColumnsForFilters: (state, action: PayloadAction<{ columns: ColumnsForFiltersType[] }>) => {
            state.activitiesGridColumnsForFilters = action.payload.columns
        },
        onSetActivitiesGridFiltersButtonsModalOpened: (state, action: PayloadAction<{ headerName: string, isModalOpened: boolean }>) => {
            state.activitiesGridColumnsForFilters = state.activitiesGridColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName ? {
                ...m,
                isModalBtnOpened: action.payload.isModalOpened
            } : {...m, isModalBtnOpened: false})
        },
        onSetFiltersForActivitiesGrid: (state, action: PayloadAction<{ gridFilters: ColumnsFiltersType[] }>) => {
            state.activitiesGridFiltersForArray = action.payload.gridFilters
        },
        onChangeActivitiesGridFilterConditionValue: (state, action: PayloadAction<{ title: string, value: string }>) => {

            state.activitiesGridColumnsForFilters = state.activitiesGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                conditionValue: action.payload.value
            } : m)
        },
        onChangeActivitiesGridFilterValue: (state, action: PayloadAction<{ title?: string, value: any, startValue?: string, endValue?: string, columnTitle?: string }>) => {
            state.activitiesGridColumnsForFilters = state.activitiesGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title || m.title === action.payload.columnTitle ? {
                ...m,
                value: action.payload.value,
                startValue: action.payload.startValue,
                endValue: action.payload.endValue
            } : m)
        },
        onChangeActivitiesGridFilterStartValue: (state, action: PayloadAction<{ title: string, value: any }>) => {
            state.activitiesGridColumnsForFilters = state.activitiesGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                startValue: action.payload.value
            } : m)
        },
        onChangeActivitiesGridFilterEndValue: (state, action: PayloadAction<{ title: string, value: any }>) => {
            state.activitiesGridColumnsForFilters = state.activitiesGridColumnsForFilters.map((m: any) => m.headerName === action.payload.title ? {
                ...m,
                endValue: action.payload.value
            } : m)
        },
        onSetActivitiesGridData: (state, action: PayloadAction<{ properties: any[] }>) => {
            state.activitiesGridRows = action.payload.properties
        },
        onSetActivitiesGridFiltersButtonsSelected: (state, action: PayloadAction<{ headerName?: string, isFilterSelected: boolean, columnTitle?: string }>) => {
            state.activitiesGridColumnsForFilters = state.activitiesGridColumnsForFilters.map((m: any) => m.headerName === action.payload.headerName || m.title === action.payload.columnTitle ? {
                ...m,
                isFilterSelected: action.payload.isFilterSelected
            } : m)
        },
        onChangeModalActivityFormField: (state, action: PayloadAction<{ value: string | null, inputName: string, numberEntity?: number, activityKindTypeIsPropAndReqRequired?: number }>) => {
            if (action.payload.inputName === 'Organizations') {
                state.modalActivityFormFields.organisations = action.payload.value
            } else if (action.payload.inputName === 'Domain') {
                // @ts-ignore
                state.modalActivityFormFields.domain = action.payload.value
            } else if (action.payload.inputName === 'Contacts') {
                state.modalActivityFormFields.contacts = action.payload.value
            } else if (action.payload.inputName === 'Type') {
                state.modalActivityFormFields.type = action.payload.value
                state.typeNumberEntity = action.payload.numberEntity
            } else if (action.payload.inputName === 'Kind') {
                state.modalActivityFormFields.kind = action.payload.value
                state.kindNumberEntity = action.payload.numberEntity
                state.activityKindTypeIsPropAndReqRequired = action.payload.activityKindTypeIsPropAndReqRequired
            } else if (action.payload.inputName === 'Urgency') {
                state.modalActivityFormFields.urgency = action.payload.value
            } else if (action.payload.inputName === 'Remark') {
                // @ts-ignore
                state.modalActivityFormFields.remark = action.payload.value
            } else if (action.payload.inputName === 'Description') {
                // @ts-ignore
                state.modalActivityFormFields.description = action.payload.value
            } else if (action.payload.inputName === 'date_from') {
                state.modalActivityFormFields.date_from = action.payload.value
            } else if (action.payload.inputName === 'date_till') {
                state.modalActivityFormFields.date_till = action.payload.value
            }
        },
        onSetActivitiesFormFields: (state, action: PayloadAction<{ currentRow: CurrentRowType, kindNumberEntity: number, typeNumberEntity: number }>) => {
            state.modalActivityFormFields.organisations = action.payload.currentRow.MODIFIED_BY!
            state.modalActivityFormFields.domain = action.payload.currentRow.WE_TYPE_DOMAIN_CD!
            state.modalActivityFormFields.position = action.payload.currentRow.WE_TYPE_POSITION!
            // state.modalActivityFormFields.contacts = action.payload.contacts.
            state.typeNumberEntity = action.payload.typeNumberEntity
            state.kindNumberEntity = action.payload.kindNumberEntity
            state.modalActivityFormFields.type = action.payload.currentRow.ACT_TYPE!
            state.modalActivityFormFields.kind = action.payload.currentRow.ACT_KIND!
            state.modalActivityFormFields.remark = action.payload.currentRow.REMARKS!
            state.modalActivityFormFields.description = action.payload.currentRow.DESCRIPTION!
            state.modalActivityFormFields.date_from = action.payload.currentRow.DATE_CREATED!
            state.modalActivityFormFields.date_till = action.payload.currentRow.DATE_MODIFIED!
        },
        onClearActivitiesFormFields: (state) => {
            state.modalActivityFormFields.organisations = null;
            state.modalActivityFormFields.contacts = null;
            state.modalActivityFormFields.type = '';
            state.modalActivityFormFields.domain = '';
            state.modalActivityFormFields.kind = null;
            state.modalActivityFormFields.date_from = moment();
            state.modalActivityFormFields.date_till = moment();
            state.modalActivityFormFields.urgency = '';
            state.modalActivityFormFields.description = '';
            state.modalActivityFormFields.remark = '';
            state.activitiesTypes = [];
            state.typeNumberEntity = null
        },
        onSetActivitiesModalOpened: (state, action: PayloadAction<boolean>) => {
            state.isActivitiesModalOpened = action.payload
        },
        onSetActivitiesCurrentRow: (state, action: PayloadAction<CurrentRowType>) => {
            state.currentRow = action.payload
        },
        onSetActivityEditMode: (state, action: PayloadAction<boolean>) => {
            state.activityEditMode = action.payload
        },
        onSetCurrentContactRef: (state, action: PayloadAction<number>) => {
            state.currentContactRef = action.payload
        },
        onSetCurrentCompanyRef: (state, action: PayloadAction<number | null>) => {
            state.currentCompanyRef = action.payload
        },
        onSetCurrentContactActivityRef: (state, action: PayloadAction<number>) => {
            state.currentActivityRef = action.payload
        },
        onSetCurrentContactReq: (state, action: PayloadAction<number>) => {
            state.currentContactReq = action.payload
        },
        onSetCurrentActivityGridType: (state, action: PayloadAction<'Requirements' | 'Properties'>) => {
            state.currentActivitiesGridType = action.payload
        },
        onCheckActivitiesPropertiesIds: (state, action: PayloadAction<{ property: number, isChecked: boolean }>) => {
            if (action.payload.isChecked) {
                state.checkedPropertiesIds.push(action.payload.property)
            } else {
                state.checkedPropertiesIds = state.checkedPropertiesIds.filter((c: any) => c !== action.payload.property)
                state.allActivitiesEntitiesArray = state.allActivitiesEntitiesArray.filter((c: any) => c.link_entity_list !== action.payload.property)

            }
        },
        onCheckActivitiesProperties: (state, action: PayloadAction<{ property: ActivityPropertyType, isChecked: boolean, gridType: string, contactId?: number, contactName?: string, orgId?: number, orgName?: string, }>) => {
            if (action.payload.isChecked) {
                state.checkedProperties.push(action.payload.property)
            } else {
                if (action.payload.gridType === 'Properties') {
                    state.checkedProperties = state.checkedProperties.filter((c: any) => c.PROP_ID !== action.payload.property.PROP_ID)
                } else if (action.payload.gridType === 'All Properties/Upcoming') {
                    state.checkedProperties = state.checkedProperties.filter((c: any) => !(c.PROP_REF === action.payload.property.PROP_REF && c.CO_REF === action.payload.property.CO_REF))
                } else if (action.payload.gridType === 'Activities/Properties') {
                    state.checkedProperties = state.checkedProperties.filter((c: any) => c.REF !== action.payload.property.REF)
                }

            }
        },
        onDeleteCheckedProperty(state, action: PayloadAction<{ prop_id: number, withoutReq: boolean, req_id?: number, isSoloReq?: boolean }>) {
            if (action.payload.withoutReq) {
                state.checkedProperties = state.checkedProperties.filter((c: any) => !(Number(c.PROP_REF) === Number(action.payload.prop_id)))
                state.checkedPropertiesIds = state.checkedPropertiesIds.filter((c: any) => c !== action.payload.prop_id)
            } else {
                if (action.payload.isSoloReq) {
                    state.checkedProperties = state.checkedProperties.filter((c: any) => !(Number(c.REF) === Number(action.payload.prop_id)))
                } else {
                    state.checkedProperties = state.checkedProperties.filter((c: any) => !(Number(c.PROP_REF) === Number(action.payload.prop_id)))
                }
                state.checkedPropertiesIds = state.checkedPropertiesIds.filter((c: any) => c !== action.payload.prop_id)

                const isLastSurfaceWithReqId = state.checkedSurfaces.find((s) => s.REQ_REF === action.payload.req_id)
                const isLastPropertyWithReqId = state.checkedProperties.find((s) => s.REQ_REF === action.payload.req_id)
                if (isLastSurfaceWithReqId || isLastPropertyWithReqId) {
                    console.log('not last')
                } else {
                    state.checkedReqIds = state.checkedReqIds.filter((c: any) => Number(c) !== Number(action.payload.req_id))
                }
                state.allActivitiesEntitiesArray = state.allActivitiesEntitiesArray.filter((c: any) => c.link_entity_list !== action.payload.prop_id)
            }
        },

        onDeleteCheckedReq(state, action: PayloadAction<number>) {
            // state.checkedReqIds = state.checkedReqIds.filter((id: any) => id !== )
            state.checkedReqIds = state.checkedReqIds.filter((c: any) => Number(c) !== Number(action.payload))
            state.allActivitiesEntitiesArray = state.allActivitiesEntitiesArray.filter((c: any) => c.link_entity_list !== action.payload)
            state.checkedProperties = state.checkedProperties.filter((c: any) => Number(c.REQ_REF) !== Number(action.payload)).map(item => ({
                ...item,
                entityListEntityId: null
            }))
            state.checkedSurfaces = state.checkedSurfaces.filter((c: any) => Number(c.REQ_REF) !== Number(action.payload))
        },
        onSetActivitiesSurfaceIsOpened: (state, action: PayloadAction<{ isOpened: boolean, ref: string }>) => {
            state.activitiesCreationGridPropsByReqRows = state.activitiesCreationGridPropsByReqRows.map((p: any) => {
                return (
                    Number(p.REF) === Number(action.payload.ref) ? {...p, isOpened: action.payload.isOpened} : p
                )
            })
        },
        onSetActivitiesSurfaces: (state, action: PayloadAction<{ surfaces: any[], ref: string }>) => {
            state.activitiesCreationGridPropsByReqRows = state.activitiesCreationGridPropsByReqRows.map((p: any) => {
                return (
                    Number(p.REF) === Number(action.payload.ref) ? {...p, surfaces: action.payload.surfaces} : p
                )
            })
        },
        onCheckSurfacesIds: (state, action: PayloadAction<{ surfaceId: number, isChecked: boolean }>) => {
            if (action.payload.isChecked) {
                state.checkedSurfacesIds.push(action.payload.surfaceId)
            } else {
                state.checkedSurfacesIds = state.checkedSurfacesIds.filter((c: any) => Number(c) !== Number(action.payload.surfaceId))
                state.allActivitiesEntitiesArray = state.allActivitiesEntitiesArray.filter((c: any) => c.link_entity_list !== action.payload.surfaceId)
            }
        },
        onCheckSurfaces: (state, action: PayloadAction<{ surface: any, isChecked: boolean }>) => {
            if (action.payload.isChecked) {
                state.checkedSurfaces.push(action.payload.surface)
            } else {
                state.checkedSurfaces = state.checkedSurfaces.filter((c: any) => Number(c.VALUE_01) !== Number(action.payload.surface.VALUE_01) && Number(c.SURF_REF) !== Number(action.payload.surface.SURF_REF))
            }
        },
        onDeleteCheckedSurface(state, action: PayloadAction<{ surface_id: number, req_id?: number, isSoloReq?: boolean }>) {
            if (action.payload.isSoloReq) {
                state.checkedSurfaces = state.checkedSurfaces.filter((c: any) => !(Number(c.VALUE_01) === Number(action.payload.surface_id)))
            } else {
                state.checkedSurfaces = state.checkedSurfaces.filter((c: any) => !(Number(c.SURF_REF) === Number(action.payload.surface_id)))
            }

            state.checkedSurfacesIds = state.checkedSurfacesIds.filter((c: any) => Number(c) !== Number(action.payload.surface_id))
            const isLastSurfaceWithReqId = state.checkedSurfaces.find((s) => s.REQ_REF === action.payload.req_id)
            const isLastPropertyWithReqId = state.checkedProperties.find((s) => s.REQ_REF === action.payload.req_id)
            if (isLastSurfaceWithReqId || isLastPropertyWithReqId) {
                console.log('not last')
            } else {
                state.checkedReqIds = state.checkedReqIds.filter((c: any) => Number(c) !== Number(action.payload.req_id))
            }

            state.allActivitiesEntitiesArray = state.allActivitiesEntitiesArray.filter((c: any) => c.link_entity_list !== action.payload.surface_id)

        },
        onSetActivitiesIsDataLoading: (state, action: PayloadAction<boolean>) => {
            state.isDataLoading = action.payload
        },
        onSetActivitiesIsEmptyReqs: (state, action: PayloadAction<boolean>) => {
            state.isEmptyReqs = action.payload
        },
        onSetActivitiesIsPropsLoading: (state, action: PayloadAction<boolean>) => {
            state.isPropsLoading = action.payload
        },
        onSetActivitiesIsSurfacesLoading: (state, action: PayloadAction<boolean>) => {
            state.isSurfacesLoading = action.payload
        },
        onClearActivitiesPropertiesAndSurfaces: (state) => {
            state.checkedSurfacesIds = []
            state.checkedSurfaces = []
            state.checkedPropertiesIds = []
            state.checkedProperties = []
            state.activitiesProperties = []
            state.activitiesSurfaces = []
            state.activitiesCreationGridPropsByReqColumns = []
            state.activitiesCreationGridPropsByReqRows = []
            state.checkedReqs = []
            state.checkedReqIds = []
        },
        onClearPropContactsCheckedProperties: (state) => {
            state.checkedProperties = []
        },
        onSetIsDescriptionFieldError: (state, action: PayloadAction<boolean>) => {
            state.isDescriptionFieldError = action.payload
        },
        onSetCurrentModalTab: (state, action: PayloadAction<'General' | 'Existing Links'>) => {
            state.currentModalTab = action.payload
        },
        onSetPropertiesIds: (state, action: PayloadAction<number[]>) => {
            state.checkedPropertiesIds = action.payload
        },
        onSetSurfaceIds: (state, action: PayloadAction<number[]>) => {
            state.checkedSurfacesIds = action.payload
        },
        onSetReqIds: (state, action: PayloadAction<number[]>) => {
            state.checkedReqIds = action.payload
        },
        onSetActivitiesProperties: (state, action: PayloadAction<ActivityPropertyType[]>) => {
            state.checkedProperties = action.payload
        },
        onSetActivitiesSurfacesUpdated: (state, action: PayloadAction<ActivityPropertyType[]>) => {
            state.checkedSurfaces = action.payload
        },
        onSetActivitiesRequirementsUpdated: (state, action: PayloadAction<ActivityPropertyType[]>) => {
            state.checkedReqs = action.payload
        },
        onSetAllActivitiesEntitiesArray: (state, action: PayloadAction<any[]>) => {
            state.allActivitiesEntitiesArray = action.payload
        },
        onSetIsPasswordCorrect: (state, action: PayloadAction<1 | 0>) => {
            state.isPasswordCorrect = action.payload
        },
        onClearActivitiesPropertiesData: (state) => {
            state.activitiesProperties = []
        },
        onSetActivitiesContactId: (state, action: PayloadAction<number | null>) => {
            state.contactId = action.payload
        },
        onSetActivitiesOrganizationId: (state, action: PayloadAction<number | null>) => {
            state.organizationId = action.payload
        },
        onSetRequirementsSearchedContact: (state, action: PayloadAction<RequirementsSearchedContact | null>) => {
            state.requirementsSearchedContact = action.payload
        },
        onSetRequirementsSearchedOrganization: (state, action: PayloadAction<RequirementsSearchedOrganization | null>) => {
            state.requirementsSearchedOrganization = action.payload
        },
        onClearActivitiesContacts: (state) => {
            state.activitiesFormContacts = []
        },
        onSetActivitiesCurrentContact: (state, action: PayloadAction<any>) => {
            state.activitiesCurrentContact = action.payload
        },
        onSetActivitiesCurrentOrganization: (state, action: PayloadAction<any>) => {
            state.activitiesCurrentOrganization = action.payload
        },
        onClearActivitiesGridData: (state) => {
            state.activitiesGridColumns = []
        },
        onSetIsSavingButtonDisabled: (state, action: PayloadAction<boolean>) => {
            state.isSavingButtonDisabled = action.payload
        },
        onClearSearchedCompaniesViaContact: (state) => {
            state.searchedCompaniesViaContact = []
        },
        onSwitchInputsMode: (state, action: PayloadAction<boolean>) => {
            state.activitiesModalSwitch = action.payload
        },
        onChangeCurrentGridTab: (state, action: PayloadAction<'Reqs' | 'Properties' | 'Surfaces'>) => {
            state.currentGridTab = action.payload
        },
        onResetActivitiesFiltersButtonsSelected: (state) => {

            state.activitiesGridColumnsForFilters = state.activitiesGridColumnsForFilters.map((m: ColumnsForFiltersType) => m && {
                ...m,
                isFilterSelected: false,
                isModalBtnOpened: false,
                value: '',
                startValue: '',
                endValue: '',
                conditionValue: ''
            })

        },
        onResetCreateActivitiesGridData: (state) => {
            state.activitiesCreationGridReqColumns = []
            state.activitiesCreationGridReqRows = []
            state.activitiesCreationGridPropColumns = []
            state.activitiesCreationGridPropRows = []
            state.activitiesCreationGridSurfColumns = []
            state.activitiesCreationGridSurfRows = []
            state.checkedProperties = []
            state.checkedPropertiesIds = []
            state.checkedSurfaces = []
            state.checkedSurfacesIds = []
            state.checkedReqs = []
            state.checkedReqIds = []
        },
        onAddActivitiesReqsPropsSurfaces: (state, action: PayloadAction<{ gridTypeByField: string, gridRow: any, entityId: number, isChecked: boolean, singleReq?: number, contactListId: number }>) => {
            if (action.payload.gridTypeByField === 'REF') {
                if (action.payload.isChecked) {
                    state.checkedReqs.push(action.payload.gridRow)
                    state.checkedReqIds.push(action.payload.entityId)
                } else {
                    state.checkedReqs = state.checkedReqs.filter((c: any) => Number(c.REF) !== Number(action.payload.entityId))
                    state.checkedReqIds = state.checkedReqIds.filter((c: any) => Number(c) !== Number(action.payload.entityId))
                    // state.allActivitiesEntitiesArray = state.allActivitiesEntitiesArray.filter((c: any) => c.link_entity_list !== action.payload.entityId)
                    // state.checkedProperties = state.checkedProperties.filter((c: any) => Number(c.REQ_REF) !== Number(action.payload.entityId)).map(item => ({
                    //     ...item,
                    //     entityListEntityId: null
                    // }))
                    // state.checkedSurfaces = state.checkedSurfaces.filter((c: any) => Number(c.REQ_REF) !== Number(action.payload.entityId))

                }
            } else if (action.payload.gridTypeByField === 'PROP_REF') {

                if (action.payload.isChecked) {


                    state.checkedProperties.push(action.payload.gridRow)
                    state.checkedPropertiesIds.push(action.payload.entityId)

                } else {
                    state.checkedProperties = state.checkedProperties.filter((c: any) => !(Number(c.PROP_REF) === Number(action.payload.entityId)))

                    state.checkedPropertiesIds = state.checkedPropertiesIds.filter((c: any) => Number(c) !== Number(action.payload.entityId))
                    // state.allActivitiesEntitiesArray = state.allActivitiesEntitiesArray.filter((c: any) => c.link_entity_list !== action.payload.gridRow.REQ_REF)
                    // const isLastSurfaceWithReqId = state.checkedSurfaces.find((s) => s.REQ_REF === action.payload.gridRow.REQ_REF)
                    // const isLastPropertyWithReqId = state.checkedProperties.find((s) => s.REQ_REF === action.payload.gridRow.REQ_REF)
                    // if (isLastSurfaceWithReqId || isLastPropertyWithReqId) {
                    //     console.log('not last')
                    // } else {
                    //     // state.checkedReqIds = state.checkedReqIds.filter((c: any) => Number(c) !== Number(action.payload.gridRow.REQ_REF))
                    // }
                }
            } else if (action.payload.gridTypeByField === 'REQ/Properties') {
                if (action.payload.isChecked) {
                    if (action.payload.singleReq) {
                        state.checkedReqIds.push(action.payload.singleReq)
                    }
                    state.checkedProperties.push(action.payload.gridRow)
                } else {
                    state.checkedProperties = state.checkedProperties.filter((c: any) => !(Number(c.PROP_ID) === Number(action.payload.entityId)))
                }
            } else {
                if (action.payload.isChecked) {

                    if (action.payload.contactListId === state.currentContactRef) {
                        if (!state.checkedReqIds.includes(action.payload.gridRow.REQ_REF)) {
                            state.checkedReqIds.push(action.payload.gridRow.REQ_REF)
                        }
                    }
                    state.checkedSurfacesIds.push(action.payload.entityId)
                    state.checkedSurfaces.push(action.payload.gridRow)


                } else {
                    state.checkedSurfaces = state.checkedSurfaces.filter((c: any) => !(Number(c.SURF_REF) === Number(action.payload.entityId)))
                    state.checkedSurfacesIds = state.checkedSurfacesIds.filter((c: any) => Number(c) !== Number(action.payload.entityId))
                    // state.allActivitiesEntitiesArray = state.allActivitiesEntitiesArray.filter((c: any) => c.link_entity_list !== action.payload.gridRow.REQ_REF)
                    // const isLastSurfaceWithReqId = state.checkedSurfaces.find((s) => s.REQ_REF === action.payload.gridRow.REQ_REF)
                    // const isLastPropertyWithReqId = state.checkedProperties.find((s) => s.REQ_REF === action.payload.gridRow.REQ_REF)
                    // console.log(isLastSurfaceWithReqId)
                    // if (isLastSurfaceWithReqId || isLastPropertyWithReqId) {
                    //     console.log('not last')
                    // } else {
                    //     state.checkedReqIds = state.checkedReqIds.filter((c: any) => Number(c) !== Number(action.payload.gridRow.REQ_REF))
                    // }
                }
            }
        },
        onSetCurrentActivityPropertiesAndSurfaces: (state, action: PayloadAction<any[]>) => {
            state.currentActivityPropertiesAndSurfaces = action.payload
        },
        onSetEntityListIds: (state, action: PayloadAction<{ entity_id: number, ref_id: number }[]>) => {
            state.entityListFromServer = action.payload
        },
        setActivitiesShownColumns: (state, action: PayloadAction<{ columns: any[] }>) => {
            state.activitiesGridShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setActivitiesHiddenColumns: (state, action: PayloadAction<{ columns: any[] }>) => {
            state.activitiesGridHiddenColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setActivitiesCreationGridShownColumns: (state, action: PayloadAction<{ columns: any[] }>) => {
            state.activitiesCreationGridShownColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        setActivitiesCreationGridHiddenColumns: (state, action: PayloadAction<{ columns: any[] }>) => {
            state.activitiesCreationGridHiddenColumns = action.payload.columns.sort((a: any, b: any) => a.col_pos - b.col_pos)
        },
        onSetActivitiesLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[] }>) => {
            state.activitiesSortModel = action.payload.sort_model
        },
        setActivitiesGridColumns: (state, action: PayloadAction<any[]>) => {
            state.activitiesGridColumns = action.payload
        },
        onSetActivitiesGridSortingString: (state, action: PayloadAction<{ sorting_string: string | null }>) => {
            state.activitiesSortingString = action.payload.sorting_string
        },
        onSetActivitiesCreationLocalSortModel: (state, action: PayloadAction<{ sort_model: GridSortItem[], gridType: 'Reqs' | 'Properties' | 'Surfaces' }>) => {
            if (action.payload.gridType === 'Reqs') {
                state.activitiesRequirementsSortModel = action.payload.sort_model
            } else if (action.payload.gridType === 'Properties') {
                state.activitiesPropertiesSortModel = action.payload.sort_model
            } else {
                state.activitiesSurfacesSortModel = action.payload.sort_model
            }
        },
        onSetActivitiesCreationSortingString: (state, action: PayloadAction<{ sorting_string: string | null, gridType: 'Reqs' | 'Properties' | 'Surfaces' }>) => {
            if (action.payload.gridType === 'Reqs') {
                state.activitiesRequirementsSortingString = action.payload.sorting_string
            } else if (action.payload.gridType === 'Properties') {
                state.activitiesPropertiesSortingString = action.payload.sorting_string
            } else {
                state.activitiesSurfacesSortingString = action.payload.sorting_string
            }
        },
        onChangeActivitiesGridColumnsPosition: (state, action: PayloadAction<{ columns: string[], isCreationGrid: boolean }>) => {
            if (action.payload.isCreationGrid) {
                state.activitiesCreationGridShownColumns = state.activitiesCreationGridShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field) - action.payload.columns.indexOf(b.field))
            } else {
                state.activitiesGridShownColumns = state.activitiesGridShownColumns.sort((a, b) => action.payload.columns.indexOf(a.field) - action.payload.columns.indexOf(b.field))
            }

        },
        onChangeActivitiesGridColumnsWidth: (state, action: PayloadAction<{ columns: any, isCreationGrid: boolean }>) => {
            if (action.payload.isCreationGrid) {
                const currentStateColumns = state.activitiesCreationGridShownColumns
                Object.entries(action.payload.columns).forEach((item: any) => {
                    const columnName = item[0];
                    const gridColumnParams = item[1];
                    const currentColumn = currentStateColumns.find(obj => obj.field === columnName);
                    if (currentColumn && gridColumnParams && gridColumnParams.width) {
                        currentColumn.width = gridColumnParams.width;
                    }
                });
            } else {
                const currentStateColumns = state.activitiesGridShownColumns
                Object.entries(action.payload.columns).forEach((item: any) => {
                    const columnName = item[0]
                    const gridColumnParams = item[1]
                    const currentColumn = currentStateColumns.find(obj => obj.field === columnName)
                    if (currentColumn && gridColumnParams && gridColumnParams.width) {
                        currentColumn.width = gridColumnParams.width
                    }
                })
            }

        },
        setAllPropertiesShown: (state, action: PayloadAction<boolean | null>) => {
            state.allPropertiesShown = action.payload
        },
        setAllSurfacesShown: (state, action: PayloadAction<boolean | null>) => {
            state.allSurfacesShown = action.payload
        },
        setActivitiesProperties: (state, action: PayloadAction<any[]>) => {
            state.activitiesCreationGridPropRows = action.payload
        },
        setActivitiesSurfaces: (state, action: PayloadAction<any[]>) => {
            state.activitiesCreationGridSurfRows = action.payload
        },
        setActivitiesCurrentType: (state, action: PayloadAction<string | null>) => {
            state.currentFilterType = action.payload
        },
        setActivitiesCurrentKind: (state, action: PayloadAction<string | null>) => {
            state.currentFilterKind = action.payload
        },
        setIsActivityDeleteEnabled: (state, action: PayloadAction<boolean>) => {
            state.isActivityDeleteEnabled = action.payload
        },
        setContactsWasFound: (state, action: PayloadAction<boolean>) => {
            state.contactNotFound = action.payload
        },
        onClearSearchedCompanies: (state) => {
            state.searchedContactsViaCompanies = []
        },
        onChangeActivitiesGridMode: (state, action: PayloadAction<{gridType: string, currentMode: string}>) => {
            state.activitiesGetDataMode =
                state.activitiesGetDataMode
                .map((a: ActivitiesGetDataMode) => a.gridType === action.payload.gridType ? {...a, currentMode: action.payload.currentMode === 'Function' ? 1 : 2} : a)
        },
        onChangeActivitiesPropertiesSearchValue: (state, action: PayloadAction<string | null>) => {
            state.activitiesPropertiesSearchValue = action.payload
        },
        setActivitiesSurfacesGridError: (state, action: PayloadAction<boolean>) => {
            state.activitySurfacesGridError = action.payload
        },
        setActivitiesPropertiesGridError: (state, action: PayloadAction<boolean>) => {
            state.activityPropertiesGridError = action.payload
        },
        addAbortController: (state, action: PayloadAction<AbortController>) => {
            state.abortControllers.push(action.payload); // Add new AbortController
        },
        clearAllAbortControllers: (state) => {
            state.abortControllers.forEach((controller) => controller.abort()); // Abort all running requests
            state.abortControllers = []; // Clear the controllers
        },
        removeAbortController: (state, action: PayloadAction<AbortController>) => {
            state.abortControllers = state.abortControllers.filter(
                (ctrl) => ctrl !== action.payload
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetActivitiesGridData.fulfilled, (state, action) => {
                state.activitiesGridRows = action.payload
                // eslint-disable-next-line
                const properties = action.payload.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }
                        });
                        obj['isOpened'] = false;
                        obj['surfaces'] = [];
                        return obj;
                    }
                });
                state.activitiesGridRows = properties
                let activitiesGridColumns
                if (state.isActivityDeleteEnabled) {
                    activitiesGridColumns = action.payload[0].concat({
                        COL_NAME: "ACTIONS",
                        COL_OPTIONS: null,
                        COL_POS: 6,
                        COL_TITLE: "Actions",
                        COL_TYPE: null,
                        COL_VALUE: null,
                        COL_WIDTH: 800
                    })
                } else {
                    activitiesGridColumns = action.payload[0]
                }
                state.activitiesGridColumns = activitiesGridColumns.map((c: any, index: number) => {
                    if (c.COL_NAME === "COMPANY_REF" || c.COL_NAME === "CONTACT_REF" || c.COL_NAME === "IX_PRIORITY" || c.COL_NAME === 'DATE_BEGIN' || c.COL_NAME === 'DATE_END') {
                        return {
                            field: c.COL_NAME,
                            headerName: c.COL_TITLE,
                            width: 0,
                            id: index,
                            dataType: c.COL_TYPE,
                            isFilterSelected: false,
                            col_pos: c.COL_POS,
                            col_value: c.COL_VALUE,
                            col_options: c.COL_OPTIONS,
                            key: index,
                            hide: true
                        }
                    } else if (c.COL_NAME === "DATE_MODIFIED" || c.COL_NAME === "DATE_CREATED") {
                        return {
                            field: c.COL_NAME,
                            headerName: c.COL_TITLE,
                            width: c.COL_WIDTH,
                            id: index,
                            dataType: c.COL_TYPE,
                            isFilterSelected: false,
                            col_pos: c.COL_POS,
                            col_value: moment(c.COL_VALUE).format('YYYY-MM-DD HH:mm:ss'),
                            col_options: c.COL_OPTIONS,
                            key: index,
                        }
                    } else if (c.COL_NAME === "ACTIONS") {
                        return {
                            field: c.COL_NAME,
                            headerName: c.COL_TITLE,
                            width: c.COL_WIDTH === 0 ? 0 : 180,
                            id: index,
                            dataType: c.COL_TYPE,
                            isFilterSelected: false,
                            col_pos: 5,
                            col_value: c.COL_VALUE,
                            col_options: c.COL_OPTIONS,
                            key: index,
                            columnTypes: 'right',
                        }
                    } else {
                        return {
                            field: c.COL_NAME,
                            headerName: c.COL_TITLE,
                            width: c.COL_WIDTH,
                            id: index,
                            dataType: c.COL_TYPE,
                            isFilterSelected: false,
                            col_pos: c.COL_POS,
                            col_value: c.COL_VALUE,
                            col_options: c.COL_OPTIONS,
                            key: index,
                        }
                    }
                }).sort((a: any, b: any) => a.col_pos - b.col_pos)
                state.activitiesGridColumnsForFilters = action.payload[0].map((g: any, index: number) => {
                    if (g.COL_NAME === "COMPANY_REF" || g.COL_NAME === "CONTACT_REF" || g.COL_NAME === "IX_PRIORITY" || g.COL_NAME === 'DATE_BEGIN' || g.COL_NAME === 'DATE_END') {
                        return {
                            title: g.COL_NAME,
                            headerName: g.COL_TITLE,
                            value: null,
                            condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: g.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                            hide: true,
                        }
                    } else if (g.COL_NAME === "DATE_MODIFIED" || g.COL_NAME === "DATE_CREATED") {
                        return {
                            title: g.COL_NAME,
                            headerName: g.COL_TITLE,
                            value: null,
                            condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: g.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }
                    } else {
                        return {
                            title: g.COL_NAME,
                            headerName: g.COL_TITLE,
                            value: null,
                            condition: g.COL_TYPE === "class java.lang.String" ? 'LIKE' : '=',
                            dataType: g.COL_TYPE,
                            isModalBtnOpened: false,
                            isFilterSelected: false,
                            filterable: false,
                        }
                    }


                })
                state.activitiesDefaultGridRows = properties
            })
            .addCase(GetActivitiesTypeKind.fulfilled, (state, action) => {
                state.activitiesTypeKind = action.payload
            })
            .addCase(GetActivitiesTypes.fulfilled, (state, action) => {
                state.activitiesTypes = action.payload
                if(action.payload){
                    state.modalActivityFormFields.type = action.payload!![0]?.AE_LI_VALUE
                    state.typeNumberEntity = action.payload!![0]?.AE_NO_INDEX
                }

            })
            .addCase(GetFiltersActivitiesTypes.fulfilled, (state, action) => {
                state.filtersActivitiesTypes = action.payload
            })
            .addCase(GetFiltersActivitiesTypeKind.fulfilled, (state, action) => {
                state.filtersActivitiesTypeKind = action.payload
            })
            .addCase(GetActivitiesRequirements.fulfilled, (state, action) => {
                // eslint-disable-next-line
                state.activitiesCreationGridReqRows = action.payload.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['isOpened'] = false;

                        return obj;
                    }
                });
                state.activitiesCreationGridReqColumns = action.payload[0]
            })
            .addCase(GetActivitiesContactsThunk.fulfilled, (state, action) => {
                state.activitiesFormContacts = [...state.activitiesFormContacts, action.payload]
            })
            .addCase(GetRequirementsContactsThunk.fulfilled, (state, action) => {
                state.activitiesFormContacts = [...state.activitiesFormContacts, action.payload]
            })
            .addCase(GetActivitiesContactsForPropContactsThunk.fulfilled, (state, action) => {
                state.activitiesFormContacts = [action.payload]
            })
            .addCase(GetActivitiesContactsByActivityRefThunk.fulfilled, (state, action) => {
                state.activitiesFormContacts = [...state.activitiesFormContacts, action.payload]
            })
            .addCase(GetActivitiesPropertiesThunk.fulfilled, (state, action) => {
                // eslint-disable-next-line
                state.activitiesCreationGridPropRows = action.payload?.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_NAME === 'PROP_REF') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_NAME === 'REQ_REF') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_NAME === 'SURFACE_EXISTED') {
                                obj['width'] = 0
                            } else if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }
                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['surfaces'] = [];
                        obj['isOpened'] = false;
                        return obj;
                    }
                });
                const gridRows = state.activitiesCreationGridPropRows;
                // @ts-ignore
                state.checkedProperties = gridRows.filter((num: any) => state.checkedPropertiesIds?.some(obj => obj === num.PROP_REF))
                state.activitiesCreationGridPropColumns = action.payload && action.payload[0]
            })
            .addCase(GetActivitiesSurfacesThunk.fulfilled, (state, action) => {
                // eslint-disable-next-line
                state.activitiesCreationGridSurfRows = action.payload?.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['isOpened'] = false;

                        return obj;
                    }
                });
                const gridRows = state.activitiesCreationGridSurfRows
                state.checkedSurfaces =  gridRows.filter((num: any) => state.checkedSurfacesIds?.some(obj => obj === num.SURF_REF))
                state.activitiesCreationGridSurfColumns = action.payload && action.payload[0]
            })
            .addCase(GetActivitiesRefLinksThunk.fulfilled, (state, action) => {
                state.activityRefLinks = action.payload
            })
            .addCase(ActivitiesGridCheckPasswordThunk.fulfilled, (state, action) => {
                state.isPasswordCorrect = action.payload
            })
            .addCase(ActivitiesGridCheckIsPasswordNeededThunk.fulfilled, (state, action) => {
                state.isPasswordNeeded = action.payload
            })
            .addCase(SearchContactsByCompanyNameThunk.fulfilled, (state, action) => {
                state.searchedCompaniesViaContact = action.payload
            })
            .addCase(GetActivitiesCompaniesByContactIdThunk.fulfilled, (state, action) => {
                state.searchedContactsViaCompanies = action.payload
            })
            .addCase(GetRequirementsCompaniesByContactIdThunk.fulfilled, (state, action) => {
                state.searchedContactsViaCompanies = action.payload
            })
            .addCase(GetActivitiesByReqRefThunk.fulfilled, (state, action) => {
                // eslint-disable-next-line
                state.activitiesCreationGridPropsByReqRows = action.payload.map((innerArr: any, index: number) => {
                    if (innerArr !== null) {
                        const obj: any = {};
                        innerArr.forEach((currentValue: any) => {
                            if (currentValue.COL_NAME === 'REF') {
                                const foundObject = innerArr.find((obj: any) => obj.COL_NAME === "SURFACE_EXISTED");
                                obj['withSurface'] = foundObject.COL_VALUE === 1
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_NAME === 'REQ_REF') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_NAME === 'SURFACE_EXISTED') {
                                obj['width'] = 0
                            } else if (currentValue.COL_TYPE === 'class java.math.BigDecimal') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? parseFloat(currentValue.COL_VALUE.toString().replace(/\./g, '').replace(',', '.')) : null;
                            } else if (currentValue.COL_TYPE === 'class java.time.LocalDateTime') {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE !== null ? moment(currentValue.COL_VALUE).format('YYYY-MM-DD HH:mm:ss') : null;
                            } else {
                                obj[currentValue.COL_NAME] = currentValue.COL_VALUE;
                            }

                        });
                        obj['id'] = index;
                        obj['isFiltered'] = false;
                        obj['surfaces'] = [];
                        obj['isOpened'] = false;

                        return obj;
                    }
                });
                state.activitiesCreationGridPropsByReqColumns = action.payload[0]
            })

    }
})

export const {
    onSetActivitiesGridFiltersButtonsModalOpened,
    onSetFiltersForActivitiesGrid,
    onChangeActivitiesGridFilterConditionValue,
    onChangeActivitiesGridFilterValue,
    onChangeActivitiesGridFilterStartValue,
    onChangeActivitiesGridFilterEndValue,
    onSetActivitiesGridData,
    onSetActivitiesGridFiltersButtonsSelected,
    onChangeModalActivityFormField,
    onSetActivitiesModalOpened,
    onSetActivitiesCurrentRow,
    onSetActivityEditMode,
    onSetCurrentContactRef,
    onSetCurrentActivityGridType,
    onCheckActivitiesPropertiesIds,
    onCheckActivitiesProperties,
    onDeleteCheckedProperty,
    onSetActivitiesSurfaceIsOpened,
    onSetActivitiesSurfaces,
    onCheckSurfacesIds,
    onCheckSurfaces,
    onSetCurrentContactReq,
    onDeleteCheckedSurface,
    onSetActivitiesFormFields,
    onClearActivitiesFormFields,
    onSetCurrentContactActivityRef,
    onSetActivitiesIsDataLoading,
    onSetActivitiesIsEmptyReqs,
    onClearActivitiesPropertiesAndSurfaces,
    onSetIsDescriptionFieldError,
    onSetCurrentModalTab,
    onSetPropertiesIds,
    onSetSurfaceIds,
    onSetActivitiesProperties,
    onSetActivitiesSurfacesUpdated,
    onSetAllActivitiesEntitiesArray,
    onSetIsSavingButtonDisabled,
    onClearActivitiesPropertiesData,
    onSetActivitiesContactId,
    onSetActivitiesOrganizationId,
    onClearActivitiesContacts,
    onClearActivitiesGridData,
    onClearSearchedCompaniesViaContact,
    onSwitchInputsMode,
    onChangeCurrentGridTab,
    onResetCreateActivitiesGridData,
    onAddActivitiesReqsPropsSurfaces,
    onSetReqIds,
    onDeleteCheckedReq,
    onSetCurrentActivityPropertiesAndSurfaces,
    onSetEntityListIds,
    setActivitiesShownColumns,
    setActivitiesHiddenColumns,
    onSetActivitiesLocalSortModel,
    setActivitiesGridColumns,
    onSetActivitiesGridSortingString,
    onChangeActivitiesGridColumnsPosition,
    onChangeActivitiesGridColumnsWidth,
    setActivitiesCreationGridShownColumns,
    setActivitiesCreationGridHiddenColumns,
    onSetActivitiesIsPropsLoading,
    onSetActivitiesIsSurfacesLoading,
    onSetCurrentCompanyRef,
    setAllPropertiesShown,
    setAllSurfacesShown,
    setActivitiesProperties,
    setActivitiesSurfaces,
    setActivitiesCurrentType,
    setActivitiesCurrentKind,
    setIsActivityDeleteEnabled,
    onSetActivitiesCreationLocalSortModel,
    onSetActivitiesCreationSortingString,
    setContactsWasFound,
    onSetRequirementsSearchedContact,
    onSetRequirementsSearchedOrganization,
    onClearSearchedCompanies,
    setActivitiesGridColumnsForFilters,
    onResetActivitiesFiltersButtonsSelected,
    onChangeActivitiesGridMode,
    onChangeActivitiesPropertiesSearchValue,
    setActivitiesSurfacesGridError,
    setActivitiesPropertiesGridError,
    onClearPropContactsCheckedProperties,
    addAbortController,
    clearAllAbortControllers,
    removeAbortController,
    onSetActivitiesRequirementsUpdated,
    onSetActivitiesCurrentOrganization,
    onSetActivitiesCurrentContact
} = activitiesGridSlice.actions

export const selectActivitiesGridColumns = (state: RootState): GridColumns[] => state.activitiesGrid.activitiesGridColumns
export const selectActivitiesGridRows = (state: RootState): any => state.activitiesGrid.activitiesGridRows
export const selectActivitiesGridFilterColumns = (state: RootState): any => state.activitiesGrid.activitiesGridColumnsForFilters
export const selectActivitiesGridFiltersForColumns = (state: RootState): any => state.activitiesGrid.activitiesGridFiltersForArray
export const selectActivitiesGridDefaultRows = (state: RootState): any => state.activitiesGrid.activitiesDefaultGridRows
export const selectActivitiesTypeKind = (state: RootState): ActivitiesTypeKind[] => state.activitiesGrid.activitiesTypeKind
export const selectActivitiesTypes = (state: RootState): ActivitiesTypeKind[] => state.activitiesGrid.activitiesTypes
export const selectActivitiesModalFormFields = (state: RootState): ModalActivitiesFormFields => state.activitiesGrid.modalActivityFormFields
export const selectActivitiesModalIsOpened = (state: RootState): boolean => state.activitiesGrid.isActivitiesModalOpened
export const selectActivitiesCurrentRow = (state: RootState): CurrentRowType => state.activitiesGrid.currentRow
export const selectActivityEditMode = (state: RootState): boolean => state.activitiesGrid.activityEditMode
export const selectActivityReqs = (state: RootState): ActivitiesReqType[] => state.activitiesGrid.activitiesReqs
export const selectActivityFormContacts = (state: RootState): any[] => state.activitiesGrid.activitiesFormContacts
export const selectCurrentContactRef = (state: RootState): number => state.activitiesGrid.currentContactRef
export const selectCurrentCompanyRef = (state: RootState): number | null => state.activitiesGrid.currentCompanyRef
export const selectCurrentContactReq = (state: RootState): number => state.activitiesGrid.currentContactReq
export const selectActivitiesProperties = (state: RootState): any[] => state.activitiesGrid.activitiesProperties
export const selectCurrentActivitiesGridType = (state: RootState): 'Requirements' | 'Properties' => state.activitiesGrid.currentActivitiesGridType
export const selectCurrentActivitiesCheckedPropertiesIds = (state: RootState): number[] => state.activitiesGrid.checkedPropertiesIds
export const selectCurrentActivitiesCheckedProperties = (state: RootState): ActivityPropertyType[] => state.activitiesGrid.checkedProperties
export const selectActivitiesCheckedSurfacesIds = (state: RootState): number[] => state.activitiesGrid.checkedSurfacesIds
export const selectActivitiesCheckedSurfaces = (state: RootState): number[] => state.activitiesGrid.checkedSurfaces
export const selectActivitiesTypeNumberEntity = (state: RootState): number | undefined | null => state.activitiesGrid.typeNumberEntity
export const selectActivitiesKindNumberEntity = (state: RootState): number | undefined | null => state.activitiesGrid.kindNumberEntity
export const selectActivitiesIsDataLoading = (state: RootState): boolean => state.activitiesGrid.isDataLoading
export const selectActivitiesIsEmptyReqs = (state: RootState): boolean => state.activitiesGrid.isEmptyReqs
export const selectActivitiesIsDescriptionFieldError = (state: RootState): boolean => state.activitiesGrid.isDescriptionFieldError
export const selectActivityModalCurrentTab = (state: RootState): 'General' | 'Existing Links' => state.activitiesGrid.currentModalTab
export const selectAllActivitiesEntitiesArray = (state: RootState): any[] => state.activitiesGrid.allActivitiesEntitiesArray
export const selectOrganizationId = (state: RootState): number | null => state.activitiesGrid.organizationId
export const selectContactId = (state: RootState): number | null => state.activitiesGrid.contactId
export const selectActivitiesIsPasswordNeeded = (state: RootState): 1 | 0 => state.activitiesGrid.isPasswordNeeded
export const selectActivityKindTypeIsPropAndReqRequired = (state: RootState): number | undefined => state.activitiesGrid.activityKindTypeIsPropAndReqRequired
export const selectIsSavingButtonDisabled = (state: RootState): boolean => state.activitiesGrid.isSavingButtonDisabled
export const selectSearchedCompaniesViaContact = (state: RootState): any[] => state.activitiesGrid.searchedCompaniesViaContact
export const selectSearchedContactsViaCompanies = (state: RootState): any[] => state.activitiesGrid.searchedContactsViaCompanies
export const selectIsInputsSwitched = (state: RootState): boolean => state.activitiesGrid.activitiesModalSwitch
export const selectActivitiesReqsColumns = (state: RootState): any[] => state.activitiesGrid.activitiesReqsColumns
export const selectActivitiesCurrentGridTab = (state: RootState): 'Reqs' | 'Properties' | 'Surfaces' => state.activitiesGrid.currentGridTab
export const selectActivitiesSurfaces = (state: RootState): any[] => state.activitiesGrid.activitiesSurfaces
export const selectActivitiesCreationGridReqColumns = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridReqColumns
export const selectActivitiesCreationGridReqRows = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridReqRows
export const selectActivitiesCreationGridPropColumns = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridPropColumns
export const selectActivitiesCreationGridPropRows = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridPropRows
export const selectActivitiesCreationGridSurfColumns = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridSurfColumns
export const selectActivitiesCreationGridSurfRows = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridSurfRows
export const selectActivitiesCreationCheckedReqs = (state: RootState): any[] => state.activitiesGrid.checkedReqs
export const selectActivitiesCreationCheckedReqIds = (state: RootState): any[] => state.activitiesGrid.checkedReqIds
export const selectCurrentActivityPropertiesAndSurfaces = (state: RootState): any[] => state.activitiesGrid.currentActivityPropertiesAndSurfaces
export const selectEntityListFromServer = (state: RootState): any[] => state.activitiesGrid.entityListFromServer
export const selectActivitiesCreationGridPropsByReqColumns = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridPropsByReqColumns
export const selectActivitiesCreationGridPropsByReqRows = (state: RootState): ActivityCreationGridRowType[] => state.activitiesGrid.activitiesCreationGridPropsByReqRows
export const selectActivitiesGridShownColumns = (state: RootState): any[] => state.activitiesGrid.activitiesGridShownColumns
export const selectActivitiesGridHiddenColumns = (state: RootState): any[] => state.activitiesGrid.activitiesGridHiddenColumns
export const selectActivitiesCreationGridShownColumns = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridShownColumns
export const selectActivitiesCreationGridHiddenColumns = (state: RootState): any[] => state.activitiesGrid.activitiesCreationGridHiddenColumns
export const selectActivitiesSortingString = (state: RootState): string | null => state.activitiesGrid.activitiesSortingString
export const selectActivitiesSortModel = (state: RootState): [] | GridSortItem[] => state.activitiesGrid.activitiesSortModel
export const selectActivitiesIsPropsLoading = (state: RootState): boolean | null => state.activitiesGrid.isPropsLoading
export const selectActivitiesIsSurfacesLoading = (state: RootState): boolean | null => state.activitiesGrid.isSurfacesLoading
export const selectAllPropertiesShown = (state: RootState): boolean | null => state.activitiesGrid.allPropertiesShown
export const selectAllSurfacesShown = (state: RootState): boolean | null => state.activitiesGrid.allSurfacesShown
export const selectFiltersActivitiesTypeKind = (state: RootState): ActivitiesTypeKind[] => state.activitiesGrid.filtersActivitiesTypeKind
export const selectFiltersActivitiesTypes = (state: RootState): ActivitiesTypeKind[] => state.activitiesGrid.filtersActivitiesTypes
export const selectCurrentActivityKind = (state: RootState): string | null => state.activitiesGrid.currentFilterKind
export const selectCurrentActivityType = (state: RootState): string | null => state.activitiesGrid.currentFilterType
export const selectActivitiesCreationReqsSortingString = (state: RootState): string | null => state.activitiesGrid.activitiesRequirementsSortingString
export const selectActivitiesCreationReqsSortModel = (state: RootState): [] | GridSortItem[] => state.activitiesGrid.activitiesRequirementsSortModel
export const selectActivitiesCreationPropertiesSortingString = (state: RootState): string | null => state.activitiesGrid.activitiesPropertiesSortingString
export const selectActivitiesCreationPropertiesSortModel = (state: RootState): [] | GridSortItem[] => state.activitiesGrid.activitiesPropertiesSortModel
export const selectActivitiesCreationSurfacesSortingString = (state: RootState): string | null => state.activitiesGrid.activitiesSurfacesSortingString
export const selectActivitiesCreationSurfacesSortModel = (state: RootState): [] | GridSortItem[] => state.activitiesGrid.activitiesSurfacesSortModel
export const selectContactsWasNotFound = (state: RootState): boolean => state.activitiesGrid.contactNotFound
export const selectRequirementsSearchedOrganization = (state: RootState): RequirementsSearchedOrganization | null => state.activitiesGrid.requirementsSearchedOrganization
export const selectRequirementsSearchedContact = (state: RootState): RequirementsSearchedContact | null => state.activitiesGrid.requirementsSearchedContact
export const selectActivitiesGetDataMode = (state: RootState): ActivitiesGetDataMode[] => state.activitiesGrid.activitiesGetDataMode
export const selectActivitiesSearchValue = (state: RootState): string | null => state.activitiesGrid.activitiesPropertiesSearchValue
export const selectActivitiesSurfacesDataError = (state: RootState): boolean => state.activitiesGrid.activitySurfacesGridError
export const selectActivitiesPropertiesDataError = (state: RootState): boolean => state.activitiesGrid.activityPropertiesGridError
export const selectActivitiesCurrentContact = (state: RootState): any => state.activitiesGrid.activitiesCurrentContact
export const selectActivitiesCurrentOrganization = (state: RootState): any => state.activitiesGrid.activitiesCurrentOrganization

export const GetActivitiesGridData = createAsyncThunk<any, GridActivitiesRequest, AsyncThunkConfig>(
    'activities/getActivitiesGridData',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(onSetActivitiesIsDataLoading(true))
        try {
            const {status, data} = await gridApi.getGridActivitiesData(reqData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle})
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetActivitiesGridSortingString({sorting_string: data.sorting_rule}));
                    thunkAPI.dispatch(onSetActivitiesLocalSortModel({sort_model: sortingCondition}));
                } else {
                    thunkAPI.dispatch(onSetActivitiesGridSortingString({sorting_string: null}));
                    thunkAPI.dispatch(onSetActivitiesLocalSortModel({sort_model: []}));
                }
                return data.grid_column_data
            } else {
                return thunkAPI.rejectWithValue([])
            }
        } catch (error: any) {
            thunkAPI.dispatch(onClearActivitiesGridData())
            return thunkAPI.rejectWithValue('Activities were not found')
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
            thunkAPI.dispatch(onSetActivitiesIsDataLoading(false))
        }
    }
)

export const GetActivitiesTypeKind = createAsyncThunk<ActivitiesTypeKind[], ActivityTypeKindReqData, AsyncThunkConfig>(
    'activities/getActivitiesTypeKind',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.getActivitiesTypeKind(reqData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetFiltersActivitiesTypeKind = createAsyncThunk<ActivitiesTypeKind[], void, AsyncThunkConfig>(
    'activities/getFiltersActivitiesTypeKind',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.getFiltersActivitiesTypeKind()
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetActivitiesTypes = createAsyncThunk<ActivitiesTypeKind[], { domain: string, position?: string | null, withDomain?: boolean }, AsyncThunkConfig>(
    'activities/getActivitiesTypes',
    async (reqData, thunkAPI) => {
        try {
            const response: any = await activitiesApi.getActivitiesTypes(reqData.domain, reqData.position)
            if (response && response.status === 200 && response.data) {
                thunkAPI.fulfillWithValue(response.data.resultSetList, {appStatus: AppStatusType.idle})
                return response.data.resultSetList
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
);

export const GetFiltersActivitiesTypes = createAsyncThunk<ActivitiesTypeKind[], void, AsyncThunkConfig>(
    'activities/getFiltersActivitiesTypes',
    async (reqData, thunkAPI) => {
        try {
            const response = await activitiesApi.getFiltersActivitiesTypes()
            if (response && response.status === 200 && response.data) {
                thunkAPI.fulfillWithValue(response.data.resultSetList, {appStatus: AppStatusType.idle})
                return response.data.resultSetList
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
);


export const GetActivitiesRequirements = createAsyncThunk<any[], {
    p_agent_ref: number
    p_company_ref?: number | null
    p_sort_order: string | null
    p_domain?: string | null,
}, AsyncThunkConfig>(
    'activities/getActivitiesRequirements',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(onSetActivitiesIsDataLoading(true))
        try {
            const {status, data} = await activitiesApi.getActivitiesRequirements(reqData)
            if (status === 200) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle})
                if (!data.grid_column_data.length) {
                    thunkAPI.dispatch(onSetActivitiesIsEmptyReqs(true))
                }
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetActivitiesCreationSortingString({
                        sorting_string: data.sorting_rule,
                        gridType: 'Reqs'
                    }));
                    thunkAPI.dispatch(onSetActivitiesCreationLocalSortModel({
                        sort_model: sortingCondition,
                        gridType: 'Reqs'
                    }));
                } else {
                    thunkAPI.dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Reqs'}));
                    thunkAPI.dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Reqs'}));
                }
                return data.grid_column_data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
        finally {
            thunkAPI.dispatch(onSetActivitiesIsDataLoading(false))
        }
    }
)

export const GetActivitiesContactsThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'activities/getActivitiesContactsThunk',
    async (companyRef, thunkAPI) => {
        try {
            const {status, data} = await contactsApi.getContactByOrganization(companyRef)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                if (data.resultSetList.flat(1).length <= 1) {
                    thunkAPI.dispatch(onSetCurrentContactRef(data.resultSetList.flat(1)[0].PE_REF))
                    thunkAPI.dispatch(onSetActivitiesContactId(data.resultSetList.flat(1)[0].PE_REF))
                    console.log(data.resultSetList.flat(1)[0])
                    thunkAPI.dispatch(onChangeModalActivityFormField({
                        value: `${data.resultSetList.flat(1)[0].PE_FIRST_NAME} ${data.resultSetList.flat(1)[0].PE_LAST_NAME}  ${data.resultSetList.flat(1)[0]?.PE_EMAIL && true ? '| ' + data.resultSetList.flat(1)[0]?.PE_EMAIL : ''} ${data.resultSetList.flat(1)[0]?.TEL_NUMBER && true ? ` | Phone: ${data.resultSetList.flat(1)[0]?.TEL_NUMBER}` : ''}`,
                        inputName: 'Contacts'
                    }))
                }
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetRequirementsContactsThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'activities/getRequirementsContactsThunk',
    async (companyRef, thunkAPI) => {
        try {
            const {status, data} = await contactsApi.getContactByOrganization(companyRef)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetActivitiesContactsForPropContactsThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'activities/getActivitiesContactsForPropContactsThunk',
    async (companyRef, thunkAPI) => {
        try {
            const {status, data} = await contactsApi.getContactByOrganization(companyRef)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                if (data.resultSetList.flat(1).length <= 1) {
                    thunkAPI.dispatch(onSetCurrentContactRef(data.resultSetList.flat(1)[0].PE_REF))
                    thunkAPI.dispatch(onSetActivitiesContactId(data.resultSetList.flat(1)[0].PE_REF))
                    thunkAPI.dispatch(onChangeModalActivityFormField({
                        value: `${data.resultSetList.flat(1)[0].PE_FIRST_NAME} ${data.resultSetList.flat(1)[0].PE_LAST_NAME}  ${data.resultSetList.flat(1)[0]?.PE_EMAIL && true ? '| ' + data.resultSetList.flat(1)[0]?.PE_EMAIL : ''} ${data.resultSetList.flat(1)[0]?.TEL_NUMBER && true ? ` | Phone: ${data.resultSetList.flat(1)[0]?.TEL_NUMBER}` : ''}`,
                        inputName: 'Contacts'
                    }))
                }
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetActivitiesPropertiesThunk = createAsyncThunk<any, {
    reqData: {
        p_agent_ref: number
        p_company_ref?: number | null,
        p_contact_ref?: number | null
        p_sort_order: string | null,
        p_status_idx: number,
        p_domain: string | null,
        p_function_idx?: number | null,
        p_street_address?: string | null,
    }, allPropertiesShown: boolean | null, checkedPropertiesIds?: number[]
}, AsyncThunkConfig>(
    'activities/getActivitiesPropertiesThunk',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAllPropertiesGridColumns([]))
        thunkAPI.dispatch(setActivitiesProperties([]))
        thunkAPI.dispatch(onSetActivitiesIsPropsLoading(true))
        thunkAPI.dispatch(setActivitiesPropertiesGridError(false))
        const abortCtrl = getAbortController();
        console.log(reqData.checkedPropertiesIds)
        if(!reqData.checkedPropertiesIds?.length){
            thunkAPI.dispatch(setAbortController(abortCtrl));
            thunkAPI.dispatch(addAbortController(abortCtrl))
        }
        thunkAPI.dispatch(onSetAllPropertiesReqLoading(true))
        try {
            const {status, data} = await activitiesApi.getActivitiesProperties(reqData.reqData, {signal: !reqData.checkedPropertiesIds?.length ? abortCtrl.signal : undefined})
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle})
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetActivitiesCreationSortingString({
                        sorting_string: data.sorting_rule,
                        gridType: 'Properties'
                    }));
                    thunkAPI.dispatch(onSetActivitiesCreationLocalSortModel({
                        sort_model: sortingCondition,
                        gridType: 'Properties'
                    }));
                } else {
                    thunkAPI.dispatch(onSetActivitiesCreationSortingString({
                        sorting_string: null,
                        gridType: 'Properties'
                    }));
                    thunkAPI.dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Properties'}));
                }
                thunkAPI.dispatch(onSetActivitiesIsPropsLoading(false))
                thunkAPI.dispatch(onSetActivitiesIsDataLoading(false))
                return data.grid_column_data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            if (error.message === "canceled") {
            } else {
                thunkAPI.dispatch(setAllPropertiesGridColumns([]))
                thunkAPI.dispatch(setActivitiesProperties([]))
                thunkAPI.dispatch(onSetActivitiesIsPropsLoading(false))
                thunkAPI.dispatch(onSetActivitiesIsDataLoading(false))
                return thunkAPI.rejectWithValue(error?.response?.data?.message);
            }
            // if (reqData.allPropertiesShown) {
            //     return thunkAPI.rejectWithValue(error?.response?.data?.message)
            // } else {
            //     return thunkAPI.rejectWithValue('Properties linked with your company was not found, please use switch in properties tab to search all available properties')
            // }
        }
        finally {
            thunkAPI.dispatch(removeAbortController(abortCtrl)); // Clean up the AbortController
        }
    }
)

export const GetActivitiesSurfacesThunk = createAsyncThunk<any, {
    reqData: {
        p_agent_ref: number
        p_company_ref?: number | null,
        p_contact_ref?: number | null
        p_sort_order: string | null,
        p_domain: string | null,
        p_status_idx: number,
        p_function_idx?: number | null,
        p_street_address?: string | null,
    }, allSurfacesShown: boolean | null, checkedSurfaceIds?: number[]
}, AsyncThunkConfig>(
    'activities/getActivitiesSurfacesThunk',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(onSetActivitiesIsSurfacesLoading(true))
        thunkAPI.dispatch(setActivitiesSurfacesGridError(false))
        const abortCtrl = getAbortController();
        if(!reqData.checkedSurfaceIds?.length){
            thunkAPI.dispatch(setAbortController(abortCtrl));
            thunkAPI.dispatch(addAbortController(abortCtrl))
        }
        thunkAPI.dispatch(onSetAllPropertiesReqLoading(true))
        thunkAPI.dispatch(setActivitiesSurfaces([]))
        thunkAPI.dispatch(setAllPropertiesGridColumns([]))
        try {
            const {status, data} = await activitiesApi.getActivitiesSurfaces(reqData.reqData, {signal: !reqData.checkedSurfaceIds?.length ? abortCtrl.signal : undefined})
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle})
                if (data.sorting_rule !== null) {
                    const sortingCondition = data.sorting_rule.split(',').map((item: any) => {
                        const [field, sort] = item.trim().split(' ');
                        return {field, sort: sort.toLowerCase()};
                    });
                    thunkAPI.dispatch(onSetActivitiesCreationSortingString({
                        sorting_string: data.sorting_rule,
                        gridType: 'Surfaces'
                    }));
                    thunkAPI.dispatch(onSetActivitiesCreationLocalSortModel({
                        sort_model: sortingCondition,
                        gridType: 'Surfaces'
                    }));
                } else {
                    thunkAPI.dispatch(onSetActivitiesCreationSortingString({
                        sorting_string: null,
                        gridType: 'Surfaces'
                    }));
                    thunkAPI.dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Surfaces'}));
                }
                thunkAPI.dispatch(onSetActivitiesIsSurfacesLoading(false))
                thunkAPI.dispatch(onSetActivitiesIsDataLoading(false))
                return data.grid_column_data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            if (error.message === "canceled") {
            }
            else{
                thunkAPI.dispatch(setActivitiesSurfaces([]))
                thunkAPI.dispatch(setActivitiesSurfacesGridError(true))
                thunkAPI.dispatch(onSetActivitiesIsSurfacesLoading(false))
                thunkAPI.dispatch(onSetActivitiesIsDataLoading(false))
                return thunkAPI.rejectWithValue(error?.response?.data?.message)
            }

            // if (reqData.allSurfacesShown) {
            //     return thunkAPI.rejectWithValue('Surfaces were not found')
            // } else {
            //     return thunkAPI.rejectWithValue('Surfaces linked with your company was not found, please use switch in surfaces tab to search all available surfaces')
            // }
        } finally {
            thunkAPI.dispatch(removeAbortController(abortCtrl)); // Clean up the AbortController
        }
    }
)

export const GetActivitiesSurfaceThunk = createAsyncThunk<any, { req_id: number, prop_id: number }, AsyncThunkConfig>(
    'activities/getActivitiesSurfaceThunk',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await activitiesApi.getActivitiesPropertySurface(requestData.req_id, requestData.prop_id)
            if (status === 200 && data) {
                thunkAPI.dispatch(onSetActivitiesSurfaceIsOpened({isOpened: true, ref: String(requestData.prop_id)}))
                thunkAPI.dispatch(onSetActivitiesSurfaces({
                    surfaces: data.resultSetList,
                    ref: String(requestData.prop_id)
                }))
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CreateNewActivityThunk = createAsyncThunk<any, { activityData: CreateAndUpdateActivityRequest, isActivityGrid?: boolean }, AsyncThunkConfig>(
    'activities/createNewActivityThunk',
    async (requestData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.createNewActivity(requestData.activityData)
            if (status === 200) {
                thunkAPI.dispatch(setAppStatus(2))
                thunkAPI.dispatch(setSuccessMessage('Activity successfully created'))
                thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Activity successfully created'
                })
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            setTimeout(() => {
                thunkAPI.dispatch(setAppStatus(0))
                thunkAPI.dispatch(setSuccessMessage(''))
                // thunkAPI.dispatch(onClearActivitiesPropertiesAndSurfaces())
                if (requestData.isActivityGrid !== false) {
                    window.location.replace('/activitiesGrid')
                }
                thunkAPI.dispatch(onSetIsSavingButtonDisabled(false))
            }, 3000)
        }
    }
)

export const UpdateExistingActivityThunk = createAsyncThunk<any, { requestData: CreateAndUpdateActivityRequest, reqRef: number }, AsyncThunkConfig>(
    'activities/updateExistingActivityThunk',
    async (requestData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await activitiesApi.updateExistingActivity(requestData.requestData, requestData.reqRef)
            if (status === 200) {
                thunkAPI.dispatch(setAppStatus(2))
                thunkAPI.dispatch(setSuccessMessage('Activity successfully created'))
                thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Activity successfully created'
                })
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(2))
            thunkAPI.dispatch(setSuccessMessage('Activity successfully updated'))
            setTimeout(() => {
                thunkAPI.dispatch(setAppStatus(0))
                thunkAPI.dispatch(setSuccessMessage(''))
                thunkAPI.dispatch(onClearActivitiesPropertiesAndSurfaces())
                window.location.replace('/activitiesGrid')
                thunkAPI.dispatch(onSetIsSavingButtonDisabled(false))
            }, 3000)
        }
    }
)


export const DeleteActivityThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'activities/getActivityThunk',
    async (reqRef, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.deleteActivity(reqRef)
            if (status === 200) {
                thunkAPI.fulfillWithValue({}, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue({})
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
            thunkAPI.dispatch(setAppStatus(2))
            thunkAPI.dispatch(setSuccessMessage('Activity successfully deleted'))
            setTimeout(() => {
                thunkAPI.dispatch(setAppStatus(0))
                thunkAPI.dispatch(setSuccessMessage(''))
                window.location.reload()
            }, 1000)
        }
    }
)

export const GetActivitiesRefLinksThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'activities/getActivitiesRefLinksThunk',
    async (refId, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await activitiesApi.getActivityRefLinks(refId)
            if (status === 200 && data) {
                const propsIds = []
                const surfaceIds = []
                const reqIds: number[] = []
//                 for (let i = 0; i < data.link_entity_list.length; i++) {
//                     if (data.link_type_list[i] === 1) {
//                         reqIds.unshift(data.link_entity_list[i]);
//                     }
//                 }
                for (let i = 0; i < data.link_entity_list.length; i++) {
                    if (data.link_type_list[i] === 0) {
                        propsIds.push(data.link_entity_list[i]);
                    } else if (data.link_type_list[i] === 14) {
                        surfaceIds.push(data.link_entity_list[i]);
                    } else if (data.link_type_list[i] === 1) {
                        reqIds.unshift(data.link_entity_list[i]);
                    }
                }
                const allActivitiesEntitiesArray = data.link_entity_list.map((entity: any, index: any) => ({
                    link_entity_list: entity,
                    link_ref_list: data.link_ref_list[index],
                    link_type_list: data.link_type_list[index]
                }));
                thunkAPI.dispatch(onSetAllActivitiesEntitiesArray(allActivitiesEntitiesArray))
                thunkAPI.dispatch(onSetPropertiesIds(propsIds))
                thunkAPI.dispatch(onSetSurfaceIds(surfaceIds))
                thunkAPI.dispatch(onSetReqIds(reqIds))
                // thunkAPI.dispatch(onSetActivitiesSurfacesUpdated(surfaceIds.map((s: any) => {return{
                //     VALUE_01: s
                // }})))
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const ActivitiesGridCheckPasswordThunk = createAsyncThunk<any, { checkPwData: CheckPasswordData, reqRef: number }, AsyncThunkConfig>(
    'activities/getActivitiesGridCheckPassword',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.activitiesCheckPassword(reqData.checkPwData)
            if (status === 200 && data) {
                if (data === 1) {
                    thunkAPI.dispatch(DeleteActivityThunk(reqData.reqRef))
                } else if (data === 0) {
                    message.error('The password you entered is incorrect')
                }
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue('The password you entered is incorrect')
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(null)
        }
    }
)

export const ActivitiesGridCheckIsPasswordNeededThunk = createAsyncThunk<any, CheckPasswordData, AsyncThunkConfig>(
    'activities/getActivitiesGridCheckIsPasswordNeeded',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await activitiesApi.activitiesCheckIsPasswordNeeded(reqData)
            if (status === 200) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(null)
        }
    }
)

export const GetActivitiesContactsByActivityRefThunk = createAsyncThunk<any, { company_ref: number, activity_ref: number }, AsyncThunkConfig>(
    'activities/getActivitiesContactsByActivityRefThunk',
    async (reqData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await contactsApi.getContactByOrganizationAndActivityRef(reqData.company_ref, reqData.activity_ref)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                thunkAPI.dispatch(onSetCurrentContactRef(Number(data.resultSetList[0].PE_REF)))
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export const SearchContactsByCompanyNameThunk = createAsyncThunk<any, {search_value: string | null, address?: string, pe_ref?: string}, AsyncThunkConfig>(
    'activities/searchContactsByCompanyName',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setContactsWasFound(false))
        try {
            const {status, data} = await activitiesApi.searchCompaniesByContact(reqData.search_value, reqData.address, reqData.pe_ref)
            if (status === 200 && data) {
                if (data.resultSetList.length === 0) {
                    thunkAPI.dispatch(setContactsWasFound(true))
                }
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
            thunkAPI.dispatch(onSetIsCompaniesLoading(false))
        }
    }
)

export const GetActivitiesCompaniesByContactIdThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'activities/getActivitiesCompaniesByContactId',
    async (person_ref, thunkAPI) => {
        try {
            const {status, data} = await companiesApi.getCompaniesByContactRef(person_ref)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                if (data.resultSetList.flat(1).length <= 1) {
                    thunkAPI.dispatch(onSetActivitiesOrganizationId(data.resultSetList.flat(1)[0].REF))
                    gridDataHelper.setCurrentSearchedCompanyId(data.resultSetList.flat(1)[0].REF)
                    thunkAPI.dispatch(onChangeModalActivityFormField({
                        value: `${data.resultSetList.flat(1)[0].NAME} ${data.resultSetList.flat(1)[0].CITY && true ? '| ' + data.resultSetList.flat(1)[0].CITY : ''} ${data.resultSetList.flat(1)[0].PE_EMAIL && true ? '| ' + data.resultSetList.flat(1)[0].PE_EMAIL : ''} ${data.resultSetList.flat(1)[0]?.PHONE && true ? ` | Phone: ${data.resultSetList.flat(1)[0]?.PHONE}` : ''}`,
                        inputName: 'Organizations'
                    }))
                    thunkAPI.dispatch(onSetActivitiesCurrentOrganization({
                        value: `${data.resultSetList.flat(1)[0].NAME}`,
                        label: `${data.resultSetList.flat(1)[0].NAME}`,
                        key: data.resultSetList.flat(1)[0].REF,
                        ref: data.resultSetList.flat(1)[0].REF,
                        phone: data.resultSetList.flat(1)[0]?.PHONE,
                        email: data.resultSetList.flat(1)[0]?.PE_EMAIL}))
                }
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetRequirementsCompaniesByContactIdThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'activities/getRequirementsCompaniesByContactId',
    async (person_ref, thunkAPI) => {
        try {
            const {status, data} = await companiesApi.getCompaniesByContactRef(person_ref)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const CheckIsUserHasAddedPropsAndReqThunk = createAsyncThunk<any, number, AsyncThunkConfig>(
    'activities/checkIsUserHasAddedPropsAndReq',
    async (user_ref, thunkAPI) => {
        try {
            const {status, data} = await userAPI.checkIsUserHasAddedPropsAndReq(user_ref)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
                return data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetActivitiesByReqRefThunk = createAsyncThunk<any, {
    p_agent_ref: number
    p_req_ref: number
    p_sort_order: string | null
}, AsyncThunkConfig>(
    'activities/getActivitiesByReqRefThunk',
    async (requestData, thunkAPI) => {
        thunkAPI.dispatch(onSetActivitiesIsDataLoading(true))
        try {
            const {
                status,
                data
            } = await activitiesApi.getActivitiesPropertiesByReq(requestData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.grid_column_data, {appStatus: AppStatusType.idle})
                return data.grid_column_data
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        } finally {
            thunkAPI.dispatch(onSetActivitiesIsDataLoading(false))
        }
    }
)


//// types
export interface ActivityCreationGridRowType {
    withSurface: boolean
    PROP_REF: number
    REQ_REF: number
    DESCRIPTION: string
    COUNTRY: string
    CITY: string
    ZIP: string
    ADDRESS: string
    DATE_CREATED: string
    DATE_MODIFIED: string
    width: number
    id: number
    isFiltered: boolean
    surfaces: any[],
    isOpened: boolean
    entityListEntityId: number
}

export interface ActivityGridRowType {
    withSurface: boolean
    PROP_REF: number
    REQ_REF: number
    DESCRIPTION: string
    COUNTRY: string
    CITY: string
    ZIP: string
    ADDRESS: string
    DATE_CREATED: string
    DATE_MODIFIED: string
    width: number
    id: number
    isFiltered: boolean
    surfaces: any[],
    isOpened: boolean
    entityListEntityId: number
}


export default activitiesGridSlice.reducer


