import React, {ChangeEvent, useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import buttonsS
    from '../../components/PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons.module.scss';
import {
    ActivitiesGridCheckPasswordThunk,
    GetActivitiesContactsByActivityRefThunk,
    GetActivitiesGridData,
    GetActivitiesRefLinksThunk,
    GetActivitiesTypes, GetFiltersActivitiesTypeKind, GetFiltersActivitiesTypes,
    onChangeActivitiesGridColumnsPosition,
    onChangeActivitiesGridColumnsWidth,
    onChangeCurrentGridTab,
    onChangeModalActivityFormField,
    onClearActivitiesContacts,
    onClearActivitiesFormFields,
    onClearActivitiesPropertiesAndSurfaces,
    onClearActivitiesPropertiesData, onResetActivitiesFiltersButtonsSelected,
    onResetCreateActivitiesGridData, onSetActivitiesCurrentContact, onSetActivitiesCurrentOrganization,
    onSetActivitiesCurrentRow,
    onSetActivitiesFormFields, onSetActivitiesGridData,
    onSetActivitiesIsDataLoading,
    onSetActivitiesIsEmptyReqs,
    onSetActivitiesLocalSortModel,
    onSetActivitiesModalOpened,
    onSetActivityEditMode, onSetCurrentCompanyRef,
    onSetCurrentContactActivityRef,
    onSetCurrentContactRef,
    onSetCurrentModalTab,
    onSetFiltersForActivitiesGrid, onSetIsDescriptionFieldError,
    selectActivitiesGridColumns,
    selectActivitiesGridDefaultRows, selectActivitiesGridFilterColumns, selectActivitiesGridFiltersForColumns,
    selectActivitiesGridHiddenColumns,
    selectActivitiesGridRows,
    selectActivitiesGridShownColumns,
    selectActivitiesIsDataLoading,
    selectActivitiesIsPasswordNeeded,
    selectActivitiesModalIsOpened,
    selectActivitiesSortingString, selectActivitiesSortModel, setActivitiesCurrentKind, setActivitiesCurrentType,
    setActivitiesGridColumnsForFilters,
    setActivitiesHiddenColumns,
    setActivitiesShownColumns, setAllPropertiesShown, setAllSurfacesShown, setIsActivityDeleteEnabled,
} from "../../store/activitesGrid";
import {AvailableUserMenuItem, selectAvailableMenuItems, selectUserData} from "../../store/userReducer";
import moment from "moment";
import {DataGridPro, GridColumnMenu, useGridApiRef} from "@mui/x-data-grid-pro";
import s from "../PropertiesPage/Properties.module.scss";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import Box from "@mui/material/Box";
import {customSortFunction, numericComparator} from "../../helpers/sortingDataHelper";
import commonS from "../RequirementsPage/CommonTableStyles.module.scss";

import {
    clearPropertiesForEmail,
    clearSurfacesForEmail,
    onClearCheckedProperties,
    onClearCheckedSurfaces,
    onClearPropertiesMessageBody,
    onFilterPropertiesGrid,
    onResetPropertiesGridColumns,
    onSetAllSurfaceClosed,
    onSetIsActivityModalOpened,
    onSetIsCheckReqMatchFirstTime,
    onSetLocalSortModel,
    onSetProperties,
    onSetPropertiesGridLoaderMessage, onSetPropertiesMessageBody,
    onSetPropertiesOfferFormError,
    onSetReqGridHeight,
    onSetSortingString,
    ResetAllGridConfigsThunk,
    selectPropertiesGridLoaderMessage,
    setShownColumns,
} from "../../store/propertiesReducer";
import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import PropertyFilterModal from "../PropertiesPage/PropertyFilterModal/PropertyFilterModal";
import PropertiesSearchInput from "../PropertiesCommonComponents/PropertiesSearchInput/PropertiesSearchInput";
import ActivitiesGridModal from "../ActivitiesGridModal/ActivitiesGridModal";
import {useNavigate} from "react-router";
import {Button, Empty, Input, message, Select} from "antd";
import {GetCompanyByRefThunk, onClearCompanies} from "../../store/companies";
import plusImg from './../../img/icons/plus.svg'
import {activitiesDataHelper, gridDataHelper} from "../../helpers/localStorageHelper";
import {ReactComponent as DeleteActivityIcon} from "../../img/icons/deleteActivityIcon.svg";
import {addMonths, addDays, startOfWeek ,endOfWeek, subDays, startOfMonth, endOfMonth } from 'date-fns';

import {DateRangePicker} from 'rsuite';
import PropertiesHeaderButtons from "../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";
import PropertiesViewOptions from "../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
import {gridApi} from "../../app/api";
import {sessionStorageGridFilters} from "../../helpers/sessionStorageHelper";
import {gridFiltering} from "../../customFunctions/gridFiltering";
import {Pagination} from "@mui/material";
import {selectCurrentScreenWidth, selectIsMobileFiltersOpened} from "../../store/utilityReducer";
import {throttle} from "lodash";
import MobileFiltersButton from "../common/MobileFiltersButton/MobileFiltersButton";
import MobileFiltersMenu from "../common/MobileFiltersMenu/MobileFiltersMenu";


const ActivitiesGrid = () => {
    return (
        <PageWithSidebar tableMargins={'27px 20px 20px'}>
            <ActivitiesGridPageComponent/>
        </PageWithSidebar>
    )
}


const ActivitiesGridPageComponent = () => {
    const dispatch = useAppDispatch()
    const userData = useAppSelector(selectUserData)
    const activitiesGridColumns = useAppSelector(selectActivitiesGridColumns)
    const activitiesGridRows = useAppSelector(selectActivitiesGridRows)
    const [searchTerm, setSearchTerm] = useState('');
    const rowsPerPageOptions = [150, 200, 500];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
    const [page, setPage] = React.useState(1);
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 340);
    const apiRef = useGridApiRef();
    const sortModel = useAppSelector(selectActivitiesSortModel)
    const isAddActivityModalOpened = useAppSelector(selectActivitiesModalIsOpened)
    const navigate = useNavigate()
    const isDataLoading = useAppSelector(selectActivitiesIsDataLoading)
    const [deleteModalOpened, setDeleteModalOpened] = useState(false)
    const isPasswordNeeded = useAppSelector(selectActivitiesIsPasswordNeeded)
    let defaultActivitiesGridRows = useAppSelector(selectActivitiesGridDefaultRows)
    const [password, setPassword] = useState('')
    const sortingRule = useAppSelector(selectActivitiesSortingString)
    const [localActivityRef, setLocalActivityRef] = useState(0)
    const gridLoaderMessage = useAppSelector(selectPropertiesGridLoaderMessage)
    const [filtersShown, setFiltersShown] = useState(false)
    const [checked, setChecked] = useState<any>([]);
    const shownColumns = useAppSelector(selectActivitiesGridShownColumns)
    const [localSortModel, setLocalSortModel] = useState({});
    const hiddenColumns = useAppSelector(selectActivitiesGridHiddenColumns)
    const currentDate = useMemo(() => {
        return new Date(); // Get current date
    }, []);
    const startOfDay = useMemo(() => {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0); // Set start of the day
    }, [currentDate]);
    const endOfDay = useMemo(() => {
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999); // Set end of the day
    }, [currentDate]);
    const availableMenuItems = useAppSelector(selectAvailableMenuItems)
    const isActivityDeleteEnabled = availableMenuItems!?.find((i:AvailableUserMenuItem) => i.OBJECT_NAME === 'Button.Activities.Delete')
    const gridFilters = useAppSelector(selectActivitiesGridFiltersForColumns)
    const activitiesGridColumnsForFilters = useAppSelector(selectActivitiesGridFilterColumns)
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)
    const isMobileMenuOpened = useAppSelector(selectIsMobileFiltersOpened)
    // eslint-disable-next-line
    const threeMonthsAgo = new Date(); // Get today's date
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // Set three months ago
    // eslint-disable-next-line
    const today = new Date(); // Get today's date

    useEffect(() => {
        // dispatch(onSetActivitiesGridData({properties: []}))
        // dispatch(setActivitiesShownColumns({columns: []}))
        dispatch(onSetProperties({properties: [], gridType: 'Properties'}))
        dispatch(onSetFiltersForActivitiesGrid({gridFilters: []}))
        dispatch(onSetProperties({properties: [], gridType: 'All Properties/REQ'}))
        dispatch(setShownColumns({columns: [], gridType: 'Properties'}))
        dispatch(onResetPropertiesGridColumns([]))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        gridDataHelper.removeConditionGridFiltersString()
        gridDataHelper.removePropertiesGridConfig()
        gridDataHelper.removeRowId()
        gridDataHelper.removeRowTitle()
        gridDataHelper.removePropertiesGridFilters()
        dispatch(onClearPropertiesMessageBody())
        dispatch(clearPropertiesForEmail([]))
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetAllSurfaceClosed())
        dispatch(onClearCheckedProperties())
        dispatch(onClearCheckedSurfaces())
        dispatch(onFilterPropertiesGrid({gridFilters: [], gridType: 'Properties'}))
        dispatch(onSetSortingString({sorting_string: null, gridType: 'Properties'}));
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}));
        dispatch(onSetPropertiesGridLoaderMessage(''))
        gridDataHelper.removeIsCheckReqMatchFirstTime()
        dispatch(onSetIsCheckReqMatchFirstTime(false))
        /////reset activity modal fields
        dispatch(onSetIsActivityModalOpened(false))
        dispatch(onSetIsDescriptionFieldError(false))
        dispatch(clearPropertiesForEmail([]))
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetAllSurfaceClosed())
        dispatch(onClearCheckedProperties())
        dispatch(onClearCheckedSurfaces())
        dispatch(clearSurfacesForEmail([]))
        dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
        dispatch(onSetPropertiesMessageBody(''))
        dispatch(onClearActivitiesPropertiesAndSurfaces())
        //
        dispatch(onSetLocalSortModel({sort_model: [], gridType: 'Properties'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Type'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Kind'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Description'}))
        dispatch(onChangeModalActivityFormField({value: '', inputName: 'Remark'}))
        dispatch(onSetActivitiesCurrentOrganization({}))
        dispatch(onSetActivitiesCurrentContact({}))

    }, [dispatch])


    const [selectedDateRange, setSelectedDateRange] = useState<[any | null, any | null]>(
        [
            activitiesDataHelper.getActivityDateStart() !== false ? new Date(activitiesDataHelper.getActivityDateStart()) : startOfDay,
            activitiesDataHelper.getActivityDateEnd() !== false ? new Date(activitiesDataHelper.getActivityDateEnd()) : endOfDay]);


    useEffect(() => {
        dispatch(setIsActivityDeleteEnabled(isActivityDeleteEnabled?.IS_ACCESS === 1))
    }, [dispatch, isActivityDeleteEnabled])


    useEffect(() => {
        dispatch(onClearActivitiesContacts())
        // dispatch(GetActivitiesTypes())
        dispatch(onClearCompanies())
        dispatch(onSetActivitiesIsEmptyReqs(false))
        dispatch(onClearActivitiesFormFields())
        dispatch(onClearActivitiesPropertiesAndSurfaces())
        dispatch(onClearActivitiesPropertiesData())
        dispatch(setAllPropertiesShown(null))
        dispatch(setAllSurfacesShown(null))
        dispatch(onSetActivitiesCurrentRow({
            ACT_KIND: "Some Kind",
            ACT_TYPE: "Some Type",
            DATE_CREATED: "2023-08-18",
            DATE_MODIFIED: "2023-08-18",
            DESCRIPTION: "Some description",
            MODIFIED_BY: "User123",
            REF: 123,
            REMARKS: "Some remarks",
            REQ_REF: 456,
            CONTACT_REF: '',
            COMPANY_REF: '',
            IX_PRIORITY: 1,
            DATE_BEGIN: '',
            DATE_END: '',
            WE_TYPE_DOMAIN_CD: '',
            WE_TYPE_POSITION: ''
        }))
    }, [dispatch])

    useEffect(() => {
        dispatch(GetFiltersActivitiesTypeKind())
        dispatch(GetFiltersActivitiesTypes())
    }, [dispatch])

    useEffect(() => {

        if (!activitiesDataHelper.getActivityDateEnd() && activitiesDataHelper.getActivityDateStart()) {
            activitiesDataHelper.setActivityDateStart(startOfDay)
            activitiesDataHelper.setActivityDateEnd(endOfDay)
        }
    }, [startOfDay, endOfDay])

    useEffect(() => {
        if (selectedDateRange && selectedDateRange.length) {
            const endDate = new Date(selectedDateRange[1]);
            endDate.setHours(20, 59, 59, 999);
            const startDate = new Date(selectedDateRange[0]);
            startDate.setHours(0, 0, 0, 0);
            dispatch(GetActivitiesGridData({
                p_agent_ref: Number(userData.user_ref),
                p_end_period: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                p_start_period: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                p_sort_order: sortingRule
            }))
            dispatch(onSetPropertiesGridLoaderMessage('Loading'))
        }

    }, [userData.user_ref, selectedDateRange, dispatch, sortingRule])


    useEffect(() => {
        dispatch(onSetActivityEditMode(false))
        dispatch(onSetActivitiesModalOpened(false))
        dispatch(onResetCreateActivitiesGridData())
        dispatch(onChangeCurrentGridTab('Reqs'))
    }, [dispatch])

    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };


    const debounce = (func: Function, wait: number) => {
        let timeout: ReturnType<typeof setTimeout> | null;
        return function (this: any, ...args: any[]) {
            const context = this;
            clearTimeout(timeout!);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };

    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage - 340);
            dispatch(onSetReqGridHeight(heightForPage))
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [dispatch]);


    let sortedRows = customSortFunction(activitiesGridRows, sortModel);


    const rows = sortedRows.reduce((acc, row) => {
        const values = Object.values(row);
        const useStartsWith = searchTerm.endsWith('%');
        const processedSearchTerm = searchTerm.slice(0, -1)!?.toLowerCase();
        if (values.some(value => {
            const stringValue = String(value)!?.toLowerCase();
            return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(searchTerm!?.toLowerCase());
        })) {
            acc.push(row);
        }
        return acc;
    }, []);

    //
    // const handleSortModelChange = (newModel: any) => {
    //     setSortModel(newModel);
    // };

    const handlePageChange = (params: any, value: number) => {
        setPage(value);
        afterPaginationPageChangeScrollToTop();
    }

    const handleSortModelChange = (newModel: any) => {
        const newLocalModel: any = {...localSortModel};
        newModel.forEach((model: any) => {
            newLocalModel[model.field] = model;
        });
        setLocalSortModel(newLocalModel);
        const mergedArray = [...sortModel, ...newModel];

        // Find the index of the matching field object in the merged array
        const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);

        // Check if the sort value is different and update it
        if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
            // eslint-disable-next-line
            const updatedObject = {
                ...mergedArray[index],
                sort: newModel[0]?.sort
            };
            mergedArray[index] = updatedObject;
        }

        dispatch(onSetActivitiesLocalSortModel({sort_model: mergedArray}));
    };

    const afterPaginationPageChangeScrollToTop = () => {
        const gridApi = apiRef.current;
        if (!gridApi) return;
        gridApi.scrollToIndexes({rowIndex: 0});
    };

    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event);
        // setPage(1)
        setPage(1)
    }

    const getRowClassName = (params: any) => {
        return (params.id % 2 === 0) ? 'even-row' : 'odd-row';
    };


    const onResetFilters = () => {
        dispatch(onSetActivitiesGridData({properties: defaultActivitiesGridRows}))
        // const resetShownColumns = shownColumns.map((c: any) => c && {
        //     ...c,
        //     isFilterSelected: false,
        //     isModalBtnOpened: false
        // })
        // dispatch(setShownColumns({columns: resetShownColumns, gridType: currentGrid}))
        dispatch(onResetActivitiesFiltersButtonsSelected())
        dispatch(onSetFiltersForActivitiesGrid({gridFilters: []}))
        dispatch(setActivitiesCurrentType(null))
        dispatch(setActivitiesCurrentKind(null))
        sessionStorageGridFilters.removeActivitiesGridFilters()

    }
    useEffect(() => {
        dispatch(onChangeModalActivityFormField({
            value: 'low',
            inputName: 'Urgency'
        }))
    }, [dispatch])
    const onResetSorting = () => {
        dispatch(onSetActivitiesLocalSortModel({sort_model: []}))
        setLocalSortModel({})
    }

    useEffect(() => {
        dispatch(onChangeModalActivityFormField({value: null, inputName: 'date_till'}))

    }, [dispatch])


    useEffect(() => {
        dispatch(onSetCurrentCompanyRef(null))
    }, [dispatch])

    const onRowClick = (row: any, event: any) => {
        event.preventDefault()
        dispatch(onSetActivitiesCurrentRow(row.row))
        dispatch(onSetActivityEditMode(true))
        dispatch(GetActivitiesTypes({domain: row.row.WE_TYPE_DOMAIN_CD}))
            .then(() => {
                // @ts-ignore
                dispatch(onSetActivitiesFormFields({currentRow: row.row}))
                dispatch(onSetCurrentContactActivityRef(row.row.REQ_REF))
                const companiesArray = row?.row?.COMPANY_REF?.split(',')
                const contactsArray = row?.row?.CONTACT_REF?.split(',')
                companiesArray?.forEach(function (company: string) {
                    dispatch(GetCompanyByRefThunk(Number(company)))
                    dispatch(onSetCurrentContactRef(Number(company)))
                    dispatch(onSetCurrentCompanyRef(Number(company)))
                    dispatch(GetActivitiesContactsByActivityRefThunk({
                        company_ref: Number(company),
                        activity_ref: Number(row.row.REF)
                    }))
                });
                contactsArray?.forEach(function (contact: string) {

                    dispatch(onSetCurrentContactRef(Number(contact)))

                });
                dispatch(onChangeModalActivityFormField({value: row.row?.DATE_END, inputName: 'date_till'}))
                dispatch(onChangeModalActivityFormField({
                    value: row.row?.IX_PRIORITY === 0 ? 'low' : row.row?.IX_PRIORITY === 1 ? 'avg' : 'high',
                    inputName: 'Urgency'
                }))
                dispatch(onChangeModalActivityFormField({
                    value: row.row?.DATE_BEGIN,
                    inputName: 'date_from'
                }))


            })
            .then(() => {
                dispatch(GetActivitiesRefLinksThunk(row.row.REF))
            })
            .finally(() => {
                setTimeout(() => {
                    navigate('/organizationActivities')
                    dispatch(onSetCurrentModalTab('General'))
                }, 1000)

            })



    }


    const onCheckIsPropertyReqDataRelevant = () => {
        if (selectedDateRange && selectedDateRange.length) {
            dispatch(GetActivitiesGridData({
                p_agent_ref: Number(userData.user_ref),
                p_end_period: selectedDateRange[1],
                p_start_period: selectedDateRange[0],
                p_sort_order: null
            }))
            dispatch(onSetPropertiesGridLoaderMessage('Loading'))
        }
    }

    const onOpenDeleteActivityModal = (e: any, activityId: number) => {
        e.stopPropagation();
        setDeleteModalOpened(true)
        setLocalActivityRef(activityId)
    }


    const onSetPassword = (value: ChangeEvent<HTMLInputElement>) => {
        setPassword(value.target.value)
    }

    const onDeleteActivities = () => {
        dispatch(ActivitiesGridCheckPasswordThunk({
            checkPwData: {
                list_code: 'activities',
                operation: 'D',
                password: isPasswordNeeded ? password : 'act999'
            }, reqRef: Number(localActivityRef)

        }))
    }

    useEffect(() => {
        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getActivitiesGridFilters()
        if (filterColumnsFromSessionStorage && filterColumnsFromSessionStorage.length) {
            dispatch(setActivitiesGridColumnsForFilters({
                columns: activitiesGridColumns.map((g: any) => {
                    return {
                        title: g.field,
                        headerName: g.headerName,
                        value: null,
                        condition: g.dataType === "class java.lang.String" ? 'LIKE' : '=',
                        dataType: g.dataType,
                        isModalBtnOpened: false,
                        isFilterSelected: false,
                        filterable: false,
                    }
                })
            }))
            dispatch(onSetFiltersForActivitiesGrid({gridFilters: filterColumnsFromSessionStorage}))
        }
        dispatch(setActivitiesGridColumnsForFilters({
            columns: activitiesGridColumns.map((g: any) => {
                return {
                    title: g.field,
                    headerName: g.headerName,
                    value: null,
                    condition: g.dataType === "class java.lang.String" ? 'LIKE' : '=',
                    dataType: g.dataType,
                    isModalBtnOpened: false,
                    isFilterSelected: false,
                    filterable: false,
                }
            })
        }))
    }, [dispatch, activitiesGridColumns])


    useEffect(() => {
        const filterColumnsFromSessionStorage = sessionStorageGridFilters.getActivitiesGridFilters()
        if (filterColumnsFromSessionStorage) {
            gridFiltering(filterColumnsFromSessionStorage, defaultActivitiesGridRows, activitiesGridColumnsForFilters, 'Activities Grid', dispatch)
        }
        // eslint-disable-next-line
    }, [dispatch, defaultActivitiesGridRows])


    const onOpenActivityModal = () => {
        dispatch(onSetActivitiesModalOpened(true))
        dispatch(onChangeModalActivityFormField({
            value: 'low',
            inputName: 'Urgency'
        }))
        dispatch(onChangeModalActivityFormField({value: null, inputName: 'date_till'}))
    }


    const onDateChange = (values: any) => {
        setSelectedDateRange(values);
        if (values !== null) {
            const endDate = new Date(values[1]);
            endDate.setHours(23, 59, 59, 999);
            activitiesDataHelper.setActivityDateStart(values[0])
            activitiesDataHelper.setActivityDateEnd(endDate)
        }
    }

    const predefinedRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },
        {
            label: 'All time',
            value: [new Date(new Date().getFullYear() - 4, 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last week',
            closeOverlay: false,
            value: (value: any) => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, {weekStartsOn: 0}), -7),
                    addDays(endOfWeek(start, {weekStartsOn: 0}), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: (value: any) => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, {weekStartsOn: 0}), 7),
                    addDays(endOfWeek(start, {weekStartsOn: 0}), 7)
                ];
            },
            appearance: 'default'
        }
    ];

    useEffect(() => {
        dispatch(setActivitiesShownColumns({
            columns: activitiesGridColumns.filter((c: any) => c?.width !== 0 && c.field !== 'COMPANY_REF' && c.field !== 'CONTACT_REF' && c.field !== 'DATE_BEGIN' && c.field !== "DATE_END" && c.field !== "REQ_REF" && c.field !== "IX_PRIORITY").map((c: any, index: number) => {
                if (c.dataType === 'class java.math.BigDecimal') {
                    return {
                        field: c.field,
                        headerName: c.headerName,
                        width: c.width,
                        checked: false,
                        id: index,
                        dataType: c.dataType,
                        isFilterSelected: false,
                        col_pos: c.col_pos,
                        col_value: c.col_value,
                        col_options: c.col_options,
                        withSurface: c.withSurface,
                        key: index,
                        sortComparator: numericComparator
                    }
                } else {
                    return {
                        field: c.field,
                        headerName: c.headerName,
                        width: c.width,
                        checked: false,
                        id: index,
                        dataType: c.dataType,
                        isFilterSelected: false,
                        col_pos: c.col_pos,
                        col_value: c.col_value,
                        col_options: c.col_options,
                        withSurface: c.withSurface,
                        key: index,
                    }
                }


            })
        }))
        dispatch(setActivitiesHiddenColumns({
            columns: activitiesGridColumns.filter((c: any) => c?.width === 0 && c.field !== 'DATE_BEGIN' && c.field !== "DATE_END" && c.field !== "REQ_REF" && c.field !== "IX_PRIORITY").map((c: any, index: number) => {
                if (c.dataType === 'class java.math.BigDecimal') {
                    return {
                        field: c.field,
                        headerName: c.headerName,
                        width: 0,
                        checked: false,
                        id: index,
                        dataType: c.dataType,
                        isFilterSelected: false,
                        col_pos: c.col_pos,
                        col_value: c.col_value,
                        col_options: c.col_options,
                        withSurface: c.withSurface,
                        key: index,
                        sortComparator: numericComparator
                    }
                } else {
                    return {
                        field: c.field,
                        headerName: c.headerName,
                        width: 0,
                        checked: false,
                        id: index,
                        dataType: c.dataType,
                        isFilterSelected: false,
                        col_pos: c.col_pos,
                        col_value: c.col_value,
                        col_options: c.col_options,
                        withSurface: c.withSurface,
                        key: index,
                    }
                }
            })
        }))
        setChecked(activitiesGridColumns.filter((c: any) => c?.width === 0).map((c: any) => c.field))

        // eslint-disable-next-line
    }, [dispatch, activitiesGridColumns])

    useEffect(() => {
        const sortModelObject: any = {};
        for (const item of sortModel) {
            sortModelObject[item.field] = {
                field: item.field,
                sort: item.sort
            };
        }
        setLocalSortModel(sortModelObject)
    }, [sortModel])


    const onResetConfigs = () => {
        dispatch(onResetActivitiesFiltersButtonsSelected())
        dispatch(onSetFiltersForActivitiesGrid({gridFilters: []}))
        dispatch(setActivitiesCurrentType(null))
        dispatch(setActivitiesCurrentKind(null))
        sessionStorageGridFilters.removeActivitiesGridFilters()
        dispatch(setActivitiesShownColumns({columns: []}))
        dispatch(onSetActivitiesIsDataLoading(true))
        setSearchTerm('')
        dispatch(onSetActivitiesLocalSortModel({sort_model: []}))
        const endDate = new Date(selectedDateRange[1]);
        endDate.setHours(20, 59, 59, 999);
        const startDate = new Date(selectedDateRange[0]);
        startDate.setHours(0, 0, 0, 0);
        dispatch(onSetActivitiesLocalSortModel({sort_model: []}));
        setLocalSortModel({})
        dispatch(ResetAllGridConfigsThunk({
            p_agent_ref: Number(userData.user_ref),
            p_row_req: null,
            grid_type: 'WE_ACTIVITY_DATA'
        }))
            .then(() => {
                dispatch(GetActivitiesGridData({
                    p_agent_ref: Number(userData.user_ref),
                    p_end_period: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    p_start_period: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    p_sort_order: null
                }))
                // dispatch(onSetPropertiesGridLoaderMessage('Loading'))
            })
        dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
    }
    const onSaveGridConfigs = () => {
        // dispatch(onSetActivitiesIsDataLoading(true))
        let hiddenColumnsResult;
        let shownColumnsResult;
        if (hiddenColumns.length) {
            hiddenColumnsResult = hiddenColumns.map((yItem: any, index: number) => {
                let xMatch = activitiesGridColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
                // @ts-ignore
                xMatch = {...xMatch, COL_WIDTH: 0, COL_POS: shownColumns.length + index}
                return xMatch ? {...yItem, ...xMatch} : yItem;
            });
        } else if (!hiddenColumns.length) {
            hiddenColumnsResult = []
        }
        if (shownColumns.length) {
            shownColumnsResult = shownColumns.map((yItem: any) => {
                let xMatch = activitiesGridColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
                // @ts-ignore
                xMatch = {...xMatch, COL_WIDTH: yItem.width}
                return xMatch ? {...yItem, ...xMatch} : yItem;
            });
        } else if (!shownColumns.length) {
            shownColumnsResult = shownColumns
        }
        const shownColumnsResultFinal = shownColumnsResult?.map((g: any, index: number) => {
            return {
                COL_NAME: g.field,
                COL_OPTIONS: g.col_options,
                COL_POS: index,
                COL_TITLE: g.headerName,
                COL_TYPE: g.dataType,
                COL_VALUE: g.col_value,
                COL_WIDTH: g.COL_WIDTH
            }
        }).filter((c: any) => c.COL_NAME !== 'ACTIVITY_EXISTED' && c.COL_NAME !== 'SURFACE_EXISTED')
        const hiddenColumnsResultFinal = hiddenColumnsResult?.map((g: any) => {
            return {
                COL_NAME: g.field,
                COL_OPTIONS: g.col_options,
                COL_POS: g.COL_POS,
                COL_TITLE: g.headerName,
                COL_TYPE: g.dataType,
                COL_VALUE: g.col_value,
                COL_WIDTH: g.COL_WIDTH
            }
        }).filter((c: any) => c.COL_NAME !== 'ACTIVITY_EXISTED' && c.COL_NAME !== 'SURFACE_EXISTED')

        function mergeArrays(hiddenColumns: any, shownColumns: any) {
            const result = [];
            for (const hiddenColumn of hiddenColumns) {
                const matchingObjS = shownColumns.find((objS: any) => objS.COL_NAME === hiddenColumn.COL_NAME);
                if (matchingObjS) {
                    hiddenColumn.COL_WIDTH = matchingObjS.COL_WIDTH;
                }
                result.push(hiddenColumn);
            }
            for (const shownColumn of shownColumns) {
                if (!result.find(obj => obj.COL_NAME === shownColumn.COL_NAME)) {
                    result.push(shownColumn);
                }
            }
            return result;
        }

        const sortConditionStrArray = Object.keys(localSortModel).map(key => {
            // @ts-ignore
            return {"field": localSortModel[key].field, "sort": localSortModel[key].sort};
        });
        const sortConditionStr = sortConditionStrArray?.map((item: any) => `${item.field} ${item.sort.toUpperCase()}`).join(', ');
        const req = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsResultFinal, shownColumnsResultFinal),
            p_row_req: null,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : 'REF DESC',
            grid_type: 'WE_ACTIVITY_DATA',
        }
        gridApi.updateGridColumnData(req)
            .then(() => {
                message.success('Grid configs were saved')
                // const endDate = new Date(selectedDateRange[1]);
                // endDate.setHours(20, 59, 59, 999);
                // const startDate = new Date(selectedDateRange[0]);
                // startDate.setHours(0, 0, 0, 0);
                // dispatch(GetActivitiesGridData({
                //     p_agent_ref: Number(userData.user_ref),
                //     p_end_period: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                //     p_start_period: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                //     p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : 'REF DESC',
                // }))

            })
            .catch((e) => {
                console.log(e)
            })
        // dispatch(setRequirementsShownColumns({columns: []}))
        // dispatch(setActivitiesGridColumns([]))
        // dispatch(onSetSortingString({sorting_string: null, gridType: 'Requirements'}));
        // dispatch(onSetRequirementsLocalSortModel({sort_model: []}));
        // dispatch(onSetPropertiesGridLoaderMessage('Saving data is in progress'))
    }
    const onSetFiltersShown = () => {
        setFiltersShown(!filtersShown)
    }

    const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevent event bubbling to document click event listener
        onSetFiltersShown();
    };

    const handleStateChange = (params: any) => {
        // Access the grid state
        dispatch(onChangeActivitiesGridColumnsPosition({columns: params.columns.all, isCreationGrid: false}))
        dispatch(onChangeActivitiesGridColumnsWidth({columns: params.columns.lookup, isCreationGrid: false}))
    };


    const ROW_CHUNK_SIZE = 50;
    const [loadedRows, setLoadedRows] = useState<any>([]);
    const [currentChunk, setCurrentChunk] = useState(0);

    const activitiesDataRowsPaginated = useMemo(() => {
        return rows.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    }, [rows, page, rowsPerPage]);

    // Compute the rows based on the current chunk
    const paginatedData = useMemo(() => {
        const end = currentChunk * ROW_CHUNK_SIZE;
        return activitiesDataRowsPaginated.slice(0, end);
    }, [activitiesDataRowsPaginated, currentChunk]);

    useEffect(() => {
        // Check if the data itself has changed, not just the length
        const isDataDifferent = JSON.stringify(paginatedData) !== JSON.stringify(loadedRows);
        if (isDataDifferent) {
            setLoadedRows(paginatedData);
        }
        // eslint-disable-next-line
    }, [paginatedData]);

    // eslint-disable-next-line
    const handleRowsScrollEnd = useCallback(
        throttle(() => {
            const gridContainer = document.querySelector('.MuiDataGrid-virtualScroller');
            if (gridContainer) {
                const { scrollTop, scrollHeight, clientHeight } = gridContainer;

                // Ensure this logic runs only when really needed
                if (scrollTop + clientHeight >= scrollHeight - 50 && currentChunk * ROW_CHUNK_SIZE < activitiesDataRowsPaginated.length) {
                    setCurrentChunk(prev => prev + 1);
                }
            }
        }, 500), // Increased throttle time to avoid excessive triggers
        [currentChunk, activitiesDataRowsPaginated.length]
    );

    useEffect(() => {
        const gridContainer = document.querySelector('.MuiDataGrid-virtualScroller');
        if (gridContainer) {
            gridContainer.addEventListener('scroll', handleRowsScrollEnd);
        }
        return () => {
            if (gridContainer) {
                gridContainer.removeEventListener('scroll', handleRowsScrollEnd);
            }
        };
    }, [handleRowsScrollEnd]);

    return (
        <div
            className={s.inner}
        >
            <div
                className={commonS.tableInner}
                style={{
                    padding: '9px 0 16px 12px'
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: currentScreenWidth!! > 440 ? 'center' : 'flex-start',
                    flexDirection: currentScreenWidth!! > 440 ? 'row' : 'column'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: currentScreenWidth!! > 440 ? 'space-between' : 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                        gap: currentScreenWidth!! > 440 ? '0' : '10px'
                    }}>
                        <h1 className={s.tableTitle}>Activities</h1>
                        {
                            currentScreenWidth!! > 768
                            &&
                            <div>
                                <PropertiesHeaderButtons
                                    onSetFiltersShown={handleFiltersShownClick}
                                    onResetConfigs={onResetConfigs}
                                    onSaveGridConfigs={onSaveGridConfigs}
                                    isDisabledSaveBtn={!rows.length || isDataLoading}
                                    disabledBtns={!rows.length || isDataLoading}
                                    gridType={'Activities Grid'}
                                    resetFilters={() => {}}
                                />
                            </div>
                        }
                        {
                            currentScreenWidth! <= 768
                            &&
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                                <MobileFiltersButton customMargin={'0'}/>
                            </div>
                        }

                    </div>

                        <div style={{
                            marginRight: currentScreenWidth!! < 860 ? '20px' : 0,
                            display: 'flex',
                            margin: currentScreenWidth!! > 440 ? '0' : '10px 0'
                        }}>
                            <button
                                className={`${buttonsS.viewBtn} ${buttonsS.viewBtn_cancel}`}
                                onClick={onCheckIsPropertyReqDataRelevant}
                                style={{
                                    marginRight: '10px',
                                    height: currentScreenWidth!! > 768 ? '32px' : '40px',
                                    marginLeft: currentScreenWidth!! > 440 ? '10px' : '0',
                                }}
                                disabled={isDataLoading}
                            >
                                <svg width="25" height="25" viewBox="0 0 18 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg" style={{
                                    marginTop: '2px'
                                }}>
                                    <path
                                        d="M1.90321 7.29677C1.90321 10.341 4.11041 12.4147 6.58893 12.8439C6.87255 12.893 7.06266 13.1627 7.01355 13.4464C6.96444 13.73 6.69471 13.9201 6.41109 13.871C3.49942 13.3668 0.86084 10.9127 0.86084 7.29677C0.860839 5.76009 1.55996 4.55245 2.37639 3.63377C2.96124 2.97568 3.63034 2.44135 4.16846 2.03202L2.53205 2.03202C2.25591 2.03202 2.03205 1.80816 2.03205 1.53202C2.03205 1.25588 2.25591 1.03202 2.53205 1.03202L5.53205 1.03202C5.80819 1.03202 6.03205 1.25588 6.03205 1.53202L6.03205 4.53202C6.03205 4.80816 5.80819 5.03202 5.53205 5.03202C5.25591 5.03202 5.03205 4.80816 5.03205 4.53202L5.03205 2.68645L5.03054 2.68759L5.03045 2.68766L5.03044 2.68767L5.03043 2.68767C4.45896 3.11868 3.76059 3.64538 3.15554 4.3262C2.44102 5.13021 1.90321 6.10154 1.90321 7.29677ZM13.0109 7.70321C13.0109 4.69115 10.8505 2.6296 8.40384 2.17029C8.12093 2.11718 7.93465 1.84479 7.98776 1.56188C8.04087 1.27898 8.31326 1.0927 8.59616 1.14581C11.4704 1.68541 14.0532 4.12605 14.0532 7.70321C14.0532 9.23988 13.3541 10.4475 12.5377 11.3662C11.9528 12.0243 11.2837 12.5586 10.7456 12.968L12.3821 12.968C12.6582 12.968 12.8821 13.1918 12.8821 13.468C12.8821 13.7441 12.6582 13.968 12.3821 13.968L9.38205 13.968C9.10591 13.968 8.88205 13.7441 8.88205 13.468L8.88205 10.468C8.88205 10.1918 9.10591 9.96796 9.38205 9.96796C9.65819 9.96796 9.88205 10.1918 9.88205 10.468L9.88205 12.3135L9.88362 12.3123C10.4551 11.8813 11.1535 11.3546 11.7585 10.6738C12.4731 9.86976 13.0109 8.89844 13.0109 7.70321Z"
                                        fill="#101828"
                                        stroke="#101828" strokeWidth="0.2" strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />

                                </svg>
                                Refresh
                            </button>
                            <button className={s.addActivityBtn} style={{
                                minWidth: '115px',
                                height: currentScreenWidth!! > 768 ? '32px' : '40px',
                            }} onClick={onOpenActivityModal}><img
                                src={plusImg} alt={'plus'} style={{
                                marginRight: '8px',

                            }}/> Activity
                            </button>
                        </div>

                </div>

                <div style={{
                    display: 'flex',
                    alignItems: currentScreenWidth!! > 640 ? 'center' : 'flex-start',
                    marginBottom: '10px',
                    marginTop: currentScreenWidth!! > 640 ? '0' : '10px',
                    flexDirection: currentScreenWidth!! > 640 ? 'row' : 'column'
                }}>
                    <PropertiesSearchInput
                        searchTerm={searchTerm}
                        setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
                        handleSearchInputChange={handleSearchInputChange}
                        isActivity={true}
                    />
                    <div style={{
                        marginLeft: currentScreenWidth!! > 640 ? '30px' : '0',
                        marginBottom: '15px',
                        marginTop: '5px'
                    }}>
                        {/* @ts-ignore */}
                        <DateRangePicker onChange={(value) => onDateChange(value)} ranges={predefinedRanges}
                                         placeholder="Start date - End date"
                                         style={{width: 280}}
                                         defaultCalendarValue={selectedDateRange}
                                         value={selectedDateRange}
                        />
                    </div>
                </div>
                {
                    currentScreenWidth!! > 768
                    &&
                    <PropertiesFilterButtons
                        gridType={'Activities Grid'}
                        shownColumns={shownColumns.filter((c: any) => c.field !== 'ACTIONS' && c.field !== 'COMPANY_REF' && c.field !== 'CONTACT_REF' && c.field !== 'IX_PRIORITY' && c.field !== 'DATE_BEGIN' && c.field !== 'DATE_END')}
                        onResetFilters={onResetFilters}
                        onResetSorting={onResetSorting}
                        isActivity={true}
                    />
                }

                <Box sx={{position: 'relative', width: '100%'}}>
                    <>
                        <PropertiesViewOptions
                            checked={checked}
                            filtersShown={filtersShown}
                            setChecked={(checkedArray: string[]) => setChecked(checkedArray)}
                            setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
                            gridType={'Activities Grid'}
                        />
                        <div style={{
                            position: 'relative',
                            width: '100%',
                        }}>
                            {activitiesGridColumns.map((c: any, index) => {
                                return (
                                    <PropertyFilterModal
                                        gridType={'Activities Grid'}
                                        textValue={c.field}
                                        key={index}
                                    />
                                )
                            })}
                        </div>
                        {
                            isDataLoading
                                ?
                                <div style={{
                                    height: gridContentHeight + 115,
                                    width: '400px',
                                    margin: '0 auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}>
                                    <div style={{
                                        height: '300px',
                                        width: '100%',
                                        textAlign: 'center'
                                    }}>
                                        {/* @ts-ignore */}
                                        <Lottie config={{animationData: animatedLoader, loop: true, height: '60px !important'
                                        }}
                                        />
                                        <p style={{
                                            textAlign: 'center'
                                        }}>{gridLoaderMessage}</p>
                                    </div>
                                </div>
                                :
                                activitiesGridColumns.length
                                    ?
                                    <DataGridPro
                                        getRowClassName={getRowClassName}
                                        rows={loadedRows}
                                        columns={shownColumns.map((column: any, index: number) => ({
                                            ...column,

                                            renderCell: (params: any) => {
                                                return (
                                                    <CustomCell field={column.field}
                                                                value={params.value}
                                                                params={params}
                                                                properties={activitiesDataRowsPaginated}
                                                                key={index}
                                                                dataType={column.dataType}
                                                                onActivityDelete={(e: any) => onOpenDeleteActivityModal(e, params.id)}

                                                    />
                                                )


                                            },
                                            renderHeader: (params: any) => {
                                                return (
                                                    <div style={{
                                                        display: 'flex',
                                                    }}>
                                                        <span
                                                            className={s.columnHeader}>
                                                            {params?.colDef?.headerName?.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}
                                                        </span>
                                                    </div>
                                                );
                                                // }

                                            },
                                        }))}
                                        apiRef={apiRef}
                                        autoHeight={true}
                                        getRowId={e => e.REF}
                                        sortModel={Object.values(localSortModel)}
                                        sortingOrder={['desc', 'asc']}
                                        onSortModelChange={handleSortModelChange}
                                        onStateChange={handleStateChange}
                                        onRowDoubleClick={onRowClick}
                                        disableColumnSelector={true}
                                        // onStateChange={handleStateChange}
                                        {...loadedRows}
                                        rowCount={activitiesDataRowsPaginated.length}
                                        onRowsScrollEnd={handleRowsScrollEnd}
                                        rowHeight={25}
                                        initialState={{pinnedColumns: {left: [], right: ['ACTIONS']}}}
                                        sx={{
                                            borderRight: 'none',
                                            borderLeft: 'none',
                                            borderBottom: 'none',
                                            borderRadius: '0px',
                                            // minHeight: '600px',
                                            // height: height > 950 ? `${gridContentHeight}px !important` : height > 900 ? `${gridContentHeight - 150}px !important` : `${gridContentHeight + 150}px !important`,
                                            "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                                opacity: 0
                                            },
                                            // "& .MuiDataGrid-columnHeader:nth-child(9)": {
                                            //     width: '0 !important',
                                            //     minWidth: '0 !important',
                                            //     maxWidth: '0 !important',
                                            //     display: 'none !important'
                                            // },
                                            // "& .MuiDataGrid-columnHeader:nth-child(10)": {
                                            //     width: '0 !important',
                                            //     minWidth: '0 !important',
                                            //     maxWidth: '0 !important',
                                            //     display: 'none !important'
                                            // },
                                            // "& .MuiDataGrid-columnHeader:nth-child(11)": {
                                            //     width: '0 !important',
                                            //     minWidth: '0 !important',
                                            //     maxWidth: '0 !important',
                                            //     display: 'none !important'
                                            // },
                                            "& .MuiDataGrid-cellContent": {
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                color: '#111827'
                                            },
                                            // "& .MuiDataGrid-cell:nth-child(9)": {
                                            //     width: '0 !important',
                                            //     minWidth: '0 !important',
                                            //     maxWidth: '0 !important',
                                            //     display: 'none !important'
                                            // },
                                            // "& .MuiDataGrid-cell:nth-child(10)": {
                                            //     width: '0 !important',
                                            //     minWidth: '0 !important',
                                            //     maxWidth: '0 !important',
                                            //     display: 'none !important'
                                            // },
                                            // "& .MuiDataGrid-cell:nth-child(11)": {
                                            //     width: '0 !important',
                                            //     minWidth: '0 !important',
                                            //     maxWidth: '0 !important',
                                            //     display: 'none !important'
                                            // },
                                            "& .MuiDataGrid-columnHeaderTitle": {
                                                fontFamily: 'Inter, sans-serif',
                                                fontWeight: 600,
                                                color: '#6B7280',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            },
                                            "& .MuiDataGrid-row:hover": {
                                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                            },
                                            "& .MuiDataGrid-row.Mui-selected": {
                                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                            },
                                            "& .MuiDataGrid-row.Mui-selected:hover": {
                                                backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                            },
                                            "& .MuiDataGrid-cell:focus": {
                                                outline: 'none'
                                            },
                                            "& .MuiDataGrid-toolbarContainer": {
                                                color: '#454545',
                                                position: 'absolute',
                                                top: '-50px',
                                                right: '0px'
                                            },
                                            "& .MuiTablePagination-displayedRows": {
                                                margin: '0',
                                                fontFamily: 'Inter, sans-serif !important',
                                                fontWeight: 400,
                                                color: '#6B7280',
                                                fontSize: '12px',
                                                lineHeight: '20px',
                                            },
                                            "& .MuiPagination-root": {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                padding: '15px 0px'
                                            },
                                            "& .MuiButtonBase-root.Mui-selected": {
                                                backgroundColor: '#eff1f3',
                                                borderRadius: '0px'
                                            },
                                            "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                width: '40px',
                                                height: '40px',
                                                margin: 0,
                                                borderRight: '1px solid #D0D5DD',
                                                borderLeft: '1px solid #D0D5DD',
                                                borderTop: 'none',
                                                borderBottom: 'none',
                                                fontFamily: 'Inter, sans-serif !important',
                                                fontWeight: 500,
                                                color: '#1D2939',
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                borderRadius: '0px'
                                            },
                                            "& .MuiPagination-ul": {
                                                border: '1px solid #D0D5DD',
                                                borderRadius: '8px'
                                            },
                                            "& .MuiDataGrid-row": {
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)'
                                            },
                                            "& .MuiDataGrid-row:nth-of-type(odd)": {
                                                backgroundColor: '#F9FAFB',

                                            },
                                            "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                borderRadius: '0px',
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-columnHeaders": {
                                                backgroundColor: '#FCFDFE'
                                            },
                                            "& .MuiDivider-root": {
                                                display: 'none',
                                                height: '0',
                                                width: '0',
                                                opacity: '0 !important',
                                                borderColor: '#fff '
                                            },
                                            "& .MuiBox-root": {
                                                height: 'auto'
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderBottom: 'none'
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                overflowY: 'scroll !important',
                                                overflowX: 'scroll',
                                                height: `${gridContentHeight - 125}px !important`,
                                                minHeight: 'auto',
                                            },
                                            "& .MuiDataGrid-virtualScrollerContent": {},
                                            "& .MuiDataGrid-pinnedColumnHeaders--right": {
                                                paddingRight: '17px !important'
                                            }
                                        }}
                                        components={{
                                            Footer: () =>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: currentScreenWidth! > 768 ? 'space-between' : 'center',
                                                    alignItems: 'center',
                                                    width: '100%'
                                                }}>
                                                    {
                                                        currentScreenWidth! > 768
                                                        &&
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}>
                                                            <p
                                                                className={s.footerText}
                                                            >
                                                                Rows per page:
                                                            </p>
                                                            <Select
                                                                value={rowsPerPage}
                                                                onChange={handleRowsPerPageChange}
                                                                className={s.rowsPerPage}
                                                            >
                                                                {rowsPerPageOptions.map((option) => (
                                                                    <Select.Option key={option} value={option}>
                                                                        {option}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    }
                                                    <Pagination
                                                        count={Math.ceil(gridFilters.length ? (rows.length / rowsPerPage) : (defaultActivitiesGridRows.length / rowsPerPage))}
                                                        page={page}
                                                        onChange={handlePageChange}
                                                        variant="outlined"
                                                        shape="rounded"
                                                    />
                                                    {
                                                        currentScreenWidth! > 768
                                                        &&
                                                        <p className={s.footerText}>
                                                            Total{gridFilters.length ? '/Filtered' : ''} requirements: {gridFilters.length ? defaultActivitiesGridRows.length : rows.length}{gridFilters.length ? `/${rows.length}` : ''}
                                                        </p>
                                                    }
                                                </div>,
                                            ColumnMenu: (props) => {
                                                return (
                                                    <GridColumnMenu
                                                        hideMenu={() => {
                                                        }}
                                                        currentColumn={
                                                            {
                                                                field: props.currentColumn.field,
                                                                filterable: false,
                                                                hideable: false,
                                                            }
                                                        }
                                                        open={true}
                                                    />
                                                )
                                            },

                                        }}
                                    />
                                    :
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: `${gridContentHeight + 30}px`
                                    }}>
                                        <Empty/>
                                    </div>

                        }

                    </>
                </Box>
                {
                    isMobileMenuOpened
                    &&
                    <MobileFiltersMenu
                        checkedViewOptions={checked}
                        filtersShown={true}
                        setCheckedViewOptions={setChecked}
                        setFiltersShown={setFiltersShown}
                        gridType={'Activities Grid'}
                        shownColumns={shownColumns}
                        hiddenColumns={hiddenColumns}
                        userData={userData}
                        today={today}
                        threeMonthsAgo={threeMonthsAgo}
                        gridColumns={activitiesGridColumns}
                        setSearchTerm={setSearchTerm}
                        setLocalSortModel={setLocalSortModel}
                        localSortModel={localSortModel}
                        handleFiltersShownClick={handleFiltersShownClick}
                        resetFilters={onResetFilters}
                    />
                }
            </div>
            {
                isAddActivityModalOpened && <ActivitiesGridModal/>
            }
            {
                deleteModalOpened
                &&
                <div className={s.popConfirm}>
                    <div className={s.popConfirm__content} style={{
                        height: 'auto'
                    }}>
                        <DeleteActivityIcon/>
                        <p className={s.popConfirm__deleteTitle}>Delete Activity {Number(localActivityRef)}</p>
                        <p className={s.popConfirm__deleteText}>Are you sure you want to delete this activity? This
                            action cannot be undone.</p>
                        {
                            isPasswordNeeded === 1
                            &&
                            <div className={s.popConfirm__item}>
                                <label>Password</label>
                                <Input onChange={onSetPassword} type='password' style={{
                                    height: '44px',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                    border: '1px solid #D0D5DD',
                                    borderRadius: '8px'
                                }}/>
                            </div>
                        }

                        <div className={s.popConfirm__deleteBtns}>
                            <Button
                                type='default'
                                style={{
                                    width: '170px',
                                    height: '44px',
                                }}
                                onClick={() => setDeleteModalOpened(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='primary'
                                style={{
                                    height: '44px',
                                    backgroundColor: '#D92D20',
                                    color: '#fff',
                                    width: '170px',
                                    border: '1px solid #D92D20',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}
                                onClick={onDeleteActivities}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>

            }
        </div>


    );
};


const CustomCell = React.memo(({
                                   value,
                                   field,
                                   onActivityDelete
                               }: any) => {

    if (field === 'ACTIONS') {
        return <button
            className={s.deleteBtn}
            onClick={onActivityDelete}
        >
            Delete
        </button>
    } else {
        return (

            <div>{value}</div>
        )
    }


});

export default ActivitiesGrid;


