import React from 'react';
import s from "../EditProperty.module.scss";
import {Input, Select} from "antd";
import {
    onChangeIsAddressModalOpened, onUpdateBrokeragePropertyField,
    selectBrokerageUpdatedPropertyFields,
    selectCountriesArray
} from "../../../../store/propertiesReducer";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {selectPropertiesTypes} from "../../../../store/utilityReducer";

interface EditAddressTabProps {
    fieldsWithError: boolean
}

const EditAddressTab = ({fieldsWithError} : EditAddressTabProps) => {
    const countries = useAppSelector(selectCountriesArray)
    const dispatch = useAppDispatch()
    const formFields = useAppSelector(selectBrokerageUpdatedPropertyFields)
    const propertiesTypes = useAppSelector(selectPropertiesTypes)

    const countryOptions = countries.map((a: any) => ({
        value: a.cntry_desc_default,
        label: a.cntry_desc_default,
    }))
    const domainStatuses = [
        {
            value: 1, label: 'Inactive'
        },
        {
            value: 2, label: 'Active'
        }
    ]
    const propertiesTypesValues = propertiesTypes.map((type: { id: number, value: string }) => ({
        value: `${type.id}`,
        label: `${type.value}`,
    }))


    const handleStreetAddressChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const value = e.target.value;
        if (/^[0-9]{0,5}$/.test(value)) {
            onFormInputChange(e, inputName);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        const isValid = /^[/-]*$/.test(newValue); // Regex to allow only '/' and '-'

        if (isValid) {
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'address.addr_no_connect', value: e.target.value}))
        }
    };

    const onChangeDomainStatus = (value: number, domain: string) => {
        if(domain === 'status_cd'){
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'status_cd', value: value}))
        }
        else if(domain === 'status_or'){
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'status_or', value: value}))
        }
        else if(domain === 'status_rr'){
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'status_rr', value: value}))
        }
        else{
            dispatch(onUpdateBrokeragePropertyField({fieldKey: 'status_rs', value: value}))
        }
    }

    const onFormInputChange = (e: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        const {value} = e.target;
        dispatch(onUpdateBrokeragePropertyField({fieldKey: inputName, value: value}))
    }

    const onFormSelectChange = (value: string, inputName: string) => {
        dispatch(onUpdateBrokeragePropertyField({fieldKey: inputName, value: Number(value)}))
    }

    return (
        <>
            <div className={s.form}>
                <div className={s.form__rowDouble}>
                    <div className={s.form__row} style={{
                        width: '493px',
                        marginRight: '32px',
                        minWidth: '493px'
                    }}>
                        <label>Short desc.</label>
                        <Input placeholder={'Enter short desc.'}
                               value={formFields.description!!}
                               onChange={(e) => onFormInputChange(e, 'description')}/>
                    </div>
                    <div className={s.form__row} style={{
                        maxWidth: '493px',
                        minWidth: '493px'
                    }}>
                        <label>Property type</label>
                        <Select
                            onChange={(e) => onFormSelectChange(e, 'class_index')}
                            style={{width: '100%'}}
                            placeholder={'Select type'}
                            className={'reqCreateFormSelect'}
                            value={propertiesTypesValues.find(
                                (d) => Number(d.value) === Number(formFields.class_index)
                            )?.label}
                        >
                            {
                                propertiesTypesValues.map((d: { value: string, label: string }) => {
                                    return (
                                        <option
                                            key={d.value}
                                            value={d.value}
                                        >
                                            {d.label}
                                        </option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
                <div className={s.form__rowDouble}>
                    <div className={s.form__row} style={{
                        width: '493px',
                        marginRight: '32px',
                        minWidth: '493px'
                    }}>
                        <label>Country<span>*</span></label>
                        <Select
                            showSearch
                            placeholder="Select country"
                            value={countryOptions.find((c) => c.value === formFields.address.cntry_desc_local)?.label || null}
                            onChange={(value) => onFormSelectChange(value !== undefined ? value : null, 'address.cntry_desc_local')}
                            style={{ width: '100%' }}
                            className={'reqCreateFormSelect'}
                            options={countryOptions}
                            optionFilterProp="label"
                            allowClear={true}
                            filterOption={(input, option) =>
                                option?.label.toLowerCase().includes(input.toLowerCase())
                            }
                        />
                        {(formFields.address.cntry_desc_local === null || Number(formFields.address.cntry_desc_local) === 0) && fieldsWithError &&
                            <p className={s.form__error}>Please select country!</p>}
                    </div>
                    <div className={s.form__row} style={{
                        width: '493px',
                        minWidth: '493px'
                    }}>
                        <label>State</label>
                        <Input placeholder={'Enter state'}
                               value={formFields.address.addr_state_admin_level_1!!}
                               onChange={(e) => onFormInputChange(e, 'address.addr_state_admin_level_1')}/>
                    </div>
                </div>
                <div className={s.form__rowDouble}>
                    <div className={s.form__row} style={{
                        width: '493px',
                        marginRight: '32px',
                        minWidth: '493px'
                    }}>
                        <label>City<span>*</span></label>
                        <Input placeholder={'Enter city'}
                               value={formFields.address.addr_city!!}
                               onChange={(e) => onFormInputChange(e, 'address.addr_city')}/>
                        {(formFields.address.addr_city === null || formFields.address.addr_city === '') && fieldsWithError &&
                            <p className={s.form__error}>Please enter your city!</p>}
                    </div>
                    <div className={s.form__row} style={{
                        width: '142px',
                        minWidth: '142px',
                        marginRight: '16px'
                    }}>
                        <label>Post code<span>*</span></label>
                        <Input placeholder={'Enter post code'}
                               value={formFields.address.addr_zip!!}
                               onChange={(e) => onFormInputChange(e, 'address.addr_zip')}/>
                        {(formFields.address.addr_zip === null || formFields.address.addr_zip === '') && fieldsWithError &&
                            <p className={s.form__error}>Please enter your post code!</p>}
                    </div>
                    <div className={s.form__row}>
                        <label>House number</label>
                        <div style={{
                            display: 'flex',
                            gap: '4px'
                        }}>
                            <Input
                                value={formFields.address.addr_nf_no_from!!}
                                style={{
                                    width: '69px',
                                    minWidth: '69px'
                                }}
                                onChange={(e) => handleStreetAddressChange(e, 'address.addr_nf_no_from')}/>
                            <Input
                                style={{
                                    width: '72px',
                                    minWidth: '72px'
                                }}
                                value={formFields.address.addr_no_from_rest!!}
                                onChange={(e) => onFormInputChange(e, 'address.addr_no_from_rest')}/>
                            <Input
                                style={{
                                    width: '38px',
                                    minWidth: '38px'
                                }}
                                value={formFields.address.addr_no_connect!!}
                                placeholder={'/'}
                                onChange={(e) => handleInputChange(e)}/>
                            <Input
                                style={{
                                    width: '72px',
                                    minWidth: '72px'
                                }}
                                value={formFields.address.addr_nf_no_to!!}
                                onChange={(e) => handleStreetAddressChange(e, 'address.addr_nf_no_to')}/>
                            <Input
                                style={{
                                    width: '69px',
                                    minWidth: '69px'
                                }}
                                value={formFields.address.addr_no_to_rest!!}
                                onChange={(e) => onFormInputChange(e, 'address.addr_no_to_rest')}/>
                        </div>
                    </div>
                </div>
                <div className={s.form__row} style={{
                    width: '1017px',
                }}>
                    <label>Street<span>*</span></label>
                    <Input placeholder={'Enter street address'}
                           value={formFields.address.addr_street !== null ? formFields.address.addr_street!.replace(/[\d/]+/g, '')!! : ''}
                           onChange={(e) => onFormInputChange(e, 'address.addr_street')}/>
                    {(formFields.address.addr_street === null || formFields.address.addr_street === '') && fieldsWithError &&
                        <p className={s.form__error}>Please enter your street address!</p>}
                </div>
            </div>
            <p className={s.mapPin} onClick={() => dispatch(onChangeIsAddressModalOpened(true))}>Map Pin</p>
            <div style={{
                display: 'flex',
                width: '100%',
                gap: '32px'
            }}>
                {
                    formFields.status_cd !== null
                    &&
                    <div style={{
                        width: '230px',
                    }}>

                        <div className={s.form__row}>
                            <label>Status (CD)</label>
                            <Select
                                onChange={(e) => onChangeDomainStatus(e, 'status_cd')}
                                style={{width: '100%'}}
                                placeholder={'Select domain status'}
                                options={domainStatuses}
                                className={'reqCreateFormSelect'}
                                value={formFields.status_cd!!}
                            />
                        </div>
                    </div>
                }
                {
                    formFields.status_or !== null
                    &&
                    <div style={{
                        width: '230px',
                    }}>

                        <div className={s.form__row} >
                            <label>Status (OR)</label>
                            <Select
                                onChange={(e) => onChangeDomainStatus(e, 'status_or')}
                                style={{width: '100%'}}
                                placeholder={'Select domain status'}
                                options={domainStatuses}
                                className={'reqCreateFormSelect'}
                                value={formFields.status_or!!}
                            />
                        </div>
                    </div>
                }
                {
                    formFields.status_rr !== null
                    &&
                    <div style={{
                        width: '230px',
                    }}>

                        <div className={s.form__row}>
                            <label>Status (RR)</label>
                            <Select
                                onChange={(e) => onChangeDomainStatus(e, 'status_rr')}
                                style={{width: '100%'}}
                                placeholder={'Select domain status'}
                                options={domainStatuses}
                                className={'reqCreateFormSelect'}
                                value={formFields.status_rr!!}
                            />
                        </div>
                    </div>
                }
                {
                    formFields.status_rs !== null
                    &&
                    <div style={{
                        width: '230px',
                    }}>

                        <div className={s.form__row}>
                            <label>Status (RS)</label>
                            <Select
                                onChange={(e) => onChangeDomainStatus(e, 'status_rs')}
                                style={{width: '100%'}}
                                placeholder={'Select domain status'}
                                options={domainStatuses}
                                className={'reqCreateFormSelect'}
                                value={formFields.status_rs!!}
                            />
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default EditAddressTab;